<div #grid class="grid">
  @if (widgetSettings) {
  <div #worldwatch class="worldwatch-carousel">
    <div *ngIf="widgetSettings.worldwatch.display" class="widget-section">
      <app-worldwatch-carousel [theme]="widgetSettings.worldwatch.theme"></app-worldwatch-carousel>
    </div>
  </div>
  <div #recentSupportActivity class="recent-support-activity">
    <div *ngIf="widgetSettings.recentSupportActivity.display" class="widget-section">
      <app-recent-support-activity [theme]="widgetSettings.recentSupportActivity.theme"></app-recent-support-activity>
    </div>
  </div>
  <div #latestActivity class="latest-activity">
    <div *ngIf="widgetSettings.latestActivity.display" class="widget-section">
      <app-latest-activity [theme]="widgetSettings.latestActivity.theme"></app-latest-activity>
    </div>
  </div>
  <div #pendingCasesOcd class="pending-cases-ocd">
    <div *ngIf="widgetSettings.pendingCasesOcd.display" class="widget-section">
      <app-pending-cases-ocd [theme]="widgetSettings.pendingCasesOcd.theme"></app-pending-cases-ocd>
    </div>
  </div>
  <div #openCases class="open-cases">
    <div *ngIf="widgetSettings.openCases.display" class="widget-section">
      <app-open-cases [theme]="widgetSettings.openCases.theme"></app-open-cases>
    </div>
  </div>
  <div #trueFalsePositives class="trueFalsePositives">
    <div *ngIf="widgetSettings.truePositivesVSFalsePositives.display" class="widget-section">
      <app-true-positives-vs-false-positives [theme]="widgetSettings.truePositivesVSFalsePositives.theme"></app-true-positives-vs-false-positives>
    </div>
  </div>
  }
</div>


<div [ngClass]="['rounded', 'p-3', 'mb-3', isDarkThemeEnabled ? 'bg-black' : 'bg-white']" [attr.style]="isDarkThemeEnabled ? 'background-color: rgb(20,20,20) !important;': ''">
    @if ((actionRoute && actionTitle) || title) {
        <div
        class="d-flex justify-content-between flex-wrap align-items-center mb-2 mb-lg-4">
        @if (title) {
            <h3 class="m-0">
                {{ title | translate }}
            </h3>
        }
        <!-- Link to another page -->
        @if (actionRoute && actionTitle) {
            <a class="link-chevron" [routerLink]="actionRoute">{{
                actionTitle | translate
            }}</a>
        }
        <!-- Optional children for those who need actions on the top bar -->
        <ng-content select="[action]" />
        </div>
    }
    
    <ng-content />
</div>


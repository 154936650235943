<app-card title="pages.cybersocxdr.last24h.last24HoursInformations" actionTitle="pages.cybersocxdr.last24h.viewAllIncidents" actionRoute="/xtended-soc/incidents">
  <div class="row">
    <div class="d-flex gap-3">
      <div class="fw-bold">
        <span class="badge rounded-pill fs-5 text-bg-primary">{{
          last24HData?.criticalIncidents ?? ''
        }}</span>
        {{ 'pages.cybersocxdr.last24h.criticalIncidents' | translate }}
      </div>
      <div class="fw-bold">
        <span class="badge rounded-pill fs-5 text-bg-primary">{{
          last24HData?.newIncidents ?? ''
        }}</span>
        {{ 'pages.cybersocxdr.last24h.newIncidents' | translate }}
      </div>
    </div>
  </div>
</app-card>


<form>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ 'modals.saveFilter.title' | translate }}</h4>
    <button type="button" class="btn-close" (click)="close()"><span class="visually-hidden"></span></button>
  </div>
  <div class="modal-body">

    <div class="d-flex radio">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="create"
          [(ngModel)]="radioAction">
        <label class="form-check-label" for="flexRadioDefault1">
          {{ 'modals.saveFilter.createFilter' | translate }}
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="update"
          [(ngModel)]="radioAction">
        <label class="form-check-label" for="flexRadioDefault2">
          {{ 'modals.saveFilter.updateFilter' | translate }}
        </label>
      </div>
    </div>

    <div *ngIf="radioAction === 'create'">
      <label for="reference">{{ 'modals.saveFilter.filterName' | translate }}</label>
      <input type="text" class="form-control" name="filterName" aria-describedby="filterName"
        [(ngModel)]="filterName" />
    </div>

    <div *ngIf="radioAction === 'update'">
      <label for="reference">{{ 'modals.saveFilter.selectFilter' | translate }}</label>
      <select class="form-control" name="search-select" aria-label="Default select example"
        [(ngModel)]="selectedFilter">
        <option *ngFor="let search of params.savedSearches" [ngValue]="search">{{search.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="radioAction === 'create'" type="button" class="btn btn-primary" [disabled]="!filterName"
      (click)="success()">
      {{ 'pages.generic.save' | translate }}
    </button>
    <button *ngIf="radioAction === 'update'" type="button" class="btn btn-primary" (click)="success()">
      {{ 'pages.generic.update' | translate }}
    </button>
    <button type="button" class="btn btn-secondary" (click)="close()">
      {{ 'pages.generic.close' | translate }}
    </button>
  </div>
</form>

import { Component, Input } from '@angular/core';
import { PointOptionsObject } from 'highcharts';

@Component({
  selector: 'app-4A3W-preview',
  templateUrl: './four-a-three-w-preview.component.html',
})
export class FourAThreeWPreviewComponent {
  @Input()
  actions: PointOptionsObject[];
}

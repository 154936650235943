<!-- content -->
@if (displayTimeLineChart) {
<div class="content">

    <div class="row pt-5">
        <div class="col-12 text-center">
            <h3>{{ 'pages.securitycase.casesTimeline' | translate }}
                <app-help message="{{ 'pages.securitycase.caseTimelineDesc' | translate }}"></app-help>
            </h3>
        </div>

    </div>
    <div class="row p-0" [ngClass]="{'selected': pointSelected}">
        <div class="col-2 text-center p-0">
            <div class="heading-div">
                <p class="heading-paragraphs" [ngClass]="{'active': pointSelected?.yIndex === 0}"><span class="label">{{
                        'pages.securitycase.customer' | translate }}</span></p>
                <p class="heading-paragraphs" [ngClass]="{'active': pointSelected?.yIndex === 1}"><span class="label">{{
                        'pages.securitycase.orangeCyberdefense' | translate }}</span></p>
            </div>
        </div>
        <div class="col-10">

            <highcharts-chart
                [Highcharts]="highcharts"
                [options]="timeLineChart"
                [(update)]="updateDonutFlag"
                class="timeline-chart defaultChart">
            </highcharts-chart>

            <nav class="stepped-process mt-2" [ngClass]="{'stepped-process-dark': isDarkTheme}"
                aria-label="Checkout process">
                <p class="float-start mt-2 me-2 fw-bold d-sm-none">Step</p>
                <ol>
                    <li class="stepped-process-item" [ngClass]="{'active': pointSelected?.xIndex === 0}">
                        <span class="stepped-process-link" alt="Under Investigation" title="Under Investigation">{{
                            'pages.securitycase.underInvestigation' | translate }}</span>
                    </li>
                    <li class="stepped-process-item" [ngClass]="{'active': pointSelected?.xIndex === 1}">
                        <span class="stepped-process-link" alt="Escalated" title="Escalated">{{
                            'pages.securitycase.escalated' | translate }}</span>
                    </li>
                    <li class="stepped-process-item" [ngClass]="{'active': pointSelected?.xIndex === 2}">
                        <span class="stepped-process-link" alt="Pending OCD" title="Pending OCD">{{
                            'pages.securitycase.pendingOcd' | translate }}</span>
                    </li>
                    <li class="stepped-process-item" [ngClass]="{'active': pointSelected?.xIndex === 3}">
                        <span class="stepped-process-link" alt="Closed" title="Closed">{{
                            'pages.securitycase.closed' | translate }}</span>
                    </li>
                </ol>
            </nav>
        </div>

        @if (showCasesTable && showCases.length > 0) {
        <div class="row pt-3" *ngIf="showCasesTable && showCases.length > 0">
            <div class="row">
                <div class="col-12">

                    <div class="row faketableheaderrow">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 faketableheader"
                            (click)="sortResults('caseNumber');">
                            {{'pages.support.dashboard.fieldTitles.reference' | translate}} <fa-icon
                                [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block faketableheader"
                            (click)="sortResults('subject');">
                            {{'pages.support.dashboard.fieldTitles.subject' | translate}} <fa-icon
                                [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 faketableheader"
                            (click)="sortResults('priority');">
                            {{'pages.support.dashboard.fieldTitles.priority' | translate}} <fa-icon
                                [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-4 col-4 faketableheader" (click)="sortResults('status');">
                            {{'pages.support.dashboard.fieldTitles.status' | translate}} <fa-icon
                                [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                        </div>
                        <div class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader" (click)="sortResults('type');">
                            {{'pages.support.dashboard.fieldTitles.type' | translate}} <fa-icon [icon]="['fas', 'sort']"
                                class="p-1"></fa-icon>
                        </div>
                        <div class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader"
                            (click)="sortResults('createdDate');">
                            {{'pages.support.dashboard.fieldTitles.created' | translate}} <fa-icon
                                [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                        </div>
                    </div>

                    @for (case of showCases; track case.caseId) {
                    <div class="row faketablerow"
                        [routerLink]="['/support/view/' + case.id]" routerLinkActive="activeLink">
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 faketablecell">
                            {{case.caseNumber}}
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 d-none d-md-block faketablecell">
                            {{case.subject}}
                        </div>
                        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 faketablecell">
                            {{case.priority}}
                        </div>
                        <div class="col-xl-2 col-lg-2 col-sm-4 col-4 faketablecell">
                            {{case.status}}
                        </div>
                        <div class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">
                            {{case.type}}
                        </div>
                        <div class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">
                            {{case.createdDate}}
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
        }
    </div>
</div>
}
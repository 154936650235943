import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/shared/services/context-service';
import { DateTime } from 'luxon';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { Router } from '@angular/router';
import { Severity } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { SLANotification } from 'src/app/shared/model/cybersocxdr/facetsValues';

@Component({
  selector: 'app-route-withfacets',
  templateUrl: './route-withfacets.component.html',
})
export class RouteWithfacetsComponent {
  @Input() title?: string;

  /* The data to pass for a link to appear on the card, alternatively you can pass a children named action */
  @Input() actionTitle?: string;
  @Input() countOlderThan1Month?: number;
  @Input() actionRoute?: string;

  isDarkThemeEnabled: boolean;
  constructor(contextService: ContextService, public globalFilters: XtendedFiltersService, private router: Router) {
    this.isDarkThemeEnabled = contextService.isDarkTheme();
  }

  changeCreatedFromDate() {
    const today = DateTime.now();

    const startDate = today.minus({ months: 7 });
    const endDate = today.minus({ months: 1 });

    const formattedStartDate = startDate.toFormat('yyyy-MM-dd');
    const formattedEndDate = endDate.toFormat('yyyy-MM-dd');
    this.globalFilters.searchCriteria.createdFrom = formattedStartDate;
    this.globalFilters.searchCriteria.createdTo = formattedEndDate;
    this.globalFilters.serializeSeachCriteria();
    this.globalFilters.searchCriteria.severitys = [Severity.HIGH, Severity.CRITICAL];
    this.globalFilters.searchCriteria.slaNotification = [
      SLANotification.BREAK,
      SLANotification.AT_RISK,
      SLANotification.IN,
    ];
  }

  changeCreatedFromDateAndNavigate() {
    this.changeCreatedFromDate();
    if (this.actionRoute) {
      this.router.navigate([this.actionRoute]);
    }
  }
}

<div
  [ngClass]="[contextService.isDashboard ? 'bg-black' : contextService.bgTheme, hasPadding ? 'notifications' : 'no-padding-notifications']">

  <!-- GP GLOBAL NOTIFICATION -->
  <div *ngFor="let alert of gpAlerts" class="d-flex align-items-center"
    [class]="['alert', 'alert-' + alert.category, 'alert-dismissible', 'fade', 'show']" role="alert">
    <span class="alert-icon"><span class="visually-hidden">Error</span></span>
    <div class="gp-notifications">
      <span>{{alert.title}}</span>
      <p [innerHtml]="alert.message"></p>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="alert" (click)="closeGPAlert(alert)">
      <span class="visually-hidden">Close</span>
    </button>
  </div>

  <!-- SESSION NOTIFICATION -->
  <div *ngFor="let alert of alertService.alerts"
    [class]="['alert', 'alert-' + alert.type, 'alert-dismissible', 'fade', 'show']" (click)="closeAlert(alert)"
    role="alert">
    <span class="alert-icon"><span class="visually-hidden">Error</span></span>
    <p [innerHtml]="alert.message"></p>
    <button type="button" class="btn-close" data-bs-dismiss="alert">
      <span class="visually-hidden">Close</span>
    </button>
  </div>

  <!-- TOAST -->
  <div class="toast-container position-fixed bottom-0 end-0 p-3">
    <ng-container #toastContainer></ng-container>
  </div>

</div>
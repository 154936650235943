import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ItsmService } from '../../services/itsm.service';
import { SupportCaseSearchCriteria } from '../../model/searchCriteria';
import { Router } from '@angular/router';
import { Case } from '../../model/itsm';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AppSharedModule } from 'src/app/app-shared.module';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { constants } from '../../constants';

@Component({
  standalone: true,
  selector: 'app-search-bar',
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    TranslateModule,
    InfiniteScrollModule,
    AppSharedModule,
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: './search-bar.component.scss',
})
export class SearchBarComponent implements OnInit, OnDestroy {

  private offset = 0;
  private limit = constants.recordsPerPage.searchBar;
  private caseCount = 0;
  private searchTermsSubject: Subject<string> = new Subject<string>();
  private searchTermsSubscription: Subscription;
  //minimum time interval between search requests. Avoid to trigger search at each keyup whil typing the search terms
  private debounceTime = 500;

  public cases: Case[] = [];
  public inputValue: string = '';
  public selectedCaseIndex = 0;
  public searchCanvas = false;
  public resultsLoading = false;
  public randomValue = 0;

  
  @HostListener('keydown', ['$event'])
  handleKeyEvent(event: KeyboardEvent) {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      // prevent native scroll since it's specifically handle below
      event.preventDefault();
    }
    switch(event.key) {
      case 'Escape': this.closeSearchPanel(); // allow to close search panel using escape
        break;
      case 'Enter': this.goToSelectedCase(); // go to selected case
        break;
      case 'ArrowUp': this.keyScroll(event.key); // scroll up
        break;
      case 'ArrowDown': this.keyScroll(event.key); // scroll down
        break;
    }
  }
  
  constructor(private itsmService: ItsmService, private router: Router) { }

  ngOnInit(): void {
    this.randomValue = Math.floor(Math.random() * 1000);

    // Observable used for being able to use a debounce on input
    this.searchTermsSubscription = this.searchTermsSubject
      .pipe( debounceTime(this.debounceTime) )
      .subscribe((term) => { this.initSearch(term) });
  }

  ngOnDestroy(): void {
    this.searchTermsSubscription.unsubscribe();
  }

  public triggerSearch(term: string) {
    this.searchTermsSubject.next(term);
  }

  private initSearch(term: string) {
    this.cases = [];
    this.selectedCaseIndex = 0;
    const searchCriteria = new SupportCaseSearchCriteria();
    searchCriteria.likeSearch = term;
    searchCriteria.SNCount = true;
    this.itsmService.caseSearch(searchCriteria).then( count => {
      this.searchCanvas = true;
      this.resultsLoading = true;
      this.caseCount = count;
      this.offset = this.cases.length;
      this.search(term).then( res => {
        this.resultsLoading = false;
        this.cases = this.cases.concat(...res);
      });
    });
  }

  public onScroll() {
    if (this.cases.length < this.caseCount) {
      this.search(this.inputValue).then( res => {
        this.resultsLoading = false;
        this.cases = this.cases.concat(...res)
      });
    }
  }

  private keyScroll(key: 'ArrowDown' | 'ArrowUp') {
    let selectedCaseElement: HTMLElement;
    let indexToFocus = 3;
    const nbDisplayedCases = 6; 

    // go through the results list using arrow up and arrow down
    if (key === 'ArrowUp') {
      this.selectedCaseIndex = this.selectedCaseIndex > 0 ? this.selectedCaseIndex - 1 : this.cases.length-1;
    } else {
      this.selectedCaseIndex = this.selectedCaseIndex < this.cases.length-1 ? this.selectedCaseIndex + 1 : 0;
    }
    
    // focus the scroll to see selected element on top until scroll reaches the last nbDisplayedCases results. Then focus stays still.
    indexToFocus = this.selectedCaseIndex < this.cases.length - nbDisplayedCases ? this.selectedCaseIndex : (this.caseCount-1) - nbDisplayedCases;
    selectedCaseElement = document.getElementById('caseIndex_' + indexToFocus) ?? document.getElementById('caseIndex_' + 0);
    selectedCaseElement.scrollIntoView();
  }

  private search(term: string): Promise<Case[]> {
    if (!term.trim()) {
      // prevent to skip these step in the subscribe answer
      this.resultsLoading = false;
      // if not search term, return empty observable.
      return Promise.resolve([]);
    }
    
    const searchCriteria = new SupportCaseSearchCriteria();
    searchCriteria.likeSearch = term;
    searchCriteria.skip = this.offset;
    searchCriteria.limit = this.limit;
    
    return this.itsmService.caseSearch(searchCriteria);
  }

  public focusSearch() {
    document.getElementById('inputSearch').focus();
  }

  private goToSelectedCase() {
    this.goToCase(this.cases[this.selectedCaseIndex].id)
  }

  public goToCase(caseId: string) {
    // navigateByUrl alone won't reload page if already on support/view page
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/support/view/${caseId}`])});
    this.closeSearchPanel();
  }

  public closeSearchPanel() {
    this.searchCanvas = false;
    this.inputValue = '';
    this.cases = [];
  }

}

<div class="row mb-3">
    <div class="col-12 pt-2">
        <h3>{{ 'pages.securitycase.benchmarking.incidentsByActors' | translate }}</h3>
        <div class="btn-group graph" role="group" aria-label="Toggle view">
            <ng-template #graphView>{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</ng-template>
            <ng-template #boxView>{{ 'pages.securitycase.benchmarking.showBoxFormat' | translate }}</ng-template>
            <ng-template #columnView>{{ 'pages.securitycase.benchmarking.showColumnView' | translate }}</ng-template>
            <button class="btn btn-icon" [ngbTooltip]="columnView" (click)="tasView = 'column'; resetCases()"
                [ngClass]="{'active':tasView === 'column' }">
                <fa-icon [icon]="['fas', 'chart-bar']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showColumnView' | translate }}</div>
            </button>
            <button class="btn btn-icon" [ngbTooltip]="graphView" (click)="tasView = 'ring'; resetCases()"
                [ngClass]="{'active':tasView === 'ring' }">
                <fa-icon [icon]="['fas', 'chart-pie']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</div>
            </button>
            <button class="btn btn-icon" [ngbTooltip]="boxView" (click)="tasView = 'box'; resetCases()"
                [ngClass]="{'active':tasView === 'box' }">
                <fa-icon [icon]="['fas', 'sort-numeric-up-alt']"size="2x"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showBoxFormat' | translate }}</div>
            </button>
        </div>
    </div>
</div>
<div class="row" *ngIf="tasView === 'column'">
    <div class="col-12">
        <div class="row">
        </div>
        <div class="row">
            <div class="col-12 text-center">
                <highcharts-chart [Highcharts]="Highcharts" [options]="columnComparisonChartOptions"
                    [(update)]="updateDonutFlag" [oneToOne]=true
                    style="width: 100%; height: 400px; display: block;"></highcharts-chart>
            </div>
        </div>

    </div>
    <div class="col-12 text-center">
        <app-incident-panel [showCases]="showIncidentByActorsColumnCases"></app-incident-panel>
    </div>
</div>
<div class="row" *ngIf="tasView === 'ring'">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

        <div class="row pt-5">
            <div class="col-12 text-center">
                <h6>{{currentPeriodNameExpanded}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">

                <highcharts-chart [Highcharts]="Highcharts" [options]="actorDonutChartOptions"
                    [(update)]="updateDonutFlag" [oneToOne]=true
                    style="width: 100%; height: 400px; display: block;"></highcharts-chart>

            </div>
        </div>

    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center">

        <div class="row pt-5">
            <div class="col-12 text-center">
                <h6>{{previousPeriodNameExpanded}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">

                <highcharts-chart [Highcharts]="Highcharts" [options]="previousActorDonutChartOptions"
                    [(update)]="updateDonutFlag" [oneToOne]=true
                    style="width: 100%; height: 400px; display: block;"></highcharts-chart>

            </div>
        </div>

    </div>
    <div class="col-12 text-center">
        <app-incident-panel [showCases]="showIncidentByActorsGraphCases"></app-incident-panel>
    </div>
</div>
<div class="row" *ngIf="currentCases && tasView === 'box'">
    <div class="lines col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

        <div class="row pt-5">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{currentPeriodName === 'last30days' ? 'Last 30 days' : 'Last full calendar month'}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="currentData['total']" [icon]="totalTrend">
                </app-data-boxes>

            </div>


            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'external')">
                <app-data-boxes [className]="'crit'" [heading]="'External'" [count]="currentData['external']"
                    [icon]="externalTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'internal')">
                <app-data-boxes [className]="'high'" [heading]="'Internal'" [count]="currentData['internal']"
                    [icon]="internalTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'partners')">
                <app-data-boxes [className]="'medium'" [heading]="'Partners'" [count]="currentData['partners']"
                    [icon]="partnersTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'unknown')">
                <app-data-boxes [className]="'low'" [heading]="'Unknown'" [count]="currentData['unknown']"
                    [icon]="unknownTrend">
                </app-data-boxes>
            </div>

        </div>
        <div class="row pt-5">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{previousPeriodName === 'previous30days' ? 'Previous 30 days' : 'Previous full calendar month'}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="previousData['total']">
                </app-data-boxes>
            </div>


            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByPeriod('previous', 'external')">
                <app-data-boxes [className]="'crit'" [heading]="'External'" [count]="previousData['external']">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByPeriod('previous', 'internal')">
                <app-data-boxes [className]="'high'" [heading]="'Internal'" [count]="previousData['internal']">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByPeriod('previous', 'partners')">
                <app-data-boxes [className]="'medium'" [heading]="'Partners'" [count]="previousData['partners']">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'unknown')">
                <app-data-boxes [className]="'low'" [heading]="'Unknown'" [count]="previousData['unknown']">
                </app-data-boxes>
            </div>

        </div>

        <div class="row pt-5">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{ 'pages.securitycase.benchmarking.highestMonth' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases['total'].month, 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="highestMonthCases['total'].amount" [footerMonth]="highestMonthCases['total'].month">
                </app-data-boxes>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases['external'].month, 'external')">
                <app-data-boxes [className]="'crit'" [heading]="'External'"
                    [count]="highestMonthCases['external'].amount" [footerMonth]="highestMonthCases['external'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases['internal'].month, 'internal')">
                <app-data-boxes [className]="'high'" [heading]="'Internal'"
                    [count]="highestMonthCases['internal'].amount" [footerMonth]="highestMonthCases['internal'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases['partners'].month, 'partners')">
                <app-data-boxes [className]="'medium'" [heading]="'Partners'"
                    [count]="highestMonthCases['partners'].amount" [footerMonth]="highestMonthCases['partners'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases['unknown'].month, 'unknown')">
                <app-data-boxes [className]="'low'" [heading]="'Unknown'" [count]="highestMonthCases['unknown'].amount"
                    [footerMonth]="highestMonthCases['unknown'].month">
                </app-data-boxes>
            </div>

        </div>

        <div class="row pt-5">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{ 'pages.securitycase.benchmarking.lowestMonth' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases['total'].month, 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="lowestMonthCases['total'].amount" [footerMonth]="lowestMonthCases['total'].month">
                </app-data-boxes>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases['external'].month, 'external')">
                <app-data-boxes [className]="'crit'" [heading]="'External'"
                    [count]="lowestMonthCases['external'].amount" [footerMonth]="lowestMonthCases['external'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases['internal'].month, 'internal')">
                <app-data-boxes [className]="'high'" [heading]="'Internal'"
                    [count]="lowestMonthCases['internal'].amount" [footerMonth]="lowestMonthCases['internal'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases['partners'].month, 'partners')">
                <app-data-boxes [className]="'medium'" [heading]="'Partners'"
                    [count]="lowestMonthCases['partners'].amount" [footerMonth]="lowestMonthCases['partners'].month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases['unknown'].month, 'unknown')">
                <app-data-boxes [className]="'low'" [heading]="'Unknown'" [count]="lowestMonthCases['unknown'].amount"
                    [footerMonth]="lowestMonthCases['unknown'].month">
                </app-data-boxes>
            </div>

        </div>

        <app-incident-panel [showCases]="showIncidentByActorsBoxesCases"></app-incident-panel>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { Case } from '../../model/itsm';

@Component({
  selector: 'app-incident-panel',
  templateUrl: './incident-panel.component.html',
  styleUrls: ['./incident-panel.component.scss']
})
export class IncidentPanelComponent implements OnInit {

  @Input()
  showCases: Case[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}

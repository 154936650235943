import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ScanFile } from 'src/app/updates/cert-tools/page/cert-tools-items';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilescanHttpService {

  // API endpoint
  private url = environment.endpoints.fileScan;
  
  constructor(private httpClient: HttpClient) {}

  // @get /search
  async search(): Promise<any> {
    const search$ = this.httpClient.get(this.url + FileScanAPI.search, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom(search$);
  }

  // @get /vitalQuota
  async getVitalQuota(): Promise<any> {
    const quotas$ = this.httpClient.get(this.url + FileScanAPI.vitalQuota, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom(quotas$);
  }

  // @post /vitalUpload
  async postVitalUpload(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    const upload$ = this.httpClient.post(this.url + FileScanAPI.vitalUpload, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom(upload$);
  }

  // @get /vitalHistory
  async getVitalHistory(): Promise<any> {
    const history$ = this.httpClient.get(this.url + FileScanAPI.vitalHistory);
    return await firstValueFrom(history$);
  }

  // @post /updateVitalQuota
  async postUpdateVitalQuota(value: number): Promise<any> {
    const update$ = this.httpClient.post(
      this.url + FileScanAPI.updateVitalQuota,
      {
        quota: value,
      },
      {
        reportProgress: true,
        responseType: 'json',
      }
    );
    return await firstValueFrom(update$);
  }

  // @post /p2aFileScan
  async submitP2AFileScan(params, scanFile: ScanFile): Promise<any> {
    const formData = new FormData();
    formData.append('file', scanFile.file);

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        formData.append(key, params[key]);
      }
    }

    const response$ = this.httpClient.post(this.url + FileScanAPI.p2aFileScan, formData, {
      responseType: 'json',
    });
    return await firstValueFrom(response$);
  }

  // @get /p2aFileScan/status/{analysisId}
  async getP2AFileScanStatus(id: number): Promise<any> {
    const response$ = this.httpClient.get(this.url + FileScanAPI.p2aFileScanStatus + id);
    return await firstValueFrom(response$);
  }

  // @get /p2aFileScan
  async getP2AReportPDF(queryParams: HttpParams): Promise<any> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf');

    const url = this.url + FileScanAPI.p2aFileScan;

    const response$ = this.httpClient.get(url, {
      params: queryParams,
      headers,
      responseType: 'blob',
    });
    return await firstValueFrom(response$);
  }

}

enum FileScanAPI {
  vitalUpload = 'vitalUpload',
  p2aFileScan = 'p2aFileScan',
  p2aFileScanStatus = 'p2aFileScan/status/',
  search = 'search',
  vitalQuota = 'vitalQuota',
  vitalHistory = 'vitalHistory',
  updateVitalQuota = 'updateVitalQuota',
}

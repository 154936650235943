import { Component, OnInit } from '@angular/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { FileAccessService } from 'src/app/shared/services/fileaccess.service';
import { File, FileStructure } from '../../shared/model/files';
import { TranslateService } from '@ngx-translate/core';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { Utilities } from 'src/app/shared/utilities';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';



@Component({
  selector: 'app-reports-static',
  templateUrl: './reports-static.component.html',
  styleUrls: ['./reports-static.component.scss']
})
export class ReportsStaticComponent implements OnInit {

  selectedReportType: string;

  serviceReports: any[];
  csocReports: any[];
  certReports: any[];
  otherReports: any[];

  selectedReports: any[];

  filesList: File[];
  fileNames: string[];
  fileStructure: FileStructure;

  currentPath: string[];
  viewedFileStructure: FileStructure;
  loading: boolean;

  months = ['January','February','March','April','May', 'June','July','August','September','October','November','December'];

  fileFilter: string;
  createdFrom: string;
  createdTo: string;
  validDateRanges = true;

  constructor(
    private fileAccessService: FileAccessService,
    private alertService: AlertService,
    private translateService: TranslateService,
    private userActivity: UserActivityService
  ) { }

  ngOnInit() {
    this.fileNames = [];
    this.currentPath = [];
    this.loading = true;

    this.fileAccessService.getFilesList()
      .then(res => {
        this.filesList = res;
      })
      .then(() => {
        // sort files by recently created
        this.filesList.sort((f1, f2) => (f1.created > f2.created) ? -1 : 1);
      })
      .then(() => {
        // create folder heirarchy for display
        this.fileStructure = this.evalFileStruct(this.filesList);;
        this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));

        this.loading = false;

      })
      .catch(err => {
        this.handlerError(err);
      });

    this.userActivity.logActivity(TYPES.reports, PAGES.reportsAndDocuments, ACTIONS.visit);
  }

  validateDateRanges() {
    this.validDateRanges = (this.createdFrom && this.createdTo) ? Utilities.checkDateRange(this.createdFrom, this.createdTo) : true;
  }

  evalFileStruct(filesList: File[]): FileStructure {
    // create array of file names
    this.fileNames = filesList.map(f => f.title);

    let fa = null;
    if(this.fileFilter == null) {
      fa = this.fileNames;
    } else {
      fa = this.fileNames.filter(value => value.toLowerCase().includes(this.fileFilter.toLowerCase()));
    }
    if(this.createdFrom && this.createdTo && !this.validDateRanges) {
      this.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantReturnResults')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
      return null;
    }
    if(this.createdFrom) {
      const comparisonDate = new Date(this.createdFrom);
      fa = fa.filter(filename => this.getFileCreationDate(filename) >= comparisonDate.getTime());
    }
    if(this.createdTo) {
      const comparisonDate = new Date(this.createdTo);
      comparisonDate.setDate(comparisonDate.getDate() + 1);
      fa = fa.filter(filename => this.getFileCreationDate(filename) <= comparisonDate.getTime());
    }

    const obj: FileStructure = {};
    fa.forEach((path) => {
      if(!path.endsWith('/')){
        path.split('/').reduce((r, e) => (r[e] || (r[e] = {})), obj);
      }
    });

    return obj;
  }

  fullResetPath(){
    this.currentPath = [];
    this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
  }

  resetPath(index: number){
    this.currentPath = this.currentPath.slice(0,index+1);
    this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
    this.currentPath.forEach(cp => {
      this.viewedFileStructure = this.viewedFileStructure[cp];
    });
  }

  openFolder(key: string){
    this.currentPath.push(key);
    this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
    this.currentPath.forEach(cp => {
      this.viewedFileStructure = this.viewedFileStructure[cp];
    });
  }

  /**
   * Creates the full (bucket) path from the current folder path and clicked filename
   */
  getFilePath(folderPath, filename) {
    if (folderPath.length > 0) {
      return folderPath.join('/') + '/' + filename;
    } else {
      // no leading forwardslash
      return filename;
    }
  }

  getFileCreationDate(filename: string) {
    const i = this.filesList.findIndex(file => file.title === filename);
    if(i>=0) {return Math.round(this.filesList[i].created / 1000);}
    else {return null;}
  }

  filter() {
    this.fileStructure = this.evalFileStruct(this.filesList);
    this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
  }

  reset() {
    this.fileFilter = null;
    this.createdFrom = null;
    this.createdTo = null;
    this.validDateRanges = true;
    this.fileStructure = this.evalFileStruct(this.filesList);
    this.viewedFileStructure = JSON.parse(JSON.stringify(this.fileStructure));
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
   private handlerError(err){
    this.alertService.handlerError(err);
  }

  private originalOrder = (a: any, b: any): number => 0;
}

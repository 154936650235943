<div class="section" *ngIf="loadedActivities">
  <h3 class="title">{{ 'pages.dashboard.latestActivity.title' | translate }}
    <app-help message="{{ 'pages.dashboard.activitySummaryDesc' | translate }}"></app-help>
  </h3>

  <div class="wrapper">
    <div class="row faketableheaderrow">
        <div class="col-xl-1 col-lg-1 col-sm-2 col-2 faketableheader"></div>
        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-10 col-10 faketableheader">{{'pages.dashboard.latestActivity.activity' | translate}}</div>
        <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketableheader">{{'pages.dashboard.latestActivity.priority' | translate}}</div>
        <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketableheader">{{'pages.dashboard.latestActivity.updatedBy' | translate}}</div>
        <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketableheader">{{'pages.dashboard.latestActivity.updated' | translate}}</div>
    </div>
    <div class="row faketablerow" *ngFor="let act of subsetActivities" [routerLink]="act.url" routerLinkActive="router-link-active" >

        <ng-template #list>
            <div [innerHTML]="act.iconAlt"></div>
        </ng-template>

        <div class="col-xl-1 col-lg-1 col-sm-2 col-2 faketablecell">
          <fa-icon [icon]="['fas', 'briefcase']" size="lg" *ngIf="act.type === 'Case'"  data-toggle="tooltip" data-placement="top" aria-labelledby="latestCases"></fa-icon>
          <fa-icon [icon]="['fas', 'radiation']" size="lg" *ngIf="act.type === 'Vulnerabilities'"  data-toggle="tooltip" data-placement="top" aria-labelledby="latestVulnerabilities"></fa-icon>
          <fa-icon [icon]="['fas', 'broadcast-tower']" size="lg" *ngIf="act.type === 'Signals'"  data-toggle="tooltip" data-placement="top" aria-labelledby="latestSignals"></fa-icon>
        </div>

        <!--aria labels-->
        <div id="iconLabels" class="visually-hidden">
          <span id="latestCases">{{ 'pages.dashboard.created30days' | translate }}</span>
          <span id="latestVulnerabilities">{{ 'menu.updates.vulnerabilities' | translate }}</span>
          <span id="latestSignals">{{ 'pages.updates.signal.signals' | translate }}</span>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-10 col-10 faketablecell" style="text-align: left;">{{act.count}} {{act.description}}</div>
        @if (act.iconAlt) {
          <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell" [ngClass]="act.iconStyle | lowercase | removeWhiteSpace" placement="top" [ngbTooltip]="list"></div>
        } @else if (act.priority) {
          <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell" [ngClass]="act.iconStyle | lowercase | removeWhiteSpace"> <span class="tag p-1 w-100 text-black" [ngClass]="['P' + act.priority | lowercase | removeWhiteSpace]" >{{act.priority}}</span></div>
        } @else {
          <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell"></div>
        }
        <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell" style="text-align: center;">{{act.updatedBy ? act.updatedBy.substring(0,16) + '...' : ocdLabel}}</div>
        <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">{{act.date | date: 'dd/MM/yyyy HH:mm'}}</div>


    </div>
  </div>

  <div class="card-body justify-content-center p-t-1 p-3" *ngIf="subsetActivities && loadedActivities">
    <ngb-pagination [collectionSize]="activities.length" pageSize="12" page="currentPage" [(page)]="currentPage" [maxSize]="10" aria-label="Pagination" (pageChange)="pageActivity($event)">
        <ng-template ngbPaginationPrevious></ng-template>
        <ng-template ngbPaginationNext></ng-template>
    </ngb-pagination>
  </div>

  <div class="row justify-content-center orangeText" *ngIf="loadedActivities && !subsetActivities">
    <fa-icon [icon]="['fas', 'exclamation-circle']" size="2x"></fa-icon>
  </div>

  <div class="row p-3 justify-content-center redborder-sides" *ngIf="subsetActivities && subsetActivities.length === 0">
    <div class="col-md-12 table-border justify-content-center">
        {{ 'pages.generic.noResults' | translate }}
    </div>
  </div>
</div>

<div class="row justify-content-center  p-5" *ngIf="!loadedActivities">
  <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

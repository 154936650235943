<div class="row mb-3">
    <div class="col-12 flex-row pt-2">
        <h3>{{ 'pages.securitycase.benchmarking.incidentByAttributes' | translate }}</h3>
        <div class="btn-group graph" role="group" aria-label="Toggle view">
            <ng-template #graphView>{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</ng-template>
            <ng-template #columnView>{{ 'pages.securitycase.benchmarking.showColumnView' | translate }}</ng-template>

            <button class="btn btn-icon" [ngbTooltip]="graphView" (click)="tarView = 'ring'; resetCases()" [ngClass]="{'active':tarView === 'ring' }">
                <fa-icon [icon]="['fas', 'chart-pie']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</div>
            </button>
            <button class="btn btn-icon" [ngbTooltip]="columnView" (click)="tarView = 'column'; resetCases()" [ngClass]="{'active':tarView === 'column' }">
                <fa-icon [icon]="['fas', 'chart-bar']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showColumnView' | translate }}</div>
            </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="tarView === 'ring'">
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">

      <div class="row">
          <div class="col-12 text-center">
              <h4>{{currentPeriodNameExpanded}}</h4>
          </div>
      </div>
      <div class="row">
          <div class="col-12 text-center">

              <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="attributesDonutChartOptions"
              [(update)]="updateDonutFlag"
              [oneToOne]=true

              style="width: 100%; height: 400px; display: block;"
              ></highcharts-chart>

          </div>
      </div>

  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center">

      <div class="row">
          <div class="col-12 text-center">
              <h4>{{previousPeriodNameExpanded}}</h4>
          </div>
      </div>
      <div class="row">
          <div class="col-12 text-center">

              <highcharts-chart
              [Highcharts]="Highcharts"
              [options]="previousAttributesDonutChartOptions"
              [(update)]="updateDonutFlag"
              [oneToOne]=true

              style="width: 100%; height: 400px; display: block;"
              ></highcharts-chart>

          </div>
      </div>

  </div>
  <div class="col-12">
    <app-incident-panel [showCases]="showIncidentByAttributeCases"></app-incident-panel>
  </div>
</div>

<div class="row" *ngIf="tarView === 'column'">
    <div class="col-12">
        <div class="row">
            <div class="col-12 text-center">

                <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="columnComparisonChartOptions"
                [(update)]="updateDonutFlag"
                [oneToOne]=true

                style="width: 100%; height: 400px; display: block;"
                ></highcharts-chart>

            </div>
        </div>

    </div>
    <div class="col-12 text-center">
        <app-incident-panel [showCases]="showIncidentByAttributeColumnCase"></app-incident-panel>
    </div>
</div>

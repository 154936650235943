import { Component, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-integrations-dashboard',
  templateUrl: './integrations-dashboard.component.html',
  styleUrls: ['./integrations-dashboard.component.scss']
})
export class IntegrationsDashboardComponent implements OnInit {

  constructor(private userActivity: UserActivityService) { }

  ngOnInit(): void {
    this.userActivity.logActivity(TYPES.resources, PAGES.integrations, ACTIONS.visit);
  }

}

<app-card
    title="pages.cybersocxdr.home.feedbackReminderTitle"
    actionTitle="pages.cybersocxdr.home.feedbackReminderAction"
    actionRoute="/xtended-soc/contract"
>
    <p>
    {{
        'pages.cybersocxdr.home.feedbackReminderDescription' | translate
    }}
    </p>
</app-card>

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {RssFeedEntry} from '../model/rssFeedEntry';

@Injectable()
export class RssService {

  constructor(private http: HttpClient) {
  }
  private getRssUrl = environment.endpoints.rss;

  private static handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  async rss(region: string, postType: string): Promise<RssFeedEntry[]> {
    return this.http
      .post(this.getRssUrl, JSON.stringify({region, postType}))
      .toPromise()
      .then(response => response as RssFeedEntry[])
      .catch(RssService.handleError);
  }
}

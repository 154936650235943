import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constants } from 'src/app/shared/constants';
import { ContextService } from 'src/app/shared/services/context-service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  public mailToInfoSec = constants.mailToPrivacy;

  constructor( 
    public translateService: TranslateService,
    public contextService: ContextService
  ) { }

  ngOnInit(): void {
  }

}

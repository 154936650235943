import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserManagementComponent } from './usermanagement/usermanagement.component';
import { NewRolesComponent } from './new-roles/new-roles.component';

const routes: Routes = [
  {
    path: 'user',
    component: LayoutComponent,
    canActivate: [OktaAuthGuard],
    children: [
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      {
        path: 'management',
        component: UserManagementComponent,
        canActivate: [OktaAuthGuard],
        pathMatch: 'full',
        data: {
          title: 'menu.userman',
          urls: [{ title: 'menu.userman' }],
        }
      },
      {
        path: 'new-roles',
        component: NewRolesComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.newRoles',
          urls: [{ title: 'menu.newRoles' }],
        }
      },
      {
        path: 'edit',
        component: UserEditComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.userman',
          urls: [{ title: 'menu.userman' }],
        }
      },
      {
        path: 'edit/:userId',
        component: UserEditComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.userman',
          urls: [{ title: 'menu.userman' }],
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }

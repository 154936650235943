import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FileStatus, ScanFile, dynamicsValues } from '../../../page/cert-tools-items';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilescanHttpService } from 'src/app/shared/httpServices/filescan-http.service';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { MfdDynamicAnalysisReportParameters } from 'src/app/shared/modals/modal';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { HttpParams } from '@angular/common/http';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';

@Component({
  selector: 'app-dynamic-mfd-upload',
  templateUrl: './dynamic-mfd-upload.component.html',
  styleUrls: ['./dynamic-mfd-upload.component.scss'],
})
export class DynamicMfdUploadComponent implements OnInit {
  // multiple criteria for vendor selection
  dropdownSettingsHooks: IDropdownSettings = {
    singleSelection: false,
    selectAllText: '',
    unSelectAllText: '',
    itemsShowLimit: 5,
    idField: 'field',
    textField: 'name',
    allowSearchFilter: false,
  };

  public scanFiles: ScanFile[] = [];
  public mfdQuota;

  // load constants
  //hooks = dynamicsValues.hooks;
  hooks = [];
  specificLaunchEnv = dynamicsValues.specificLaunchEnv;

  // initialize form values
  targettedCountry = dynamicsValues.targettedCountry;
  iatReconstruction = dynamicsValues.iatReconstruction;
  vms = dynamicsValues.vms;
  useBankerEnvironment = dynamicsValues.useBankerEnvironment;

  // initialize default form values
  defaultPassword = 'infected';
  defaultFollowProcess = 'cmd.exe,powershell.exe';
  defaultVm = 'Windows7SP1x64';
  defaultRuleSets = 'OCD';

  // init files list
  files: ScanFile[] = [];

  fileSettingsForm: FormGroup;
  generalForm: FormGroup;
  dumpsForm: FormGroup;
  advancedForm: FormGroup;
  sufficientQuota: boolean;

  private gpTimeout = 30; // add 30 sec to timeout
  private gpPeriod = 10;  // wait 10 sec on each iteratin

  constructor(
    private formBuilder: FormBuilder,
    private filescanHttpService: FilescanHttpService,
    private modalService: ModalService,
    private mfdService: MfdService,
    private alertService: AlertService,
    private userActivity: UserActivityService
  ) {}

  // getters
  get timeout() {
    return this.generalForm.get('timeout');
  }
  get timeAccelerationFactor() {
    return this.generalForm.get('timeAccelerationFactor');
  }
  get dumpScreenYResolution() {
    return this.dumpsForm.get('dumpScreenYResolution');
  }
  get dumpMaximumSizeSingle() {
    return this.dumpsForm.get('dumpMaximumSizeSingle');
  }
  get dumpMaximumSizePerProcess() {
    return this.dumpsForm.get('dumpMaximumSizePerProcess');
  }
  get dumpMaximumSizeTotal() {
    return this.dumpsForm.get('dumpMaximumSizeTotal');
  }
  get dumpDiscardIfDeltaUnderPermillion() {
    return this.dumpsForm.get('dumpDiscardIfDeltaUnderPermillion');
  }

  ngOnInit(): void {
    this.refreshQuotas();

    this.initGeneralForm();

    this.initDumpsForm();

    this.initAdvancedForm();

    // init global form
    this.fileSettingsForm = this.formBuilder.group({
      general: this.generalForm,
      dumps: this.dumpsForm,
      advanced: this.advancedForm,
    });
  }

  public refreshQuotas() {
    this.mfdService
      .getQuota()
      .then((quota) => {
        this.mfdQuota = quota;
      })
      .catch((e) => {
        this.alertService.handlerError(e);
      });
  }

  public onSubmit() {
    // gather all form params
    const params = Object.assign(this.generalForm.value, this.dumpsForm.value, this.advancedForm.value);

    // get all files to scan
    const filesToAnalyse = this.scanFiles.filter(
      (file) => file.status === FileStatus.onHold || file.status === FileStatus.inError
    );

    // check validy and files presence
    if (this.fileSettingsForm.valid && filesToAnalyse.length > 0) {
      for (const file of filesToAnalyse) {
        file.attempt = (this.timeout.value + this.gpTimeout) / this.gpPeriod;
        file.status = FileStatus.inAnalysis;
        this.submitP2AFileScan(params, file);
      }
    }
  }

  public refreshFile(scanFile: ScanFile, fromUser: boolean) {
    if(fromUser) {
      scanFile.status = FileStatus.inAnalysis;
    }
    this.getPDFReport(scanFile, true);
  }

  /**
   * open the P2A report file
   * @param scanFile
   */
  public openScanReport(scanFile: ScanFile) {
    if (scanFile.status === FileStatus.inSuccess) {
      const params = new MfdDynamicAnalysisReportParameters();
      params.filename = scanFile.file.name;
      params.reportAnalysis = scanFile.reportAnalysis;
      this.modalService.openMfdDynamicAnalysisReport(params);
    }
  }

  /**
   * for ZIP paswword, reveal the password
   */
  public revalPassword() {
    const passwordElement = document.getElementById('password') as HTMLInputElement;
    if (passwordElement.type === 'password') {
      passwordElement.type = 'text';
    } else {
      passwordElement.type = 'password';
    }
  }

  private submitP2AFileScan(params: any, file: ScanFile) {
    this.filescanHttpService.submitP2AFileScan(params, file).then(
      (response) => {
        try {
          const summary = JSON.parse(response);
          file.analysisId = summary.analysis;
          this.getP2AStatusReport(file);
          this.userActivity.logActivity(TYPES.services, PAGES.dynamicMfdUpload, ACTIONS.fileScan);
        } catch {
          file.status = FileStatus.inError;
        }
      },
      (error) => {
        file.status = FileStatus.inError;
        file.error = error.statusText;
      }
    );
  }

  private getP2AStatusReport(file: ScanFile) {

    this.filescanHttpService.getP2AFileScanStatus(file.analysisId).then(
      (response) => {
        const summary = JSON.parse(response);
        if( file.attempt === 0) {
          file.status = FileStatus.inError;
          file.error = 'Analysis timeout reached';
        } else if ( summary.status === 'ready') {
          this.getPDFReport(file, false);
        } else {
          file.attempt --;
          setTimeout(() => {
            this.getP2AStatusReport(file);
          }, this.gpPeriod * 1000 );
        }
      },
      () => {
        file.status = FileStatus.inError;
      }
    );
  }

  private getPDFReport(file: ScanFile, fromRefresh: boolean) {

    let queryParams = new HttpParams();
    queryParams = queryParams.append('analysisId', '' + file.analysisId);
    queryParams = queryParams.append('fileName', file.file.name);
    queryParams = queryParams.append('fromRefresh', '' + fromRefresh);

    this.filescanHttpService.getP2AReportPDF(queryParams).then(
      (response) => {
        try {
          file.reportAnalysis = new Blob([response], { type: 'application/pdf' });
          file.status = FileStatus.inSuccess;
        } catch {
          file.status = FileStatus.inError;
        }
        this.refreshQuotas();

        if(!fromRefresh) {
          this.scheduleRefresh(file);
        }
      },
      (error) => {
        file.status = FileStatus.inError;
        file.error = error.statusText;
      }
    );
  }

  private scheduleRefresh(file: ScanFile) {
    setTimeout(() => {
      this.refreshFile(file, false);
    }, 15000);
  }

  private initGeneralForm() {
    // base form
    this.generalForm = new FormGroup({
      timeout: new FormControl(40, { validators: [Validators.min(10), Validators.max(600)] }),
      vm: new FormControl(this.defaultVm),
      customPassword: new FormControl(this.defaultPassword),
      useVnc: new FormControl(true),
      freeMode: new FormControl(false),
      light: new FormControl(false),
      useTaintAnalysis: new FormControl(false),
      autoStart: new FormControl(true),
      useBankerEnvironment: new FormControl(''),
      isPublic: new FormControl(true),
      shared: new FormControl(false),
      confinedMode: new FormControl(false),
      torMode: new FormControl(false),
      targettedCountry: new FormControl(1036),
      timeSystemNewDate: new FormControl('0'),
      timeAutotriggerScheduledTasks: new FormControl(true),
      timeAccelerationFactor: new FormControl(7, {
        validators: [Validators.min(1), Validators.max(16)],
      }),
      rulesets: new FormControl(this.defaultRuleSets),
    });
  }

  private initDumpsForm() {
    // base form
    this.dumpsForm = new FormGroup({
      dumpEnableFileDumps: new FormControl(true),
      dumpEnableNetworkDumps: new FormControl(true),
      dumpEnableRegistryDumps: new FormControl(true),
      dumpEnableCryptoDumps: new FormControl(true),
      dumpEnablesScreenDumps: new FormControl(true),
      dumpScreenYResolution: new FormControl(600, {
        validators: [Validators.min(100), Validators.max(1200)],
      }),
      dumpEnableImageDumps: new FormControl(true),
      dumpEnableMemoryDumps: new FormControl(true),
      dumpEnableMemoryDumpsUrlAnalysis: new FormControl(true),
      dumpMemoryScanForSubobjects: new FormControl(true),
      dumpImageApisResolveIat: new FormControl(0),
      dumpMemoryApisResolveIat: new FormControl(0),
      dumpMaximumSizeSingle: new FormControl(80, {
        validators: [Validators.min(10), Validators.max(1000)],
      }),
      dumpMaximumSizePerProcess: new FormControl(160, {
        validators: [Validators.min(10), Validators.max(1000)],
      }),
      dumpMaximumSizeTotal: new FormControl(300, {
        validators: [Validators.min(10), Validators.max(1000)],
      }),
      dumpDiscardIfDeltaUnderPermillion: new FormControl(1, {
        validators: [Validators.min(1), Validators.max(33)],
      }),
    });
  }

  private initAdvancedForm() {
    // base form
    this.advancedForm = new FormGroup({
      followProcess: new FormControl(this.defaultFollowProcess),
      extraArgs: new FormControl(''),
      customCommandLine: new FormControl(''),
      hooks: new FormControl(this.hooks),
      disableHooks: new FormControl(false),
      antiAntiVm: new FormControl(true),
      antiFingerprint: new FormControl(true),
      timeSystemUsePeheaderTime: new FormControl(true),
      env: new FormControl(''),
      monitorHeapExecute: new FormControl(true),
      monitorPeHeaders: new FormControl(false),
      errorBreakOnWerfault: new FormControl(false),
      errorBreakOnUncatchedExceptions: new FormControl(false),
      traceApiCalls: new FormControl(false),
      traceComCalls: new FormControl(false),
      verbositylevel: new FormControl(5, {
        validators: [Validators.min(0), Validators.max(7)],
      }),
      fastExitConfidence: new FormControl(false),
      taintAnalysisMinBufferSize: new FormControl(2048, {
        validators: [Validators.min(0), Validators.max(4096)],
      }),
      taintAnalysisMaxZlogSize: new FormControl(100, {
        validators: [Validators.min(100), Validators.max(40000)],
      }),
    });
  }
}

import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

type SerieType = Highcharts.SeriesAreaOptions | Highcharts.SeriesSplineOptions;

@Component({
  selector: 'app-areaovertime',
  templateUrl: '../basechart/basechart.component.html',
})
export class AreaovertimeComponent extends BaseChartComponent<Array<SerieType>, { series: SerieType[] } & Highcharts.Options> {
  @Input() createdTo: string;
  @Input() createdFrom: string;
  @Input() titleY: string;
  @Input() isStacking = false;
  @Input() average = false;
  @Input() suffix = 'minutes';

  series: Highcharts.SeriesXrangeOptions;
  options: { series: SerieType[] } & Highcharts.Options = {
    chart: {
      type: 'areaspline',
    },
    title: {
      text: '',
    },
    legend: {
      layout: 'horizontal',
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b',
      },
      title: {
        text: 'Date',
      },
      min: undefined,
      max: undefined,
    },
    yAxis: {
      title: {
        text: '',
      },
    },
    tooltip: {
      pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y} </b><br/>`,
      shared: true,
    },

    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      spline: {
        dashStyle: 'Dash',
      },
      areaspline: {
        fillOpacity: 0.5,
      },
      series: {},
    },
    series: undefined,
  };

  draw(renderingId: string) {
    // Default to no data
    this.options.series = [{ name: 'No data found for this period', data: [], type: 'area' }];
    this.options.plotOptions.series = {};
    this.options.tooltip.valueSuffix = ` ${this.suffix}`;
    this.options.title.text = this.title;
    this.options.yAxis = {
      title: {
        text: this.titleY,
      },
    };

    if (this.data[0]) {
      this.options.series = this.data;
    }

    if (this.createdFrom) {
      (this.options.xAxis as Highcharts.XAxisOptions).min = new Date(this.createdFrom).getTime();
    }
    if (this.createdTo) {
      (this.options.xAxis as Highcharts.XAxisOptions).max = new Date(this.createdTo).getTime();
    }

    if (this.options.series.length > 1 && this.average) {
      // Find the average serie from the backend and edit its visual properties
      // This depends awfully from visual decisions made from the backend, which I'm not too fond of.
      const averageSerie = this.options.series.find((serie) => serie.name === 'Average')
      if(averageSerie){
        averageSerie.type = 'spline'
      }
    }

    if (this.options.series.length > 1 && this.isStacking) {
      this.options.plotOptions.series = {
        stacking: 'normal',
        marker: {
          enabled: true,
        },
      };

      const hashmap: { [key: string | number]: number } = {};
      for (const serie of this.options.series) {
        const datasList = serie.data;
        for (const dataItem of datasList) {
          hashmap[dataItem[0]] = (hashmap[dataItem[0]] ?? 0) + dataItem[1];
        }
      }

      const highestValue = Math.max(...Object.values(hashmap));

      if (highestValue) {
        this.options.yAxis = {
          title: {
            text: this.titleY,
          },
          max: highestValue * (2.25 / 2),
        };
      }
    }
  }
}

<div >
    <form [formGroup]="surveyForm" class="question">
        <div class="closed-choice btn-group" role="group">
            <div>
                <input type="radio" class="btn-check" id="option1" autocomplete="off" type="radio" [formControlName]="question.title" [name]="question.title" [value]="true">
                <label class="btn btn-secondary" for="option1">Yes</label>
            </div>
            <div>
                <input type="radio" class="btn-check"  id="option2" autocomplete="off" type="radio" [formControlName]="question.title" [name]="question.title" [value]="false">
                <label class="btn btn-secondary" for="option2">No</label>
            </div>
        </div>
    </form>
</div>
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { GenericModalComponent } from './templates/generic-modal/generic-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditColumnParameters, GenerateReportParameters, GlobalFeedbackParameters, MfdDynamicAnalysisReportParameters, ModalParameters, NotificationParameters, SaveFilterParameters } from './modal';
import { ExportModalComponent } from './templates/export-modal/export-modal.component';
import { EditColumnsModalComponent } from './templates/edit-columns-modal/edit-columns-modal.component';
import { SaveFilterModalComponent } from './templates/save-filter-modal/save-filter-modal.component';
import { DynamicMfdAnalysisReportModalComponent } from 'src/app/updates/cert-tools/mfd-services/dynamic-analysis/dynamic-mfd-analysis-report/dynamic-mfd-analysis-report.component';

import { NotificationComponent } from './templates/notification/notification.component';
import { GlobalFeedbackComponent } from 'src/app/dashboard/feedbacks/global-feedback/global-feedback.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private ngModalService: NgbModal
  ) { }

  /**
   * open generic confirm modal
   */
  public confirmModal(params: ModalParameters, size = 'l') {
    const modal = this.ngModalService.open(GenericModalComponent, {backdrop: 'static', size, keyboard: false, centered: true});
    modal.componentInstance.params = params;
  }

  /**
   * open info modal
   */
   public infoModal(params: ModalParameters, size = 'l') {
    const modal = this.ngModalService.open(GenericModalComponent, {backdrop: 'static', size, keyboard: false, centered: true});
    params.successBtnLabel = undefined;
    params.successCallback = undefined;
    modal.componentInstance.params = params;
  }

  /**
   * open export modal
   */
  public exportModal(params: GenerateReportParameters, size = 'l') {
    const modal = this.ngModalService.open(ExportModalComponent, {backdrop: 'static', size, keyboard: false, centered: true});
    modal.componentInstance.params = params;
  }

  /**
   * open edit columns modal
   */
  public editColumnsModal(params: EditColumnParameters, size = 'l') {
    const modal = this.ngModalService.open(EditColumnsModalComponent, {backdrop: 'static', size, keyboard: false, centered: true});
    modal.componentInstance.params = params;
  }

  /**
   * open save filter modal
   */
  public saveFilterModal(params: SaveFilterParameters, size = 'l') {
    const modal = this.ngModalService.open(SaveFilterModalComponent, {backdrop: 'static', size, keyboard: false, centered: true});
    modal.componentInstance.params = params;
  }

  /**
   * open save filter modal
   */
  public openMfdDynamicAnalysisReport(params: MfdDynamicAnalysisReportParameters, size = 'xxl') {
    const modal = this.ngModalService.open(DynamicMfdAnalysisReportModalComponent,
       {backdrop: 'static', size, keyboard: false, centered: true});
    modal.componentInstance.params = params;
  }

  /**
   * open notification modal
   */
  public openNotification(params: NotificationParameters, size = 'l') {
    const modal = this.ngModalService.open(NotificationComponent, {backdrop: 'static', size, keyboard: false, centered: false});
    modal.componentInstance.params = params;
  }

  /**
   * open feedbacks modal
   */
  public openGlobalFeedback(params: GlobalFeedbackParameters, size = 'xl') {
    const modal = this.ngModalService.open(GlobalFeedbackComponent, {backdrop: 'static', size, keyboard: false, centered: false});
    modal.componentInstance.params = params;
  }

}

<div class="row mb-3">
    <div class="col-12 pt-2">
        <h3>{{ 'pages.securitycase.benchmarking.incidentsByPriority' | translate }}</h3>
        <div class="btn-group rounded graph" role="group" aria-label="Toggle view">
            <ng-template #graphView>{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</ng-template>
            <ng-template #boxView>{{ 'pages.securitycase.benchmarking.showBoxFormat' | translate }}</ng-template>
            <button class="btn btn-icon" [ngbTooltip]="graphView" (click)="ibpViewGraph = true; resetCases()"
                [ngClass]="{'active':ibpViewGraph === true }">
                <fa-icon [icon]="['fas', 'chart-bar']" size="2x" class="text-center" [fixedWidth]="true"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showGraph' | translate }}</div>
            </button>
            <button class="btn btn-icon" [ngbTooltip]="boxView" (click)="ibpViewGraph = false; resetCases()"
                [ngClass]="{'active':ibpViewGraph === false }">
                <fa-icon [icon]="['fas', 'sort-numeric-up-alt']" size="2x"></fa-icon>
                <div class="visually-hidden text-black">{{ 'pages.securitycase.benchmarking.showBoxFormat' | translate }}</div>
            </button>
        </div>
    </div>
</div>

<div class="row" *ngIf="ibpViewGraph">
    <div class="col-12 text-start justify-content-start">
        <highcharts-chart [Highcharts]="Highcharts" [options]="incidentsChartOptions" [(update)]="updateIncidentChart"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>

        <app-incident-panel [showCases]="showIncidentByPriorityGraphCases"></app-incident-panel>

    </div>
</div>


<div class="row" *ngIf="ibpViewGraph === false">
    <div class="lines col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

        <div class="row">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{currentPeriodName === 'last30days' ? 'Last 30 days' : 'Last full calendar month'}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate" [count]="currentData.total"
                    [icon]="totalTrend">
                </app-data-boxes>

            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'critical')">
                <app-data-boxes [className]="'crit'" [heading]="'menu.reports.criticalPriority' | translate"
                    [count]="currentData.critical" [icon]="criticalTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'high')">
                <app-data-boxes [className]="'high'" [heading]="'menu.reports.highPriority' | translate"
                    [count]="currentData.high" [icon]="highTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'medium')">
                <app-data-boxes [className]="'medium'" [heading]="'menu.reports.mediumPriority' | translate"
                    [count]="currentData.medium" [icon]="mediumTrend">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('current', 'low')">
                <app-data-boxes [className]="'low'" [heading]="'menu.reports.lowPriority' | translate"
                    [count]="currentData.low" [icon]="lowTrend">
                </app-data-boxes>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{previousPeriodName === 'previous30days' ? 'Previous 30 days' : 'Previous full calendar month'}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="previousData.total">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByPeriod('previous', 'critical')">
                <app-data-boxes [className]="'crit'" [heading]="'menu.reports.criticalPriority' | translate"
                    [count]="previousData.critical">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'high')">
                <app-data-boxes [className]="'high'" [heading]="'menu.reports.highPriority' | translate"
                    [count]="previousData.high">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'medium')">
                <app-data-boxes [className]="'medium'" [heading]="'menu.reports.mediumPriority' | translate"
                    [count]="previousData.medium">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4" (click)="selectCasesByPeriod('previous', 'low')">
                <app-data-boxes [className]="'low'" [heading]="'menu.reports.lowPriority' | translate"
                    [count]="previousData.low">
                </app-data-boxes>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>{{ 'pages.securitycase.benchmarking.highestMonth' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases.total.month, 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="highestMonthCases.total.amount" [footerMonth]="highestMonthCases.total.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases.critical.month, 'critical')">
                <app-data-boxes [className]="'crit'" [heading]="'menu.reports.criticalPriority' | translate"
                    [count]="highestMonthCases.critical.amount" [footerMonth]="highestMonthCases.critical.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases.high.month, 'high')">
                <app-data-boxes [className]="'high'" [heading]="'menu.reports.highPriority' | translate"
                    [count]="highestMonthCases.high.amount" [footerMonth]="highestMonthCases.high.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases.medium.month, 'medium')">
                <app-data-boxes [className]="'medium'" [heading]="'menu.reports.mediumPriority' | translate"
                    [count]="highestMonthCases.medium.amount" [footerMonth]="highestMonthCases.medium.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(highestMonthCases.low.month, 'low')">
                <app-data-boxes [className]="'low'" [heading]="'menu.reports.lowPriority' | translate"
                    [count]="highestMonthCases.low.amount" [footerMonth]="highestMonthCases.low.month">
                </app-data-boxes>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4">
                <div class="card boxshadow">
                    <div class="card-body h-90">
                        <div class="text-center">
                            <p>Lowest Month</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases.total.month, 'total')">
                <app-data-boxes [className]="" [heading]="'pages.generic.total' | translate"
                    [count]="lowestMonthCases.total.amount" [footerMonth]="lowestMonthCases.total.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases.critical.month, 'critical')">
                <app-data-boxes [className]="'crit'" [heading]="'menu.reports.criticalPriority' | translate"
                    [count]="lowestMonthCases.critical.amount" [footerMonth]="lowestMonthCases.critical.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases.high.month, 'high')">
                <app-data-boxes [className]="'high'" [heading]="'menu.reports.highPriority' | translate"
                    [count]="lowestMonthCases.high.amount" [footerMonth]="lowestMonthCases.high.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases.medium.month, 'medium')">
                <app-data-boxes [className]="'medium'" [heading]="'menu.reports.mediumPriority' | translate"
                    [count]="lowestMonthCases.medium.amount" [footerMonth]="lowestMonthCases.medium.month">
                </app-data-boxes>
            </div>
            <div class="col-xl-2 col-lg-4 col-md-4 col-sm-4 col-4"
                (click)="selectCasesByMonth(lowestMonthCases.low.month, 'low')">
                <app-data-boxes [className]="'low'" [heading]="'menu.reports.lowPriority' | translate"
                    [count]="lowestMonthCases.low.amount" [footerMonth]="lowestMonthCases.low.month">
                </app-data-boxes>
            </div>
        </div>

        <app-incident-panel [showCases]="showIncidentByPriorityBoxesCases"></app-incident-panel>

    </div>
</div>

export class File {
    title: string;
    contentType: string;
    blobId: any;
    bucketName: string;
    created: number;
}

export interface FileStructure {
    [key: string]: Record<string, never> | FileStructure;
}

export type FileStructureArray = {
    name: string;
    value: FileStructureArray;
    created?: number;
    folderPath?: string[];
}[];


export enum ReportPdfAccessLevel {
    internal, confidential, restricted
}

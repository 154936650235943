import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FEEDBACKFORM, constants } from 'src/app/shared/constants';
import { HttpUsersPreferencesService } from 'src/app/shared/httpServices/http-users-preferences.service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-add-feedback',
  templateUrl: './add-feedback.component.html',
  styleUrls: ['./add-feedback.component.scss']
})
export class AddFeedbackComponent implements OnInit {

  @Output() finished = new EventEmitter<boolean>();
  @Input() feedback: Feedback;
  public submitted = false;

  public pages = FEEDBACKFORM.feedback.pages;
  public subpages = [];
  public categories = FEEDBACKFORM.feedback.category;
  public consultMode = false;
  public actionlabel: string;
  public feedbackForm: FormGroup;
  public loading = false;

  constructor(
    private httpUsersPreferencesService: HttpUsersPreferencesService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {

    this.feedbackForm = new FormGroup({
      page: new FormControl('', Validators.required),
      subpage: new FormControl(''),
      category: new FormControl('', Validators.required),
      title: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(constants.regex.onlyAlphanumericValues)
      ]),
      feedback: new FormControl('', [
        Validators.required,
        Validators.pattern(constants.regex.onlyAlphanumericValues)
      ])
    });

    if (this.feedback) {
      // modal opened with feedback
      this.consultMode = true;
      this.feedbackForm.patchValue(this.feedback);
      if(this.feedback.subpage) {
        this.subpages = this.pages.filter(e => e.value === this.feedback.page)[0].subpages;
      }
      Object.keys(this.feedbackForm.controls).forEach(ctrl => {
        this.feedbackForm.controls[ctrl].disable();
      });

      if (this.feedback.state === 'open') {
        this.actionlabel = this.translateService.instant('buttons.action.archive');
      } else {
        this.actionlabel = this.translateService.instant('buttons.action.open');
      }

    } else {
      // new feedback
      this.feedbackForm.get('page').valueChanges.subscribe(value => {
        this.fillSubPageOnChange(value);
      });
      this.feedbackForm.controls.subpage.disable();
    }
  }

  /**
   * initialize subpage select from selected page
   */
  public fillSubPageOnChange(selectedpage: string) {
    if (selectedpage === '') {
      this.feedbackForm.get('subpage').patchValue('');
      this.feedbackForm.controls.subpage.disable();
    } else {
      const subpages = this.pages.filter(e => e.value === selectedpage)[0].subpages;
      // reset subpage value
      this.feedbackForm.get('subpage').patchValue('');
      if (subpages.length === 0) {
        this.feedbackForm.controls.subpage.disable();
      } else {
        this.feedbackForm.controls.subpage.enable();
        this.subpages = subpages;
      }
    }
  }

  /**
   * send request to create feedback
   */
  public submit() {
    this.submitted = true;
    if (this.feedbackForm.valid) {
      const feedback = this.feedbackForm.value as Feedback;
      this.loading = true;
      this.httpUsersPreferencesService.postfeedback(feedback)
        .then(() => {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.creationSucceed'));
        })
        .catch(() => {
          this.alertService.addError(this.translateService.instant('modals.feedbackMenu.feedback.creationFailed'));
        })
        .then(() => {
          this.loading = false;
          this.finished.emit(false);
        });
    }
  }

  /**
   * update state (open or archived) feedback state
   */
  public updateFeedbackState() {
    this.feedback.state = this.feedback.state === 'open' ? 'archived' : 'open';
    this.httpUsersPreferencesService.postfeedback(this.feedback)
      .then(() => {
        if(this.feedback.state === 'open') {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.openSucceed'));
        } else {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.archiveSucceed'));
        }
      })
      .catch(() => {
        this.alertService.addError(this.translateService.instant('modals.feedbackMenu.feedback.archiveFailed'));
      })
      .then(() => {
        this.finished.emit(true);
      });
  }

  /**
   * delete existing feedback
   */
  public delete() {
    this.httpUsersPreferencesService.deletefeedback(this.feedback._id)
      .then(() => {
        this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.feedback.deleteSucceed'));
      })
      .catch(() => {
        this.alertService.addError(this.translateService.instant('modals.feedbackMenu.feedback.deleteFailed'));
      })
      .then(() => {
        this.finished.emit(true);
      });
  }

  /**
   * close modal and trigger callback if needed
   */
  public close() {
    this.finished.emit(false);
  }
}

export class Feedback {
  public category: string;
  public created: string;
  public feedback: string;
  public login: string;
  public metadatas: string;
  public page: string;
  public state: string;
  public subpage: string;
  public title: string;
  public _id: string;
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Case } from '../../model/itsm';

@Component({
  selector: 'app-support-list',
  templateUrl: './support-list.component.html',
  styleUrls: ['./support-list.component.scss']
})
export class SupportListComponent implements OnInit {

  @Input()
  cases: Case[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Output()
  flagEmitter = new EventEmitter<any>();

  @Output()
  sortEmitter = new EventEmitter<any>();

  @Input()
  loaded: boolean;

  @Input()
  scrollLoading: boolean;

  @Input()
  selectedColumns: {name: string; field: string}[] = [
    {name:'Case Number', field: 'caseNumber'},
    {name:'Subject', field: 'subject'},
    {name:'Priority', field: 'priority'},
    {name:'Status', field: 'status'},
    {name:'Type', field: 'type'},
    {name:'Created Date', field: 'createdDate'},
    {name:'Updated', field: 'lastModifiedDate'},
    {name:'Contact', field: 'contact'}
  ];

  constructor() { }

  ngOnInit() {
  }

  flagCase(event, caseRef: string): boolean {
    event.stopPropagation();
    this.flagEmitter.emit(caseRef);
    return false;
  }

  sortResults(event, field: string): void{
    event.stopPropagation();
    this.sortEmitter.emit(field);
  }

}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface MenuLink {
  name: string;
  route: string;
  saveSearchDisplay: boolean;
  filterDisplay: boolean;
  haveTabs: boolean;
  sourceDisplay: boolean;
  sourceSelectable: boolean;
}

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent {
  constructor(private router: Router) {}

  @Input()
  menuLinks: MenuLink[] = [];

  isActiveMenu(menu: MenuLink): boolean {
    return menu.route === this.router.url || (menu.haveTabs && this.router.url.startsWith(menu.route));
  }
}

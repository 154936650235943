<div *ngIf="contextService.contextLoaded" class="page-container"
  [ngClass]="contextService.isDashboard ? 'dashboard' : contextService.bgTheme + ' pb-3'"
  [attr.data-bs-theme]="contextService.isDashboard ? '' : contextService.bsTheme">

  <h1 class="visually-hidden">{{ 'menu.portalName' | translate }}</h1>

  <!-- user nav-bar -->
  <app-user-navbar [(userObject)]="userObject"
    [changeTenants]="changeTenants" [accessRestricted]="false"></app-user-navbar>

  <!-- global portal services nav-bar -->
  <app-services-navbar></app-services-navbar>
  
  <app-banner></app-banner>

  <!-- TODO: Remove post event -->
  @if (contextService.isDashboard) {
    <div class="announcement">
      <a href="https://www.orangecyberdefense.com/uk/insights/events/orange-cyberdefense-summit-2024" rel="noopener" target="_blank">
        <img class="announcement-img" src="../../../assets/images/communication/Banner1.jpg" style="width:80%" alt="OCD summit event"/>
      </a>
    </div>
  }
  
  
  <app-alert></app-alert>

  <div class="page" [ngClass]="[contextService.fullWidth ? 'noMargin' : 'withMargin']">
    <!-- left margin -->
    <div></div>
    <!-- content -->
    <div>
      <router-outlet (activate)="onActivate($event)"></router-outlet>
    </div>
    <!-- right margin-->
    <div></div>
  </div>

  <div aria-label="Back to top" class="back-to-top" (click)="scrollUp()">
    <a class="back-to-top-link btn btn-icon btn-secondary" [ngClass]="contextService.bsTheme" title="Back to top"></a>
  </div>

</div>


<div *ngIf="contextService.contextLoaded">
  <app-footer></app-footer>
</div>


<div *ngIf="!contextService.contextLoaded" class="text-center"
  style="background-color: black; height: 100vh; width: 100vw; padding-top: 12em;">
  <h2>{{ 'pages.generic.standby' | translate }}</h2>
  <div class="spinner-border text-primary spinner-grow-lg" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="d-flex justify-content-between">
  <h3 *ngIf="!editting">{{ 'pages.viewTenant.addUser' | translate }}</h3>
  <h3 *ngIf="editting">{{ 'pages.viewTenant.editUser' | translate }}</h3>
  <a [href]="'/user/management'" class="btn btn-primary">{{ 'pages.viewTenant.listUsers' | translate }}</a>
</div>

<div class="row">
  <div class="col-12">
    <ul>
      <li>{{ 'pages.user.userCredentials' | translate }}</li>
      <li>{{ 'pages.user.portalAccessInfo' | translate }}</li>
      <li>{{ 'pages.user.requiredRoles' | translate }}</li>
      <li>{{ 'pages.user.userRegistration' | translate }}</li>
      <li>{{ 'pages.user.accountActivation' | translate }}</li>
      <li>{{ 'pages.user.adminMandatoryRoleDesc' | translate }}</li>
    </ul>
  </div>
</div>

<div class="grouping">
  <form [formGroup]="userForm" (submit)="addUser();">
    <div class="row">

      <div class="row" *ngIf="!editting">
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.firstName' | translate }}</label>
          <input type="text" formControlName="firstName"
            [ngClass]="{ 'is-invalid': submittedUser && uf.firstName.errors }" class="form-control" name="firstName"
            aria-describedby="firstName" placeholder="First name">
          <div *ngIf="submittedUser && uf.firstName.errors" class="invalid-feedback">
            <div *ngIf="uf.firstName.errors.required">{{ 'pages.generic.firstNameReq' | translate }}</div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.lastName' | translate }}</label>
          <input type="text" formControlName="lastName"
            [ngClass]="{ 'is-invalid': submittedUser && uf.lastName.errors }" class="form-control" name="lastName"
            aria-describedby="lastName" placeholder="Last name">
          <div *ngIf="submittedUser && uf.lastName.errors" class="invalid-feedback">
            <div *ngIf="uf.lastName.errors.required">{{ 'pages.generic.lastNameReq' | translate }}</div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.email' | translate }}</label>
          <input type="text" formControlName="email" [ngClass]="{ 'is-invalid': submittedUser && uf.email.errors }"
            class="form-control" name="email" aria-describedby="email" placeholder="Email">
          <div *ngIf="submittedUser && uf.email.errors" class="invalid-feedback">
            <div *ngIf="uf.email.errors.required">{{ 'pages.generic.emailReq' | translate }}</div>
            <div *ngIf="uf.email.errors.email">{{ 'pages.generic.emailValid' | translate }}</div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="edittingUser">
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.firstName' | translate }}</label>
          {{edittingUser.profile.firstName}}
        </div>
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.lastName' | translate }}</label>
          {{edittingUser.profile.lastName}}
        </div>
        <div class="col-12 mb-3">
          <label for="query">{{ 'pages.generic.email' | translate }}</label>
          {{edittingUser.profile.email}}
        </div>
      </div>

      <div class="col-12 mb-3">
        <div class="row">
          <div class="col-3" *ngFor="let app of selectedTenantApps">
            <div class="card" style="width: 18rem;">
              <div class="card-body text-center">
                <h5 class="card-title"> <img src="{{app.logo[0].href}}" style="width:200px;" alt=""/> {{app.name}}</h5>
                <p class="card-text">{{app.created | date: 'dd/MM/yyyy HH:mm'}}</p>
                <a (click)="activateAppForUser(app)" routerLinkActive="router-link-active"
                  *ngIf="!activatedUserApps.includes(app)" class="btn btn-primary" data-placement="top"
                  title="{{ 'pages.user.adminActivateDesc' | translate }}">{{ 'pages.generic.activate' | translate
                  }}</a>
                <a (click)="deactivateAppForUser(app)" routerLinkActive="router-link-active"
                  *ngIf="activatedUserApps.includes(app)" class="btn btn-primary">{{ 'pages.generic.deactivate' |
                  translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-3">
        <h4 for="query">{{ 'pages.viewTenant.availRoles' | translate }}</h4>

        <h6>SOC User</h6>
        <div *ngFor="let r of availableUserRoles" class="ps-2">
          <ng-container *ngIf="r.name === 'Access_SOC_Role'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_SOC_Reports'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <app-help *ngIf="r.name === 'Access_SOC_Role'"
            message="{{'pages.user.accessSOCDesc' | translate}}"></app-help>
        </div>

        <h6 style="margin-top:10px;">CyberSOC User</h6>
        <div *ngFor="let r of availableUserRoles" class="ps-2">

          <ng-container *ngIf="r.name === 'Access_CSOC_Role'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_CSOC_Reports'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <app-help *ngIf="r.name === 'Access_CSOC_Role'"
            message="{{'pages.user.accessCSOCDesc' | translate}}"></app-help>
        </div>

        <h6 style="margin-top:10px;">{{'pages.user.reportViewingPermissions' | translate}}</h6>
        <div *ngFor="let r of availableUserRoles" class="ps-2">
          <ng-container *ngIf="r.name === 'Access_Restricted_Reports'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_Internal_Reports'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_Confidential_Reports'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>
        </div>

        <h6 style="margin-top:10px;">{{'pages.user.permittedActions' | translate}}</h6>
        <div *ngFor="let r of availableUserRoles" class="ps-2">

          <ng-container *ngIf="r.name === 'Access_Change_Request'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Create_Change_Request'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_Service_Request'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Create_Service_Request'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Access_Incident'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <ng-container *ngIf="r.name === 'Create_Incident'">
            <input type="checkbox" [id]="r.id" [checked]="selectedUserRoleIds.includes(r.id)"
              (change)="selectRole($event, r.id)">
            <label class="ps-2">{{r.appName}} - {{r.name}}</label>
          </ng-container>

          <app-help *ngIf="r.name === 'Create_Change_Request'"
            message="{{'pages.user.createCRDesc' | translate}}"></app-help>
          <app-help *ngIf="r.name === 'Create_Service_Request'"
            message="{{'pages.user.createSRDesc' | translate}}"></app-help>
          <app-help *ngIf="r.name === 'Create_Incident'"
            message="{{'pages.user.createIncDesc' | translate}}"></app-help>
          <app-help *ngIf="r.name === 'Access_Change_Request'"
            message="{{'pages.user.accessCRDesc' | translate}}"></app-help>
          <app-help *ngIf="r.name === 'Access_Service_Request'"
            message="{{'pages.user.accessSRDesc' | translate}}"></app-help>
          <app-help *ngIf="r.name === 'Access_Incident'"
            message="{{'pages.user.accessIncDesc' | translate}}"></app-help>
        </div>
      </div>

      <div *ngIf="editting">
        <button class="btn btn-primary" type="submit">{{ 'pages.generic.update' | translate }}</button>
        <a [href]="'/user/management'" class="btn btn-primary">{{ 'pages.generic.cancel' | translate }}</a>
      </div>

      <div *ngIf="!editting">
        <button class="btn btn-primary" type="submit">{{ 'pages.generic.add' | translate }}</button>
        <a [href]="'/user/management'" class="btn btn-primary">{{ 'pages.generic.cancel' | translate }}</a>
      </div>
    </div>
  </form>
</div>

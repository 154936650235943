
<div class="card-body">
  <div class="row">
    <div class="col-4">
      <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="pieChartOptions"
          [(update)]="updateDonutFlag"
          [oneToOne]=true
          style="width: 100%; display: block; max-width: 400px; margin-left: auto; margin-right: auto;"
          ></highcharts-chart>
    </div>
    <div class="col-8">
      <div class="card">
        <h5 class="card-header d-flex flex-row justify-content-between align-items-center">
          <p class="align-middle mb-0" *ngIf="signaturesInfected === 0 && !scanNotFound">
            <fa-icon [icon]="['fas', 'check-circle']" size="md" data-placement="top" title="waiting icon" class="me-3"></fa-icon>
            {{'pages.updates.certTools.mfd.noEnginesDetected' | translate}}
          </p>
          <p class="align-middle mb-0" *ngIf="signaturesInfected > 0 && !scanNotFound">
            <fa-icon [icon]="['fas', 'exclamation-circle']" size="md" data-placement="top" title="waiting icon" class="me-3"></fa-icon>
            {{signaturesInfected}} {{'pages.updates.certTools.mfd.enginesDetected' | translate}}
          </p>
          <p class="align-middle mb-0" *ngIf="scanNotFound">
            <fa-icon [icon]="['fas', 'exclamation-circle']" size="md" data-placement="top" title="waiting icon" class="me-3"></fa-icon>
            {{'pages.updates.certTools.mfd.noEnginesResponse' | translate}}
          </p>
        </h5>
        <div class="card-body">
          <div class="row">
            <div class="col-8">
              <p class="lead font-bold">{{scanNotFound ? ('pages.updates.certTools.mfdResults.scanNotFound' | translate) : mfdNames[0]}}</p>
              <p class="text-muted">
                {{ scanNotFound ? "--" : mfdBasicProperties.sha256 }}
              </p>
              <p>
                <span class="tag tag-secondary">{{ scanNotFound ? "--" : mfdBasicProperties.mimeType }}</span>
              </p>
            </div>
            <div class="col-4 text-end">
              <p *ngIf="isDateDefined(mfdHistory?.lastAnalysis)">
                {{mfdHistory.lastAnalysis | date: 'mediumDate'}} <span class="tag">{{ 'pages.updates.certTools.mfdResults.date' | translate }}</span>
              </p>
              <p *ngIf="!isDateDefined(mfdHistory?.lastAnalysis)">
                -- <span class="tag">{{ 'pages.updates.certTools.mfdResults.date' | translate }}</span>
              </p>
              <p>
                {{mfdBasicProperties?.fileSize | humanFileSize }} <span class="tag">{{ 'pages.updates.certTools.mfdResults.size' | translate }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<br/>
<br/>

<div *ngIf="scanNotFound" class="alert alert-warning" role="alert">
  <span class="alert-icon"><span class="visually-hidden">Warning</span></span>
  <p>{{ 'pages.updates.certTools.mfdResults.scanNotFound' | translate }}</p>
</div>

<br/>
<br/>

<div class="card-body" *ngIf="!scanNotFound">
  <div class="row pt-3">
    <div class="col-1"></div>
    <div class="col-10">
      <ul class="nav nav-tabs nav-tabs-light">
        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="{{ 'pages.assets.dashboard.assetContractDesc' | translate }}">
          <a class="nav-link" [ngClass]="{ 'active' : page === 'engines' }" (click)="page = 'engines';">{{ 'pages.updates.certTools.mfdResults.engine' | translate }}</a>
        </li>
        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="{{ 'pages.assets.dashboard.assetFlagDesc' | translate }}">
          <a class="nav-link" [ngClass]="{ 'active' : page === 'details' }" (click)="page = 'details';">
            {{ 'pages.updates.certTools.mfdResults.details' | translate }}
          </a>
        </li>
        <li class="nav-item" data-toggle="tooltip" data-placement="top" title="{{ 'pages.assets.dashboard.assetFlagDesc' | translate }}">
          <a class="nav-link" [ngClass]="{ 'active' : page === 'links' }" (click)="page = 'links';">{{ 'pages.updates.certTools.mfdResults.links' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="card-body" *ngIf="page === 'engines'">

  <div class="row pt-3" *ngIf="enginesScan?.length > 0">
    <div class="col-1"></div>
    <div class="col-10">

      <div class="row faketableheaderrow">
        <div class="col-2 faketableheader text-start">
          {{ 'pages.updates.certTools.mfdResults.engine' | translate }}
        </div>
        <div class="col-2 faketableheader text-start">
          {{ 'pages.updates.certTools.mfdResults.version' | translate }}
        </div>
        <div class="col-3 faketableheader text-start">
          {{ 'pages.updates.certTools.mfdResults.analysisDate' | translate }}
        </div>
        <div class="col-2 faketableheader text-start">
          {{ 'pages.updates.certTools.mfdResults.updateDate' | translate }}
        </div>
        <div class="col-3 faketableheader text-end">
          {{ 'pages.updates.certTools.mfdResults.signature' | translate }}
        </div>
      </div>

      <div class="row faketablerow text-start" *ngFor="let scan of enginesScan">
        <div class="col-2 faketablecell text-start">
          {{scan.name}}
        </div>
        <div class="col-2 faketablecell text-start">
          {{scan.version}}
        </div>
        <div class="col-3 faketablecell text-start">
          {{scan.analysisDate | date: 'dd/MM/yyyy HH:mm'}}
        </div>
        <div class="col-2 faketablecell text-start">
          {{scan.updateDate}}
        </div>
        <div class="col-3 faketablecell text-end">
          <div *ngIf="!scan?.error">
            <fa-icon [icon]="['fas', 'check']" style="color: #32c832"></fa-icon>
            <span> Clean </span>
          </div>
          <div *ngIf="scan?.error">
            <fa-icon [icon]="['fas', 'xmark']" style="color: #cd3c14"></fa-icon>
            <span> {{scan.error}} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="card-body" *ngIf="page === 'details'">
  <div class="row pt-3">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="detail-panel" *ngIf="mfdBasicProperties">
        <p class="h4 font-bold">
          {{ 'pages.updates.certTools.mfdResults.basicProperties' | translate }}
        </p>
        <table class="table table-sm" aria-describedby="basic properties">
          <tbody>
            <tr>
              <th scope="row"><p class="text-muted mb-0">MD5</p></th>
              <td>{{mfdBasicProperties.md5}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">SHA1</p></th>
              <td>{{mfdBasicProperties.sha1}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">SHA256</p></th>
              <td>{{mfdBasicProperties.sha256}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">SSDEEP</p></th>
              <td>{{mfdBasicProperties.ssdeep}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.mimeType' | translate }}</p></th>
              <td>{{mfdBasicProperties.mimeType}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.mimeEncoding' | translate }}</p></th>
              <td>{{mfdBasicProperties.mimeEncoding}}</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.fileSize' | translate }}</p></th>
              <td>{{mfdBasicProperties.fileSize | humanFileSize}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row detail-panel" *ngIf="mfdHistory">
        <p class="h4 font-bold">
          {{ 'pages.updates.certTools.mfdResults.history' | translate }}
        </p>
        <table class="table table-sm" aria-describedby="file history">
          <tbody>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.firstSubmission' | translate }}</p></th>
              <td *ngIf="isDateDefined(mfdHistory?.lastAnalysis)">{{mfdHistory.firstSubmission | date: 'dd/MM/yyyy HH:mm'}}</td>
              <td *ngIf="!isDateDefined(mfdHistory?.lastAnalysis)">--</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.lastSubmission' | translate }}</p></th>
              <td *ngIf="isDateDefined(mfdHistory?.lastAnalysis)">{{mfdHistory.lastSubmission | date: 'dd/MM/yyyy HH:mm'}}</td>
              <td *ngIf="!isDateDefined(mfdHistory?.lastAnalysis)">--</td>
            </tr>
            <tr>
              <th scope="row"><p class="text-muted mb-0">{{ 'pages.updates.certTools.mfdResults.lastAnalysis' | translate }}</p></th>
              <td *ngIf="isDateDefined(mfdHistory?.lastAnalysis)">{{mfdHistory.lastAnalysis | date: 'dd/MM/yyyy HH:mm'}}</td>
              <td *ngIf="!isDateDefined(mfdHistory?.lastAnalysis)">--</td>
            </tr>
          </tbody>
        </table>
      </div>

    <div class="row detail-panel" *ngIf="mfdNames">
      <p class="h4 font-bold">
        {{ 'pages.updates.certTools.mfdResults.names' | translate }}
      </p>
      <p *ngFor="let name of mfdNames">{{name}}</p>
    </div>
    <div class="row detail-panel" *ngIf="mfdExifTools">
      <p class="h4 font-bold">
        {{ 'pages.updates.certTools.mfdResults.exifTools' | translate }}
      </p>
      <table class="table table-sm" aria-describedby="exif tools">
        <tbody>
          <tr *ngFor="let prop of mfdExifTools">
            <th scope="row"><p class="text-muted mb-0">{{prop.name}}</p></th>
            <td>{{prop.value}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row detail-panel" *ngIf="mfdTrid">
      <p class="h4 font-bold">
        {{ 'pages.updates.certTools.mfdResults.trid' | translate }}
      </p>
      <p *ngFor="let trid of mfdTrid">{{trid}}</p>
    </div>
  </div>
</div>
</div>

<div class="card-body" *ngIf="page === 'links'">
  <br />
  <br />
  <div class="row pt-3">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="alert alert-warning" role="alert">
        <span class="alert-icon"><span class="visually-hidden">Warning</span></span>
        <p>{{ 'pages.updates.certTools.mfdResults.noRecords' | translate }}</p>
      </div>
    </div>
  </div>
</div>



<!-- div class="card-body justify-content-center p-t-1 p-3" *ngIf="(page === 'assets' || page === 'flagged') && assetsLoaded">
    <ngb-pagination [collectionSize]="assets.length" pageSize="12" page="currentPage" [(page)]="currentPage" [maxSize]="10" aria-label="Pagination" (pageChange)="onPageChange($event)">
        <ng-template ngbPaginationPrevious></ng-template>
        <ng-template ngbPaginationNext></ng-template>
    </ngb-pagination>
</div> -->

<div class="row">
  <div class="col-xl-1 col-lg-1 hidden-md"></div>
  <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-xs-12">

    <div class="row">
        <div class="col-12">

            <div>
                <p>
                <p [innerHTML]="'pages.policy.cookie.noticeInfoP1' | translate"></p>
                <p>{{ 'pages.policy.cookie.noticeInfoP2' | translate }}</p>
                <ul>
                    <li>{{ 'pages.policy.cookie.noticeInfoP3' | translate }}</li>
                </ul>
                <p>&nbsp;</p>
                <p>{{ 'pages.policy.cookie.noticeInfoP4' | translate }}
                </p>
                <ul>
                    <li style="color:#ff7900">{{ 'pages.policy.cookie.noticeInfoP5' | translate }}</li>
                </ul>
                <p>&nbsp;</p>
                <h3><strong>{{ 'pages.policy.cookie.cookieList' | translate }}</strong></h3>
                <h4>{{ 'pages.policy.cookie.necessaryCookies' | translate }}</h4>
                <p>{{ 'pages.policy.cookie.necessaryCookiesInfo' | translate }}</p>
                <p>&nbsp;</p>
                <table id="cookie-policy" class="table" style="width: 85%; margin-left: auto; margin-right: auto;" aria-describedby="cookie policy">
                    <tbody>
                        <tr class="rowfiller">
                            <th>
                                <p>{{ 'pages.policy.cookie.cookieSubgroup' | translate }}</p>
                            </th>
                            <th>
                                <p>{{ 'pages.policy.cookie.cookies' | translate }}</p>
                            </th>
                            <th>
                                <p>{{ 'pages.policy.cookie.cookiesUsed' | translate }}</p>
                            </th>
                            <th>
                                <p>{{ 'pages.policy.cookie.lifeSpan' | translate }}</p>
                            </th>
                        </tr>
                        <tr *ngFor="let cookie of cookieList">
                            <td>
                                <p><strong>{{cookie.subgroup}}</strong></p>
                            </td>
                            <td>
                                <p>{{cookie.name}}</p>
                            </td>
                            <td>
                                <p>{{cookie.type}}</p>
                            </td>
                            <td>
                                <p>{{cookie.lifeSpan}}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br /><br /><br /><br />

        </div>
    </div>

  </div>
</div>

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  buildHttpParams,
  CybersocXDRDashboardSearchCriteria,
  CybersocXDRDashboardSearchCriteriaKey,
} from '../../model/searchCriteria';
import { CaseXDR, ExtendedCaseXDR } from '../../model/cybersocxdr/microsocticket';
import { SlideData, CommitteeShort, CommitteeData } from '../../model/cybersocxdr/committeeData';
import { SlaGauge } from '../../model/cybersocxdr/slagauge';
import { firstValueFrom } from 'rxjs';
import { IncidentScale } from '../../model/cybersocxdr/incidentScale';
import { CountOvertime } from '../../model/cybersocxdr/countOvertime';
import { IncidentCount } from '../../model/cybersocxdr/incidentCount';
import { ClientInfos } from '../../model/cybersocxdr/clientInfo';
import { InsightData } from '../../model/cybersocxdr/insight';
import { ClientContact } from '../../model/cybersocxdr/clientContact';
import { BacklogStatus } from '../../model/cybersocxdr/backlogStatus';
import { Last24HData } from '../../model/cybersocxdr/last24HData';
import { PaginatedList } from '../../model/cybersocxdr/paginatedList';
import { RepartitionChartItem } from '../../model/cybersocxdr/charts/common';
import { OpenedIncident } from '../../model/cybersocxdr/openedIncident';
import { IncidentEvolution } from '../../model/cybersocxdr/incidentEvolution';
import { IncidentDataSource } from '../../model/cybersocxdr/incidentDataSourceEnum';

const ROOT_URL = environment.endpoints.cybersocxdr;
const INCIDENTS_URL = ROOT_URL + 'incidents';
const BACKLOG_URL = ROOT_URL + 'backlog';
const HISTOGRAM_URL = INCIDENTS_URL + '/histogram';
const COMMITTEE_URL = ROOT_URL + 'committees';
const CLIENT_URL = ROOT_URL + 'clients';
const INSIGHT_URL = ROOT_URL + 'insight';

type QueryOptions = {
  headers?: HttpHeaders | { [header: string]: string | string[] };
  params?: HttpParams | { [param: string]: string | string[] };
  withCredentials?: boolean;
  body?: any;
};

@Injectable()
export class CybersocxdrService {
  constructor(private http: HttpClient) {}

  /** Wrapper for the promise and error handler */
  genericRequest<T>(method: string, url: string, options?: QueryOptions): Promise<T> {
    return firstValueFrom(this.http.request<T>(method, url, options))
      .then((response) => response)
      .catch(this.handleError);
  }

  genericGet<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('get', url, options);
  }

  genericPost<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('post', url, options);
  }

  genericPut<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('put', url, options);
  }

  genericDelete<T>(url: string, options?: QueryOptions): Promise<T> {
    return this.genericRequest('delete', url, options);
  }

  getSourceParams(source: IncidentDataSource): HttpParams {
    return new HttpParams().set('incidentDataSource', source);
  }

  async getDashboardData(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/severity', {
      params: buildHttpParams(searchCriteria, new HttpParams(), ['groupLevel1', 'groupLevel2']),
    });
  }

  async getIncidentsOverTime(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    const params = new HttpParams();

    return await this.genericGet<any>(INCIDENTS_URL, {
      params: buildHttpParams(searchCriteria, params, [
        'groupLevel1',
        'groupLevel2',
        'repartitionGroupbyLevel',
        'groupbyField',
        'subGroupbyField',
      ]),
    });
  }

  async getBacklogStatus(): Promise<BacklogStatus> {
    return await this.genericGet<BacklogStatus>(BACKLOG_URL + '/status');
  }

  async getLast24HData(): Promise<Last24HData> {
    return await this.genericGet<Last24HData>(BACKLOG_URL + '/last24h');
  }

  async getLastIncidentsNeedingActions(): Promise<CaseXDR[]> {
    return await this.genericGet<CaseXDR[]>(INCIDENTS_URL + '/lastNeedingActions');
  }

  async getMTTDOverTime(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/mttd', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getMTTIOverTime(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/mtti', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getMTTDTime(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/timemttd', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getMTTITime(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/timemtti', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getHistogramIncidents(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    const params = new HttpParams();

    return await this.genericGet<any>(HISTOGRAM_URL, {
      params: buildHttpParams(searchCriteria, params, [
        'groupLevel1',
        'groupLevel2',
        'repartitionGroupbyLevel',
        'groupbyField',
        'subGroupbyField',
      ]),
    });
  }

  async getIncidentsRepartitions(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/repartition', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getCategoryHeatMap(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/category-heatmap', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getTop5OpenedIncidents(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<OpenedIncident[]> {
    return await this.genericGet<any>(INCIDENTS_URL + '/top5-opened-incident', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getVolumeBubbles(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/volume-bubbles', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLANotifGauge(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<SlaGauge> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla/notification', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLAInvestGauge(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<SlaGauge> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla/investigation', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLAGlobalMttd(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<SlaGauge> {
    return await this.genericGet<any>(INCIDENTS_URL + '/globalmttd', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLAGlobalMtti(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<SlaGauge> {
    return await this.genericGet<any>(INCIDENTS_URL + '/globalmtti', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLANotifStatusRate(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla/notification/rate', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSLAInvestStatusRate(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla/investigation/rate', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }
  async getSlaBreakClosureVerdictOverTimeNotification(
    searchCriteria: CybersocXDRDashboardSearchCriteria
  ): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla-break-closure-verdict-notification', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getSlaBreakClosureVerdictOverTimeInvestigation(
    searchCriteria: CybersocXDRDashboardSearchCriteria
  ): Promise<any> {
    return await this.genericGet<any>(INCIDENTS_URL + '/sla-break-closure-verdict-investigation', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async isMicrosocXDRosmClient(): Promise<boolean> {
    return await this.genericGet<boolean>(CLIENT_URL + '/ismicrosocxdrclient').catch((error) => false);
  }

  async getCases(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<CaseXDR[]> {
    let params = new HttpParams();
    if (searchCriteria.sortBy) {
      params = params.append('sortBy', searchCriteria.sortBy.field);
      params = params.append('sortType', searchCriteria.sortBy.type);
    }
    params = buildHttpParams(searchCriteria, params, [
      'groupLevel1',
      'groupLevel2',
      'limit',
      'skip',
      'slaInvestigationStatusBroken',
      'slaNotificationStatusBroken',
      'status',
    ]);
    return await this.genericGet<CaseXDR[]>(INCIDENTS_URL + '/cases', {
      params,
    });
  }

  async getPaginatedCases(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<PaginatedList<CaseXDR>> {
    let params = new HttpParams();
    if (searchCriteria.sortBy) {
      params = params.append('sortBy', searchCriteria.sortBy.field);
      params = params.append('sortType', searchCriteria.sortBy.type);
    }
    params = buildHttpParams(searchCriteria, params, [
      'groupLevel1',
      'groupLevel2',
      'id',
      'limit',
      'skip',
      'slaInvestigationStatusBroken',
      'slaNotificationStatusBroken',
    ]);
    return await this.genericGet<PaginatedList<CaseXDR>>(INCIDENTS_URL + '/paginatedCases', {
      params,
    });
  }

  async getFlaggedPaginatedCases(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<PaginatedList<CaseXDR>> {
    let params = new HttpParams();
    if (searchCriteria.sortBy) {
      params = params.append('sortBy', searchCriteria.sortBy.field);
      params = params.append('sortType', searchCriteria.sortBy.type);
    }
    params = buildHttpParams(searchCriteria, params, ['limit', 'skip']);
    return await this.genericGet<PaginatedList<CaseXDR>>(INCIDENTS_URL + '/paginatedCases/flag', {
      params,
    });
  }

  async casesCSVExport(searchCriteria: CybersocXDRDashboardSearchCriteria) {
    let params = new HttpParams();
    if (searchCriteria.sortBy) {
      params = params.append('sortBy', searchCriteria.sortBy.field);
      params = params.append('sortType', searchCriteria.sortBy.type);
    }
    params = buildHttpParams(searchCriteria, params, [
      'groupLevel1',
      'groupLevel2',
      'id',
      'slaInvestigationStatusBroken',
      'slaNotificationStatusBroken',
    ]);
    return this.http.get(INCIDENTS_URL + '/cases/csv', {
      params,
      responseType: 'blob',
    });
  }

  /** Get a single case data */
  async getCase(id: number, source: IncidentDataSource = IncidentDataSource.ELASTIC): Promise<ExtendedCaseXDR> {
    return await this.genericGet(INCIDENTS_URL + `/case/${id}`, { params: this.getSourceParams(source) });
  }

  async countCases(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<IncidentCount> {
    const keys: CybersocXDRDashboardSearchCriteriaKey[] = [
      'slaInvestigationStatusBroken',
      'slaNotificationStatusBroken',
      'status',
    ];
    return await this.genericGet<IncidentCount>(INCIDENTS_URL + '/cases/count', {
      params: buildHttpParams(searchCriteria, new HttpParams(), keys),
    });
  }

  async getSimilarCases(id: number): Promise<Array<CaseXDR>> {
    return await this.genericGet(INCIDENTS_URL + `/case/${id}/similar`);
  }

  async getSimilarCasesCountOvertime(id: number): Promise<Array<CountOvertime>> {
    return await this.genericGet(INCIDENTS_URL + `/case/${id}/similar/count`);
  }

  async getCasesGroupBy(searchCriteria, groupBy: string): Promise<RepartitionChartItem[]> {
    return await this.genericGet(INCIDENTS_URL + `/cases/groupby/${groupBy}`, {
      params: buildHttpParams(searchCriteria, new HttpParams(), ['groupLevel1', 'groupLevel2']),
    });
  }

  async getCasesGroupByOvertime(searchCriteria, groupBy: string): Promise<RepartitionChartItem[]> {
    // Ajoutez les paramètres groupbyLevel et groupbyField à searchCriteria
    return await this.genericGet(INCIDENTS_URL, {
      params: buildHttpParams({ ...searchCriteria, groupbyLevel: 1, groupbyField: groupBy }, new HttpParams(), [
        'groupLevel1',
        'groupLevel2',
        'repartitionGroupbyLevel',
        'groupbyField',
      ]),
    });
  }

  async getCategoryCountOvertime(id: number): Promise<Array<CountOvertime>> {
    return await this.genericGet(INCIDENTS_URL + `/case/${id}/category/count`);
  }

  async getHighlights(): Promise<any> {
    return await this.genericGet<any>(COMMITTEE_URL + '/highlights');
  }

  async getGantt(): Promise<any> {
    return await this.genericGet<any>(COMMITTEE_URL + '/gantt');
  }

  async getCommitteeInfo(committeeId: number): Promise<SlideData> {
    return await this.genericGet<SlideData>(COMMITTEE_URL + '/' + committeeId);
  }

  async listCommittee(): Promise<Array<CommitteeShort>> {
    return await this.genericGet<Array<CommitteeShort>>(COMMITTEE_URL);
  }

  async previewCommittee(committeeData: CommitteeData): Promise<any> {
    let params = new HttpParams();
    if (committeeData.createdFrom) {
      params = params.append('createdFrom', committeeData.createdFrom);
    }
    if (committeeData.createdTo) {
      params = params.append('createdTo', committeeData.createdTo);
    }
    return await this.genericGet<any>(COMMITTEE_URL + '/preview', { params });
  }

  createCommittee(committeeData: CommitteeData): Promise<any> {
    return firstValueFrom(this.http.post(environment.endpoints.cybersocxdr + 'committees', committeeData))
      .then((response) => response)
      .catch(this.handleError);
  }

  async getClientInfo(): Promise<ClientInfos> {
    return await this.genericGet<ClientInfos>(CLIENT_URL + '/info');
  }

  async putClientContacts(newContacts: ClientContact[], contractId: number) {
    await this.genericPut(CLIENT_URL + `/contract/${contractId}/contacts`, {
      body: newContacts,
    });
  }

  async getIncidentCaseScale(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<IncidentScale> {
    return await this.genericGet<IncidentScale>(INCIDENTS_URL + '/cases/scale', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getFlaggedCasesId(source: IncidentDataSource = IncidentDataSource.ELASTIC): Promise<number[]> {
    return await this.genericGet<number[]>(INCIDENTS_URL + `/cases/flag`, { params: this.getSourceParams(source) });
  }

  async flagIncident(incidentId: number, source: IncidentDataSource = IncidentDataSource.ELASTIC) {
    return await this.genericPost<void>(INCIDENTS_URL + `/cases/flag/${incidentId}`, {
      params: this.getSourceParams(source),
    });
  }

  async deleteFlagIncident(incidentId: number, source: IncidentDataSource = IncidentDataSource.ELASTIC) {
    return await this.genericDelete<void>(INCIDENTS_URL + `/cases/flag/${incidentId}`, {
      params: this.getSourceParams(source),
    });
  }

  async getFilteredRepartitionData(
    searchCriteria: CybersocXDRDashboardSearchCriteria,
    groupByLevel: string
  ): Promise<any[]> {
    const data = await this.getIncidentsRepartitions(searchCriteria);
    return data?.filter((value) => value.id.split('.')[0] === groupByLevel);
  }

  async getGroupByFilterTypes(): Promise<string[]> {
    return await this.genericGet<string[]>(CLIENT_URL + '/repartition-filter-names');
  }

  async getAdvancedFilterValues(): Promise<any> {
    return await this.genericGet<string[][]>(INCIDENTS_URL + '/advanced-filters-values');
  }

  async getIncidentBySeverityEvolution(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<IncidentEvolution> {
    return await this.genericGet<any>(INCIDENTS_URL + '/severity/evolution', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getHasAnsweredSurvey(): Promise<boolean> {
    return await this.genericGet<boolean>(CLIENT_URL + '/survey/up-to-date');
  }

  async getClientInsights(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<InsightData[]> {
    return await this.genericGet<any>(INSIGHT_URL, {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getClient4AInsights(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<InsightData[]> {
    return await this.genericGet<any>(INSIGHT_URL + '/4A', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  async getClient3WInsights(searchCriteria: CybersocXDRDashboardSearchCriteria): Promise<InsightData[]> {
    return await this.genericGet<any>(INSIGHT_URL + '/3W', {
      params: buildHttpParams(searchCriteria, new HttpParams(), []),
    });
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}

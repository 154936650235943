import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WorldWatchUtilService {

    /**
     * 
     * @param list 
     */
    public getSeverityStyle(severity): string {
        switch (severity) {
            case 0:
                return 'information'
            case 1:
                return 'very-low';
            case 2:
                return 'low';
            case 3:
                return 'medium';
            case 4:
                return 'high';
            case 5:
                return 'critical';
        }
    }

    public getSeverityLabel(severity): string {
        switch (severity) {
            case 0:
                return 'pages.updates.signal.informative'
            case 1:
                return 'pages.updates.signal.veryLow';
            case 2:
                return 'pages.updates.signal.low';
            case 3:
                return 'pages.updates.signal.medium';
            case 4:
                return 'pages.updates.signal.high';
            case 5:
                return 'pages.updates.signal.critical';
        }
    }

}
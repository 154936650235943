<div class="p-12 text-center">
  <p>{{'pages.reports.document.fileReportdescriptionDesc' | translate}}</p>
</div>
<div class="pb-3">
  <div class="card" data-placement="top">
    <div class="card-body">
      <form>
        <div class="row justify-content-betwwen">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-3">
            <label for="filenameFilter">{{ 'pages.reports.document.fileFilterDesc' | translate }}</label>
            <input type="text" class="form-control" name="filename" id="filenameFilter"
              [(ngModel)]="fileFilter">
          </div>
          <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
            <div class="row">
              <div class="col-6">
                <div class="custom-label" id="createdFromLabel">
                  {{ 'pages.reports.document.createdFrom' | translate }}
                  <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
                </div>
                <input type="date" class="form-control" name="updatedFrom" aria-labelledby="createdFromLabel"
                  [(ngModel)]="createdFrom" (change)="validateDateRanges()"
                  [ngClass]="{'is-invalid': !validDateRanges}">
              </div>
              <div class="col-6">
                <div class="custom-label" id="createdToLabel">
                  {{ 'pages.reports.document.createdTo' | translate }}
                  <app-help message="{{ 'pages.generic.dateRangeHelpDesc' | translate }}"></app-help>
                </div>
                <input type="date" class="form-control" name="updatedTo" aria-labelledby="createdToLabel"
                  [(ngModel)]="createdTo" (change)="validateDateRanges()" [ngClass]="{'is-invalid': !validDateRanges}">
              </div>
            </div>
          </div>
        </div>
        <div class="ms-2 me-1">
          <button [disabled]="!validDateRanges" class="btn btn-primary" (click)="filter()">{{ 'pages.generic.filter' |
            translate }}</button>
          <button class="btn btn-primary" (click)="reset();">{{ 'pages.generic.reset' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="row mt-5">
  <div class="row mt-5 justify-content-center p-5" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
  <app-file-view *ngIf="!loading" [viewedFileStructure]="viewedFileStructure" [filesList]="filesList"
    class="me-3 mb-5"></app-file-view>
</div>
<br /><br />
<div class="upload-file">
  
  @if(uploading) {
    <!-- loader -->
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  } @else {
    <!-- file selection -->
    <div class="selection">
      <label class="btn btn-default">
        <input type="file" multiple (change)="selectFiles($event)" />
      </label>
      <app-help message="{{ 'pages.shared.upload-info' | translate : {maxSize: maxFileSizeMB} }}" size='xl'></app-help>
      <button class="btn btn-primary" [disabled]="!selectedFiles" (click)="uploadFiles()">
        {{ 'buttons.action.upload' | translate }}
      </button>
    </div>
  }
</div>
export enum InsightCategory {
  BACKLOG = 'backlog',
  INCIDENT = 'incident',
  FOUR_A_THREE_W = '4A3W',
}

export class InsightData {
  title: string;
  periodTotal: number;
  previousPeriodTotal: number;
  lastFiveValues: [number, number][];
  lowerIsBetter: boolean; // Used to display color of variations
  category: InsightCategory[];
}

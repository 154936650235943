export enum PriorityOneErrorContacts {
  netherlands = 'Orange Cyberdefense Netherlands: +31 88 1234250',
  belgium = 'Orange Cyberdefense Belgium: +32 78 77 00 24',
  denmark = 'Orange Cyberdefense Denmark: +45 70 200 438',
  norway = 'Orange Cyberdefense Norway: +47 67 57 37 47',
  sweden = 'Orange Cyberdefense Sweden: +46 40 694 51 95',
  swedenTollFree = 'Orange Cyberdefense Sweden (Toll free): 0200 12 52 31',
  unitedKingdom = 'Orange Cyberdefense United Kingdom: +44 (0)3333 441224',
  germany = 'Orange Cyberdefense Germany: +49 89 2000 1480 300'
}

<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.editColumns.title' | translate }}</h5>
  <button type="button" class="btn-close" (click)="close()"><span class="visually-hidden"></span></button>
</div>
<div class="modal-body">
  @if (params) {
  <div class="form-group col-12 pt-2">
    <label for="contact">{{ 'modals.editColumns.columnsToDisplay' | translate }}</label>
    <ng-multiselect-dropdown [settings]="params?.dropdownSettingsColumns" [data]="params?.columnsSelection"
      [(ngModel)]="params.selectedColumns" name="columnFilter">
    </ng-multiselect-dropdown>
  </div>
  }
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="close()">
    {{ 'pages.generic.close' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="success()">
    {{ 'pages.generic.save' | translate }}
  </button>

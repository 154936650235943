import { Component, OnInit } from '@angular/core';
import { CybersocxdrService } from '../../../shared/services/xtendedsoc/cybersocxdr.service';
import { Contract } from '../../../shared/model/cybersocxdr/clientInfo';
import { ClientContact } from 'src/app/shared/model/cybersocxdr/clientContact';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { XtendedFiltersService } from '../../xtendedfilters.service';

@Component({
  selector: 'app-xtended-contract-page',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss'],
})
export class XtendedSOCContractComponent implements OnInit {
  hasAnsweredSurvey: boolean = true;
  selectedTenant: string;

  private contractChangeSubscription: Subscription;
  contract: Contract | null = null;

  technoIconMap = {
    'Rapid7 InsightIDR': '/assets/images/icons/rapid7.svg',
    'Cortex XDR': '/assets/images/icons/palo.svg',
    'Cortex XSOAR': '/assets/images/icons/palo.svg',
    'Crowdstrike falcon': '/assets/images/icons/crowdstrike.svg',
    'Tehtris XDR': '/assets/images/icons/tehtris.svg',
    'Sentinel One': '/assets/images/icons/sentinelone.svg',
    Sekoia: '/assets/images/icons/sekoia.svg',
    Grafana: '/assets/images/icons/grafana.svg',
    'Harfanglab EDR': '/assets/images/icons/harfanglab.svg',
  };

  constructor(
    private cybersocxdrService: CybersocxdrService,
    public filterService: XtendedFiltersService,
    private readonly translate: TranslateService
  ) {}

  async ngOnInit() {
    this.hasAnsweredSurvey = await this.cybersocxdrService.getHasAnsweredSurvey();
    this.contract = this.filterService.selectedContract;
    this.contractChangeSubscription = this.filterService.contractChangeEvent.subscribe((contract) => {
      this.contract = contract;
    });
  }

  ngOnDestroy() {
    if (this.contractChangeSubscription) this.contractChangeSubscription.unsubscribe();
  }

  parseSLA(sla: string | number) {
    if (typeof sla == 'string') {
      const convertedMinutes = parseInt(sla, 10);
      if (Number.isNaN(convertedMinutes)) {
        return sla;
      }
      sla = convertedMinutes;
    }

    if (sla == 0) {
      // Best effort SLA
      return this.translate.instant('pages.cybersocxdr.dashboard.bestEffortSla');
    }

    if (sla < 60) {
      return `${sla} minutes`;
    }
    return `${Math.floor(sla / 60)}h` + (sla % 60 !== 0 ? `${sla % 60}min` : '');
  }

  /** Send the new data, and opportunistically update on the frontend */
  sendNewClientContacts(newContacts: ClientContact[]) {
    console.log('New contacts', newContacts);
    if (this.contract) {
      this.cybersocxdrService.putClientContacts(newContacts, this.contract?.contractId);
      this.filterService.clientInfo = {
        ...this.filterService.clientInfo,
        contracts: this.filterService.clientInfo?.contracts.map((c) =>
          c.contractId === this.contract?.contractId ? { ...c, contacts: newContacts } : c
        ),
      };
    }
  }
}

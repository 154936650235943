<div class="analytics" *ngIf="!pageLoading; else showSpinner">
  <p *ngIf="routesCount?.length === 0">No route visited</p>
  <div class="grid-container">
    <div *ngFor="let widget of widgetsOptions">
      <highcharts-chart [Highcharts]="highcharts" [options]='widget.options' [className]='widget.className'>
      </highcharts-chart>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <div class="row justify-content-center p-5" *ngIf="routesCount === undefined">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
</ng-template>
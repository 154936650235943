import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-mfd-upload',
  templateUrl: './mfd-upload.component.html',
  styleUrls: ['./mfd-upload.component.scss']
})
export class MfdUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLElement>;
  file: File = null;
  loading = false;
  loadingQuota = true;
  mfdQuota: number;

  constructor(
    private router: Router,
    private mfdService: MfdService,
    private alertService: AlertService,
    private userActivityService: UserActivityService,
    private notificationService: NotificationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.mfdService.getQuota()
    .then(quota => {
      this.mfdQuota = quota;
      this.loadingQuota = false;
    })
    .catch(e => {
      this.alertService.handlerError(e);
    });

    this.userActivityService.logActivity(TYPES.services, PAGES.mfdUpload, ACTIONS.visit);
  }

  upload() {
    this.loading = true;
    this.mfdService.upload(this.file)
    .then(record => {
      this.loading = false;
      this.notificationService.sendNotification(
        NotificationCategory.fileScan,
        {title: this.file.name, content: this.file.name},
        NotificationAudience.admins
      );
      if(record != null) {
        this.router.navigate(['/', 'updates', 'services', 'certtools', 'mfdresults', record.fileinfo.SHA256]);
      }
    })
    .catch(e => {
      this.alertService.addError(this.translateService.instant('pages.updates.certTools.mfd.fileUploadingFailed'));
      this.loading = false;
      this.alertService.handlerError(e);
    });
  }

  /** On file select */
  onChange(event) {
    this.file = event.target.files[0];
  }

  /** trigger a click on the file input element */
  triggerFileInputClick() {
    const el: HTMLElement = this.fileInput.nativeElement;
    el.click();
  }

}


<div class="row justify-content-center  p-5" *ngIf="!loaded">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>

<div class="row pt-3" *ngIf="loaded">
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
       {{'pages.securitycase.secIncDesc' | translate}}
       <div class="mt-4">
           <button class="btn btn-primary" (click)="exportReport(content);" data-toggle="tooltip" data-placement="top" title="{{ 'pages.support.dashboard.downloadResDesc' | translate }}" *ngIf="!generateReportLoading">
                {{ 'buttons.action.generateReport' | translate }}
            </button>
            <button class="btn btn-primary" type="button" *ngIf="generateReportLoading">
                <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                {{ 'pages.generic.loading' | translate }}
            </button>
       </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center pt-2" *ngIf="loaded && errorMessage">

        <h4 style="color:red;">{{errorMessage}}</h4>

    </div>
    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" *ngIf="loaded && !errorMessage">
        <!-- True positives vs false positives chart --> 
        <app-tpvsfp-chart [data]="chartsData.data" width="100%" height="500px"></app-tpvsfp-chart>

    </div>
</div>

<div class="row pt-3" *ngIf="loaded && !errorMessage">
    <div class="col-12">

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active' : page === 'tp' }" (click)="page = 'tp'">{{'pages.securitycase.truePositive' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active' : page === 'fp' }" (click)="page = 'fp'">{{'pages.securitycase.falsePositive' | translate}}</a>
            </li>
        </ul>

    </div>
</div>

<div class="row pt-3" *ngIf="loaded && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">

        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active' : subpage === 'analytics' }" (click)="subpage = 'analytics'">{{'pages.securitycase.analytics' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [ngClass]="{ 'active' : subpage === 'data' }" (click)="subpage = 'data'; dataTabClicked()">{{'pages.securitycase.data' | translate}}</a>
            </li>
        </ul>

    </div>
    <div class="col-1"></div>
</div>

<div class="row pt-4" *ngIf="loaded && page === 'tp' && subpage === 'analytics' && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">
        <div class="row">
            <!-- Threats chart -->
            <app-threats-chart [data]="chartsData.data" (showData)="showData($event)" width="100%" height="500px"></app-threats-chart>
        </div>
    </div>
</div>

<div class="row pt-5" *ngIf="loaded && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">

        <div class="monthly-navination row">
            <div class="col-12 text-center">
                <h2>{{'pages.securitycase.monthlyBreakdown' | translate}}</h2>
                <button
                    class="btn" [disabled]="!canGetPreviousMonth()"
                    (click)="canGetPreviousMonth() && changeMonth('back')">
                    <fa-icon [icon]="['fas', 'angles-left']" size="2x"></fa-icon>
                </button>
                <span style="font-size: 1.5rem;">{{chartsData.currentMonth}}</span>
                <button
                    class="btn" [disabled]="!canGetNextMonth()"
                    (click)="canGetNextMonth() && changeMonth('fwd')">
                    <fa-icon [icon]="['fas', 'angles-right']" size="2x"></fa-icon>
                </button>
            </div>
        </div>

    </div>
</div>

<div class="row pt-3" *ngIf="loaded && page === 'tp' && subpage === 'analytics' && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">
        <div class="row">
            <div class="wrapper">
                <!-- Charts grid -->
                <app-threat-actor-chart class="rankOneLeft"
                    [data]="chartsData.data"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-threat-actor-chart>
                <app-threat-killchain-chart class="rankOneRight"
                    [data]="chartsData.data"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-threat-killchain-chart>
                <app-threat-action-chart class="rankTwoLeft"
                    [data]="chartsData.data"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-threat-action-chart>
                <app-threat-assets-chart class="rankTwoRight"
                    [data]="chartsData.data"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-threat-assets-chart>
                <app-threat-attributes-chart class="rankThreeLeft"
                    [data]="chartsData.data"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-threat-attributes-chart>
            </div>
        </div>
    </div>
</div>

<div class="row pt-3" *ngIf="loaded && page === 'fp' && subpage === 'analytics' && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">
        <div class="row wrapper">
            <!-- False positives charts -->
            <div *ngFor="let chart of falsePositiveCategories; index as i;">
                <app-false-positives-chart *ngIf="chart" [class]="chartPositions[i]"
                    [data]="chartsData.data"
                    [chart]="chart"
                    [monthIndex]="chartsData.monthIndex"
                    (showData)="showData($event)">
                </app-false-positives-chart>
            </div>
        </div>
    </div>
</div>

<div class="row pt-3" *ngIf="loaded && page === 'tp' && subpage === 'data' && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">

        <div class="row">
            <div class="col-12">

                <div class="row faketableheaderrow">
                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 faketableheader" (click)="sortAssets('saseNumber');">
                        {{'pages.securitycase.caseNumber' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-2 d-none d-md-block faketableheader" (click)="sortAssets('status');">
                      {{'pages.securitycase.status' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8 faketableheader" (click)="sortAssets('Ssubject');">
                      {{'pages.securitycase.subject' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader" (click)="sortAssets('action');">
                      {{'pages.securitycase.actionSubAction' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader" (click)="sortAssets('actor');">
                      {{'pages.securitycase.actor' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader" (click)="sortAssets('killchain');">
                      {{'pages.securitycase.killChain' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                </div>

                <div class="row faketablerow" *ngFor="let case of chartsData.currentMonthDataTP" [routerLink]="['/support/view/' + case.id]" routerLinkActive="activeLink">
                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 faketablecell">
                        {{case.caseNumber}}
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-2 d-none d-md-block faketablecell">
                        {{case.status}}
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8  faketablecell">
                        {{case.subject}}
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">
                        {{case.action}} / {{case.subAction}}
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">
                        {{case.actor}}
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">
                        {{case.killchain | replace : ';' : ','}}
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="row pt-3" *ngIf="loaded && page === 'fp' && subpage === 'data' && !errorMessage">
    <div class="col-1"></div>
    <div class="col-10">

        <div class="row">
            <div class="col-12">

                <div class="row faketableheaderrow">
                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 faketableheader" (click)="sortAssets('caseNumber');">
                        {{'pages.securitycase.caseNumber' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketableheader" (click)="sortAssets('status');">
                        {{'pages.securitycase.status' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8 faketableheader" (click)="sortAssets('subject');">
                        {{'pages.securitycase.subject' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader" (click)="sortAssets('whatFalsePositive');">
                        {{'pages.securitycase.what' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketableheader" (click)="sortAssets('whyFalsePositive');">
                        {{'pages.securitycase.why' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                    <div class="col-xl-1 col-lg-1 d-none d-lg-block faketableheader" (click)="sortAssets('whoFalsePositive');">
                        {{'pages.securitycase.who' | translate}} <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
                    </div>
                </div>

                <div class="row faketablerow" *ngFor="let case of chartsData.currentMonthDataFP" [routerLink]="['/support/view/' + case.id]" routerLinkActive="activeLink">
                    <div class="col-xl-1 col-lg-1 col-md-2 col-sm-4 col-4 faketablecell">
                        {{case.caseNumber}}
                    </div>
                    <div class="col-xl-2 col-lg-2 col-md-2 d-none d-md-block faketablecell">
                        {{case.status}}
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-8 col-8 faketablecell">
                        {{case.subject}}
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">
                        {{case.whatFalsePositive}}
                    </div>
                    <div class="col-xl-2 col-lg-2 d-none d-lg-block faketablecell">
                        {{case.whyFalsePositive}}
                    </div>
                    <div class="col-xl-1 col-lg-1 d-none d-lg-block faketablecell">
                        {{case.whoFalsePositive}}
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.export.title' | translate }}</h5>
    </div>
    <div class="modal-body">
        <div class="form-group col-12 pt-2">
            <label for="contact">{{ 'modals.export.reportName' | translate }}</label>
            <input type="text" class="form-control" name="PDFName" aria-describedby="PDFName" [(ngModel)]="pdfName">
        </div>
        <div class="row">
            <div class="col-6">
                <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="reportOption">
                    <option value="csv">{{ 'pages.generic.reportCSV' | translate }}</option>
                </select>
            </div>
            <div class="col-6">
                <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="pdfAccessLevel">
                    <option title="{{ 'pages.generic.ACCESS_INTERNAL_REPORTS' | translate }}" value="0">{{ 'pages.generic.internal' | translate }}</option>
                    <option title="{{ 'pages.generic.ACCESS_CONFIDENTIAL_REPORTS' | translate }}" value="1">{{ 'pages.generic.confidential' | translate }}</option>
                    <option title="{{ 'pages.generic.ACCESS_RESTRICTED_REPORTS' | translate }}" value="2">{{ 'pages.generic.restricted' | translate }}</option>
            </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate }}</button>
    </div>
</ng-template>


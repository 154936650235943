<div class="customize">
  <div>
    <table class="table table-hover">
      <caption class="visually-hidden"></caption>
      <thead>
        <tr>
          <th scope="col">Widget</th>
          <th scope="col">Display</th>
        </tr>
      </thead>
      <tbody>
        @for (widget of availableWidgets; track widget.name) {
        <tr (click)="updateWidget(widget)">
          <td><label class="thiner" [for]="widget.name + '_switch'">{{ widget.displayName }}</label></td>
          <td>
            <div class="form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                [id]="widget.name + '_switch'"
                [(ngModel)]="widget.selected"
                [attr.aria-checked]="widget.selected"/>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  <div>
    <div class="row"
      dndDropzone
      (dndDrop)="onDrop($event)"
      [dndHorizontal]="false">
      <div dndPlaceholderRef class="placeholder"></div>
      <div *ngFor="let widget of selectedWidgets"
        class="card mb-1"
        [ngClass]="'col-'+widget.width"
        [dndDraggable]="widget"
        [dndEffectAllowed]="'linkMove'">
        <button type="button" class="btn btn-secondary btn-icon btn-sm close-button" (click)="deleteWidget(widget.name)">X</button>
        <app-fake-latest-activity *ngIf="widget.name === 'latestActivity'"></app-fake-latest-activity>
        <app-fake-recent-support-activity *ngIf="widget.name === 'recentSupportActivity'"></app-fake-recent-support-activity>
        <app-fake-widget1 *ngIf="widget.name === 'widget1'"></app-fake-widget1>
        <app-fake-widget2 *ngIf="widget.name === 'widget2'"></app-fake-widget2>
        <app-fake-worldwatch-carousel *ngIf="widget.name === 'worldwatch'"></app-fake-worldwatch-carousel>
        <app-fake-since-login *ngIf="widget.name === 'sinceLogin' && widget.width === 12"></app-fake-since-login>
        <app-fake-since-login-half-width *ngIf="widget.name === 'sinceLogin' && widget.width === 6"></app-fake-since-login-half-width>
        <app-fake-pending-cases-ocd *ngIf="widget.name === 'pendingCasesOcd'"></app-fake-pending-cases-ocd>
        <app-fake-open-cases *ngIf="widget.name === 'openCases'"></app-fake-open-cases>
        <app-fake-true-positives-vs-false-positives *ngIf="widget.name === 'truePositivesVSFalsePositives'"></app-fake-true-positives-vs-false-positives>
      </div>
    </div>
  </div>

</div>


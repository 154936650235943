<app-card
  title="pages.cybersocxdr.backlog.backlogSanityScore"
  actionTitle="pages.cybersocxdr.backlog.viewBacklog"
  actionRoute="/xtended-soc/dashboard"
>
  <div class="mb-4">
    <div
      class="progress h-100"
      role="progressbar"
      aria-valuenow="0"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <div class="progress-bar py-1" [style]="getBarStyle()">
        {{ backlogStatus?.sanityScore ?? 0 }}%
      </div>
    </div>
  </div>

  <div
    class="d-flex justify-content-between flex-wrap align-items-center mb-2 mb-md-4"
  ></div>
  <div class="row">
    <div
      class="col-5 d-flex flex-column justify-content-center align-items-center gap-2"
    >
      <div>
        <div class="d-flex justify-content-between gap-2 flex-wrap">
          <div class="fs-1 fw-bold">{{ backlogStatus?.total ?? 0 }}</div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <app-route-withfacets
          actionTitle="pages.cybersocxdr.backlog.olderThanAMonth"
          countOlderThan1Month="{{ backlogStatus?.olderThanAMonth ?? 0 }}"
          actionRoute="/xtended-soc/incidents"
        >
        </app-route-withfacets>
      </div>
    </div>
    <div class="col-7">
      <div id="backlog"></div>
    </div>
  </div>
</app-card>

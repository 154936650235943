<div class="container p-0 mw-100 overflow-x-auto">
  <app-tabbar [currentTab]="tab" [tabs]="tabs"></app-tabbar>
  <div
    class="container bg-body-tertiary p-3 border-0 tab-content"
    style="max-width: 1800px"
  >
    @if(tab === 'closed'){
    <div class="row justify-content-between mb-3">
      <div class="col-md-5 col-12">
        <app-card title="pages.cybersocxdr.4A3W.closedIncidentsPerVerdict">
          <app-pie
            [data]="incidentsClosedByVerdict"
            title=""
            suffix=""
            innerSize="0"
          />
        </app-card>
      </div>
      <div class="col-md-7 col-12">
        <app-card class="h-100 mw-100" title="pages.cybersocxdr.4A3W.closedIncidentsPerVerdict">
          <app-barovertime
            title=""
            [data]="incidentsClosedByVerdictOvertime"
          />
        </app-card>
      </div>
    </div>
    }@else if (tab === '4A') {
    <div class="row justify-content-between mb-3">
      <div class="col-md-5 col-12">
        <app-card title="pages.cybersocxdr.4A3W.4ATitle">
          <p class="lh-base">
            {{ 'pages.cybersocxdr.4A3W.4ADescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.actor' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.actorDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.action' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.actionDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.asset' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.assetDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.attribute' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.attributeDescription' | translate }}
          </p>
        </app-card>
        <app-card class="position-relative">
          <div class="mt-4">
            <app-treemap [data]="fourARepartitionData"></app-treemap>
          </div>
          <select
            class="form-select form-control rounded me-2"
            name="fourARepartitionField"
            aria-describedby="fourARepartitionField"
            [(ngModel)]="fourARepartitionField"
            id="fourARepartitionField"
            (change)="onSelect4ARepartition($event.target.value)"
          >
            <option
              *ngFor="let option of fourARepartitionOptions"
              [value]="option.value"
            >
              {{ option.translateKey | translate }}
            </option>
          </select>
        </app-card>
      </div>
      <div class="col-md-7 col-12">
        <app-card class="mw-100">
          <div class="row mx-1">
            <p class="col-4 fw-bold">
              {{ 'pages.cybersocxdr.4A3W.actor' | translate }}
            </p>
            <p class="col-4 text-center fw-bold">
              {{ 'pages.cybersocxdr.4A3W.action' | translate }}
            </p>
            <p class="col-4 text-end fw-bold">
              {{ 'pages.cybersocxdr.4A3W.asset' | translate }}
            </p>
          </div>
          <div class="row">
            <app-sankey [data]="sankey4ASeries" title="" />
          </div>
        </app-card>
        <app-card class="mw-100">
          <app-insight-carrousel
            [insights]="fourAInsights"
            [displayHeader]="false"
          />
        </app-card>
      </div>
      <div class="col-12">
        <app-card class="mw-100">
          <app-pagination-incident-list
            [currentPage]="truePositiveIncidentsCurrentPage"
            (fetchPage)="loadTruePositivePage($event)"
            (newFlagEmitter)="onFlag($event)"
          />
        </app-card>
      </div>
    </div>
    } @else if (tab === '3W') {
    <div class="row justify-content-between mb-3">
      <div class="col-md-5 col-12">
        <app-card title="pages.cybersocxdr.4A3W.3WTitle">
          <p class="lh-base">
            {{ 'pages.cybersocxdr.4A3W.3WDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.why' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whyDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.who' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whoDescription' | translate }}<br />
            <b> {{ 'pages.cybersocxdr.4A3W.what' | translate }}</b> :
            {{ 'pages.cybersocxdr.4A3W.whatDescription' | translate }}
          </p>
        </app-card>
        <app-card class="position-relative">
          <div class="mt-4">
            <app-treemap [data]="threeWRepartitionData"/>
          </div>
          <select
            class="form-select form-control rounded me-2"
            name="threeWRepartitionField"
            aria-describedby="threeWRepartitionField"
            [(ngModel)]="threeWRepartitionField"
            id="threeWRepartitionField"
            (change)="onSelect3WRepartition($event.target.value)"
          >
            <option
              *ngFor="let option of threeWRepartitionOptions"
              [value]="option.value"
            >
              {{ option.translateKey | translate }}
            </option>
          </select>
        </app-card>
      </div>
      <div class="col-md-7 col-12">
        <app-card class="mw-100">
          <div class="row mx-1">
            <p class="col-4 fw-bold">
              {{ 'pages.cybersocxdr.4A3W.why' | translate }}
            </p>
            <p class="col-4 text-center fw-bold">
              {{ 'pages.cybersocxdr.4A3W.who' | translate }}
            </p>
            <p class="col-4 text-end fw-bold">
              {{ 'pages.cybersocxdr.4A3W.what' | translate }}
            </p>
          </div>
          <div class="row">
            <app-sankey [data]="sankey3WSeries" title="" />
          </div>
        </app-card>
        <app-card class="mw-100">
          <app-insight-carrousel
            [insights]="threeWInsights"
            [displayHeader]="false"
          />
        </app-card>
      </div>
      <div class="col-12">
        <app-card class="mw-100">
          <app-pagination-incident-list
            [currentPage]="falsePositiveIncidentsCurrentPage"
            (fetchPage)="loadFalsePositivePage($event)"
            (newFlagEmitter)="onFlag($event)"
         />
        </app-card>
      </div>
    </div>
    }
  </div>
</div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CVE } from '../../model/cve';

@Component({
  selector: 'app-vuln-grid',
  templateUrl: './vuln-grid.component.html',
  styleUrls: ['./vuln-grid.component.scss']
})
export class VulnGridComponent implements OnInit {

  @Input()
  cves: CVE[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Input()
  loaded: boolean;

  @Output()
  flagEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  flagSignal(event, cveRef: string): void {
    event.stopPropagation();
    this.flagEmitter.emit(cveRef);
  }

}

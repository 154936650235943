import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'severity',
})
export class SeverityPipe implements PipeTransform {


  transform(value: string | number): string {
    if (typeof value === 'string') {
      value = parseInt(value, 10);
    }

    return [
      'pages.cybersocxdr.dashboard.severity_informational',
      'pages.cybersocxdr.dashboard.severity_low',
      'pages.cybersocxdr.dashboard.severity_medium',
      'pages.cybersocxdr.dashboard.severity_high',
      'pages.cybersocxdr.dashboard.severity_critical',
    ][value];
  }
}

import { Component, Input } from '@angular/core';
import { ModalParameters } from '../../modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GenericModalComponent {

  @Input() params: ModalParameters;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  public success() {
    this.activeModal.close();
    if(this.params.successCallback) {
      this.params.successCallback();
    }
  }

  public close() {
    this.activeModal.close();
    if(this.params.closeCallback) {
      this.params.closeCallback();
    }
  }
}

/* eslint-disable no-underscore-dangle */
import { Component, OnInit, ViewChild } from '@angular/core';
import { App, OktaGroup, OktaUser, UserOperation } from 'src/app/shared/model/okta';
import { AlertService } from 'src/app/shared/services/alert.service';
import { OktaUserService } from 'src/app/shared/services/oktauser.service';
import { OktaAuthService } from 'src/app/app.service';

import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageItem } from 'src/app/shared/model/shared-items';
import { ModalParameters } from 'src/app/shared/modals/modal';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';


@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UserManagementComponent implements OnInit {
  //
  CSOCUsers = environment.CSOCUsers;
  // data
  groups: OktaGroup[];
  adminGroup: OktaGroup;
  userGroup: OktaGroup;
  adminUsers: OktaUser[];
  userUsers: OktaUser[];
  displayedUsers: OktaUser[];

  // view variables
  dataLoaded = false;
  selectedTab: string;

  // users
  selectedUser: any;
  selectedUserLinks: any[];
  userFilter: string;

  // display
  redirecting = false;


  constructor(
    private router: Router,
    private oktaAuthService: OktaAuthService,
    private oktaUserService: OktaUserService,
    private alertService: AlertService,
    private modal: ModalService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.selectedTab = 'users';

    const currentTimestamp: number = Math.floor(Date.now() / 1000);

    if(localStorage.getItem(LocalStorageItem.oktaMta)
        && localStorage.getItem(LocalStorageItem.oktaMtaExpires)
        && (parseInt(localStorage.getItem(LocalStorageItem.oktaMtaExpires)) > currentTimestamp)
        && !this.oktaAuthService.oktaAuth.tokenManager.hasExpired(JSON.parse(localStorage.getItem(LocalStorageItem.oktaMta)))){

      this.setup();

    }else{

      this.redirecting = true;
      this.route.queryParams.subscribe(params => {
        if(!params.authd){
          this.oktaAuthService.storeOktaAdminInfoRedirect();
        }else{
          this.oktaAuthService.oktaAuth.token.parseFromUrl()
            .then(tokenContainer => {
              localStorage.setItem(LocalStorageItem.oktaMta,JSON.stringify({
                o4otoken: tokenContainer.tokens.accessToken.accessToken
              }));

              // manual tracking of mta token expiry
              const tokenExpirationTime = 3600; // seconds, as set in Okta
              const timestamp = Math.floor(Date.now() / 1000) + tokenExpirationTime;
              localStorage.setItem(LocalStorageItem.oktaMtaExpires, timestamp.toString());
            })
            .then(() => {
              this.setup();
              this.redirecting = false;
            });
        }
      });
    }
  }

   /**
    * This is called when the dropdown list of actions is initially clicked
    * It returns the full user object including a list of links which are the
    * lifecycle options for this user
    *
    * @param userId - user id
    */
  public selectUser(userId: string) {
    this.oktaUserService.getUser(userId)
      .then(res => {
        this.selectedUser = res;
        const links = [];
        if(this.selectedUser._links.resetPassword){
          links.push(new UserOperation('Reset Password', 'resetpassword'));
        }
        if(this.selectedUser._links.deactivate){
          links.push(new UserOperation('Deactivate', 'deactivate'));
        }
        if(this.selectedUser._links.activate){
          links.push(new UserOperation('Activate', 'activate'));
        }
        if(this.selectedUser._links.reactivate){
          links.push(new UserOperation('Reactivate', 'reactivate'));
        }
        if(this.selectedUser._links.delete){
          links.push(new UserOperation('Delete', 'delete'));
        }
        if(this.selectedUser._links.unlock){
          links.push(new UserOperation('Unlock', 'unlock'));
        }
        if(this.selectedUser._links.suspend){
          links.push(new UserOperation('Suspend', 'suspend'));
        }
        if(this.selectedUser._links.resetFactors){
          links.push(new UserOperation('Reset Factors', 'resetfactors'));
        }
        this.selectedUserLinks = links;
      })
      .catch(err => {
        this.alertService.handlerError(err);
      });
  }

  public filterUsers(){
    if(this.userFilter && this.userFilter.length > 0){
      this.displayedUsers = this.userUsers.filter(u => u.profile.email.includes(this.userFilter));
    }else{
      this.displayedUsers = JSON.parse(JSON.stringify(this.userUsers));
    }
  }

  public sortUsers(sort: string){
    this.displayedUsers.sort((a, b) => {
      let valA: string;
      let valB: string;
      if(sort === 'lastLogin'){
        valA = a.lastLogin.toUpperCase();
        valB = b.lastLogin.toUpperCase();
      }else if(sort === 'email'){
        valA = a.profile.email.toUpperCase();
        valB = b.profile.email.toUpperCase();
      }else if(sort === 'status'){
        valA = a.status.toUpperCase();
        valB = b.status.toUpperCase();
      }else{
        valA = '';
        valB = '';
      }

      if (valA < valB) {
        return -1;
      }
      if (valA > valB) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Run a lifecycle operation on the user object. This method creates the confirmation box
   *
   * @param userId - user id
   * @param operation - operation short name
   * @param userDisplay - user display name
   */
  public userOperation(userId: string, operation: string, userDisplay: string) {
    let operationDisplay: string;
    this.selectedUserLinks.forEach(lnk => {
        if(lnk.operation === operation){
          operationDisplay = lnk.name;
        }
    });

    const params = this.generateParams(userId, operation, operationDisplay, userDisplay);
    this.modal.confirmModal(params, 'lg');
  }

  /**
   * Open modal to confirm the upgrade of a user to admin
   *
   * @param userId - user id
   * @param userDisplay - user email address
   */
  public upgradeUserToAdmin(userId: string, userDisplay: string){
    const operation = 'upgradetoadmin';
    const operationDisplay = 'Upgrade To Admin';

    const params = this.generateParams(userId, operation, operationDisplay, userDisplay);
    this.modal.confirmModal(params, 'lg');
  }

  /**
   * Open modal to confirm the downgrade of a user from admin
   *
   * @param userId - user id
   * @param userDisplay - user email address
   */
  public downgradeUserFromAdmin(userId: string, userDisplay: string){
    const operation = 'downgradefromadmin';
    const operationDisplay = 'Downgrade From Admin';

    const params = this.generateParams(userId, operation, operationDisplay, userDisplay);
    this.modal.confirmModal(params, 'lg');
  }

  /**
   * Once confirmed run the action
   * Most operations come from a list of options in the user object from Okta
   * upgradetoadmin/downgradefromadmin are exceptions to that rule
   *
   * @param userId - user id
   * @param operation - operation name
   */
  private actionUserOperation(userId: string, operation: string, operationDisplay: string, userDisplay: string){
    if(operation === 'upgradetoadmin'){
      this.oktaUserService.addExistingUserToTenantAdmin(userId)
        .then(res => {
          const msg = 'upgraded to an admin';
          this.alertService.addSuccess(`${userDisplay} ${msg}`);
          this.notificationService.sendNotification(
            NotificationCategory.usersManagement,
            {title: userDisplay, content: `User ${userDisplay} has been ${msg}`}
          );
          this.ngOnInit();
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }else if(operation === 'downgradefromadmin'){
      this.oktaUserService.removeUserFromTenantAdmin(userId)
        .then(res => {
          const msg = 'removed as an admin';
          this.alertService.addSuccess(`${userDisplay} ${msg}`);
          this.ngOnInit();
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }else{
      this.oktaUserService.userOperation(userId, operation)
        .then(res => {
          this.alertService.addSuccess(operationDisplay + ' command completed for ' + userDisplay);
          this.ngOnInit();
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }
  }

  private generateParams(userId: string, operation: string, operationDisplay: string, userDisplay: string,) {
    const params = new ModalParameters();
    params.title = this.translateService.instant('pages.user.confirmation');
    params.closeBtnLabel = this.translateService.instant('buttons.action.cancel');
    params.successBtnLabel = this.translateService.instant('buttons.action.confirm');
    params.bodyMessage = `${this.translateService.instant('pages.user.confirmationLabel')} 
      <b>${operationDisplay}</b> ${this.translateService.instant('pages.generic.for')} <b>${userDisplay} ?</b>`;
    params.successCallback = () => this.actionUserOperation(userId, operation, operationDisplay, userDisplay);
    return params;
  }

  private setup(){
      this.oktaUserService.getUserGroups(this.oktaAuthService.userDetails.sub)
      .then(res => {

            this.groups = res;
            const adminGroupsTok = this.groups.filter(g => g.profile.name.startsWith('ADMINS_'));
            const userGroupsTok = this.groups.filter(g => g.profile.name.startsWith('USERS_'));

            if(adminGroupsTok.length > 1 || userGroupsTok.length > 1){
              this.alertService.handlerError(this.translateService.instant('pages.user.multipleTenants'));
            }else if(adminGroupsTok.length === 0 || userGroupsTok.length === 0){
              this.alertService.handlerError(this.translateService.instant('pages.user.permissionDenied'));
            }else if(adminGroupsTok.length === 1 && userGroupsTok.length === 1){

              this.adminGroup = adminGroupsTok[0];
              this.userGroup = userGroupsTok[0];
              this.oktaUserService.listUsersForGroup(this.adminGroup.id)
                .then(res2 => {
                  this.adminUsers = res2.filter(x => !this.CSOCUsers.includes(x.profile.email));
                })
                .catch(err => {
                  throw err;
                });

              this.oktaUserService.listUsersForGroup(this.userGroup.id)
                .then(res2 => {
                  this.userUsers = res2.filter(x => !this.CSOCUsers.includes(x.profile.email));
                  this.filterUsers();
                })
                .catch(err => {
                  throw err;
                });

              this.dataLoaded = true;
            }

      })
      .catch(err => {
        this.alertService.handlerError(err);
      });
  }

  public openNewRoles(user) {
    this.router.navigate(['/user/new-roles'], {queryParams: {id: user.id,email: user.profile.email }});
  }

}

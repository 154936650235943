import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { VulnProductSearchCriteria, VulnSearchCriteria, VulnSeverityStatsCriteria, VulnVendorSearchCriteria } from '../model/searchCriteria';
import { CVE } from '../model/cve';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MonthlyStat, MonthlyStats } from '../model/signal';
import { Utilities } from '../utilities';
import { SessionStorageItem } from '../model/shared-items';

@Injectable()
export class VulnerabilitiesService {

    private vulnVendorSearchURL = environment.endpoints.vulnerabilities + 'vendor/search';
    private vulnProductSearchURL = environment.endpoints.vulnerabilities + 'product/search';
    private vulnSearchURL = environment.endpoints.vulnerabilities + 'vulnerability/search';
    private vulnLoadListURL = environment.endpoints.vulnerabilities + 'vulnerability/loadList';
    private vulnLoadProductListURL = environment.endpoints.vulnerabilities + 'vulnerability/loadProductList';
    private vulnCPEUrl = environment.endpoints.vulnerabilities + 'cpe/';

    // Stats
    private vulnVendorCountURL = environment.endpoints.vulnerabilities + 'vendor/count';
    private vulnProductCountURL = environment.endpoints.vulnerabilities + 'product/count';
    private vulnCountURL = environment.endpoints.vulnerabilities + 'vulnerability/count';
    private vulnSeverityStatsURL = environment.endpoints.vulnerabilities + 'vulnerability/severityStats';
    private vulnLastUpdateURL = environment.endpoints.vulnerabilities + 'lastUpdate';
    private vulnAttributeMonthlyStatsURL = environment.endpoints.vulnerabilities + 'vulnerability/attributeMonthlyStats';

    constructor(private http: HttpClient) { }

    vulnVendorSearch(searchQuery: VulnVendorSearchCriteria): Promise<any[]> {
        return this.http
            .post(this.vulnVendorSearchURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    vulnProductSearch(searchQuery: VulnProductSearchCriteria): Promise<any[]> {
        return this.http
            .post(this.vulnProductSearchURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    vulnVendorName(searchQuery: string): Observable<string[]>{
        const searchCrit = new VulnProductSearchCriteria();
        searchCrit.typeahead = true;
        searchCrit.vendor = searchQuery;
        return this.http
            .post(this.vulnVendorSearchURL, JSON.stringify(searchCrit))
            .pipe(map(response => {
              const vendors = response as any[];
              vendors.sort();
              return (vendors).map(item => this.formatString(item.vendor));
            }));
    }

    vulnProductName(searchQuery: string, vendor: string): Observable<string[]>{
        const searchCrit = new VulnProductSearchCriteria();
        searchCrit.typeahead = true;
        if(vendor){
            searchCrit.vendor = vendor;
        }
        searchCrit.product = searchQuery;
        return this.http
            .post(this.vulnProductSearchURL, JSON.stringify(searchCrit))
            .pipe(map(response => {
                const products = response as any[];
                products.sort();
                return (products).map(item => this.formatString(item.product));
            }));
    }

    vulnSearch(searchQuery: VulnSearchCriteria): Promise<CVE[] | any> {
        return this.http
            .post(this.vulnSearchURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as CVE[])
            .catch(this.handleError);
    }

    vulnLoadProductList(searchQuery: VulnSearchCriteria): Promise<CVE[] | any[]> {
        return this.http
            .post(this.vulnLoadProductListURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    vulnLoadList(searchQuery: VulnSearchCriteria): Promise<CVE[] | any[]> {
        return this.http
            .post(this.vulnLoadListURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    vulnByCVE(cveRef: string): Promise<CVE> {
        const searchQuery = new VulnSearchCriteria();
        searchQuery.cve = cveRef;

        return this.http
            .post(this.vulnSearchURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response[0] as CVE)
            .catch(this.handleError);
    }

    vulnCPE(cpeRef: string): Promise<any> {
        return this.http
            .get(this.vulnCPEUrl + cpeRef)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    vulnVendorCount(): Promise<number> {
        return this.http
            .get(this.vulnVendorCountURL)
            .toPromise()
            .then(response => response as number)
            .catch(this.handleError);
    }

    vulnProductCount(): Promise<number> {
        return this.http
            .get(this.vulnProductCountURL)
            .toPromise()
            .then(response => response as number)
            .catch(this.handleError);
    }

    vulnCount(): Promise<number> {
        return this.http
            .get(this.vulnCountURL)
            .toPromise()
            .then(response => response as number)
            .catch(this.handleError);
    }

    vulnSeverityStats(searchQuery: VulnSeverityStatsCriteria): Promise<CVE> {
        return this.http
            .post(this.vulnSeverityStatsURL, JSON.stringify(searchQuery))
            .toPromise()
            .then(response => response[0] as CVE)
            .catch(this.handleError);
    }

    vulnLastUpdate(): Promise<any> {
        return this.http
            .get(this.vulnLastUpdateURL)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    vulnMonthlyStats(searchCritera: VulnSeverityStatsCriteria): Promise<MonthlyStats[]> {
        return this.http
            .post(this.vulnAttributeMonthlyStatsURL, JSON.stringify(searchCritera))
            .toPromise()
            .then(response => response as MonthlyStat[])
            .catch(this.handleError);
    }

    vulnsCountSinceLastLogin(date: Date): Promise<number> {
        return new Promise((resolve,reject) => {
            if(sessionStorage.getItem(SessionStorageItem.vulnsLastLoginCount)){
                resolve(Number(sessionStorage.getItem(SessionStorageItem.vulnsLastLoginCount)));
            }else{
                const tempSearchCriteria = new VulnSearchCriteria();
                tempSearchCriteria.modDateFrom = Utilities.getDateAsString(date);
                tempSearchCriteria.modDateTo = Utilities.getTodayPlusDays(1);
                tempSearchCriteria.count = true;
                this.vulnSearch(tempSearchCriteria)
                  .then(res => {
                    if(res && res.length == 0){
                        sessionStorage.setItem(SessionStorageItem.vulnsLastLoginCount, String(0));
                        resolve(0);
                    }else{
                        sessionStorage.setItem(SessionStorageItem.vulnsLastLoginCount, String(res[0].matching_vulns));
                        resolve(res[0].matching_vulns);
                    }
                  })
                  .catch(err => {
                      reject(err);
                  });
            }
        });
    }

    /** Replace _ with space and camel case words */
    formatString(word: string): string {
        let s = word;
        s = s.replace(/_/g, ' ');
        const splitStr = s.split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { XtendedSOCSLAComponent } from './pages/sla/sla.component';
import { CybersocxdrGuard } from './cybersocxdr.guard';
import { XtendedSOCIncidentDetailsComponent } from './pages/incident-details/incident-details.component';
import { XtendedSOC4A3WComponent } from './pages/4A3W/4A3W.component';
import { XtendedLayoutComponent } from '../layout/xtended-layout/xtended-layout.component';
import { XtendedSOCHomeComponent } from './pages/home/home.component';
import { XtendedSOCContractComponent } from './pages/contract/contract.component';
import { XtendedSOCIncidentsComponent } from './pages/incidents/incidents.component';
import { XtendedSOCDashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'xtended-soc',
    component: LayoutComponent,
    canActivate: [OktaAuthGuard, CybersocxdrGuard],
    children: [
      {
        path: '',
        component: XtendedLayoutComponent,
        children: [
          { path: 'xtended-soc', redirectTo: '', pathMatch: 'full' },
          {
            path: 'dashboard/:tab',
            component: XtendedSOCDashboardComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: 'dashboard',
            redirectTo: 'dashboard/overview',
            pathMatch: 'full',
          },
          {
            path: 'sla/:tab',
            component: XtendedSOCSLAComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: 'sla',
            redirectTo: 'sla/notification',
            pathMatch: 'full',
          },
          {
            path: 'incidents',
            component: XtendedSOCIncidentsComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: '4A3W/:tab',
            component: XtendedSOC4A3WComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: '4A3W',
            redirectTo: '4A3W/4A',
            pathMatch: 'full',
          },
          {
            path: 'contract',
            component: XtendedSOCContractComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: 'incident/:incidentId',
            component: XtendedSOCIncidentDetailsComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
          {
            path: '',
            component: XtendedSOCHomeComponent,
            pathMatch: 'full',
            data: {
              fullWidth: true,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CybersocxdrRoutingModule {}

import { AfterViewInit, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { getDefaultHighchartOptions, waitForAddedNode } from '../../utils';
import Highcharts from 'highcharts';
import { ContextService } from 'src/app/shared/services/context-service';

/**
 * Basic graph component, wrap the behavior common to all graphs:
 * - to facilitate DOM related operations
 * - data check for draw operations
 * - Destroying the graphs when needed
 */
@Component({
  selector: 'app-basegraph',
  template: '',
})
export abstract class BaseChartComponent<DataType, OptionType extends Highcharts.Options = Highcharts.Options>
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() public data: DataType;
  @Input() title = '';
  @Input() options: OptionType;
  @Input() showChartIfNoData: boolean = false;
  @Input() useDefaultOptions: boolean = true;
  containerRenderingId: string;
  internalRenderingId: string;
  isDOMInstanciated = false;
  hasNoData = false;

  // Ref to the chart drawn to the DOM
  chart: Highcharts.Chart;

  constructor(readonly context: ContextService) {
    const randomID = `${Math.random()}`.replace('.', '_');
    this.containerRenderingId = `chart_container_${randomID}`;
    this.internalRenderingId = `chart_${randomID}`;
  }

  ngAfterViewInit(): void {
    this.isDOMInstanciated = true;
    this.drawGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.drawGraph();
  }

  /* Avoid memory leaks by destroying the graphs */
  ngOnDestroy(): void {
    if (this.chart !== undefined && this.chart !== null) {
      this.chart.destroy();
    }
  }

  isReady(): boolean {
    return this.isDOMInstanciated;
  }

  /** Call the real drawing method if conditions are met */
  drawGraph() {
    if (this.isDataEmpty(this.data) || !this.isReady()) {
      if (!this.showChartIfNoData) this.hasNoData = true;
      return;
    }
    this.hasNoData = false;
    waitForAddedNode({
      recursive: false,
      parent: document.getElementById(this.containerRenderingId),
      observedId: this.internalRenderingId,
      done: (element) => {
        this.draw(this.internalRenderingId);
        this.createChart(
          this.internalRenderingId,
          this.useDefaultOptions
            ? Highcharts.merge({ ...getDefaultHighchartOptions(this.context.isDarkTheme()) }, { ...this.options })
            : this.options
        );
        this.chart.redraw(true);
      },
    });
  }

  /**
   * Create a chart to attach to the DOM.
   * Overrides may use this to create custom chart type not in the factory function
   */
  createChart(renderingId: string, options: Highcharts.Options): void {
    this.chart = Highcharts.chart(renderingId, options);
  }

  /**
   * For graphs with custom data, evaluate whether the data is empty.
   * Child implementations SHOULD call super
   * @param data The data stored by the graph
   */
  isDataEmpty(data: DataType): boolean {
    return (
      !data ||
      (Array.isArray(data) && data.length === 0) ||
      data[0]?.data?.length === 0 ||
      Object.keys(data).length === 0
    );
  }

  /**
   * Draw the highchart data.
   * Implementations are guaranteed to have both a DOM ready and some data to work with
   * That is, as long as the draw() is never called directly
   */
  abstract draw(renderingId: string): void;
}

<div class="row mt-auto mb-auto">
    <div class="col-12">
        
        <div class="row mb-2">
            <div class="card" style="max-width: 15rem;background-color: gray;" *ngFor="let app of appGroups">
                <ng-container *ngIf="app==='APP_TDC_USERS'">
                    <img class="card-img-top" style="height: 7rem;" src="assets/images/tdcLogo.svg" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">TDC</h5>
                        <p class="card-text"></p>
                        <a href={{tdcLoginURL}} class="btn btn-primary">Open App</a>
                    </div>
                </ng-container>
                <ng-container *ngIf="app==='APP_GLOBALPORTAL_USERS'">
                    <img class="card-img-top" style="height: 7rem;" src="assets/images/orange-logo.svg" alt="Card image cap">
                        <div class="card-body">
                        <h5 class="card-title">Global Portal</h5>
                        <p class="card-text"></p>
                        <a href="https://portal.orangecyberdefense.com/" class="btn btn-primary">Open APP</a>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
  </div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ModalParameters } from 'src/app/shared/modals/modal';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { App } from 'src/app/shared/model/okta';
import { AlertService } from 'src/app/shared/services/alert.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { OktaUserService } from 'src/app/shared/services/oktauser.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {

  selectedTenantApps: App[];

  // form for creating normal user
  userForm: FormGroup;
  submittedUser = false;
  activatedUserApps: any[];
  availableUserRoles: any[];
  selectedUserRoleIds: string[];
  adminGroupTargetGroups: any[];

  // editting user
  editting = false;
  edittingUser: any;
  userId: string;

  // display
  redirecting = false;

  //CheckingRoles
  accessSOCRole: string;
  accessCSOCRole: string;

  constructor(
    private oktaUserService: OktaUserService,
    private alertService: AlertService,
    private modal: ModalService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private router: Router
  ) { }

  get uf() { return this.userForm.controls; }

  ngOnInit() {
    this.setup();
    this.route.params.subscribe(params => {
      if(params?.userId) {
        this.editting = true;
        this.userId = params.userId;
      }
      this.oktaUserService.listTenantApps().then(res => {
        this.selectedTenantApps = res;
        if(params?.userId) {
          this.editUser(this.userId);
        }
      })
      .catch(err => {
        throw err;
      });
    });
  }

  setup(){
      this.activatedUserApps = [];
      this.availableUserRoles = [];
      this.selectedUserRoleIds = [];
      this.userForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]]
      }, {});
  }

  /**
   * Allow the editting of the a users groups
   * Uses the same form as the create user but with restrictions
   *
   * @param userId - user id
   */
  editUser(userId: string){

    this.oktaUserService.getUserIncGroups(userId).then(res => {
      this.editting = true;
      this.edittingUser = res;
      this.editting = true;

      res.groups.forEach(grp => {
        if(grp.profile.name.startsWith('APPUSERS')){
          const appId = grp.profile.name.split('_')[2];
          this.selectedTenantApps.forEach(ta => {
            if(ta.id === appId && this.activatedUserApps.indexOf(ta) === -1){
              this.activatedUserApps.push(ta);
            }
          });
        }
        if(grp.profile.name.startsWith('APPROLE')){
          const appId = grp.profile.name.split('_')[1];
          this.selectedTenantApps.forEach(ta => {
            if(ta.id === appId && this.activatedUserApps.indexOf(ta) === -1){
              this.activatedUserApps.push(ta);
            }
          });
          this.selectedUserRoleIds.push(grp.id);
        }
      });
      this.getAllRoles();
    })
    .catch(err => {
      this.alertService.handlerError(err);
    });
  }

  /**
   * Get all the role groups available for the apps that have been
   * selected for the new user.
   */
  getAllRoles(){
    this.availableUserRoles = [];
    this.activatedUserApps.forEach(aua => {
      this.oktaUserService.getAppRoles(aua.id)
        .then(res => {
          res.forEach(r => {
            r.appName = aua.name;
            r.selected = false;

            if(r.name.toString().toLowerCase() === 'access_csoc_role'){
              this.accessCSOCRole = r.id;
            }
            else if(r.name.toString().toLowerCase() === 'access_soc_role'){
              this.accessSOCRole = r.id;
            }
          });
          this.availableUserRoles = this.availableUserRoles.concat(res);
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    });
  }

  /**
   * Add a new user to the tenant
   * If the user has been edited then only update the users groups
   * Will allow existing users to be added as users to the tenant, as well as create new
   */
  addUser(){
    if(!this.selectedUserRoleIds.includes(this.accessCSOCRole) && !this.selectedUserRoleIds.includes(this.accessSOCRole)){
      const params = new ModalParameters();
      params.title = this.translateService.instant('pages.user.roles');
      params.closeBtnLabel = this.translateService.instant('buttons.action.close');
      params.bodyMessage = `${this.translateService.instant('pages.user.assignNewUser')} <br/><br/>`;

      this.modal.infoModal(params, 'lg');

      return false;
    }

    if(this.editting){
      this.activatedUserApps.forEach(ua => {
        if(!this.selectedUserRoleIds.includes(ua.APPUSERS_groupId)){
          this.selectedUserRoleIds.push(ua.APPUSERS_groupId);
        }
      });

      this.oktaUserService.updateUsersGroups(this.edittingUser.id, this.selectedUserRoleIds)
        .then(res => {

          const params = new ModalParameters();
          params.title = this.translateService.instant('pages.user.roles');
          params.closeBtnLabel = this.translateService.instant('buttons.action.close');
          params.bodyMessage = this.edittingUser.profile.email + ' ' + this.translateService.instant('pages.user.userUpdated');
          const self = this;
          params.closeCallback = () => self.router.navigateByUrl('/user/management').then(() => {
            window.location.reload();
          });

          this.modal.confirmModal(params, 'lg');
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });

    }else{
      this.submittedUser = true;
      if (this.userForm.invalid) {
          return;
      }
      this.userForm.value.login = this.userForm.value.email;
      // get all the activate app user groups and add them to the selected role group ids
      this.activatedUserApps.forEach(ua => {
        if(this.selectedUserRoleIds.includes(ua.APPUSERS_groupId)){
          this.selectedUserRoleIds.push(ua.APPUSERS_groupId);
        }
      });
      this.userForm.value.groupIds = this.selectedUserRoleIds;

      this.oktaUserService.addTenantUser(this.userForm.value)
        .then(res => {

          const params = new ModalParameters();
          const message = this.userForm.value.email + ' ' + this.translateService.instant('pages.user.userAdded');
          params.title = this.translateService.instant('pages.user.roles');
          params.closeBtnLabel = this.translateService.instant('buttons.action.close');
          params.bodyMessage = message;
          const self = this;
          params.closeCallback = () => self.router.navigateByUrl('/user/management').then(() => {
            window.location.reload();
          });
          this.notificationService.sendNotification(
            NotificationCategory.usersManagement,
            {title: this.userForm.value.email, content: message},
            NotificationAudience.admins
          );
          this.modal.confirmModal(params, 'lg');
        })
        .catch(err => {
          this.alertService.handlerError(err);
        });
    }
  }

  /**
   * Add this application to the new users available apps
   *
   * @param app - Application object
   */
  activateAppForUser(app: any){
    this.activatedUserApps.push(app);
    this.getAllRoles();
  }

  /**
   * Remove this application from the new users available apps
   * Also remove any role groups that are linked to this app
   *
   * @param app - Application object
   */
  deactivateAppForUser(app: any){
    const ind = this.activatedUserApps.indexOf(app);
    this.activatedUserApps.splice(ind, 1);

    this.availableUserRoles.forEach(aur => {
      if(aur.appName === app.name){
        const index2 = this.selectedUserRoleIds.indexOf(aur.id);
        this.selectedUserRoleIds.splice(index2, 1);
      }
    });
    this.getAllRoles();
  }

  /**
   * Add the role group id to the new users group ids
   *
   * @param event - click event
   * @param roleId - role id
   */
  selectRole(event, roleId: string){
    if(this.selectedUserRoleIds.includes(roleId)){
      const ind = this.selectedUserRoleIds.indexOf(roleId);
      this.selectedUserRoleIds.splice(ind, 1);
    }else{
      if(!this.selectedUserRoleIds.includes(roleId)){
        this.selectedUserRoleIds.push(roleId);
      }
    }
  }

}

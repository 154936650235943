<app-card
  title="pages.cybersocxdr.home.5OldestIncidentsNeedingAction"
  actionTitle="pages.cybersocxdr.home.viewIncidentsNeedingAction"
  actionRoute="/xtended-soc/incidents"
>
  <div class="row px-2">
    <table
      class="table table-striped table-hover"
      aria-describedby="incidentsNeedingActionTitle"
    >
      <thead>
        <tr>
          <th scope="col" class="col-1">
            {{ 'pages.cybersocxdr.home.id' | translate }}
          </th>
          <th scope="col" class="col-2">
            {{ 'pages.cybersocxdr.home.creationDate' | translate }}
          </th>
          <th scope="col" class="col-1">
            {{ 'pages.cybersocxdr.home.severity' | translate }}
          </th>
          <th scope="col" class="col-7">
            {{ 'pages.cybersocxdr.home.subject' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        @for (incident of incidents; track incident.id) {
        <tr>
          <th scope="row" class="align-middle">{{ incident.id }}</th>
          <th scope="row" class="align-middle">
            {{ incident.createdDate | date : 'dd/MM/yyyy' }}
          </th>
          <th scope="row" class="align-middle">
            <span
              [class]="getSeveritySpanClass(incident.severity)"
              [style]="getSeveritySpanStyle(incident.severity)"
              >{{ incident.severity | severity | translate }}</span
            >
          </th>
          <td class="align-middle">
            <a
              [routerLink]="['/xtended-soc/incident', incident.id]"
              class="text-decoration-none"
              >{{ incident.subject | anonymise }}</a
            >
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
</app-card>
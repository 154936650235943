<div class="cases">
  <div class="title">
    <div class="left title">
      <h3 [ngClass]="{'count': true, 'me-2': true, 'light-theme': theme === 'light'}">{{casesCount}}</h3>
      <h3>{{ 'pages.dashboard.openCases' | translate }}</h3>
    </div>
    <div class="right">
      <a routerLink="/support/dashboard/opencases" class="btn btn-sm btn-primary">{{ 'pages.generic.view' | translate
        }}</a>
    </div>
  </div>
  <div class="wrapper">
    <div class="faketableheaderrow" *ngIf="casesLoaded">
      <div class="col-4 faketableheader">{{ 'pages.support.dashboard.fieldTitles.reference' | translate }}</div>
      <div class="col-8 faketableheader">{{ 'pages.support.dashboard.fieldTitles.subject' | translate }}</div>
    </div>
  
    <div class="main-table">
      <a *ngFor="let case of cases; let even = even;" [routerLink]="['/support/view/' + case.id]"
        routerLinkActive="activeLink" style="text-decoration: none;">
        <div class="row faketablerow">
          <div class="col-4 faketablecell text-break" title="{{'pages.securitycase.caseNumber' | translate}}">{{ case.caseNumber }}</div>
          <div class="col-8 faketablecell text-break" title="{{'pages.securitycase.subject' | translate}}">{{ case.subject }}</div>
        </div>
      </a>
    </div>
  </div>

  <div class="footer-table"></div>

  <div class="row justify-content-center p-5" *ngIf="!casesLoaded">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
</div>

export enum OctaToken {
  accessToken = 'accessToken',
  idToken = 'idToken'
}

export class AddTenant {
    tenantName: string;
    firstName: string;
    lastName: string;
    email: string;
    login: string;
}

export class Tenant {
    id: string;
    name: string;
    ADMINS_groupId: string;
    created: string;
}

export class User {
    groupId: string;
    firstName: string;
    lastName: string;
    email: string;
    login: string;
}

export class UserOperation {
    name: string;
    operation: string; // reactivate, deactivate, delete, unlock, resetpassword, suspend, reset factors
    constructor(name: string, operation: string) {
        this.name = name;
        this.operation = operation;
     }
}

export class App {
    id: string;
    APPUSERS_groupId: string;
    name: string;
    created: string;
    lastUpdated: string;
    logo: AppLogo[];
    settings: AppSettings;
}
export class AppLogo {
    name: string;
    href: string;
    type: string;
}
export class AppSettings {
    allUser: boolean;
}

// ACTUAL OKTA OBJECTS
export class OktaUser {
    activated: string;
    created: string;
    credentials: OktaCredentials;
    id: string;
    lastLogin: string;
    lastUpdated: string;
    passwordChanged: string;
    profile: OktaProfile;
    status: string;
    statusChanged: string;
    type: OktaType;
    groups: any[]; // for returning user/groups together
}

export class OktaCredentials {
    provider: OktaProvider;
}

export class OktaProvider {
    type: string;
    name: string;
}

export class OktaType {
    id: string;
}

export class OktaProfile {
    email:  string;
    firstName:  string;
    lastName:  string;
    login:  string;
    mobilePhone:  string;
    secondEmail:  string;
}

export class OktaGroup {
    created: string;
    id: string;
    lastMembershipUpdated: string;
    lastUpdated: string;
    passwordChanged: string;
    profile: OktaGroupProfile;
    type: string;
}

export class OktaGroupProfile {
    name:  string;
    description:  string;
}

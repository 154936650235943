import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalFeedbackParameters } from 'src/app/shared/modals/modal';
import { Survey } from 'src/app/shared/model/feedback';


@Component({
  selector: 'app-global-feedback',
  templateUrl: './global-feedback.component.html',
  styleUrls: ['./global-feedback.component.scss']
})
export class GlobalFeedbackComponent implements OnInit {

  @Input() params: GlobalFeedbackParameters;

  public selectedSurvey: Survey;
  public selectedPage = 'selection';
  public feedback;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {

    if (this.params && this.params.page) {
      this.selectedPage = this.params.page;
    }

    if (this.params && this.params.feedback) {
      this.selectedPage = 'feedback';
      this.feedback = this.params.feedback;
    }
  }

  public success() {
    this.activeModal.close();
  }

  public close(withcallback: boolean) {
    this.activeModal.close();
    if (withcallback && this.params.closeCallback) {
      this.params.closeCallback();
    }
  }

  public openFeedbackModal() {
    this.selectedPage = 'feedback';
  }

  public openSurveyList() {
    this.selectedPage = 'survey-list';
  }

  public onSurveySelected(survey: Survey) {
    this.selectedSurvey = survey;
    this.selectedPage = 'survey-form';
  }

}

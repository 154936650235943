import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { AssetGridComponent } from './shared/components/asset-grid/asset-grid.component';
import { AssetListComponent } from './shared/components/asset-list/asset-list.component';
import { ContactListComponent } from './shared/components/contact-list/contact-list.component';
import { SupportListComponent } from './shared/components/support-list/support-list.component';
import { SupportGridComponent } from './shared/components/support.grid/support-grid.component';
import { CvePipe } from './shared/pipes/cve.pipe';
import { Linkify } from './shared/pipes/linkify.pipe';
import { RemoveWhiteSpacePipe } from './shared/pipes/removewhitespace.pipe';
import { FormatHTML } from './shared/pipes/formatHtml';
import { SignalPipe } from './shared/pipes/signal.pipe';
import { StatFilter } from './shared/pipes/statFilter';
import { UploadFilesComponent } from './shared/components/upload-files/upload-files.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ReplacePipe } from './shared/pipes/replace';
import { HelpComponent } from './shared/components/help/help.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { NotificationBadgeComponent } from './shared/components/notification-badge/notification-badge.component';
import { IncidentsByPriorityComponent } from './shared/components/incidents-by-priority/incidents-by-priority.component';
import { DataBoxesComponent } from './shared/components/data-boxes/data-boxes.component';
import { IncidentsByActorsComponent } from './shared/components/incidents-by-actors/incidents-by-actors.component';
import { IncidentPanelComponent } from './shared/components/incident-panel/incident-panel.component';
import { IncidentsByActionComponent } from './shared/components/incidents-by-action/incidents-by-action.component';
import { IncidentsByAttributeComponent } from './shared/components/incidents-by-attribute/incidents-by-attribute.component';
import { FileViewComponent } from './shared/components/file-view/file-view.component';
import { HumanSizePipe } from './shared/pipes/human-size.pipe';
import { StripHtmlPipe } from './shared/pipes/strip-html.pipe';
import { MFDDragAndDropComponent } from './updates/cert-tools/mfd-services/mdf-drag-and-drop/mdf-drag-and-drop.component';
import { SeverityPipe } from './shared/pipes/severity.pipe';
import { ShortUrlPipe } from './shared/pipes/shorten.pipe';
import { MultiTranslateHttpLoader } from './shared/multi-translate-http-loader';
import { InfiniteSelectComponent } from './shared/components/infinite-select/infinite-select.component';

/** AoT requires an exported function for factories */
export function createTranslateLoader(http: HttpClient) {
  const translationsVersion = environment.translationsVersion || new Date();
  return new MultiTranslateHttpLoader(http, {
    resources: [
      { prefix: './assets/i18n/', suffix: '.json?ver=' + translationsVersion },
      { prefix: './assets/i18n/cybersocxdr/', suffix: '.json?ver=' + translationsVersion },
    ],
    defaultLanguage: 'en',
  });
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    HighchartsChartModule,
    InfiniteSelectComponent
  ],
  declarations: [
    FormatHTML,
    StatFilter,
    Linkify,
    SignalPipe,
    CvePipe,
    ReplacePipe,
    RemoveWhiteSpacePipe,
    SupportGridComponent,
    SupportListComponent,
    AssetListComponent,
    ContactListComponent,
    AssetGridComponent,
    UploadFilesComponent,
    HelpComponent,
    NotificationBadgeComponent,
    IncidentsByPriorityComponent,
    DataBoxesComponent,
    IncidentsByActorsComponent,
    IncidentPanelComponent,
    IncidentsByActionComponent,
    IncidentsByAttributeComponent,
    FileViewComponent,
    HumanSizePipe,
    StripHtmlPipe,
    MFDDragAndDropComponent,
    SeverityPipe,
    ShortUrlPipe
  ],
  exports: [
    NotificationBadgeComponent,
    TranslateModule,
    FormatHTML,
    StatFilter,
    Linkify,
    SignalPipe,
    ReplacePipe,
    CvePipe,
    RemoveWhiteSpacePipe,
    SupportGridComponent,
    SupportListComponent,
    AssetListComponent,
    ContactListComponent,
    AssetGridComponent,
    HelpComponent,
    CommonModule,
    FontAwesomeModule,
    RouterModule,
    UploadFilesComponent,
    ReactiveFormsModule,
    IncidentsByPriorityComponent,
    DataBoxesComponent,
    IncidentsByActorsComponent,
    IncidentPanelComponent,
    IncidentsByActionComponent,
    IncidentsByAttributeComponent,
    FileViewComponent,
    HumanSizePipe,
    StripHtmlPipe,
    MFDDragAndDropComponent,
    SeverityPipe,
    ShortUrlPipe,
    InfiniteSelectComponent
  ],
})
export class AppSharedModule {}

<ul
  [class]="
    (isDarkThemeEnabled ? 'bg-dark ' : 'bg-white ') +
    'nav nav-underline mp-1 flex-nowrap'
  "
>
  @for (tab of tabs; track $index) {
  <li class="nav-item">
    <a [class]="getTabClasses(tab.value)" [routerLink]="tab.url">
      {{ tab.titleKey | translate }}
    </a>
  </li>
  }
</ul>

import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SaveFilterParameters, SaveFilterResponse } from '../../modal';
import { SupportCaseSearchCriteria } from 'src/app/shared/model/searchCriteria';

@Component({
  selector: 'app-save-filter',
  templateUrl: './save-filter-modal.component.html',
  styleUrls: ['./save-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SaveFilterModalComponent {

  @Input() params: SaveFilterParameters;

  public radioAction = 'create';
  public filterName: string;
  public selectedFilter: SupportCaseSearchCriteria;

  constructor(
    public activeModal: NgbActiveModal
  ) {}

  public success() {
    this.activeModal.close();
    const response = new SaveFilterResponse();
    if(this.radioAction === 'create') {
      response.filterName = this.filterName;
    } else {
      response.selectedFilter = this.selectedFilter;
    }
    this.params.successCallback(response);
  }

  public close() {
    this.activeModal.close();
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as Highcharts from 'highcharts';
import { graphconstants } from '../../graphconstants';
import { DataPoint, SecurityCaseActivity } from '../../chartModels';
import { Utilities } from 'src/app/shared/utilities';
import { Case } from 'src/app/shared/model/itsm';
import { ShowDataParameters } from '../base-chart/report-base-chart.component';
import { WidgetService } from 'src/app/shared/services/widget.service';

@Component({
  selector: 'app-threats-chart',
  templateUrl: './threats-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class ThreatsChartComponent implements OnInit {

  @Input() width = '100%';
  @Input() height = '500px';
  @Input() data!: Case[];
  @Output() showData: EventEmitter<ShowDataParameters> = new EventEmitter();

  public highcharts: typeof Highcharts = Highcharts;
  public updateFlag = false;
  public catColumnChartOptions = JSON.parse(JSON.stringify(graphconstants.catColulmnChartOptions));
  public defaultColumnChartOption = JSON.parse(JSON.stringify(graphconstants.columnChartOptions));

  public chartsList: ChartDef[] = [
    {name: 'actor', options: this.catColumnChartOptions, icon: ['fas', 'user']},
    {name: 'killchain', options: this.defaultColumnChartOption, icon: ['fas', 'bullseye']},
    {name: 'action', options: this.defaultColumnChartOption, icon: ['fas', 'check-square']},
    {name: 'attributes', options: this.defaultColumnChartOption, icon: ['far', 'check-square']},
    {name: 'u_asset', options: this.defaultColumnChartOption, icon: ['fas', 'list']},
  ];

  private xCat = [];
  private actors = [];
  private actions = [];
  private subactions = [];
  private attributes = [];
  private assets = [];
  private killchains = [];

  private caseMap: Map<string, SecurityCaseActivity>;

  constructor(
    private widgetService: WidgetService
  ) {}

  ngOnInit() {
    if (this.data && this.data.length > 0) {
      this.caseMap = this.buildCaseMap();
      this.formatGraphData();
      this.updateGraph('action');
    }
  }

  public updateGraph(datapoint: string) {
    const series: DataPoint[] = [];
    let typeTitle = '';

    if (datapoint === 'actor') {
      typeTitle = 'Actor';

      this.actors.forEach(act => {

        const seriesObj = new DataPoint();
        seriesObj.name = act;
        const seriesObjData = [];

        this.caseMap.forEach(cm => {
          if (!cm.actor.get(act)) {
            seriesObjData.push(0);
          } else {
            seriesObjData.push(cm.actor.get(act));
          }
        });

        seriesObj.data = seriesObjData;
        series.push(seriesObj);

      });

    } else if (datapoint === 'action') {
        typeTitle = 'Action';

        this.actions.forEach(act => {

          const seriesObj = new DataPoint();
          seriesObj.name = act;
          const seriesObjData = [];

          this.caseMap.forEach(cm => {
            if (!cm.action.get(act)) {
              seriesObjData.push(0);
            } else {
              seriesObjData.push(cm.action.get(act));
            }
          });

          seriesObj.data = seriesObjData;
          series.push(seriesObj);

        });

    } else if (datapoint === 'subaction') {
        typeTitle = 'Sub Action';

        this.subactions.forEach(act => {

          const seriesObj = new DataPoint();
          seriesObj.name = act;
          const seriesObjData = [];

          this.caseMap.forEach(cm => {
            if (!cm.subAction.get(act)) {
              seriesObjData.push(0);
            } else {
              seriesObjData.push(cm.subAction.get(act));
            }
          });

          seriesObj.data = seriesObjData;
          series.push(seriesObj);

        });

    } else if (datapoint === 'killchain') {
        typeTitle = 'Kill Chain';

        this.killchains.forEach(act => {

          const seriesObj = new DataPoint();
          seriesObj.name = act;
          const seriesObjData = [];

          this.caseMap.forEach(cm => {
            if (!cm.killChain.get(act)) {
              seriesObjData.push(0);
            } else {
              seriesObjData.push(cm.killChain.get(act));
            }
          });

          seriesObj.data = seriesObjData;
          series.push(seriesObj);

        });
    }
    else if (datapoint === 'u_asset') {
      typeTitle = 'Asset';

      this.assets.forEach(act => {

        const seriesObj = new DataPoint();
        seriesObj.name = act;
        const seriesObjData = [];

        this.caseMap.forEach(cm => {
          if (!cm.u_asset.get(act)) {
            seriesObjData.push(0);
          } else{
            seriesObjData.push(cm.u_asset.get(act));
          }
        });

        seriesObj.data = seriesObjData;
        series.push(seriesObj);

      });
  }
  else if (datapoint === 'attributes') {
        typeTitle = 'Attributes';

        this.attributes.forEach(act => {

          const seriesObj = new DataPoint();
          seriesObj.name = act;
          const seriesObjData = [];

          this.caseMap.forEach(cm => {
            if (!cm.attributes.get(act)) {
              seriesObjData.push(0);
            } else {
              seriesObjData.push(cm.attributes.get(act));
            }
          });

          seriesObj.data = seriesObjData;
          series.push(seriesObj);
        });
    }

    this.catColumnChartOptions.xAxis.categories = this.xCat;
    this.catColumnChartOptions.series = [...series];
    this.catColumnChartOptions.title.text = 'Threat ' + typeTitle;

    this.widgetService.updateWidgetStyle(this.catColumnChartOptions, null);

    this.updateFlag = true;
  }

  private buildCaseMap(): Map<string, SecurityCaseActivity> {
    const result = new Map();
    this.data.forEach(c => {
      const cd = new Date(c.createdDate);
      const actualMonth = cd.getMonth()+1;
      const dateStr = cd.getFullYear() + '-' + actualMonth;

      if (!result.get(dateStr)) {
        result.set(dateStr, new SecurityCaseActivity(dateStr));
      }

      if (Utilities.checkTruePositive(c.caseNumber, c.status, c.closureCode)) {
        const caseM = result.get(dateStr);
        caseM.truePositives.push(c);

        if (c.action) {
          const actionM = result.get(dateStr);
          if (actionM.action.get(c.action)) {
            actionM.action.set(c.action, actionM.action.get(c.action)+1);
          } else{
            actionM.action.set(c.action, 1);
          }
          result.set(dateStr, actionM);
        }

        if (c.subAction) {
          const subactionM = result.get(dateStr);
          if (subactionM.subAction.get(c.subAction)) {
            subactionM.subAction.set(c.subAction, subactionM.subAction.get(c.subAction)+1);
          } else{
            subactionM.subAction.set(c.subAction, 1);
          }
          result.set(dateStr, subactionM);
        }

        if (c.killchain) {
          const killchainM = result.get(dateStr);
          let killchains = [];
          if (c.killchain.includes(';')) {
            killchains = c.killchain.split(';');
          } else{
            killchains.push(c.killchain);
          }

          killchains.forEach(kc => {
            if (killchainM.killChain.get(kc)) {
              killchainM.killChain.set(kc, killchainM.killChain.get(kc)+1);
            } else{
              killchainM.killChain.set(kc, 1);
            }
            result.set(dateStr, killchainM);
          });
        }
        if (c.u_asset) {
          const assetM = result.get(dateStr);
          let u_asset = [];
          if (c.u_asset.includes(';')) {
            u_asset = c.u_asset.split(';');
          } else{
            u_asset.push(c.u_asset);
          }

          u_asset.forEach(kc => {
            if (assetM.u_asset.get(kc)) {
              assetM.u_asset.set(kc, assetM.u_asset.get(kc)+1);
            } else{
              assetM.u_asset.set(kc, 1);
            }
            result.set(dateStr, assetM);
          });
        }

        if (c.actor) {
          const actorM = result.get(dateStr);
          if (actorM.actor.get(c.actor)) {
            actorM.actor.set(c.actor, actorM.actor.get(c.actor)+1);
          } else {
            actorM.actor.set(c.actor, 1);
          }
          result.set(dateStr, actorM);
        }

        if (c.attributes) {
          const attributeM = result.get(dateStr);
          if (attributeM.attributes.get(c.attributes)) {
            attributeM.attributes.set(c.attributes, attributeM.attributes.get(c.attributes)+1);
          } else{
            attributeM.attributes.set(c.attributes, 1);
          }
          result.set(dateStr, attributeM);
        }
      }

      if (c.asset && c.asset.name) {
        const assNameM = result.get(dateStr);
        if (assNameM.assetName.get(c.asset.name)) {
          assNameM.assetName.set(c.asset.name, assNameM.attributes.get(c.asset.name)+1);
        } else{
          assNameM.assetName.set(c.asset.name, 1);
        }
        result.set(dateStr, assNameM);
      }

      if (c.asset && c.asset.vendor) {
        const assVenM = result.get(dateStr);
        if (assVenM.assetVendor.get(c.asset.vendor)) {
          assVenM.assetVendor.set(c.asset.vendor, assVenM.attributes.get(c.asset.vendor)+1);
        } else{
          assVenM.assetVendor.set(c.asset.vendor, 1);
        }
        result.set(dateStr, assVenM);
      }

    });
    return result;
  }

  private formatGraphData() {
    this.caseMap = new Map([...this.caseMap.entries()].reverse());
      this.caseMap.forEach(cm => {
        this.xCat.push(cm.date.slice(5) + '/' + cm.date.slice(0,4));

        cm.actor.forEach((k,y) => {
          if (!this.actors.includes(y)) {
            this.actors.push(y);
          }
        });

        cm.action.forEach((k,y) => {
          if (!this.actions.includes(y)) {
            this.actions.push(y);
          }
        });

        cm.subAction.forEach((k,y) => {
          if (!this.subactions.includes(y)) {
            this.subactions.push(y);
          }
        });

        cm.attributes.forEach((k,y) => {
          if (!this.attributes.includes(y)) {
            this.attributes.push(y);
          }
        });

        cm.killChain.forEach((k,y) => {
          if (!this.killchains.includes(y)) {
            this.killchains.push(y);
          }
        });
        cm.u_asset.forEach((k,y) => {
          if (!this.assets.includes(y)) {
            this.assets.push(y);
          }
        });
        this.actors.sort();
        this.actions.sort();
        this.killchains.sort();
        this.assets.sort();
        this.attributes.sort();
        this.subactions.sort();
      });
  }

  /** not working for now */
  private loadPlotOptions() {
    this.chartsList.forEach( chart => {
      chart.options.plotOptions.series = {
        cursor: 'pointer',
        events: {
          click: (event) => {
            this.showData.emit({
              series: chart.name,
              seriesName: event.point.name,
              posi: true
            });
          }
        }
      };
    });
  }

}

type ChartDef = {
  name: string;
  options: any;
  icon: string[];
};

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SupportRoutingModule } from './support-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { SupportDashboardComponent } from './supportdashboard/supportdashboard.component';
import { FormsModule } from '@angular/forms';
import { SupportViewComponent } from './supportview/supportview.component';
import { SupportLogComponent } from './supportlog/supportlog.component';
import {SupportcontactsComponent} from './supportcontacts/supportcontacts.component';
import {SupportcontactsaddComponent} from './supportcontactsadd/supportcontactsadd.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssetAndServiceComponent } from './supportlog/filters/asset-and-service/asset-and-service.component';

@NgModule({
  declarations: [
    SupportDashboardComponent,
    SupportViewComponent,
    SupportLogComponent,
    SupportcontactsComponent,
    SupportcontactsaddComponent
  ],
  imports: [
    NgMultiSelectDropDownModule,
    CommonModule,
    NgbModule,
    SupportRoutingModule,
    FormsModule,
    AppSharedModule,
    InfiniteScrollModule,
    AssetAndServiceComponent
  ],
  exports: [
    SupportDashboardComponent, SupportViewComponent, SupportLogComponent,SupportcontactsComponent,SupportcontactsaddComponent
  ],
  bootstrap: [
    SupportDashboardComponent, SupportViewComponent, SupportLogComponent,SupportcontactsComponent,SupportcontactsaddComponent
  ]
})
export class SupportModule { }

import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, forkJoin, map } from 'rxjs';

export type Resource = { prefix: string; suffix: string };

export class MultiTranslateHttpLoader implements TranslateLoader {
  resources: Resource[];
  defaultLanguage: string;

  constructor(
    private readonly http: HttpClient,
    { resources, defaultLanguage }: { resources: Resource[]; defaultLanguage: string }
  ) {
    this.resources = resources;
    this.defaultLanguage = defaultLanguage;
  }

  getTranslation(lang: string): Observable<Record<string, unknown>> {
    let resources: Resource[] = [...this.resources];

    return forkJoin([
      ...resources.map((config: Resource) => {
        return this.http.get<Record<string, unknown>>(`${config.prefix}${this.defaultLanguage}${config.suffix}`);
      }),
      ...resources.map((config: Resource) => {
        return this.http.get<Record<string, unknown>>(`${config.prefix}${lang}${config.suffix}`);
      }),
    ]).pipe(map((response: Record<string, unknown>[]) => mergeObjectsRecursively(response)));
  }
}

export const mergeObjectsRecursively = (objects: Record<string, unknown>[]): Record<string, unknown> => {
  const mergedObject: Record<string, unknown> = {};

  for (const obj of objects) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          mergedObject[key] = mergeObjectsRecursively([
            mergedObject[key] as Record<string, unknown>,
            obj[key] as Record<string, unknown>,
          ]);
        } else {
          mergedObject[key] = obj[key];
        }
      }
    }
  }

  return mergedObject;
};

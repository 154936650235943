import { Component, OnInit } from '@angular/core';
import { XtendedChartService } from 'src/app/shared/services/xtendedsoc/xtended-chart.service';
import { XtendedFiltersService } from '../../xtendedfilters.service';
import { XtendedSearchable } from '../xtended-page';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Tab } from '../../components/tabbar/tabbar.component';
import { InsightData } from 'src/app/shared/model/cybersocxdr/insight';
import { CybersocxdrService } from 'src/app/shared/services/xtendedsoc/cybersocxdr.service';
import { CaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { PaginatedList } from 'src/app/shared/model/cybersocxdr/paginatedList';
import { XtendedFacet } from 'src/app/shared/model/cybersocxdr/facets';
import { XtendedHighchartService } from '../../xtended-highchart.service';
import { PointOptionsObject } from 'highcharts';
import { ClosureVerdict } from 'src/app/shared/model/cybersocxdr/facetsValues';
import { Contract } from 'src/app/shared/model/cybersocxdr/clientInfo';
import { Subscription } from 'rxjs';
import { Fields4A3W } from 'src/app/shared/model/cybersocxdr/fields4A3W';

@Component({
  selector: 'app-xtended-4A3W-page',
  templateUrl: './4A3W.component.html',
  styleUrls: ['./4A3W.component.scss'],
})
export class XtendedSOC4A3WComponent implements OnInit, XtendedSearchable {
  //Url param
  tab: string;

  private contractChangeSubscription: Subscription;
  contract: Contract | null = null;

  tabs: Tab[] = [
    { titleKey: 'pages.cybersocxdr.4A3W.tabClosed', value: 'closed', url: '/xtended-soc/4A3W/closed' },
    { titleKey: 'pages.cybersocxdr.4A3W.tab4A', value: '4A', url: '/xtended-soc/4A3W/4A' },
    { titleKey: 'pages.cybersocxdr.4A3W.tab3W', value: '3W', url: '/xtended-soc/4A3W/3W' },
  ];

  constructor(
    private globalFilters: XtendedFiltersService,
    private xtendedChartService: XtendedChartService,
    private cybersocxdrService: CybersocxdrService,
    private route: ActivatedRoute,
    private router: Router,
    private xtendedHighchartService: XtendedHighchartService
  ) {}

  //Data
  incidentsClosedByVerdictOvertime: Highcharts.SeriesOptionsType[];
  incidentsClosedByVerdict: Highcharts.SeriesPieOptions[];

  sankey4ASeries: Highcharts.SeriesSankeyOptions[];
  sankey3WSeries: Highcharts.SeriesSankeyOptions[];

  fourARepartitionData: PointOptionsObject[];
  fourARepartitionField: Fields4A3W = Fields4A3W.ACTION;
  fourARepartitionOptions = [
    { value: Fields4A3W.ACTION, translateKey: 'pages.cybersocxdr.4A3W.action' },
    { value: Fields4A3W.ACTOR, translateKey: 'pages.cybersocxdr.4A3W.actor' },
    { value: Fields4A3W.ASSET, translateKey: 'pages.cybersocxdr.4A3W.asset' },
    { value: Fields4A3W.ATTRIBUTE, translateKey: 'pages.cybersocxdr.4A3W.attribute' },
  ];
  fourAInsights: InsightData[] = [];

  threeWRepartitionData: PointOptionsObject[];
  threeWRepartitionField: Fields4A3W = Fields4A3W.WHO;
  threeWRepartitionOptions = [
    { value: Fields4A3W.WHO, translateKey: 'pages.cybersocxdr.4A3W.who' },
    { value: Fields4A3W.WHY, translateKey: 'pages.cybersocxdr.4A3W.why' },
    { value: Fields4A3W.WHAT, translateKey: 'pages.cybersocxdr.4A3W.what' },
  ];
  threeWInsights: InsightData[] = [];

  truePositiveIncidentsCurrentPage: PaginatedList<CaseXDR>;
  falsePositiveIncidentsCurrentPage: PaginatedList<CaseXDR>;
  flagCurrentPage: PaginatedList<CaseXDR>;

  ngOnInit(): void {
    this.onSearch();

    this.contract = this.globalFilters.selectedContract;
    this.contractChangeSubscription = this.globalFilters.contractChangeEvent.subscribe((contract) => {
      this.contract = contract;
    });

    this.route.paramMap.subscribe((params: ParamMap) => {
      const tab = params.get('tab');
      if (['closed', '4A', '3W'].includes(tab)) this.tab = tab;
      else {
        this.router.navigate(['/xtended-soc', '4A3W', 'closed']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.contractChangeSubscription) this.contractChangeSubscription.unsubscribe();
  }

  async onSearch() {
    [
      this.fourARepartitionData,
      this.threeWRepartitionData,
      this.incidentsClosedByVerdictOvertime,
      this.incidentsClosedByVerdict,
    ] = await Promise.all([
      this.xtendedHighchartService.drillPointMapper(
        await this.xtendedChartService.get4A3WByField(this.globalFilters.searchCriteria, this.fourARepartitionField),
        this.fourARepartitionField
      ),
      this.xtendedHighchartService.drillPointMapper(
        await this.xtendedChartService.get4A3WByField(this.globalFilters.searchCriteria, this.threeWRepartitionField),
        this.threeWRepartitionField
      ),
      this.cybersocxdrService.getHistogramIncidents({
        ...this.globalFilters.searchCriteria,
        createdFrom: 'last180d',
        createdTo: undefined,
        groupbyField: XtendedFacet.CLOSURE_VERDICT,
      }),
      [
        this.xtendedHighchartService.pieChartSerie(
          await this.cybersocxdrService.getCasesGroupBy(
            this.globalFilters.searchCriteria,
            XtendedFacet.CLOSURE_VERDICT
          ),
          XtendedFacet.CLOSURE_VERDICT
        ),
      ],
      this.loadPages(true),
      this.search4A(),
      this.search3W(),
    ]);
  }

  async onSelect4ARepartition(value: Fields4A3W) {
    this.fourARepartitionField = value;
    this.fourARepartitionData = this.xtendedHighchartService.drillPointMapper(
      await this.xtendedChartService.get4A3WByField(this.globalFilters.searchCriteria, value),
      value
    );
  }

  async onSelect3WRepartition(value: Fields4A3W) {
    this.threeWRepartitionField = value;
    this.threeWRepartitionData = this.xtendedHighchartService.drillPointMapper(
      await this.xtendedChartService.get4A3WByField(this.globalFilters.searchCriteria, value),
      value
    );
  }

  async search4A() {
    const searchCriteria = this.globalFilters.searchCriteria;
    let nodes: {
      id: string;
      name: string;
    }[];
    let nodesLinks: string[][];

    [this.fourAInsights, this.truePositiveIncidentsCurrentPage, [nodes, nodesLinks]] = await Promise.all([
      this.cybersocxdrService.getClient4AInsights(searchCriteria),
      this.cybersocxdrService.getPaginatedCases({
        ...searchCriteria,
        closureVerdict: [ClosureVerdict.TRUE_POSITIVE],
        limit: 15,
      }),
      this.xtendedChartService.get4ASankey(this.globalFilters.searchCriteria),
    ]);

    this.sankey4ASeries = [
      {
        keys: ['from', 'to', 'weight'],
        nodes: nodes,
        data: nodesLinks,
        type: 'sankey',
        nodeWidth: 30,
        nodePadding: 10,
      },
    ];
  }

  async search3W() {
    const searchCriteria = this.globalFilters.searchCriteria;
    let nodes: {
      id: string;
      name: string;
    }[];
    let nodesLinks: string[][];

    [this.threeWInsights, this.falsePositiveIncidentsCurrentPage, [nodes, nodesLinks]] = await Promise.all([
      this.cybersocxdrService.getClient3WInsights(searchCriteria),
      this.cybersocxdrService.getPaginatedCases({
        ...searchCriteria,
        closureVerdict: [ClosureVerdict.FALSE_POSITIVE],
        limit: 15,
      }),
      this.xtendedChartService.get3WSankey(this.globalFilters.searchCriteria),
    ]);

    this.sankey3WSeries = [
      {
        keys: ['from', 'to', 'weight'],
        nodes: nodes,
        data: nodesLinks,
        type: 'sankey',
        nodeWidth: 30,
        nodePadding: 10,
      },
    ];
  }

  public async loadTruePositivePage(page: number) {
    this.truePositiveIncidentsCurrentPage = await this.cybersocxdrService.getPaginatedCases({
      ...this.globalFilters.searchCriteria,
      closureVerdict: [ClosureVerdict.TRUE_POSITIVE],
      limit: this.truePositiveIncidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
    });
    return this.truePositiveIncidentsCurrentPage;
  }

  async onFlag(incident: CaseXDR) {
    if (!incident.isFlagged) {
      await this.cybersocxdrService.flagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    } else {
      await this.cybersocxdrService.deleteFlagIncident(incident.id, this.contract?.incidentDataSource ?? undefined);
    }
    this.loadPages();
  }

  public async loadPages(reset: boolean = false) {
    if (reset) {
      if (this.tab === '4A') {
        this.flagCurrentPage = await this.loadTruePositivePage(0);
      } else if (this.tab === '3W') {
        this.flagCurrentPage = await this.loadFalsePositivePage(0);
      }
    } else {
      if (this.tab === '4A') {
        this.flagCurrentPage = await this.loadTruePositivePage(this.truePositiveIncidentsCurrentPage?.page ?? 0);
      } else if (this.tab === '3W') {
        this.flagCurrentPage = await this.loadFalsePositivePage(this.falsePositiveIncidentsCurrentPage?.page ?? 0);
      }
    }
  }

  public async loadFalsePositivePage(page: number) {
    this.falsePositiveIncidentsCurrentPage = await this.cybersocxdrService.getPaginatedCases({
      ...this.globalFilters.searchCriteria,
      closureVerdict: [ClosureVerdict.FALSE_POSITIVE],
      limit: this.falsePositiveIncidentsCurrentPage?.pageSize ?? 15,
      skip: page ?? 0,
    });

    return this.falsePositiveIncidentsCurrentPage;
  }
}

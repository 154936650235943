import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OktaAuthService } from './app.service';
import { ContextService } from './shared/services/context-service';
import { DataShareService } from './shared/services/data-share.service';
import { filter, map, mergeMap } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  roles: [];
  appGroups: [];
  isAuthenticated: boolean;
  public loaded = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private oktaAuthService: OktaAuthService,
    public contextService: ContextService,
    private router: Router,
    private dataService: DataShareService
  ) {

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.contextService.title = event.title;
        this.contextService.subtitle = event.subtitle;
        this.contextService.fullWidth = !!event.fullWidth;
        this.contextService.isDashboard = event.title === 'dashboard' ? true : false;
        this.contextService.helpTip = event.helpTip;
      });

    this.oktaAuthService.$isAuthenticated.subscribe(val => {
      this.isAuthenticated = val;

      if (val) {
        this.oktaAuthService.loadUserDetails()
          .then((res: any) => {
            // store user details ton store
            this.oktaAuthService.userDetails = res;
            this.oktaAuthService.initUserTenants();
            this.oktaAuthService.initUserRoles();
            
            if (res?.groups) {
              this.roles = res.groups.filter(g => g.startsWith('APPROLE_'));
              this.appGroups = res.groups.filter((grp) => grp.startsWith('APP_'));
              this.dataService.appGroups = this.appGroups;
            }
          })
          .then(() => {
            if (this.isAuthenticated && (this.roles.length > 0)) {
              // load admin data
              this.oktaAuthService.storeOktaAdminTenantInfo()
                .then(() => {

                  this.contextService.loadContext();

                  // check to see if multiple tenants are available, and if one has been selected
                  const availableTenants = this.oktaAuthService.availableTenants;
                  if (availableTenants?.length > 1 && !this.oktaAuthService.selectedTenant) {
                    // multiple tenants available but no tenants selected so redirect to that page
                    this.router.navigate(['/tenants']);
                  }

                  this.loaded = true;

                });
            } else {
              // if auth failed, e.g. user not assigned the app or doesn't have any roles
              this.loaded = true;
              this.contextService.contextLoaded = true;
              if (this.appGroups.length > 0) {
                this.router.navigateByUrl('/assignedapplications');
              }
              else {
                this.router.navigateByUrl('/not-assigned');
              }
            }
          });
      } else {
        this.loaded = true;
      }
    });
  }
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthService } from 'src/app/app.service';
import { ACTIONS, PAGES, TYPES, constants } from 'src/app/shared/constants';
import { PrioritiesOrder } from 'src/app/shared/model/activity';
import { Asset, Case, CaseType, Contact } from 'src/app/shared/model/itsm';
import { Pages } from 'src/app/shared/model/shared-items';
import { AlertService } from 'src/app/shared/services/alert.service';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { Utilities } from 'src/app/shared/utilities';

import { TranslateService } from '@ngx-translate/core';
import { LogCaseForm, Service } from './log-case-form';
import { AssetAndServiceComponent } from './filters/asset-and-service/asset-and-service.component';

@Component({
  selector: 'app-supportlog',
  templateUrl: './supportlog.component.html',
  styleUrls: ['./supportlog.component.scss']
})
export class SupportLogComponent extends LogCaseForm implements OnInit {

  @ViewChild(AssetAndServiceComponent)
  public assetAndServiceComponent: AssetAndServiceComponent;

  // PRIVATE ATTRIBUTES
  private allowedCaseTypes: string[] = [];
  private assetRef: string;
  private preferredDateConstraints: { minDelayAllowed: number; minDate: Date };
  private reqTypeValue: string;
  private userEmail: string;

  // PUBLIC ATTRIBUTES
  public case = new Case();
  public loading = true;
  public casePriorityCV: string[];
  public contacts: Contact[];
  public defaultService = { id: '', serviceName: 'default', serviceCenter: undefined };
  public filteredAssets: Asset[] = [];
  public filteredService: Service[];
  public loadingCache: boolean;
  public minDateAllowed: string;
  public page: string;
  public saved: boolean;
  public serviceSearch: string;
  public showDateTime = false;
  public submitted = false;
  public worldWatchBaseRef = constants.activity.types.signal.baseReference;
  public preferredDateMinDelayAllowed = 25;

  constructor(
    private alertService: AlertService,
    private itsmService: ItsmService,
    private route: ActivatedRoute,
    private router: Router,
    private oktaAuthService: OktaAuthService,
    private userActivity: UserActivityService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    super();
  }
  
  async ngOnInit() {
    
    this.initForm();

    await this.loadCache();
    this.initializePreferredDateConstraints();

    this.userEmail = this.oktaAuthService.userDetails.email;

    this.initializeCaseType();
    this.page = Pages.create;
    this.userActivity.logActivity(TYPES.support, PAGES.logCase, ACTIONS.visit);
    this.loadingCache = false;
    this.loading = false;
  }

  /**
  * init the reactive log case form
  */
  private initForm() {
    
    this.logForm.addControl('type', this.formBuilder.control('', [Validators.required]));
    
    this.logForm.addControl('subject', this.formBuilder.control('', [Validators.required, Validators.pattern(constants.regex.onlyAlphanumericValues)]));
    
    this.logForm.addControl('description', this.formBuilder.control('', [Validators.required, Validators.pattern(constants.regex.avoidCsvInjection)]));
      
    this.logForm.addControl('contactId', this.formBuilder.control('', [Validators.required]));
    
    this.logForm.addControl('customerReference', this.formBuilder.control('', [Validators.pattern(constants.regex.onlyAlphanumericValues)]));
    
    this.logForm.addControl('selectedService', this.formBuilder.control('', [Validators.required, this.ServiceValidator(this.defaultService)]));
    
    this.logForm.addControl('selectedAsset', this.formBuilder.control('', [this.AssetValidator(() => this.filteredAssets)]));
    
    this.logForm.addControl('worldWatchReference', this.formBuilder.control({ value: '', disabled: true }));
    
    this.type.valueChanges.subscribe((value) => {  this.updateControlsWhenTypeChange(value) });
   
    // init all values from the route params
    this.route.queryParams.subscribe(params => {
      this.worldWatchReference.patchValue(params.signalRef);
      this.assetRef = params.asset;
    });
  }

  private updateControlsWhenTypeChange(type: string) {
    switch (type) {

      // CHANGE REQUEST CONTROLS
      case (CaseType.changeRequest):
        this.logForm.removeControl('incident');
        this.logForm.addControl('changeRequest', this.formBuilder.group({
          changeType: ['', [Validators.required]],
          preferedDate: [false],
          dateTime: [{ value: '', disabled: true }, []]
        }))

        // date time value
        this.preferedDate.valueChanges.subscribe((value) => {
          console.log('preferedDate change')
          if (value) {
            this.setupDateTime(value);
            this.dateTime.enable();
          } else {
            this.dateTime.disable();
          }
        })

        // changeType control
        this.changeType.valueChanges.subscribe((value) => {
          if (value !== constants.changeTypes.emergencyChange) {
            this.dateTime.addValidators([Validators.required, this.DateTimeValidator()]);
          } else {
            this.dateTime.removeValidators([Validators.required, this.DateTimeValidator()]);
          }
          this.dateTime.updateValueAndValidity();
        })
        break;

      // INCIDENT CONTROLS
      case (CaseType.incident):
        this.logForm.removeControl('changeRequest');
        this.logForm.addControl('incident', this.formBuilder.group({
          priority: ['', [this.PriorityValidator(), Validators.required]],
        }),)
        break;

      // SERVICE REQUEST CONTROLS
      case (CaseType.serviceRequest):
        this.logForm.removeControl('changeRequest');
        this.logForm.removeControl('incident');
        break;
    }
  }

  /**
   * Cache the CV values for the dropdowns
   */
  async loadCache() {
    this.loadingCache = true;

    try {
      const responses = await Promise.all([
        Promise.resolve(),
        this.itsmService.getCachedCV('priority'),
        this.itsmService.getCachedContacts(),
      ]);

      this.casePriorityCV = responses[1].filter(n =>
        n === PrioritiesOrder.critical ||
        n === PrioritiesOrder.high ||
        n === PrioritiesOrder.medium ||
        n === PrioritiesOrder.low
      );
      this.contacts = responses[2];

    } catch (error) {
      this.alertService.handlerError(error);
      this.loadingCache = false;
    }
  }
  
  /**
   * initialize preferredDateConstraints
   */
  initializePreferredDateConstraints() {
    // minimum delay to handle a log case. User shouldn't be able to specify an handling time lower than that delay
    const now = new Date();
    this.preferredDateConstraints = {
      minDelayAllowed: this.preferredDateMinDelayAllowed,
      minDate: new Date(now.setHours(now.getHours() + this.preferredDateMinDelayAllowed))
    };
  }

  /**
   * initialize case type from okta user details
   */
  private initializeCaseType() {
    this.oktaAuthService.userDetails.groups.map(g => {
      if (g.includes('Create_')) {
        this.allowedCaseTypes.push(g.substr(g.indexOf('Create_') + 7));
      }
    });

    // filter case type by roles
    this.caseTypes = this.caseTypes.filter(t =>
      (t.value === CaseType.changeRequest && this.allowedCaseTypes.includes('Change_Request')) ||
      (t.value === CaseType.serviceRequest && this.allowedCaseTypes.includes('Service_Request')) ||
      (t.value === CaseType.incident && this.allowedCaseTypes.includes('Incident'))
    );

    if (this.contacts) {
      const cs = this.contacts.filter(c => c.email === this.oktaAuthService.userDetails.email);
      if (cs.length > 0) {
        this.contactId.patchValue(cs[0].id);
      }
    }
  }

  /**
   * setup change request date time
   */
  public setupDateTime(value: boolean) {
    if (value) {
      const datetime = this.preferredDateConstraints.minDate.getFullYear().toString()
        + '-'
        + ('0' + (this.preferredDateConstraints.minDate.getMonth() + 1).toString()).slice(-2)
        + '-'
        + ('0' + this.preferredDateConstraints.minDate.getDate().toString()).slice(-2)
        + 'T'
        + ('0' + this.preferredDateConstraints.minDate.getHours().toString()).slice(-2)
        + ':'
        + ('0' + this.preferredDateConstraints.minDate.getMinutes().toString()).slice(-2);
      this.dateTime.patchValue(datetime);
      this.minDateAllowed = datetime;
    } else {
      this.dateTime.patchValue('');
    }
  }

  /**
   * Set timeZone to snowServiceTimezone and format as standard ISO date
   * @param dateStr - date string to format and convert to serviceNow timezone
   * @returns date as string
   */
  formatDate(dateStr: string): string {
    const localDate = new Date(dateStr);

    // convert to BT / BST (London time) because API account of SNow has europe/london time zone and it expects that while converting and storing as UTC. 
    const snowDateTime = new Date(localDate.toLocaleString('en-US', { timeZone: constants.snowServiceTimeZone }));

    // Convert to ISODate format preventing timezone recalculation
    const isoDate = snowDateTime.getFullYear() + '-' +
      ('0'+ (snowDateTime.getMonth()+1)).slice(-2) + '-' +
      ('0'+ snowDateTime.getDate()).slice(-2) + 'T' +
      ('0' + snowDateTime.getHours().toString()).slice(-2) + ':' +
      ('0' + snowDateTime.getMinutes().toString()).slice(-2);
      return isoDate;
  }

  /**
   * remove selected service
   */
  public removeSelectedService() {
    this.selectedService.patchValue(this.defaultService);
    this.selectedAsset.patchValue(undefined);
    this.filteredAssets = this.assetAndServiceComponent.assets;
  }

  /**
   * Submit case
   */
  public submit() {
    this.submitted = true;
    if (this.logForm.valid) {

      // get values form form
      const values = this.logForm.getRawValue();

      // bind form to case
      this.case.type = values.type;
      this.case.subject = Utilities.getStringXSSEncoded(values.subject);
      this.case.description = Utilities.getStringXSSEncoded(values.description);
      this.case.contactId = values.contactId;
      this.case.customerReference = values.customerReference;
      this.case.worldWatchReference = values.worldWatchReference;
      this.case.dateTime = ''; // FROM ISSGP-62

      // additionnal case parameters
      this.case.reqType = this.reqTypeValue;
      this.case.openedBy = this.userEmail;

      if (this.assetAndServiceComponent.assets && this.assetAndServiceComponent.assets.length > 0) {
        this.case.reqType = 'supOp';
      } else if (this.assetAndServiceComponent.services && this.assetAndServiceComponent.services.length > 0) {
        this.case.reqType = 'detResp';
      }

      // service mapping
      this.case.contractId = this.selectedService.value.id;
      this.case.serviceCenter = this.selectedService.value.serviceCenter;
      this.case.serviceType = this.selectedService.value.serviceType;
      this.case.serviceName = this.selectedService.value.serviceName;

      // asset mapping
      if(this.selectedAsset.value) {
        this.case.assetId = this.selectedAsset.value.id;
      }

      // log case type mapping
      switch (values.type) {
        case CaseType.changeRequest: {
          const changeRequestValues = this.changeRequest.value;
          this.case.changeType = changeRequestValues.changeType;
          if (this.preferedDate.value) {
            this.case.dateTime = this.formatDate(changeRequestValues.dateTime);
          }
          break;
        }
        case CaseType.incident: {
          const incidentValues = this.incident.value;
          this.case.priority = incidentValues.priority;
          break;
        }
      }

      this.page = Pages.submit;
      this.saved = false;

      this.itsmService.caseCreate(this.case)
        .then(res => {
          this.saved = true;
          this.alertService.addSuccess(this.translateService.instant('pages.support.log.saved'));
          this.userActivity.logActivity(TYPES.support, PAGES.logCase, ACTIONS.logCase);
          this.goToCase(res.id);
        })
        .catch(err => {
          this.alertService.handlerError(err);
        })
        .finally(() => {
          this.saved = true;
        })

    }
  }

  /**
 * Redirect to case created
 */
  private goToCase(caseId) {
    this.router.navigate(['/support/view', caseId]);
  }

}

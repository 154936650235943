import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import HC_accessibility from 'highcharts/modules/accessibility';
import HC_highchartmore from 'highcharts/highcharts-more';
import { BaseChartComponent } from '../basechart/basechart.component';
import { getDefaultHighchartOptions } from '../../utils';
HC_exporting(Highcharts);
HC_accessibility(Highcharts);
HC_highchartmore(Highcharts);

// Non defined by highcharts, but the behavior of the library changes as if they were defined
type ExtendedPackedBubbleOptions = Highcharts.PlotPackedbubbleOptions & Highcharts.PlotBubbleOptions;
@Component({
  selector: 'app-packedbubblehost',
  templateUrl: '../basechart/basechart.component.html',
})
export class PackedbubblehostComponent extends BaseChartComponent<Array<Highcharts.SeriesPackedbubbleOptions>> {
  plotOptions: ExtendedPackedBubbleOptions = {
    minSize: '1%',
    maxSize: '200%',
    zMin: 0,
    zMax: 1000,
    layoutAlgorithm: {
      splitSeries: false,
      gravitationalConstant: 0.02,
    },
    dataLabels: {
      enabled: false,
      format: '{point.name}',
      style: {
        textAlign: 'center',
        textOverflow: 'ellipsis',
        width: 100,
      },
    },
  };

  options: Highcharts.Options = {
    chart: {
      type: 'packedbubble',
    },
    title: {
      text: '',
    },
    tooltip: {
      useHTML: true,
      pointFormat: '<b>{point.name}:</b> {point.value}',
    },
    plotOptions: {
      packedbubble: this.plotOptions,
    },
    series: undefined,
  };

  draw(renderingId: string) {
    this.options.title.text = this.title;
    this.options.series = this.data;

    // Compute the treshold to display the bubble value
    const valueList = [];
    for (const severitydatas of this.data) {
      // Don't laugh, any is what is defined by the .d.ts file
      for (const severitydata of severitydatas.data as any[]) {
        valueList.push(severitydata.value);
      }
    }
    const sum = valueList.reduce((a, b) => a + b, 0);
    const avg = sum / valueList.length || 0;

    this.options.plotOptions.packedbubble.dataLabels.filter = {
      property: 'y',
      operator: '>',
      value: avg,
    };
    console.log(Highcharts.merge({ ...getDefaultHighchartOptions(this.context.isDarkTheme()) }, { ...this.options }));
  }
}

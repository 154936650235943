import { Component, OnInit } from '@angular/core';
import { MfdSearch } from 'src/app/shared/model/maliciousfiledetection';
import { AlertService } from 'src/app/shared/services/alert.service';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';

@Component({
  selector: 'app-mfd-search',
  templateUrl: './mfd-search.component.html',
  styleUrls: ['./mfd-search.component.scss']
})
export class MfdSearchComponent implements OnInit {
  loading = true;
  search: MfdSearch;

  constructor(private mfdService: MfdService, private alertService: AlertService) { }

  /** fetch mfd search */
  ngOnInit(): void {
    this.mfdService.search().then( res => {
      this.search = res;
      this.loading = false;
    }).catch(reason => {
      this.alertService.handlerError(reason);
    });
  }

}

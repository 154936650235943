import { Component, OnInit } from '@angular/core';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-integrations-azure-ad-sso',
  templateUrl: './integrations-azure-ad-sso.component.html',
  styleUrls: ['./integrations-azure-ad-sso.component.scss']
})
export class IntegrationsAzureAdSSOComponent implements OnInit {

  // configuration
  singleSignOnURL: string;
  x509certificate: string;
  domain: string;
  azureExampleUrl = constants.azureExample;
  // view
  view: 'notConfigured' | 'editConfiguration' | 'configured' | 'notAvailable' = 'notConfigured';
  alertNotAvailable = false;

  constructor(private userActivity: UserActivityService) { }

  ngOnInit(): void {
    this.userActivity.logActivity(TYPES.resources, PAGES.ssoAzureActiveDirectory, ACTIONS.visit);
  }

  save() {
    this.view = 'notAvailable';
    this.alertNotAvailable = true;
  }

  closeAlert() {
    this.alertNotAvailable = false;
  }

  setupSAML() {
    this.view = 'editConfiguration';
  }

  return() {
    this.view = 'notConfigured';
  }

}

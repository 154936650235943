<div class="select-section" (clickOutside)="closeOptionsPanel()">

  <!-- SELECT -->
  <div id="inputLabel" class="custom-label">{{ labelKey | translate }}</div>
  <input class="form-control"
    type="text"
    aria-labelledby="inputLabel"
    title="{{ selectedOption?.displayValue }}"
    [(ngModel)]="userInput"
    (ngModelChange)="this.userInputUpdate.next($event); optionsCanvas = true"
    (click)="optionsCanvas = !optionsCanvas"
    placeholder="{{ 'pages.generic.search' | translate }} {{ labelKey }}" />

  <button type="button" class="btn btn-icon delete" attr.aria-label="{{ 'pages.generic.clearSelection' | translate }}"
    (click)="emptySearch()">
    <fa-icon [icon]="['fas', 'times']" size="lg" class="" data-toggle="tooltip"></fa-icon>
  </button>

  <!-- OPTIONS LIST -->
  <div [ngClass]="{'options-list': true, 'dark': isDarkTheme}" *ngIf="optionsCanvas">
    <div>
      <div class="no-data" [hidden]="options?.length > 0 || optionsLoading">
        {{ 'pages.dashboard.noResults' | translate }}
      </div>
      <ul id="optionsList" [ngClass]="options?.length > 0 ? 'got-options' : 'no-options'"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="10"
        (scrolled)="onScroll()"
        [scrollWindow]="false">

        @for (option of options; track $index) {
          <!-- Key navigation with onKeyDown | keyUp | enter handled with @HostListener in component -->
          <li class="option-item cursorpointer"
            id="optionIndex_{{$index}}"
            (click)="selectOption(option)"
            title="{{ option.displayValue }}"
            [ngClass]="$index === selectedOptionIndex && 'focused'">
            {{ option.displayValue }}
          </li>
        }

        <!-- LOADING WHEEL -->
        @if (optionsLoading) {
          <li class="spinner-container">
            <div class="spinner-border text-primary" aria-live="polite" aria-busy="true">
              <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
          </li>
        }
        
      </ul>
    </div>
    
  </div>

</div>
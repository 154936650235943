<div class="row" *ngIf="signals && loaded">
  <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 pb-2" *ngFor="let sig of signals">

    <div class="card highlight h-100 boxshadow signalicon-start" [ngClass]="sig.signalUrgency + sig.mainCategory | lowercase | removeWhiteSpace" [routerLink]="['/updates/worldwatch/viewSignal/' + sig.jiraRef]" routerLinkActive="activeLink">
      <div class="card-header" [ngClass]="sig.signalUrgency | lowercase">{{sig.summary | uppercase}}</div>
      <div class="card-body">
        <h5 class="card-title">
          <div style="float:left; width:100%">{{sig.mainCategory | uppercase}}</div>
          <span class="justify-content-end" style="display:flex;" [ngClass]="sig.signalUrgency + 'font' | lowercase">{{sig.signalUrgency | uppercase}}</span>
        </h5>
        <p class="card-text">{{sig.whatYouWillHear}}</p>
      </div>

      <div class="card-footer ">
        <div style="float:left; width:100%"><small>{{sig.author}} <br/> <i>{{sig.date | date: 'dd/MM/yyyy HH:mm'}}</i></small></div>
        <span class="justify-content-end" style="display:flex;">
          <div class="spinner-grow spinner-grow-sm text-primary" style="margin-left: -100px" *ngIf="flaggingRef && flaggingRef === sig.jiraRef">>
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
          </div>
          <fa-icon [icon]="['far', 'flag']" class="click" (click)="flagSignal($event, sig.jiraRef)" *ngIf="(flagRefs && !flagRefs.includes(sig.jiraRef)) && (!flaggingRef || (flaggingRef && flaggingRef !== sig.jiraRef))"></fa-icon>
          <fa-icon [icon]="['fas', 'flag']" class="click" (click)="flagSignal($event, sig.jiraRef)" *ngIf="(flagRefs && flagRefs.includes(sig.jiraRef)) && (!flaggingRef || (flaggingRef && flaggingRef !== sig.jiraRef))"></fa-icon>
        </span>
      </div>
    </div>

  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="!loaded">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="signals && signals.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.updates.signal.noResults' | translate }}
  </div>
</div>

import { Component } from '@angular/core';
import { XrangePointOptionsObject } from 'highcharts';
import { ExtendedCaseXDR } from 'src/app/shared/model/cybersocxdr/microsocticket';
import { BaseChartComponent } from '../basechart/basechart.component';
import * as Highcharts from 'highcharts';
import HC_XRange from 'highcharts/modules/xrange';

const DEFAULT_COLORS = ['#A885D8', '#D9C2F0', '#FFD200', '#FFF6B6', '#FF7900'];

HC_XRange(Highcharts);

@Component({
  selector: 'app-incident-timeline',
  templateUrl: '../basechart/basechart.component.html',
})
export class IncidentTimelineComponent extends BaseChartComponent<ExtendedCaseXDR['timeline']> {

  draw(renderingId: string) {
    const graphSeries: Highcharts.SeriesXrangeOptions[] = [
      {
        type: 'xrange',
        data: this.data.map((event, index) => {
          const timedEvent: XrangePointOptionsObject = {
            name: event.state,
            description: event.state,
            x: Date.parse(event.date),
            x2: Date.parse(this.data[Math.min(index + 1, this.data.length - 1)].date),
            y: 0,
            color: DEFAULT_COLORS[index],
          };

          // Add a bit of time
          if (timedEvent.x === timedEvent.x2) {timedEvent.x2 += 1.08e5;}

          return timedEvent;
        }),

        dataLabels: this.data.map((event) => ({ 
          format: '{point.name}',
          style: {
            color: "#000000",
            fontFamily: "HelvNeueOrange",
            fontSize: "14px",
            textOutline: "none"
          },
        })),
      },
    ];

    this.options = {
      tooltip: {
        enabled: true,
        xDateFormat: '%d/%m %Hh%M',
        pointFormat: '{point.name}'
      },
      chart: {
        type: 'xrange',
        height: 200
      },
      legend: { enabled: false },
      plotOptions: {
        series: {
          dataLabels: { enabled: true, inside: true },
        },
      },
      xAxis: {
        startOnTick: true,
        endOnTick: true,
        type: 'datetime',
        tickInterval: 1000 * 60 * 5, // 15 minutes
      },
      yAxis: { visible: false, categories: this.data.map((event) => event.state) },
      title: {
        text: this.title,
        floating: true
      },
      series: graphSeries,
    };
  }
}

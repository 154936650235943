import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OktaUser, App, User } from '../model/okta';

@Injectable()
export class OktaUserService {

    private usersURL = environment.endpoints.okta + 'users';
    private groupsURL = environment.endpoints.okta + 'groups';

    private proxyUsersURL = environment.endpoints.oktaProxyService + 'users';
    private serviceUsersURL = environment.endpoints.oktaService + 'users';
    private serviceAppsURL = environment.endpoints.oktaService + 'apps';
    private serviceTenantURL = environment.endpoints.oktaService + 'tenants';

    constructor(private http: HttpClient) { }

    /**
     * Direct Okta calls
     */
    listUsers(): Promise<OktaUser[]> {
        return this.http
            .get(this.usersURL)
            .toPromise()
            .then(response => response as OktaUser[])
            .catch(this.handleError);
    }

    listUsersForGroup(groupId: string): Promise<OktaUser[]> {
        return this.http
            .get(this.groupsURL + '/'+ groupId + '/users')
            .toPromise()
            .then(response => response as OktaUser[])
            .catch(this.handleError);
    }

    listGroups(): Promise<any[]> {
        return this.http
            .get(this.groupsURL)
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    getUser(userId: string): Promise<OktaUser>{
        return this.http
            .get(this.usersURL + '/' + userId)
            .toPromise()
            .then(response => response as OktaUser)
            .catch(this.handleError);
    }

    getUserGroups(userId: string): Promise<any[]>{
        return this.http
            .get(this.usersURL + '/' + userId + '/groups')
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    listApps(): Promise<any[]>{
        return this.http
            .get(this.groupsURL + '?q=APPUSERS_')
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }


    /**
     * Using backend service as proxy, lifecycle endpoints arent CORS enabled
     */
    userOperation(userId: string, operation: string): Promise<any> {
        return this.http
            .get(this.proxyUsersURL + '/' + userId + '/' + operation)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    /**
     * User backend service, but not as a proxy
     */

    listTenantApps(): Promise<App[]>{
        return this.http
            .get(this.serviceAppsURL + '/assigned')
            .toPromise()
            .then(response => response as App[])
            .catch(this.handleError);
    }

    addExistingUserToTenantAdmin(userId: string){
        return this.http
            .get(this.serviceTenantURL + '/admins/' + userId)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    removeUserFromTenantAdmin(userId: string){
        return this.http
            .delete(this.serviceTenantURL + '/admins/' + userId)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    addTenantUser(user: User): Promise<OktaUser> {
        return this.http
            .post(this.serviceTenantURL + '/user', JSON.stringify(user))
            .toPromise()
            .then(response => response as OktaUser)
            .catch(this.handleError);
    }

    getAppRoles(appId: string): Promise<any[]>{
        return this.http
            .get(this.serviceAppsURL + '/' + appId + '/roles')
            .toPromise()
            .then(response => response as any[])
            .catch(this.handleError);
    }

    getUserIncGroups(userId: string): Promise<any> {
        return this.http
            .get(this.serviceUsersURL + '/groups/' + userId)
            .toPromise()
            .then(response => response as OktaUser[])
            .catch(this.handleError);
    }

    updateUsersGroups(userId: string, groupIds: string[]): Promise<any>{
        return this.http
            .post(this.serviceUsersURL + '/' + userId + '/groups', groupIds)
            .toPromise()
            .then(response => response as any)
            .catch(this.handleError);
    }

    updateUserProfile(userId: string, firstName: string, lastName: string): Promise<string>{
        return this.http
            .post(this.serviceUsersURL + '/' + userId + '/profile', {firstName, lastName})
            .toPromise()
            .then(response => response as string)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error || error.message);
    }

}

<header class="header">
  <nav class="navbar navbar-expand-lg" [ngClass]="navBarTheme" aria-label="Service navigation">
    <div class="container-fluid">

      <a class="logo" attr.aria-label="{{ 'pages.shared.returnToDashboard' | translate }}" [routerLink]="['/dashboard']"></a>

      <!-- Burger menu (visible on small screens) -->
      <button class="navbar-toggler collapsed float-end" type="button" data-bs-toggle="collapse" data-bs-target=".service-header-1" aria-controls="service-header-1.1" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navbar with links -->
      <div id="service-header-1.1" class="navbar-collapse collapse me-lg-auto service-header-1">
        <ul class="navbar-nav">
           <!-- dashboard -->
           <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" #navbarRouterLink="routerLinkActive"
              [attr.aria-current]="navbarRouterLink.isActive ? 'page': undefined" routerLink="/dashboard"
              data-placement="top">{{ 'menu.dashboard' | translate }}</a>
          </li>

          <!-- support -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="DropdownSupport" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" title="{{ 'pages.dashboard.supportTicketDesc' | translate }}">{{
              'menu.support.title' | translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="DropdownSupport">
              <li><a class="dropdown-item" routerLink="/support/dashboard">{{ 'menu.support.overview' | translate }}</a>
              </li>
              <!-- <li><a class="dropdown-item" routerLink="/support/contacts">{{ 'menu.support.contact' | translate }}</a>
              </li> -->
              <li><a class="dropdown-item" routerLink="/support/log">{{ 'menu.support.log' | translate }}</a></li>
            </ul>
          </li>

          <!-- activity -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="dropdownActivity" role="button" data-bs-toggle="dropdown"
              aria-expanded="false"> {{ 'menu.activity.title' |translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownActivity">
              <li title="{{ 'pages.dashboard.activitySummaryDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/activity/dashboard">{{ 'menu.activity.overview' | translate }} </a></li>
              <li title="{{ 'pages.dashboard.timelineDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/activity/securityincident">{{ 'menu.activity.securityincidentTimeline' | translate }}</a></li>
            </ul>
          </li>

          <!-- asset management -->
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" #navbarRouterLink="routerLinkActive"
              [attr.aria-current]="navbarRouterLink.isActive ? 'page': undefined" (click)="clearAssetsRetention()"
              routerLink="/asset/dashboard" data-placement="top"
              title="{{ 'pages.dashboard.listAssetDesc' | translate }}">{{ 'menu.assets.title' | translate }}</a>
          </li>

          <!-- report -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="dropdownReport" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" title="{{ 'pages.dashboard.scheduleReportDesc' | translate }}">{{
              'menu.reports.title' | translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownReport">
              <li title="{{ 'pages.dashboard.reportsDocumentsDesc' | translate }}">
                <a class="dropdown-item" routerLink="/reports/standard">{{ 'menu.reports.standard' | translate }} </a>
              </li>
              <li title="{{ 'pages.dashboard.securityIncidentsDesc' | translate }}">
                <a class="dropdown-item" routerLink="/reports/live/securitycase">{{ 'menu.reports.securityCase' |
                  translate }} </a>
              </li>
              @if (hasSlaAccess) {
              <li title="{{ 'pages.dashboard.securityResolDesc' | translate }}">
                <a class="dropdown-item" routerLink="/reports/live/resolution">{{ 'menu.reports.resolution' | translate
                  }} </a>
              </li>
              }
              <li title="{{ 'pages.dashboard.incidentBenchmarkingDesc' | translate }}">
                <a class="dropdown-item" routerLink="/reports/live/benchmarking">{{ 'menu.reports.benchmarking' |
                  translate }} </a>
              </li>
              <li title="{{ 'pages.dashboard.reportSchedulingDesc' | translate }}">
                <a ngbDropdownItem routerLink="/reports/schedule">{{ 'menu.reports.reportScheduling' |
                  translate }} </a>
              </li>
            </ul>
          </li>

          <!-- ressources -->
          <li class="nav-item dropdown" title="{{ 'pages.dashboard.selectBlogsDesc' | translate }}">
            <a class="nav-link dropdown-toggle" id="dropdownResources" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">{{ 'menu.updates.title' | translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon></a>
            <ul class="dropdown-menu" aria-labelledby="dropdownResources">
              <li title="{{ 'pages.dashboard.worldwatchDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/worldwatch/dashboard">{{ 'menu.updates.worldwatch' | translate }}</a></li>
              <li title="{{ 'pages.dashboard.vulnDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/vulnerabilities/dashboard">{{ 'menu.updates.vulnerabilities' | translate }}</a>
              </li>
              <li title="{{ 'pages.dashboard.trainingDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/training">{{ 'menu.updates.training' | translate }}</a>
              </li>
              <li title="{{ 'pages.dashboard.blogsDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/blogs">{{ 'menu.updates.rss.blog' | translate }}</a></li>
              <li title="{{ 'pages.dashboard.eventsDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/events">{{ 'menu.updates.rss.events' | translate }}</a></li>
              <li title="{{ 'pages.dashboard.newsDesc' | translate }}"><a a class="dropdown-item"
                  routerLink="/updates/news">{{'menu.updates.rss.news' | translate }}</a></li>
              <li title="{{ 'pages.dashboard.whitePapersDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/whitepapers">{{ 'menu.updates.rss.whitepapers' | translate }}</a></li>
              <!-- <li title="{{ 'pages.dashboard.integrationsDesc' | translate }}"><a class="dropdown-item"
                  routerLink="/updates/integrations">{{ 'menu.updates.integrations.title' | translate }}</a></li> -->
            </ul>
          </li>

          <!-- services -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="dropdownServices" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" title="{{ 'pages.dashboard.listServDesc' | translate }}">{{
              'pages.dashboard.services' | translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon></a>
            <ul class="dropdown-menu" aria-labelledby="dropdownServices">
              <li><a *ngIf="oktaAuthService.compMicroSOCXDR" class="dropdown-item" routerLink="/xtended-soc">{{
                  'menu.cybersocxdr.title' |
                  translate }} </a></li>
              <li><a class="dropdown-item" target="_new" href="https://cert.orangecyberdefense.com/"
                  rel="noopener noreferrer">{{ 'pages.dashboard.threatDefenseCenter' | translate }}</a></li>
              <li><a class="dropdown-item" routerLink="/updates/services">{{ 'menu.updates.ourServices' | translate
                  }}</a>
              </li>
              <li><a class="dropdown-item" routerLink="/updates/services/certtools">{{ 'menu.updates.certToolbox' |
                  translate
                  }}</a></li>
                  <!-- 
                    <li><a class="dropdown-item" href={{mySecurityReportUrl}} target="_blank" rel="noopener">{{
                        'menu.updates.mySecurityReport' |
                        translate
                        }}</a></li>
                  -->
              <li *ngIf="nucleusUrl"><a class="dropdown-item" href="{{nucleusUrl}}" target="_blank"
                  rel="noopener noreferrer">{{ 'menu.updates.managedVulnerability' | translate }}</a></li>
              <li *ngIf="!nucleusUrl"><a class="dropdown-item disabled">{{ 'menu.updates.managedVulnerability' |
                  translate }}</a></li>
                  <!-- <li><a class="dropdown-item" target="_blank" href="https://ocd-soc-uk.eu.goskope.com">Netskope Admin Console</a>
              </li> -->
            </ul>
          </li>

          <!-- admin dashboard-->
          <li class="nav-item dropdown" *ngIf="oktaAuthService.userAdmin">
            <a class="nav-link dropdown-toggle" id="dropdownAdmin" role="button" data-bs-toggle="dropdown"
              aria-expanded="false" title="{{ 'pages.dashboard.interfaceManDesc' | translate }}">{{
              'pages.dashboard.admin' | translate }}
              <fa-icon [icon]="['fas', 'chevron-down']" size="1x" class="ps-1 down"></fa-icon>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdownAdmin">
              <li><a class="dropdown-item" routerLink="/user/management">{{'menu.admin.users' | translate}}</a></li>
            </ul>
          </li>
        </ul>
        
        <!-- log case -->
        <ul class="navbar-nav align-items-center ms-auto mb-1" data-placement="top"
          title="{{ 'pages.dashboard.clickToLogCaseDesc' | translate }}">
          <li class="nav-item">
            <a href="#" class="btn btn-inverse btn-primary" routerLink="/support/log">{{ 'buttons.logticket' | translate
              }}</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
import { ChartView, FalsePositiveCategory, ReportBaseChartComponent } from '../base-chart/report-base-chart.component';
import { WidgetService } from 'src/app/shared/services/widget.service';


@Component({
  selector: 'app-false-positives-chart',
  templateUrl: '../base-chart/report-base-chart.component.html',
  styleUrls: ['../base-chart/report-base-chart.component.scss']
})
export class FalsePositivesChartComponent extends ReportBaseChartComponent implements OnChanges {

  @Input() chart!: FalsePositiveCategory;

  constructor(
    public widgetService: WidgetService
  ) {
    super(widgetService);
    this.setupChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data || changes.monthIndex || changes.chart) {
      this.setupChart();
      this.loadData();
    }
  }

  private setupChart() {
    this.chartName = `${this.chart}`;
    this.attributeName = `${this.chart}FalsePositive`;
    this.isTruePositive = false;
    this.chartsDesc = [
      {type: ChartView.ring, attrName: `${this.chart}FalsePositive`}
    ];
  }

}

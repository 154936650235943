import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanFileSize'
})
export class HumanSizePipe implements PipeTransform {

  // transform a bit amount in a human readable file size
  transform(size: number): string {
    if(Number.isSafeInteger(size)) {
      if(size < 1000 && size >= 0) {
        return `${size} b`;
      }
      if(size < 1000000 && size >= 0) {
        return `${Math.round(size/100)/10} Kb`;
      }
      if(size < 1000000000 && size >= 0) {
        return `${Math.round(size/100000)/10} Mb`;
      }
      return String(size);
    }
    else {
      return null;
    }
  }

}

<div *ngIf="mfdQuota === undefined" class="dragContainer row justify-content-center p-5">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
<div *ngIf="mfdQuota !== undefined" class="dragContainer">

  <div class="quotas">
    <div *ngIf="mfdQuota > 0">
      <label class="nbr">{{mfdQuota}} </label>
      <label class="ms-1"> {{'pages.updates.certTools.dynamicMFD.dragAndDrop.numberOfQuota' | translate }}</label>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="mfdQuota === 0">
      <span class="alert-icon"><span class="sr-only">Danger</span></span>
      <label class="noQuota">{{'pages.updates.certTools.dynamicMFD.dragAndDrop.noQuotaRemaining' | translate }}</label>
    </div>
  </div>

  <div class="dragZone" [ngClass]="{ 'disabled' : mfdQuota === 0 }" appDnd (fileDropped)="onFileDropped($event)">
    <div class="mt-5 mb-4">
      <input type="file" [disabled]="!sufficientQuota || mfdQuota === 0" #fileDropRef id="fileDropRef" multiple
        (change)="fileBrowseHandler($event.target.files)" />
      <span class="icon upload-icon"></span>
    </div>
    <label>{{'pages.updates.certTools.dynamicMFD.dragAndDrop.dragAndDropFiles' | translate }}</label>
  </div>

  <div *ngIf="validForm && sufficientQuota" class="submit" (click)="validFiles()"
    [ngClass]="canLaunch() ? 'valid' : 'invalid'">
    <label>{{'pages.updates.certTools.dynamicMFD.dragAndDrop.startScan' | translate }} </label>
  </div>

  <div *ngIf="!validForm || !sufficientQuota" class="submit error">
    <label *ngIf="!validForm">{{'pages.updates.certTools.dynamicMFD.dragAndDrop.updateError' | translate }}</label>
    <label *ngIf="!sufficientQuota">{{'pages.updates.certTools.dynamicMFD.dragAndDrop.insufficientQuotas' | translate
      }}</label>
  </div>

  <div class="files-list">
    <div class="single-file" *ngFor="let scanFile of scanFiles; let i = index">

      <!-- file on hold -->
      <div *ngIf="scanFile.status === onHold" class="response on-hold">
        <fa-icon [icon]="['fas', 'file']" class="icon ms-1" size="1x"></fa-icon>
        <span class="ellipsis" [ngbTooltip]=scanFile.file.name>{{ scanFile.file.name }}</span>
        <span>{{ formatBytes(scanFile.file.size) }}</span>
        <fa-icon [icon]="['fas', 'trash']" class="icon trash action me-1" size="1x" (click)="deleteFile(i)"></fa-icon>
      </div>

      <!-- file in analysis-->
      <div *ngIf="scanFile.status === inAnalysis">
        <div class="progress" role="progressbar" aria-label="Animated striped" aria-valuenow="100" aria-valuemin="0"
          aria-valuemax="100">
          <div class="progress-bar progress-bar-striped progress-bar-animated" style="width: 100%">
            <label class="ellipsis">{{ scanFile.file.name}}</label>
        </div>
        </div>
      </div>

      <!-- file report available -->
      <div *ngIf=" scanFile.status === inSuccess" class="response success">
        <fa-icon [icon]="['fas', 'check']" class="icon status" size="2x"></fa-icon>
        <span class="ms-2 filename ellipsis" (click)="onFileClick(scanFile)">{{ scanFile.file.name }}</span>
        <fa-icon [icon]="['fas', 'arrows-rotate']" class="icon refresh ms-2" size="1.5x" (click)="onRefreshFile(scanFile)"></fa-icon>
      </div>

      <!-- file report in error -->
      <div *ngIf="scanFile.status === inError" class="response error" [ngbTooltip]="scanFile.error">
        <fa-icon [icon]="['fas', 'xmark']" class="icon status" size="2x"></fa-icon>
        <span class="ms-2 ellipsis">{{ scanFile.file.name }}</span>
        <fa-icon [icon]="['fas', 'trash']" class="icon trash action me-1" size="1x" (click)="deleteFile(i)"></fa-icon>
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InsightCategory, InsightData } from 'src/app/shared/model/cybersocxdr/insight';

@Component({
  selector: 'app-insight-carrousel',
  templateUrl: './insight-carrousel.component.html',
})
export class InsightCarrouselComponent {
  @Input() set insights(value: InsightData[]) {
    this._insights = value;
    this.filter();
    this.formatCarrouselInsights();
  }

  @Input()
  period: 'last7d' | 'last30d' = 'last30d';

  @Output()
  onPeriodChange = new EventEmitter<'last7d' | 'last30d'>();

  periodOptions = ['last7d', 'last30d'];

  //Display title, filter and order
  @Input()
  displayHeader: boolean = true;

  // make InsightCategory enum available in the template
  InsightCategory = InsightCategory;

  _insights: InsightData[];

  filterCategory: InsightCategory | '' = '';
  sortCategory: InsightCategory | '' = '';

  displayInsights: InsightData[] = [];
  carrouselInsights: InsightData[][] = [];

  formatCarrouselInsights() {
    this.carrouselInsights = [];
    for (let i = 0; i < this.displayInsights.length; i++) {
      this.carrouselInsights[Math.floor(i / 2)] = [
        ...(this.carrouselInsights[Math.floor(i / 2)] ?? []),
        this.displayInsights[i],
      ];
    }
  }

  onSelectPeriod(period: 'last7d' | 'last30d') {
    this.period = period;
    this.onPeriodChange.emit(period);
  }

  onFilterSelect(value: InsightCategory | '') {
    this.filterCategory = value;
    this.filter();
  }

  onSortSelect(value: InsightCategory | '') {
    this.sortCategory = value;
    this.sort();
  }

  filter() {
    if (this.filterCategory) {
      this.displayInsights = [
        ...this._insights.filter((insight) => insight.category.includes(this.filterCategory as InsightCategory)),
      ];
    } else {
      this.displayInsights = [...this._insights];
    }
    this.sort();
    this.formatCarrouselInsights();
  }

  sort() {
    if (this.sortCategory)
      this.displayInsights.sort((a, b) => {
        if (
          a.category.includes(this.sortCategory as InsightCategory) &&
          b.category.includes(this.sortCategory as InsightCategory)
        )
          return 0;
        if (a.category.includes(this.sortCategory as InsightCategory)) return -1;
        if (b.category.includes(this.sortCategory as InsightCategory)) return 1;
        return 0;
      });

    this.formatCarrouselInsights();
  }
}

<h5>
  {{ 'pages.reports.scheduling.nextProcessingReportTitle' | translate }}
  <!-- at {{ futureGenerationTime * 1000 | date: 'dd/MM/yyyy HH:mm'}} --> 
</h5>

<div class="row mt-5 justify-content-center p-5" *ngIf="nextGenerationSchedules === undefined">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<ul>
<li *ngFor="let schedule of nextGenerationSchedules">
  <p [innerHTML]="'pages.reports.scheduling.scheduleDesc' | translate: {pdfName: schedule.filterParameters.pdfName, scheduleDate: schedule.time * 1000 | date: 'dd/MM/yyyy HH:mm'}" style="display:inline"></p>
  <button class="btn btn-primary ms-2" (click)="deleteSchedule(schedule);">{{ 'buttons.action.delete' | translate }}</button>
</li>
</ul>

<p *ngIf="nextGenerationSchedules?.length === 0">
  {{ 'pages.reports.scheduling.noReport' | translate }}
</p>

<br/>
<br/>

<h5>{{ 'pages.reports.scheduling.futureReportTitle' | translate }}</h5>

<div class="row mt-5 justify-content-center p-5" *ngIf="futureSchedules === undefined">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<ul>
<li *ngFor="let schedule of futureSchedules">
  <p [innerHTML]="'pages.reports.scheduling.scheduleDesc' | translate: {pdfName: schedule.filterParameters.pdfName, scheduleDate: schedule.time * 1000 | date: 'dd/MM/yyyy HH:mm'}" style="display:inline"></p>
  <button class="btn btn-primary ms-2" (click)="deleteSchedule(schedule);">{{ 'buttons.action.delete' | translate }}</button>
</li>
</ul>

<p *ngIf="futureSchedules?.length === 0">
  {{ 'pages.reports.scheduling.noReport' | translate }}
</p>

<br/>
<br/>


<h5>
  {{ 'pages.reports.scheduling.completeSchedulesTitle' | translate }}
</h5>

<div class="row mt-5 justify-content-center p-5" *ngIf="completedSchedules === undefined">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row">
<div class="col-10 offset-1 col-lg-8 offset-lg-2">

<div class="row faketableheaderrow">
  <div class="col-5 faketableheader click" (click)="sortCompletedSchedulesByName()">{{ 'pages.generic.name' | translate }}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-4 d-none d-md-block faketableheader click" (click)="sortCompletedSchedulesByDate()">{{ 'pages.generic.date' | translate }}
    <fa-icon [icon]="['fas', 'sort']" class="p-1"></fa-icon>
  </div>
  <div class="col-3 faketableheader">{{ 'pages.support.dashboard.fieldTitles.status' | translate }}</div>
</div>

<div *ngIf="completedSchedules?.length === 0" class="row faketablerow">
  <div class="col-12 faketablecell">
    {{ 'pages.reports.scheduling.noCompletedSchedule' | translate }}
  </div>
</div>

<div *ngFor="let schedule of completedSchedules" class="row faketablerow">
  <div class="col-5 faketablecell">
    {{ schedule.filterParameters.pdfName }}
  </div>
  <div class="col-4 faketablecell">
    {{ schedule.time * 1000 | date : 'dd/MM/yyyy HH:mm' }}
  </div>
  <div class="col-3 faketablecell">
    <span class="tag text-bg-success">{{ 'pages.reports.scheduling.completed' | translate }}</span>
  </div>
</div>

</div>
</div>

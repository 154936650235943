/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { UserPreferenceArrayUpdateCriteria } from '../model/searchCriteria';
import { constants } from '../constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { Widget } from '../model/widget';
import { LocalStorageItem, SessionStorageItem, UserPreferences } from '../model/shared-items';
import { NotificationPreferences } from '../model/notification';
import { HttpUsersPreferencesService } from '../httpServices/http-users-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import { UtilsService } from './utils.service';
import { GPAlert, GPError } from '../model/alert';
import { Survey } from '../model/feedback';
import { TenantAccess } from '../model/TenantAccess';

@Injectable()
export class UserPreferenceService {

    private selectedWidgetsSource = new BehaviorSubject<Widget[]>(null);
    private preferencesLoaded: Subject<boolean> = new Subject<boolean>();
    public selectedWidgets = this.selectedWidgetsSource.asObservable();

    /**
     * user preferences
     */
    private _userPreferences;
    public get userPreferences() { return this._userPreferences; }
    public set userPreferences(value) { this._userPreferences = value; }

    /**
     * user language and region
     */
    public get language() { return this._userPreferences.language; }
    public get region() { return this._userPreferences.region; }

    /**
     * last login date
     */
    private _lastLoginDate;
    public get lastLoginDate() { return this._lastLoginDate; }
    public set lastLoginDate(value) { this._lastLoginDate = value; }

    /**
     * profile picture
     */
    private _profilePicture;
    public get profilePicture() { return this._profilePicture; }
    public set profilePicture(value) { this._profilePicture = value; }

    private _tenantAccesses;
    public get tenantAccesses(): TenantAccess { return this._tenantAccesses; }

    constructor(
        private httpUsersPreferencesService: HttpUsersPreferencesService,
        private translateService: TranslateService,
        private alertService: AlertService,
        private utilsService: UtilsService
    ) { }

    /**
     * When all datas loaded, initialize the service proprerties
     */
    public initializePreferences(lastLoginDate) {

        if(lastLoginDate.status === 'fulfilled') {
            this.lastLoginDate = lastLoginDate.value;
        }

        // initialize translate service with user preferences
        if (this.userPreferences.language) {
            this.translateService.setDefaultLang(this.userPreferences.language.toLowerCase());
            this.translateService.use(this.userPreferences.language.toLowerCase());
            this.preferencesLoaded.next(true);
        }
        // apply default language to en
        else {
            this.translateService.setDefaultLang('en');
            this.translateService.use('en');
        }

        // store last login before updating it for activity info
        if (this.userPreferences.lastLogin) {
            sessionStorage.setItem(SessionStorageItem.lastLogin, String(this.userPreferences.lastLogin));
        }

    }

    initialize(): Promise<{lastLogin: any; widgets: []}> {

        return this.httpUsersPreferencesService.userPrefencesInitialize()
        .then(res => {
            const widgetsSelection = res.lastLogin?.widgetsSelection;

            if(!widgetsSelection ||widgetsSelection.length === 0) {
                this.saveWidgetSelection(res.widgets);
                return res.widgets;
            }
            else {
                this.updateCacheSelectedWidgets(res.lastLogin.widgetsSelection);
            }
            return res;
        })
        .catch(this.handleError);
    }

    updateCacheSelectedWidgets(widgets: Widget[]){
        const ws = widgets.map((w)=> ({
            name : w.name,
            displayName: w.displayName,
            rank : w.rank,
            width : w.width,
            class : '',
            style : ''
        }));
        this.selectedWidgetsSource.next(ws);
        sessionStorage.setItem(constants.local.selectedWidgets, JSON.stringify(ws));
    }

    getRegisteredWidgets(): Promise<Widget[]> {
        return new Promise((resolve,reject) => {
            if(JSON.parse(sessionStorage.getItem(constants.local.libraryWidgets))){
                resolve(JSON.parse(sessionStorage.getItem(constants.local.libraryWidgets)));
            }else{

                this.httpUsersPreferencesService
                    .userPrefencesHighestRank()
                    .then(response => sessionStorage.setItem(constants.local.libraryWidgets, JSON.stringify(response)))
                    .then(() => resolve(JSON.parse(sessionStorage.getItem(constants.local.libraryWidgets))))
                    .catch(err => reject(err));
            }
        });
    }

    async getAllGPNotification(): Promise<GPAlert[]> {
        try {
            return await this.httpUsersPreferencesService.getAllNotifications();
        } catch(error) {
            this.alertService.handlerError(error);
        }
    }

    async getAllSurveys(state: string): Promise<Survey[]> {
        try {
            return await this.httpUsersPreferencesService.getAllSurveys(state);
        } catch(error) {
            this.alertService.handlerError(error);
        }
    }

    addUserSelectedWidgets(widget: Widget){

        // initialize widget properties
        const ws= {
            name : widget.name,
            displayName: widget.displayName,
            rank : widget.rank,
            width : widget.width,
            class : '',
            style : ''
        };
        const widgets = this.selectedWidgetsSource.getValue();
        widgets.push(ws);

        this.updateCacheSelectedWidgets(widgets);
        return this.userPreferencesUpdateSingle(UserPreferences.widgetsSelection, widgets);
    }

    removeUserSelectedWidgets(name: string) {
        let widgets = this.selectedWidgetsSource.getValue();
        const ws= widgets.find(w => w.name === name);

        if(ws) {
            widgets = widgets.filter(w => w.name !== name);
            this.updateCacheSelectedWidgets(widgets);
            return this.userPreferencesUpdateSingle(UserPreferences.widgetsSelection, widgets);
        }

    }

    saveWidgetSelection(widgets: Widget[]) {
        this.updateCacheSelectedWidgets(widgets);
        return this.userPreferencesUpdateSingle(UserPreferences.widgetsSelection, widgets);
    }

    getNotificationsPreferences(): Promise<[]> {

        return this.httpUsersPreferencesService
            .userPrefencesGetNotificationPreferences()
            .then(res => res)
            .catch(this.handleError);
    }

    async updateUserNotificationPreferences(notificationPreferences: NotificationPreferences): Promise<any> {
        const userPref = JSON.parse(sessionStorage.getItem(constants.local.userPreferences));
        userPref.notificationPreference = notificationPreferences.preferences ?? {};
        sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(userPref));

        return this.httpUsersPreferencesService
            .updateNotificationPreference(JSON.stringify({notificationPreference: userPref.notificationPreference}))
            .then(response => response as any)
            .catch(this.handleError);
      }

    updateUserPrefNotif(notifs: any) {
        // get lastNotification Id
        const lastNotifId = notifs[0]._id.$oid;
        // setNewPref in db
        this.userPreferencesUpdateSingle('lastNotification', lastNotifId).then( preferences => {
            // then set back user preferences in storage
            sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(preferences));
        }).catch( () => {
            this.alertService.addError('error updating last notification in preferences');
        });

        // don't know why we need to return something here
        return this.httpUsersPreferencesService
            .getUserNotifications(lastNotifId)
            .then(res => res)
            .catch(this.handleError);
    }

    /**
     *
     */
    async loadProfilePicture() {

        if(this.profilePicture) {
            // profile picture already loaded
            return;
        }
        if (localStorage.getItem(LocalStorageItem.profilePicture)) {
            this.profilePicture = localStorage.getItem(LocalStorageItem.profilePicture);
        } else {
            // no picture un localstorage, get it and store it
            try {
                const picture: Blob = await this.httpUsersPreferencesService.getProfilePicture();
                // if image sended, use it
                if(picture && picture.size > 0) {
                    const url = await this.utilsService.getImageAsUrl(picture);
                    localStorage.setItem(LocalStorageItem.profilePicture, url);
                    this.profilePicture = url;
                }
            } catch (error) {
                this.alertService.handlerError(error);
            }
        }
    }

    /**
     * Get all the users preferences for a logged in user
     * Tries to get them locally, if not then retrieve from db
     * Preferences loaded at login and app initialisation
     */
    async loadPreferences() {
        if (sessionStorage.getItem(constants.local.userPreferences)) {
            this.userPreferences = JSON.parse(sessionStorage.getItem(constants.local.userPreferences));
        } else {
            try {
                const preferences = await this.httpUsersPreferencesService.getUserPreferences();
                this.userPreferences = preferences;
                sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(this.userPreferences));
            } catch (error) {
                this.alertService.handlerError(error);
            }
        }
    }

    /**
     * Get the last login date for the user from the
     * user preferences
     * This data is cached in sessionStorage at app init
     * so that any subsequent refreshes dont update it
     * It will look for the lastLogin cache before reverting back to
     * the user preferences object
     */
    getUserLastLoginDate(): Promise<Date> {
        return new Promise((resolve) => {
            const lastLogin = sessionStorage.getItem(SessionStorageItem.lastLogin);
            if (lastLogin && lastLogin !== 'undefined') {
                resolve(new Date(Number(lastLogin)));
            } else {
                if (this.userPreferences.lastLogin) {
                    resolve(new Date(this.userPreferences.lastLogin));
                } else {
                    resolve(new Date());
                }
            }
        });
    }

    /**
     * Update a single user preference attribute with a value
     * Once complete update the local user preferences to match
     *
     * @param attribute
     * @param value
     */
    userPreferencesUpdateSingle(attribute: string, value: any): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userPrefencesUpdateSinglePrivate(attribute, value)
                .then(res => {
                    this.userPreferences = res;
                    sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(res));
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    /**
     * Update an item within an array attribute of user preferences
     * Once complete update the local user preferences to match
     *
     * @param arrayName
     * @param item
     * @param deleted
     */
    userPreferencesUpdateArray(arrayName: string, item: any, deleted: boolean): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userPrefencesUpdateArrayPrivate(arrayName, item, deleted)
                .then(res => {
                    this.refreshLocalUserPreferences();
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

     /**
      * Update an item within an array attribute of user preferences
      * The array is a child of the topLevelObject to allow better data structure
      * Once complete update the local user preferences to match
      *
      * @param arrayName
      * @param item
      * @param deleted
      */
    userPreferencesUpdateChildArray(topLevelObject: string, arrayName: string, item: any, deleted: boolean): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userPrefencesUpdateChildArrayPrivate(topLevelObject, arrayName, item, deleted)
                .then(res => {
                    this.userPreferences = res;
                    sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(res));
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    /**
     * Update the users flagged items
     * Each itemtype has its own array attribute in user preferences object
     * constants file contains a list of the item types
     *
     * @param itemType
     * @param caseRef
     */
    async userPreferencesUpdateFlags(itemType: string, flagRefs: string[], caseRef: string) {

        //const flagged = caseRef ? this.userPreferences.flags[itemType].includes(caseRef) : false;
        const deleted = flagRefs.includes(caseRef);
        if (deleted) {
            //remove flagged case
            const index = flagRefs.indexOf(caseRef, 0);
            if (index > -1) {
                flagRefs.splice(index, 1);
            }
        } else if (caseRef) {
            flagRefs.push(caseRef);
        }

        // update the users flags appropriately
        try {
            const preferences = await this.userPrefencesUpdateReferencesPrivate(UserPreferences.flags, itemType, caseRef, deleted);
            this.userPreferences = preferences;
            sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(preferences));
        } catch (exception) {
            this.alertService.handlerError(exception);
        }
    }

      /**
       * Update the users watchedProducts items
       * Each itemtype has its own array attribute in user preferences object
       * constants file contains a list of the item types
       *
       * @param itemType
       * @param reference
       * @param deleted
       */
    userPreferencesUpdateWatchedProducts(itemType: string, reference: string, deleted: boolean): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userPrefencesUpdateReferencesPrivate(UserPreferences.watched, itemType, reference, deleted)
                .then(res => {
                    this.userPreferences = res;
                    sessionStorage.setItem(constants.local.userPreferences, JSON.stringify(res));
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    /**
     * Update the users watchedProducts items
     * Each itemtype has its own array attribute in user preferences object
     * constants file contains a list of the item types
     *
     * @param itemType
     * @param reference
     * @param deleted
     */
     userPreferencesUpdateSelectedWidgets(itemType: string, reference: string, deleted: boolean): Promise<any> {
        return new Promise((resolve,reject) => {
            this.userPrefencesUpdateReferencesPrivate(UserPreferences.selectedWidgets, itemType, reference, deleted)
                .then(res => {
                    this.refreshLocalUserPreferences();
                    resolve(res);
                })
                .catch(err => reject(err));
        });
    }

    async updateProfilePicture(picture: File) {
        try {
            const updates = await Promise.allSettled([
                this.httpUsersPreferencesService.updateProfilePicture(picture),
                this.utilsService.getImageAsUrl(picture)
            ]);

            // store the new url image
            if(updates[0].status === 'fulfilled' && updates[1].status === 'fulfilled') {
                const url = updates[1].value;
                localStorage.setItem(LocalStorageItem.profilePicture, url);
                this.profilePicture = url;
            }else {
                throw new GPError(this.translateService.instant('pages.dashboard.user.updateException'), null);
            }
        } catch (exception) {
            throw new GPError(this.translateService.instant('pages.dashboard.user.updateException'), exception);
        }
    }

    async deleteProfilePicture() {
        try {
            await this.httpUsersPreferencesService.deleteProfilePicture();
            this.profilePicture = undefined;
            localStorage.removeItem(LocalStorageItem.profilePicture);
        } catch (exception) {
            throw new GPError(this.translateService.instant('pages.dashboard.user.updateException'), exception);
        }
    }

    /**
     * Refresht the locally stored preferences by deleting and recreating
     */
    private refreshLocalUserPreferences() {
        sessionStorage.removeItem(constants.local.userPreferences);
        this.loadPreferences();
    }

    /**
     * Access the endpoint to update a string array of references for a given attribute type for an item
     *
     * @param attributeType - the type of reference eg) flags
     * @param itemType - the object type eg) signals
     * @param reference - the reference
     * @param deleted - whether to delete
     */
    private userPrefencesUpdateReferencesPrivate(
        attributeType: string,
        itemType: string,
        reference: string,
        deleted: boolean
    ): Promise<any> {

        return this.httpUsersPreferencesService
            .userPrefencesUpdateReferences(JSON.stringify({attributeType, itemType, reference, delete: deleted}))
            .then(response => response as any)
            .catch(this.handleError);
    }

    /**
     * Access the endpoint to update single attribute
     *
     * @param attribute
     * @param value
     */
    private userPrefencesUpdateSinglePrivate(attribute: string, value: any): Promise<any> {
        const criteria = {};
        criteria[attribute] = value;

        return this.httpUsersPreferencesService
            .userPrefencesUpdateSinglePrivate(JSON.stringify(criteria))
            .then(response => response as any)
            .catch(this.handleError);
    }

    /**
     * Access the endpoint to update arrays
     *
     * @param arrayName
     * @param item
     * @param deleted
     */
    private userPrefencesUpdateArrayPrivate(arrayName: string, item: any, deleted: boolean): Promise<any> {
        const criteria = new UserPreferenceArrayUpdateCriteria();
        criteria.arrayName = arrayName;
        criteria.item = item;
        criteria.delete = deleted;

        return this.httpUsersPreferencesService.
            userPrefencesUpdateArray(criteria)
            .then(response => response as any)
            .catch(this.handleError);
    }

    /**
     * Access the endpoint to update arrays
     *
     * @param arrayName
     * @param item
     * @param deleted
     */
    private userPrefencesUpdateChildArrayPrivate(topLevelObject: string, arrayName: string, item: any, deleted: boolean): Promise<any> {
        const criteria = new UserPreferenceArrayUpdateCriteria();
        criteria.topLevelObject = topLevelObject;
        criteria.arrayName = arrayName;
        criteria.item = item;
        criteria.delete = deleted;

        return this.httpUsersPreferencesService
            .userPrefencesUpdateArray(criteria)
            .then(response => response as any)
            .catch(this.handleError);
    }

    /**
     * set tenantAccesses values
     */
    async loadTenantAccesses() {
        this._tenantAccesses = await this.httpUsersPreferencesService
            .getTenantAccesses().then( accesses => {
                return accesses;
            });
      }

    /**
     * Generic error handler for this service
     *
     * @param error
     */
    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

}

<div>
  <h2 class="visually-hidden">{{ 'menu.dashboard' | translate }}</h2>
  <!-- login section -->
  <app-login-section [name]="oktaAuthService.userDetails?.name" [selectedTenant]="oktaAuthService.selectedTenant"></app-login-section>

  <!-- selected widgets-->
  <div *ngIf="loaded">
    <app-widgets-dashboard [selectedWidgets]="selectedWidgets" [widgetSettings]="widgetSettings"></app-widgets-dashboard>
  </div>

  <div class="row mt-5 justify-content-center p-5" *ngIf="!selectedWidgets">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>

</div>

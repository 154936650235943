import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Survey } from 'src/app/shared/model/feedback';
import { ContextService } from 'src/app/shared/services/context-service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent implements OnInit {

  @Output() finished = new EventEmitter<boolean>();
  @Output() surveySelected = new EventEmitter<Survey>();

  public surveys: Survey[];
  public loaded = false;

  constructor(
    private contextService: ContextService,
    private userPreferenceService: UserPreferenceService
  ) { }

  async ngOnInit() {

    if (this.contextService.surveys) {
      this.surveys = this.contextService.surveys;
    } else {
      const response = await this.userPreferenceService.getAllSurveys('open');
      this.surveys = response;
      this.contextService.surveys = this.surveys;
    }

    this.loaded = true;
  }

  public openSurvey(survey: Survey) {
    this.surveySelected.emit(survey);
  }

  /**
   * close modal and trigger callback if needed
   */
  public close() {
    this.finished.emit(false);
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-searchabledropdown',
  templateUrl: './searchabledropdown.component.html',
  styleUrls: ['./searchabledropdown.component.scss'],
})
export class SearchabledropdownComponent {
  @Input() title: string;
  @Input() items: string[] = [];
  @Input() selectedItems: string[] = [];
  @Output() onCheck: EventEmitter<[string, boolean]> = new EventEmitter();
  @Input() minItemsToShowSearch = 4;
  @Input() emptyItemLabel = 'None';
  search = '';

  getItemsFiltered(): string[] {
    const items = this.items;
    if (!this.search) {
      return items;
    }
    return items.filter((item) => item.toLowerCase().includes(this.search.toLowerCase()));
  }

  check(item: string, check: boolean) {
    this.onCheck.emit([item, check]);
  }
}

import { Component, OnInit } from '@angular/core';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { MfdService } from 'src/app/shared/services/maliciousfiledetection.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-mfd-history',
  templateUrl: './mfd-history.component.html',
  styleUrls: ['./mfd-history.component.scss']
})
export class MfdHistoryComponent implements OnInit {
  history: {email: string; time: number; filename: string}[];

  constructor(
    private mfdService: MfdService,
    private userActivityService: UserActivityService,
  ) {}

  ngOnInit() {

    this.mfdService.getHistory()
      .then(h => {this.history = h.sort((s1, s2) => (s2.time - s1.time));});

    this.userActivityService.logActivity(TYPES.services, PAGES.mfdHistory, ACTIONS.visit);
  }
}

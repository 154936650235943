import { Component } from '@angular/core';

@Component({
  selector: 'app-feedback-reminder',
  templateUrl: './feedback-reminder.component.html',
  styleUrl: './feedback-reminder.component.scss'
})
export class FeedbackReminderComponent {

}

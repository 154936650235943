import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OktaAuthGuard } from '../app.guard';
import { LayoutComponent } from '../layout/layout/layout.component';
import { ActivityDashboardComponent } from './activitydashboard/activitydashboard.component';
import { ActivitySearchComponent } from './activitysearch/activitysearch.component';
import { SecurityIncidentTimelineComponent } from './security-incident-timeline/security-incident-timeline.component';

const routes: Routes = [
  {
    path: 'activity',
    component: LayoutComponent,
    canActivate: [OktaAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'search',
        component: ActivitySearchComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.activity.search',
          urls: [{ title: 'menu.activity.title' }, { title: 'menu.activity.search' }],
        }
      },
    ]
  },
  {
    path: 'activity',
    component: LayoutComponent,
    canActivate: [OktaAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: ActivityDashboardComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.activity.overview',
          urls: [{ title: 'menu.activity.title' }, { title: 'menu.activity.overview' }],
        }
      }
    ]
  },
  {
    path: 'activity',
    component: LayoutComponent,
    canActivate: [OktaAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'securityincident',
        component: SecurityIncidentTimelineComponent,
        pathMatch: 'full',
        data: {
          title: 'menu.activity.overview',
          urls: [{ title: 'menu.activity.title' }, { title: 'menu.activity.overview' }],
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivityRoutingModule { }

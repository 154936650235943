<div ngbDropdown>
  <button class="btn btn-info btn-dropdown rounded" ngbDropdownToggle>
    <div class="text-truncate">
      @for (item of selectedItems; track $index) {
      {{ item | titlecase
      }}{{ $index < selectedItems?.length - 1 ? ', ' : '' }} }@empty {
      {{ 'pages.cybersocxdr.filters.all' | translate }}}
    </div>
  </button>
  <div ngbDropdownMenu class="dropdownMenu longdropdown">
    <input
      *ngIf="items?.length > minItemsToShowSearch"
      type="text"
      placeholder="Search..."
      id="filterfilter"
      class="form-control"
      [(ngModel)]="search"
    />
    <div *ngFor="let item of getItemsFiltered()" class="my-1 mx-1 form-check">
      <input
        type="checkbox"
        class="form-check-input"
        [id]="title.replace(' ', '') + item + '-check'"
        (change)="check(item, $event.target.checked)"
        [checked]="selectedItems.includes(item)"
      />
      <label
        class="form-check-label"
        [for]="title.replace(' ', '') + item + '-check'"
        >{{ item || emptyItemLabel | titlecase }}</label
      >
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { EditColumnParameters } from '../../modal';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-edit-columns-modal',
  templateUrl: './edit-columns-modal.component.html',
  styleUrls: ['./edit-columns-modal.component.scss']
})
export class EditColumnsModalComponent {

  @Input() params: EditColumnParameters;

  constructor(
    public activeModal: NgbActiveModal
  ) {}


  public success() {
    this.activeModal.close();
    this.params.successCallback(this.params.selectedColumns);
  }

  public close() {
    this.activeModal.close();
  }

}

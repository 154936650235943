import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';

import { UpdatesRoutingModule } from './updates-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { SignalGridComponent } from '../shared/components/signal-grid/signal-grid.component';
import { SignalListComponent } from '../shared/components/signal-list/signal-list.component';
import { WorldWatchComponent } from './worldwatch/dashboard/worldwatch.component';
import { ViewSignalComponent } from './worldwatch/viewsignal/viewsignal.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { VulnerabilitiesComponent } from './vulnerabilities/dashboard/vulnerabilities.component';
import { VulnListComponent } from '../shared/components/vuln-list/vuln-list.component';
import { VulnGridComponent } from '../shared/components/vuln-grid/vuln-grid.component';
import { ViewVulnComponent } from './vulnerabilities/viewvuln/viewvuln.component';
import { ViewCpeComponent } from './vulnerabilities/viewcpe/viewcpe.component';
import { ViewTagComponent } from './worldwatch/viewtags/viewtag.component';
import { WhitepapersComponent } from './rss/whitepapers/whitepapers.component';
import { RssFeedComponent } from './rss/rss-feed/rss-feed.component';
import { BlogComponent } from './rss/blog/blog.component';
import { EventsComponent } from './rss/events/events.component';
import { NewsComponent } from './rss/news/news.component';
import { IntegrationsDashboardComponent } from './integrations/dashboard/integrations-dashboard.component';
import { IntegrationsAzureAdSSOComponent } from './integrations/azure-ad-sso/integrations-azure-ad-sso.component';
import { CertToolsComponent } from './cert-tools/page/cert-tools.component';
import { MfdResultsComponent } from './cert-tools/mfd-services/static-analysis/mfd-results/mfd-results.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MfdUploadComponent } from './cert-tools/mfd-services/static-analysis/mfd-upload/mfd-upload.component';
import { MfdSearchComponent } from './cert-tools/mfd-services/static-analysis/mfd-search/mfd-search.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RssFeedViewComponent } from './rss/rss-feed-view/rss-feed-view.component';
import { MfdHistoryComponent } from './cert-tools/mfd-services/static-analysis/mfd-history/mfd-history.component';
import { MfsServiceComponent } from './cert-tools/mfd-services/static-analysis/mfs-service/mfs-service.component';
import { DynamicMfdUploadComponent } from './cert-tools/mfd-services/dynamic-analysis/dynamic-mfd-upload/dynamic-mfd-upload.component';
import { OCDServicesComponent } from './ocd-services/ocd-services.component';
import { TrainingItemComponent } from './training/training-item/training-item.component';
import { TrainingDashboardComponent } from './training/trainingdashboard/trainingdashboard.component';
import { TrainingCourseComponent } from './training/training-course/training-course.component';
import { OffsetDatetimePipe } from '../shared/pipes/offsetdatetime.pipe';


@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    UpdatesRoutingModule,
    FormsModule,
    AppSharedModule,
    HighchartsChartModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule,
    InfiniteScrollModule,
    OffsetDatetimePipe
  ],
  declarations: [
    WorldWatchComponent,
    VulnerabilitiesComponent,
    SignalGridComponent,
    SignalListComponent,
    ViewSignalComponent,
    VulnListComponent,
    VulnGridComponent,
    ViewVulnComponent,
    ViewCpeComponent,
    ViewTagComponent,
    BlogComponent,
    EventsComponent,
    NewsComponent,
    WhitepapersComponent,
    RssFeedComponent,
    OCDServicesComponent,
    OCDServicesComponent,
    IntegrationsDashboardComponent,
    IntegrationsAzureAdSSOComponent,
    CertToolsComponent,
    MfdUploadComponent,
    MfdResultsComponent,
    MfdSearchComponent,
    RssFeedViewComponent,
    MfdHistoryComponent,
    MfsServiceComponent,
    DynamicMfdUploadComponent,
    TrainingDashboardComponent,
    TrainingItemComponent,
    TrainingCourseComponent,
  ],
  exports: [ WorldWatchComponent, VulnerabilitiesComponent ],
  bootstrap: [ WorldWatchComponent, VulnerabilitiesComponent ]
})
export class UpdatesModule {
  constructor(library: FaIconLibrary){
    library.addIconPacks(fas, far);
  }

}

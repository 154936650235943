import { Component, OnInit } from '@angular/core';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { ACTIONS, PAGES, TYPES } from 'src/app/shared/constants';
import { MFDAnalysis } from './cert-tools-items';

@Component({
  selector: 'app-cert-tools',
  templateUrl: './cert-tools.component.html',
  styleUrls: ['./cert-tools.component.scss']
})
export class CertToolsComponent implements OnInit {
  mfdQuota: number;
  loading=true;

  public mfdStaticAnalysis: MFDAnalysis;
  public mfdDynamicAnalysis: MFDAnalysis;

  public staticTitle: string;
  public staticTextKey: string;
  public staticServiceRoute: string;
  public staticHistoryRoute: string;

  constructor(
    private userActivity: UserActivityService
  ) { }

  ngOnInit() {

    this.mfdStaticAnalysis = new MFDAnalysis('pages.updates.certTools.staticAnalysis', 'pages.updates.certTools.staticMfdDesc',
    '/updates/services/certtools/mfdupload', '/updates/services/certtools/mfdhistory', true);

    this.mfdDynamicAnalysis = new MFDAnalysis('pages.updates.certTools.dynamicAnalysis', 'pages.updates.certTools.dynamicMfdDesc',
    '/updates/services/certtools/dynamicMFDupload', '/updates/services/certtools/mfdhistory', false);


    this.userActivity.logActivity(TYPES.services, PAGES.certToolbox, ACTIONS.visit);
  }

}

<div *ngIf="!loading && survey">
  <label>{{survey.title}}</label>
  <div>
    <!-- stepper -->
    <nav class="stepped-process mt-3" aria-label="Checkout process">
      <p class="float-start mt-2 me-2 fw-bold d-sm-none">Step</p>
      <ol>
        <li class="stepped-process-item" [ngClass]="{ active: question === activeQuestion }"
          *ngFor="let question of survey.questions; index as i ">
          <a class="stepped-process-link" title=""></a>
        </li>
      </ol>
    </nav>
  </div>

  <label class="title">{{activeQuestion.title}}</label>

  <!-- -->
  <ng-template #questionContainer></ng-template>

  <!-- button actions -->
  <div class="actions">

    <!-- previous -->
    <button type="button" class="btn btn-primary me-auto" [disabled]="currentQuestionIndex === 0" (click)="previousQuestion()">
      <fa-icon [icon]="['fas', 'chevron-left']" size="lg"></fa-icon>
    </button>

    <!-- next -->
    <button *ngIf="currentQuestionIndex !== (survey.questions.length - 1)" type="button" [disabled]="!surveyForm.get(activeQuestion.title).valid" class="btn btn-primary ms-auto" (click)="nextQuestion()">
      <fa-icon [icon]="['fas', 'chevron-right']" size="lg"></fa-icon>
    </button>

    <!-- submit -->
    <button *ngIf="currentQuestionIndex === (survey.questions.length - 1)" type="button" class="btn btn-primary ms-auto" (click)="submit()">
      {{ 'buttons.action.submit' | translate }}
    </button>
  </div>
</div>
<div class="row justify-content-center p-5" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>
export const environment = {
  production: true,
  translationsVersion: 8,
  tdc: {
    loginURL: 'https://api-v4.d-jkn-tdc-l-01.itm.lan/auth/okta/login',
  },
  endpoints: {
    worldwatch: 'https://api.orangecyberdefense.com/api/v1/worldwatch/',
    userpreferences: 'https://api.orangecyberdefense.com/api/v1/prefs/',
    vulnerabilities: 'https://api.orangecyberdefense.com/api/v1/vulns/',
    itsm: 'https://api.orangecyberdefense.com/api/v1/support/',
    rss: 'https://api.orangecyberdefense.com/api/v1/rss',
    okta: 'https://ocd-ciam.okta.com/api/v1/',
    oktaService: 'https://api.orangecyberdefense.com/api/v1/admin/',
    oktaProxyService: 'https://api.orangecyberdefense.com/proxy/v1/admin/',
    fileAccess: 'https://api.orangecyberdefense.com/api/v1/files/',
    fileScan: 'https://api.orangecyberdefense.com/api/v1/filesscan/',
    cybersocxdr: 'https://gpbackend.cybersocxdr.fr/',
  },
  allowedFileTypes: [
    'text/plain',
    'application/x-x509-ca-cert',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'text/csv',
    'text/html',
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.tcpdump.pcap',
    'application/x-gzip',
    'application/zip',
    'application/x-zip-compressed',
  ],
  oktaConfig: {
    issuer: 'https://ocd-ciam.okta.com/oauth2/ausph0wm67FwTrmrP416',
    clientId: '0oaph1wn7Xd8Z9Gs4416',
    redirectUri: 'https://portal.orangecyberdefense.com/callback',
    adminRedirectUri: 'https://portal.orangecyberdefense.com/user/management?authd=true',
    logoutRedirectUri: 'https://portal.orangecyberdefense.com',
    baseUrl: 'https://ocd-ciam.okta.com',
  },
  world_watch_group: 'COMPROLE_0oaph1wn7Xd8Z9Gs4416_WorldWatch',
  xtended_analytic_website_id: '24bf96b5-4c0e-4963-bc13-6b85268e4245',
  CSOCUsers: [
    'adam.ridley@orangecyberdefense.com',
    'aiste.skog@orangecyberdefense.com',
    'albin.gadaanjorgensen@orangecyberdefense.com',
    'alex.arvidsson@orangecyberdefense.com',
    'alexander.adler@orangecyberdefense.com',
    'alexander.ruhl@orangecyberdefense.com',
    'ali.copuro@orangecyberdefense.com',
    'alina.weber@orangecyberdefense.com',
    'allan.brandt@orangecyberdefense.com',
    'andre.cederholm@orangecyberdefense.com',
    'andre.henschel@orangecyberdefense.com',
    'andreas.bux@orangecyberdefense.com',
    'andreas.guenther@orangecyberdefense.com',
    'andreas.korsbakke@orangecyberdefense.com',
    'andreas.ottvall@orangecyberdefense.com',
    'andreas.persson@orangecyberdefense.com',
    'andrew.hewitt@orangecyberdefense.com',
    'andrew.ody@orangecyberdefense.com',
    'andrew.ody@orangecyberdefense.com',
    'andrew.ody@orangecyberdefense.com',
    'ashley.simmons@orangecyberdefense.com',
    'ashok.babu@orangecyberdefense.com',
    'axel.knutsson@orangecyberdefense.com',
    'benedict.camenzuli@orangecyberdefense.com',
    'briana.vanepps@orangecyberdefense.com',
    'catherine.gonzalez@orangecyberdefense.com',
    'christiaan.ileana@orangecyberdefense.com',
    'christian.ermanbriks@orangecyberdefense.com',
    'christoffer.toft@orangecyberdefense.com',
    'christopher1.martin@orangecyberdefense.com',
    'cristin.hakansson@orangecyberdefense.com',
    'cristin.hakansson@orangecyberdefense.com',
    'daniel.andreasson@orangecyberdefense.com',
    'daniel.mayr@orangecyberdefense.com',
    'daniel.nordbo@orangecyberdefense.com',
    'daniel.sillfors@orangecyberdefense.com',
    'daniel.sims@orangecyberdefense.com',
    'daniel.thomas@orangecyberdefense.com',
    'danny.nothstein@orangecyberdefense.com',
    'dauren.akilbekov@orangecyberdefense.com',
    'david.hornsten@orangecyberdefense.com',
    'david.larsson@orangecyberdefense.com',
    'dennis.bijman@orangecyberdefense.com',
    'dimitrios.vlastaras@orangecyberdefense.com',
    'ditto.devassy@orangecyberdefense.com',
    'douglas.folkesson@orangecyberdefense.com',
    'ebba.andersson@orangecyberdefense.com',
    'elias.astrom@orangecyberdefense.com',
    'elisa.ciesielski@orangecyberdefense.com',
    'elisabetta.recher@orangecyberdefense.com',
    'erik.wallberg@orangecyberdefense.com',
    'fabian.bauer@orangecyberdefense.com',
    'felix.erberth@orangecyberdefense.com',
    'frazer.weller@orangecyberdefense.com',
    'fredrik.ovnell@orangecyberdefense.com',
    'fredrik.sjostrom@orangecyberdefense.com',
    'fredrik.svensson@orangecyberdefense.com',
    'friedrich.holzner@orangecyberdefense.com',
    'gabriela.vukota@orangecyberdefense.com',
    'gayathri.thiruchchelvam@orangecyberdefense.com',
    'gustav.meyer@orangecyberdefense.com',
    'gustav.uppsall@orangecyberdefense.com',
    'hampus.bengtsson@orangecyberdefense.com',
    'hannes.ullman@orangecyberdefense.com',
    'hanse.holmqvist@orangecyberdefense.com',
    'hassan.olusesi@orangecyberdefense.com',
    'henrik.nero@orangecyberdefense.com',
    'henrik.olsson@orangecyberdefense.com',
    'henrik.olsson@orangecyberdefense.com',
    'herbert.urbanec@orangecyberdefense.com',
    'hrishikesh.narayanankutty@orangecyberdefense.com',
    'humbert.valles@orangecyberdefense.com',
    'ida.lunden@orangecyberdefense.com',
    'ilse.vansanten@orangecyberdefense.com',
    'ivanche.dimitrievski@orangecyberdefense.com',
    'jake.leftley@orangecyberdefense.com',
    'james.shelley@orangecyberdefense.com',
    'jasif.yousef@orangecyberdefense.com',
    'jeffrey.sattler@orangecyberdefense.com',
    'jem.salltun@orangecyberdefense.com',
    'jenny.antonsson@orangecyberdefense.com',
    'jens.elmlund@orangecyberdefense.com',
    'jens.vandevis@orangecyberdefense.com',
    'jesper1.madsen@orangecyberdefense.com',
    'joachim.lundberg@orangecyberdefense.com',
    'joachim.schuster@orangecyberdefense.com',
    'joachim.schuster@orangecyberdefense.com',
    'joakim.loxdal@orangecyberdefense.com',
    'joakim.nystad@orangecyberdefense.com',
    'johan.fleron@orangecyberdefense.com',
    'johan.rydell@orangecyberdefense.com',
    'johannes.gronhed@orangecyberdefense.com',
    'johannes.lindemeir@orangecyberdefense.com',
    'jonas.bergh@orangecyberdefense.com',
    'jonathan.lindgren@orangecyberdefense.com',
    'jonna.lundin@orangecyberdefense.com',
    'jorge.vanderlaat@orangecyberdefense.com',
    'joern.tillmanns@orangecyberdefense.com',
    'katarzyna.kalita@orangecyberdefense.com',
    'katharina.mayr@orangecyberdefense.com',
    'kaustubh.rasam@orangecyberdefense.com',
    'kenny.le@orangecyberdefense.com',
    'kerstin.hoermann@orangecyberdefense.com',
    'kerstin.hoermann@orangecyberdefense.com',
    'koen.stalmans@orangecyberdefense.com',
    'kristoffer.klungtveit@orangecyberdefense.com',
    'kristoffer.nedrebo@orangecyberdefense.com',
    'linus.jacobson@orangecyberdefense.com',
    'luca.avitabile@orangecyberdefense.com',
    'ludvig.lerbom@orangecyberdefense.com',
    'lukas.adel@orangecyberdefense.com',
    'lukas.marschall@orangecyberdefense.com',
    'lukas.marschall@orangecyberdefense.com',
    'lukas.soder@orangecyberdefense.com',
    'lukas.wass@orangecyberdefense.com',
    'magnus.ekdahl@orangecyberdefense.com',
    'magnus.hansson@orangecyberdefense.com',
    'magnus.rolfson@orangecyberdefense.com',
    'magnus.rolfson@orangecyberdefense.com',
    'mahmoud.allam@orangecyberdefense.com',
    'marcus.lenander@orangecyberdefense.com',
    'mark.vanderwind@orangecyberdefense.com',
    'markus.brevenius@orangecyberdefense.com',
    'markus.weberg@orangecyberdefense.com',
    'marten.ward@orangecyberdefense.com',
    'martijn.bemelmans@orangecyberdefense.com',
    'martin.oberg@orangecyberdefense.com',
    'martina.sjokvist@orangecyberdefense.com',
    'martina.sjokvist@orangecyberdefense.com',
    'mathis.villalta@orangecyberdefense.com',
    'matthew.senour@orangecyberdefense.com',
    'matthias.bissinger@orangecyberdefense.com',
    'mattias.pyykko@orangecyberdefense.com',
    'max.hirschberger@orangecyberdefense.com',
    'maximilian.schubert@orangecyberdefense.com',
    'michael.haugland@orangecyberdefense.com',
    'michael.kovalenko@orangecyberdefense.com',
    'michel.deweerd@orangecyberdefense.com',
    'michel.deweerd@orangecyberdefense.com',
    'michel.deweerd@orangecyberdefense.com',
    'nick.powney@orangecyberdefense.com',
    'nicolai.krohg@orangecyberdefense.com',
    'niklas.larsson@orangecyberdefense.com',
    'niklas.messerschmid@orangecyberdefense.com',
    'niklas.palmnert@orangecyberdefense.com',
    'nikola.tolic@orangecyberdefense.com',
    'nils.holten@orangecyberdefense.com',
    'nils.mueller@orangecyberdefense.com',
    'noah.jauchmann@orangecyberdefense.com',
    'norman.ziebal@orangecyberdefense.com',
    'ogulcan.ugur@orangecyberdefense.com',
    'olav.hoggen@orangecyberdefense.com',
    'oliver.karlsson@orangecyberdefense.com',
    'oscar.bernhardsson@orangecyberdefense.com',
    'oskar.carlsson@orangecyberdefense.com',
    'oskar.eliasson@orangecyberdefense.com',
    'panagiotis.pachymanolis@orangecyberdefense.com',
    'patrick.frank@orangecyberdefense.com',
    'patrick.regenscheit@orangecyberdefense.com',
    'peter.serti@orangecyberdefense.com',
    'philipp.kurz@orangecyberdefense.com',
    'philippe.boeij@orangecyberdefense.com',
    'pierre.vanderpol@orangecyberdefense.com',
    'pieter.rubens@orangecyberdefense.com',
    'pontus.hedman@orangecyberdefense.com',
    'pooja.hassan@orangecyberdefense.com',
    'pooja.lokesh@orangecyberdefense.com',
    'ramez.omran@orangecyberdefense.com',
    'rasmus.levin@orangecyberdefense.com',
    'reinier.keller@orangecyberdefense.com',
    'remon.svensson@orangecyberdefense.com',
    'renier.kachelhoffer@orangecyberdefense.com',
    'renzo.vanengelen@orangecyberdefense.com',
    'rob.wanmer@orangecyberdefense.com',
    'robert.litsch@orangecyberdefense.com',
    'robert.netzell@orangecyberdefense.com',
    'robin.blomdahl@orangecyberdefense.com',
    'robin.carlsson@orangecyberdefense.com',
    'robin.dietrich@orangecyberdefense.com',
    'robin.loggarfve@orangecyberdefense.com',
    'robin.roth@orangecyberdefense.com',
    'robin.wageck@orangecyberdefense.com',
    'ronny.pachel@orangecyberdefense.com',
    'rutger.smeets@orangecyberdefense.com',
    'sam.bikineh@orangecyberdefense.com',
    'sebastian.henningsson@orangecyberdefense.com',
    'sergio.ribeiro@orangecyberdefense.com',
    'shubham.bhoskar@orangecyberdefense.com',
    'shubham.bhoskar@orangecyberdefense.com',
    'shubham.bhoskar@orangecyberdefense.com',
    'simon.magnesjo@orangecyberdefense.com',
    'simon.sahlberg@orangecyberdefense.com',
    'simon.troester@orangecyberdefense.com',
    'simon.troester@orangecyberdefense.com',
    'soren.sadegibenab@orangecyberdefense.com',
    'stefan.eng@orangecyberdefense.com',
    'stefan.jonsson@orangecyberdefense.com',
    'stijn.vanderherten@orangecyberdefense.com',
    'taimoor.talpur@orangecyberdefense.com',
    'ted.olsson@orangecyberdefense.com',
    'terje.ovreberg@orangecyberdefense.com',
    'thomas.hellman@orangecyberdefense.com',
    'tim.oskarsson@orangecyberdefense.com',
    'tim.overgaard@orangecyberdefense.com',
    'tobias.pfisterer@orangecyberdefense.com',
    'tor.nilsson@orangecyberdefense.com',
    'vanja.kullander@orangecyberdefense.com',
    'vasilios.skepetzis@orangecyberdefense.com',
    'victor.pettersson@orangecyberdefense.com',
    'viktor.trpkoski@orangecyberdefense.com',
    'vilhelm.olow@orangecyberdefense.com',
    'william.samuelsson@orangecyberdefense.com',
    'wilma.andersson@orangecyberdefense.com',
    'yuki.nakamura@orangecyberdefense.com',
  ],
  nucleusIds: [
    {
      name: 'OCD_CERT_VOC_UKZA:',
      id: '148c3f78dbb3e850b54d454039961952',
      url: 'https://orangecyberdefense.nucleussec.com',
    },
    {
      name: 'OCD_CERT_VO_CSE:',
      id: '234d13c51b9a4910a81664a1604bcb40',
      url: 'https://orangecyberdefense-se.nucleussec.com',
    },
    {
      name: 'OCD_CERT_VOC_FR:',
      id: '11204c2787649910015e65370cbb35ad',
      url: 'https://orangecyberdefense-fr.nucleussec.com',
    },
  ],
};

import { Component, Input } from '@angular/core';
import { UserTheme } from 'src/app/shared/model/shared-items';

@Component({
  selector: 'app-widget-base',
  standalone: true,
  imports: [],
  templateUrl: './widget-base.component.html',
  styleUrl: './widget-base.component.scss'
})
export class WidgetBaseComponent {

  @Input() theme: UserTheme;

}

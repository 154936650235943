<div class="row">
  <div class="col-12 text-center">
    <div class="row">
      <div class="col-4">
        <div class="card-group">
          <div class="card">
            <div class="card-body text-center">
              <h5 class="card-title">{{ 'menu.reports.resolution' | translate }}</h5>
              <p class="card-text">{{ 'pages.reports.index.generatedWithSLAs' | translate }}</p>
              <a href="/reports/live/resolution" class="btn btn-primary">{{ 'pages.generic.generate' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div>

  <h2>{{ 'pages.updates.training.latestTrainings' | translate }}</h2>
  <div id="training-list" class="grid-container m-5" *ngIf="trainingsLoaded && trainings.length > 0">
    <app-training-item *ngFor="let training of trainings" [item]="training"></app-training-item>
    <div *ngTemplateOutlet="!trainingsLoaded && spinner"></div>
  </div>

  <div id="download-section" class="my-5">
    <h2>{{ 'pages.updates.training.downloads' | translate }}</h2>

    <div class="accordion" id="downloadsAccordion">

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true">
            {{ 'pages.updates.training.guides' | translate }}
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse">
          <div class="accordion-body">
            <button class="btn btn-primary mx-2" (click)="downloadDoc(userGuideDetails.fileName)">{{ userGuideDetails.fileName }}</button>
            <!-- <button *ngIf="isAdmin" class="btn btn-primary mx-2" (click)="downloadDoc(adminGuideDetails.fileName)">{{ adminGuideDetails.fileName }}</button> -->
          </div>
        </div>
      </div>

      <div class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false">
            {{ 'pages.updates.training.releaseNotes' | translate }}
          </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse">
          <div class="accordion-body">
            <div *ngIf="releaseNotesLoaded else spinner">
              <button
                class="btn btn-primary mx-2"
                *ngFor="let note of releaseNotes; index as i"
                (click)="downloadDoc(note.title)">
                  {{ note.title }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div *ngTemplateOutlet="downloadInProgress && spinner; context: {downloading: true}"></div>
  </div>
</div>

<ng-template #spinner let-downloading="downloading">
  <div class="row justify-content-center p-1">
    <span *ngIf="downloading">{{ 'pages.updates.training.downloadingFile' | translate }}</span>
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
</ng-template>

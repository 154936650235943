import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private alertService: AlertService
  ) { }


  /**
   * transform file to data url (used for 'src' attribute)
   */
  async getImageAsUrl(file: any): Promise<any> {
    try {
      return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
    } catch(error) {
      this.alertService.handlerError(error);
    }
  }
}

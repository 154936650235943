<div class="modal-header">
  <h4 class="modal-title" id="exampleModalLabel">{{ 'buttons.action.generateReport' | translate }}</h4>
  <button type="button" class="btn-close" (click)="close()"><span class="visually-hidden"></span></button>
</div>
<div class="modal-body">
  <div class="row mb-2">
    <div class="form-group col-12 pt-2">
      <label class="mb-1" for="contact">{{ 'modals.export.reportName' | translate }}</label>
      <input type="text" class="form-control" name="fileName" aria-describedby="fileName" [(ngModel)]="fileName" />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="defaultReportOption">
        <option *ngFor="let type of exportTypes | keyvalue" value={{type.value.value}}>{{ type.value.label }}</option>
      </select>
    </div>
    <div class="col-6">
      <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="defaultPDFAccessLevel">
        <option *ngFor="let access of pdfAccessLevels | keyvalue" title={{access.value.title}} value={{access.value.value}}>
          {{access.value.label | translate}}
        </option>
      </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="d-flex flex-row justify-content-between align-items-center w-100">
    <div class="custom-control custom-switch mr-auto" *ngIf="params?.reportCanBeScheduled">
      <input type="checkbox" class="form-check-input mx-1" id="customSwitch1" [(ngModel)]="reportIsScheduled">
      <label class="form-check-label" for="customSwitch1">{{ 'modals.export.planThisReport' | translate }}</label>
    </div>
    <div *ngIf="!params?.reportCanBeScheduled"></div>
    <div>
      <button type="button" class="btn btn-primary" (click)="success()" *ngIf="!reportIsScheduled">
        {{ 'pages.generic.save' | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="success()"  *ngIf="reportIsScheduled">
        {{ 'pages.generic.schedule' | translate }}
      </button>
      <button type="button" class="btn btn-secondary ms-1" (click)="close()">
        {{ 'pages.generic.close' | translate }}
      </button>
    </div>
  </div>
</div>
<div class="modal-body" *ngIf="reportIsScheduled">
  <div class="row">
    <div class="form-group col-12">
      <label for="scheduleDate">{{'pages.generic.date' | translate}}</label>
      <input type="datetime-local" class="form-control" name="scheduleDate" aria-describedby="scheduleDate"
      [(ngModel)]="scheduleDate" />
    </div>
  </div>
</div>

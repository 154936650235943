<!-- content -->
<div class="content">
  <div class="text-center p-5" *ngIf="redirecting">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
    <div class="p-3">
      <h3>{{ 'pages.user.adminAuthentication' | translate }}</h3>
    </div>
  </div>

  <div class="row pt-5" *ngIf="dataLoaded">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active' : selectedTab === 'users' }" (click)="selectedTab = 'users'">{{
            'pages.user.manageUsers' | translate }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active' : selectedTab === 'admins' }" (click)="selectedTab = 'admins'">{{
            'pages.user.manageAdministrators' | translate }}</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="row pt-3" *ngIf="adminUsers && selectedTab === 'admins'">
    <div class="col-12">
      <ng-template #statusHelp>{{ 'pages.user.provisionnedLabel' | translate }}</ng-template>
      <div class="d-flex faketableheaderrow">
        <div class="col-3 faketableheader">
          {{ 'pages.generic.email' | translate }}
        </div>
        <div class="col-3 faketableheader">
          {{ 'pages.viewTenant.status' | translate }} <fa-icon [icon]="['fas', 'question-circle']" class="p-1"
            placement="top" [ngbTooltip]="statusHelp"></fa-icon>
        </div>
        <div class="col-3 faketableheader">
          {{ 'pages.viewTenant.lastLogin' | translate }}
        </div>
        <div class="col-3 faketableheader">
          {{ 'pages.viewTenant.actions' | translate }}
        </div>
      </div>
      <div class="d-flex faketablerow" *ngFor="let admin of adminUsers">
        <div class="col-3 faketablecell">
          {{admin.profile.email}}
        </div>
        <div class="col-3 faketablecell centerme">
          {{admin.status}}
        </div>
        <div class="col-3 faketablecell centerme">
          {{admin.lastLogin | date: 'dd/MM/yyyy HH:mm'}}
        </div>
        <div class="col-3 faketablecell">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-info" id="dropdownMenu1" ngbDropdownToggle (click)="selectUser(admin.id)">
              {{'buttons.action.actions' | translate }}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenu1">
              <button ngbDropdownItem (click)="downgradeUserFromAdmin(admin.id, admin.profile.email)">
                {{'buttons.action.removeAdmin' | translate }}</button>
              <button ngbDropdownItem *ngFor="let link of selectedUserLinks"
                (click)="userOperation(admin.id, link.operation, admin.profile.email)">{{link.name}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- filter -->
  <div class="row pt-3" *ngIf="userUsers && selectedTab === 'users'">
    <div class="col-6 text-start">
      <label class="visually-hidden" for="filterLabel">{{ 'pages.generic.filter' | translate }}</label>
      <input type="text" class="form-control" name="filter" id="filter" placeholder="Filter..."
        id="filterLabel" [(ngModel)]="userFilter" (keyup)="filterUsers($event);">
    </div>
    <div class="col-6 text-end">
      <a [href]="'/user/edit'" class="btn btn-primary" data-placement="top"
        title="{{ 'pages.user.adminAddUserDesc' | translate }}">{{ 'pages.viewTenant.addUser' | translate }}</a>
    </div>

    <div class="col-12">
      <ng-template #statusHelp>
        {{ 'pages.user.provisionnedLabel' | translate }}
      </ng-template>
      <div class="d-flex faketableheaderrow">
        <div class="col-3 faketableheader">
          <span class="click" (click)="sortUsers('email')">{{ 'pages.generic.email' | translate }}</span>
        </div>
        <div class="col-3 faketableheader">
          <span class="click" (click)="sortUsers('status')">{{ 'pages.viewTenant.status' | translate }}</span> <fa-icon
            [icon]="['fas', 'question-circle']" class="p-1" placement="top" [ngbTooltip]="statusHelp"></fa-icon>
        </div>
        <div class="col-3 faketableheader">
          <span class="click" (click)="sortUsers('lastLogin')">{{ 'pages.viewTenant.lastLogin' | translate }}</span>
        </div>
        <div class="col-3 faketableheader">
          {{ 'pages.viewTenant.actions' | translate }}
        </div>
      </div>
      <div class="d-flex faketablerow" *ngFor="let user of displayedUsers">
        <div class="col-3 d-flex faketablecell">
          {{user.profile.email}}
        </div>
        <div class="col-3 faketablecell centerme">
          {{user.status}}
        </div>
        <div class="col-3 faketablecell centerme">
          {{user.lastLogin | date: 'dd/MM/yyyy HH:mm'}}
        </div>
        <div class="col-3 faketablecell centerme">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-info" id="dropdownMenu1" ngbDropdownToggle (click)="selectUser(user.id)">
              {{'buttons.action.actions' | translate }}</button>
            <div ngbDropdownMenu aria-labelledby="dropdownMenu1">
              <a ngbDropdownItem [href]="'/user/edit/'+ user.id">{{ 'pages.user.editUser' | translate }}</a>
              <button ngbDropdownItem (click)="upgradeUserToAdmin(user.id, user.profile.email)">{{
                'pages.user.makeAdmin' | translate }}</button>
                <button ngbDropdownItem (click)="openNewRoles(user)">{{
                  'pages.user.newRoles' | translate }}</button>
              <button ngbDropdownItem *ngFor="let link of selectedUserLinks"
                (click)="userOperation(user.id, link.operation, user.profile.email)">{{link.name}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content">
  <div class="modal-header">
    <fa-icon [icon]="['fas', 'file']" class="icon" size="3x"></fa-icon>
    <a>{{filename}}</a>
    <button type="button" class="btn-close" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-title="Close"
      (click)="close()"><label class="visually-hidden">"{{ 'buttons.action.close' | translate }}" :</label></button>
  </div>
  <div class="modal-body">
  @if (fileURL) {
    <pdf-viewer [src]="fileURL.changingThisBreaksApplicationSecurity" [render-text]="true" [original-size]="true"
    [zoom]="1.3" [show-borders]="false" style="width: 100%; height: 38rem"></pdf-viewer>
  }
  </div>
  <div class="modal-footer">
    <a [href]="this.fileURL" target="_new" class="btn btn-primary">{{ 'buttons.action.downloadPDF' | translate }}</a>
    <button type="button" class="btn btn-secondary" (click)="close()">{{'buttons.action.close' | translate}}</button>
  </div>
</div>

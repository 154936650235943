  <div>
    <div class="card">
      <div class="card-body">

          <div class="row selection">
            <div class="d-flex">

              <fieldset>
                <legend class="title custom-label"> {{'pages.dashboard.analytics.filterOn' | translate }} : </legend>
                @for (radio of dateRadioButtons; track radio.key) {
                <div class="radio form-check">
                  <input class="form-check-input" type="radio" name="selectedRadioDate"
                    [checked]="radio.key === selectedRadioDate" [value]="radio.key"
                    (change)='onRadioChange($event.target.value)'
                    [id]="radio.key + '_date'">
                  <label class="form-check-label" [for]="radio.key + '_date'">
                    {{radio.label | translate }}
                  </label>
                </div>
                }
              </fieldset>
              
            </div>
          </div>
      
          <!-- visits occured on date -->
          <div class="row selection" [hidden]="selectedRadioDate !== 'custom'">
            <div class="d-flex align-items-center">
              <div>
                <label class="title" for="visitedFrom"> {{'pages.dashboard.analytics.visitsOccuredFrom' | translate }}: </label>
              </div>
              <div class="col-2">
                <input type="date" class="form-control" name="createdFrom" id="visitedFrom"
                  [(ngModel)]="createdFrom" />
              </div>
              <div class="col-1 justify-content-center">
                <label for="visitedTo">{{'pages.dashboard.analytics.to' | translate }}</label>
              </div>
              <div class="col-2">
                <input type="date" class="form-control" class="form-control" name="createdTo" id="visitedTo"
                  [(ngModel)]="createdTo" />
              </div>
            </div>
          </div>

          <!-- filter activity on -->
          <div class="row selection">
            <div class="d-flex">

              <fieldset>
                <legend class="title custom-label"> {{'pages.dashboard.analytics.filterActivityOn' | translate }} :</legend>
                @for (radio of rangeRadioButtons; track radio.key) {
                <div class="radio form-check">
                  <input class="form-check-input" type="radio" name="selectedRadioRange"
                    [checked]="radio.key === selectedRadioRange" [value]="radio.key"
                    (change)='selectedRadioRange = $event.target.value'
                    [id]="radio.key + '_scope'">
                  <label class="form-check-label" [for]="radio.key + '_scope'">
                    {{radio.label | translate }}
                  </label>
                </div>
                }
              </fieldset>

            </div>
          </div>

          <div class="mt-2 mb-2">
            <button class="btn btn-primary filter" (click)="onFilter()">{{ 'pages.generic.filter' | translate
              }}</button>
          </div>

      </div>
    </div>
  </div>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Signal } from '../../model/signal';

@Component({
  selector: 'app-signal-grid',
  templateUrl: './signal-grid.component.html',
  styleUrls: ['./signal-grid.component.scss']
})
export class SignalGridComponent implements OnInit {

  @Input()
  signals: Signal[];

  @Input()
  flagRefs: any[];

  @Input()
  flaggingRef: string;

  @Input()
  loaded: boolean;

  @Output()
  flagEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  flagSignal(event, jiraRef: string): void {
    event.stopPropagation();
    this.flagEmitter.emit(jiraRef);
  }

}

import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SlaGauge } from 'src/app/shared/model/cybersocxdr/slagauge';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-slagauge',
  templateUrl: '../basechart/basechart.component.html',
})
export class SlaGaugeComponent extends BaseChartComponent<SlaGauge> {
  options: Highcharts.Options = {
    chart: { type: 'solidgauge', height: 240, width: 240 },
    title: { text: '' },
    subtitle: {
      text: '',
      floating: true,
      verticalAlign: 'middle',
      style: {
        fontSize: '16px',
      },
    },
    tooltip: {
      animation: false,
      borderWidth: 0,
      backgroundColor: this.context.isDarkTheme() ? '#000' : '#FFF',
      shadow: false,
      useHTML: true,
      style: {
        color: '#666666',
        textAlign: 'center',
        backgroundColor: '#FF0000',
        background: '#FF0000',
      },
      valueSuffix: '%',
      pointFormat:
        '{series.name}<br><span style="font-size: 2em; color: {point.color}; font-weight: bold">{point.y}</span>',
      positioner(labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2 + 2,
          y: this.chart.plotHeight / 2 - 15,
        };
      },
    },
    credits: { enabled: false },
    pane: {
      startAngle: 0,
      endAngle: 360,
      size: '100%',
      background: [
        {
          outerRadius: '112%',
          innerRadius: '88%',
          borderWidth: 0,
        },
        {
          outerRadius: '87%',
          innerRadius: '63%',
          borderWidth: 0,
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },
  };

  private slaGaugeMinRadius = 50;
  private slaGaugeMaxRadius = 110;
  private slaGap = 1;
  private slaKeyList: (keyof SlaGauge)[] = ['informational', 'low', 'medium', 'high', 'critical'];
  private slaColorPalette = {
    informational: '#ffffff',
    low: '#dcf5eb',
    medium: '#B8EBD6',
    high: '#50BE87',
    critical: '#0A6E31',
  };

  draw(renderingId: string) {
    this.options.title.text = this.title;
    this.options.series = this.buildSlaGaugeGraph(this.data);
    this.options.pane.background = this.buildBackroundPane(this.data);
    this.options.subtitle.text = `${this.buildTotalAverage(this.data)}`;
  }

  buildSlaGaugeGraph(data: SlaGauge | undefined): Highcharts.SeriesOptionsType[] {
    const nameList = this.slaKeyList.filter((value) => data[value]);
    const sliceThickness = Math.ceil((this.slaGaugeMaxRadius - this.slaGaugeMinRadius) / (nameList.length + 1));

    const graphData = nameList.map((value, index) => ({
      name: value,
      data: [
        {
          radius: `${this.slaGaugeMinRadius + sliceThickness * (index + 1)}%`,
          innerRadius: `${this.slaGaugeMinRadius + sliceThickness * (index + 0) + this.slaGap}%`,
          color: this.slaColorPalette[value],
          y: Math.ceil((100 * data[value].respected) / data[value].total),
        },
      ],
    }));

    return graphData as Highcharts.SeriesOptionsType[];
  }

  buildBackroundPane(data: SlaGauge | undefined): Highcharts.PaneBackgroundOptions[] {
    const nameList = this.slaKeyList.filter((value) => data[value]);
    const sliceThickness = Math.ceil((this.slaGaugeMaxRadius - this.slaGaugeMinRadius) / (nameList.length + 1));

    return nameList.map((value, index) => ({
      outerRadius: `${this.slaGaugeMinRadius + sliceThickness * (index + 1)}%`,
      innerRadius: `${this.slaGaugeMinRadius + sliceThickness * (index + 0) + this.slaGap}%`,
      backgroundColor: Highcharts.color('#FFB400')
        .setOpacity(0.2)
        .tweenTo(Highcharts.color('#000000'), index / 20),
      borderWidth: 0,
    }));
  }

  buildTotalAverage(data: SlaGauge | undefined) {
    const nameList = this.slaKeyList.filter((value) => data[value]);

    const totalRespected = nameList.reduce(
      (accumulator, currentValue) => data[currentValue].respected + accumulator,
      0
    );
    const totalDocuments = nameList.reduce((accumulator, currentValue) => data[currentValue].total + accumulator, 0);

    const totalAverage: number = Math.ceil((100 * totalRespected) / totalDocuments);

    return 'Total<br><span style="font-size:2em; font-weight: bold; display:block">' + totalAverage + '%' + '</span>';
  }
}

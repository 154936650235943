import { Injectable } from '@angular/core';
import { OktaAuthService } from '../app.service';

declare global {
  interface Window {
    umami: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class XtendedAnalyticsService {
  constructor(public oktaAuthService: OktaAuthService) {}

  trackEvent(name: string, data?: { [key: string]: any }) {
    const userEmail = this.oktaAuthService.userDetails?.email;
    const isOcdUser = ['@orangecyberdefense.com', '@orange.com'].some((domain) => userEmail?.endsWith(domain));
    window.umami?.track((props) => ({
      ...props,
      name,
      data,
      referrer: `https://${this.oktaAuthService.selectedTenant}${isOcdUser ? '-ocd' : ''}`,
    }));
  }
}

import { Component } from '@angular/core';
import { UserPreferenceService } from '../../services/userpreferences.service';
import { AlertService } from '../../services/alert.service';
import { UserPreferences } from '../../model/shared-items';

@Component({
  selector: 'app-flagged-list',
  templateUrl: './flagged-list.component.html',
  styleUrls: ['./flagged-list.component.scss']
})
export class FlaggedListComponent {

  // user prefs
  flags: any[];
  flagRefs: string[];
  flagType: string;
  viewType: string;
  flaggingRef: string; //used to stop the flapping of flags whilst waiting for update to complete

  constructor(
    public userPreferenceService: UserPreferenceService,
    public alertService: AlertService
  ) { }

  /** 
   * used when clic on flag button
   */
  async flag(caseRef: string) {
    this.flaggingRef = caseRef;
    await this.userPreferenceService.userPreferencesUpdateFlags(this.flagType, this.flagRefs, caseRef)
    this.flaggingRef = undefined;
  }

  /**
   * check flag user prefences exists
   */
  checkPreferences() {

    if (this.userPreferenceService.userPreferences?.flags && this.userPreferenceService.userPreferences?.flags[this.flagType]) {
      this.flagRefs = this.userPreferenceService.userPreferences.flags[this.flagType]
    } else {
      this.flagRefs = [];
    }

    // check view preferences
    if (!this.userPreferenceService.userPreferences || !this.userPreferenceService.userPreferences[this.viewType]) {
      if (this.userPreferenceService.userPreferences) {
        this.userPreferenceService.userPreferences[this.viewType] = 'list';  
      }
      // update the users flags appropriately
      this.userPreferenceService
        .userPreferencesUpdateSingle(this.viewType, 'list')
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }
  }

  /**
  * Toggle between grid/list view
  *
  * @param view
  */
  public changeViewType(view: string) {
    // set local variable to determine what view the user is using
    this.userPreferenceService.userPreferences[this.viewType] = view;

    // update the users flags appropriately
    this.userPreferenceService
      .userPreferencesUpdateSingle(this.viewType, view)
      .catch((err) => {
        this.alertService.handlerError(err);
      });
  }

}

<div *ngIf="!loading" class="content">
    <div class="feedback">
        <form [formGroup]="feedbackForm" (ngSubmit)="submit()">
            <div class="info">

                <!-- pages -->
                <div class="form-group">
                    <label for="service">{{'modals.feedbackMenu.feedback.fields.page' | translate}}</label>
                    <select class="form-select" formControlName="page" id="serviceSelect" placeholder="Pages"
                        [ngClass]="{'input-error': submitted && !feedbackForm.controls.page.valid}">
                        <option selected></option>
                        <option *ngFor="let page of pages" value={{page.value}}>{{page.labelKey | translate}}</option>
                    </select>
                </div>

                <!-- sub pages -->
                <div class="form-group">
                    <label for="service">{{'modals.feedbackMenu.feedback.fields.subpage' | translate}}</label>
                    <select class="form-select" formControlName="subpage" id="serviceSelect">
                        <option selected></option>
                        <option *ngFor="let subpage of subpages" value={{subpage.value}}>{{subpage.labelKey | translate}}</option>
                    </select>
                </div>

                <!-- category -->
                <div class="form-group">
                    <label for="category">{{'modals.feedbackMenu.feedback.fields.category' | translate}}</label>
                    <select class="form-select" formControlName="category" id="categorySelect"
                        [ngClass]="{'input-error': submitted && !feedbackForm.controls.category.valid}">
                        <option selected></option>
                        <option *ngFor="let category of categories" value={{category.value}}>{{category.labelKey | translate}}</option>
                    </select>
                </div>

            </div>

            <!-- title -->
            <div class="form-group">
                <input type="text" class="form-control title" formControlName="title"
                    placeholder="{{'modals.feedbackMenu.feedback.fields.title' | translate}}" maxlength="50"
                    [ngClass]="{'input-error': submitted && !feedbackForm.controls.title.valid}">
                @if (submitted && feedbackForm.controls.title.errors?.pattern) {
                    <div class="input-error-message">
                        {{ 'modals.feedbackMenu.feedback.fields.titlePatternError' | translate }}
                    </div>
                }
            </div>

            <!-- feedback -->
            <div class="form-group mt-1">
                <textarea class="form-control" formControlName="feedback" rows="10"
                    placeholder="{{'modals.feedbackMenu.feedback.fields.description' | translate}}"
                    [ngClass]="{'input-error': submitted && !feedbackForm.controls.feedback.valid}"></textarea>
                @if (submitted && feedbackForm.controls.feedback.errors?.pattern) {
                    <div class="input-error-message">
                        {{ 'modals.feedbackMenu.feedback.fields.feedbackPatternError' | translate }}
                    </div>
                }
            </div>
        </form>
    </div>

    <div class="actions">
        <button *ngIf="!consultMode" type="button" class="btn btn-primary" (click)="submit()">
            {{ 'buttons.action.submit' | translate }}
        </button>

        <button *ngIf="consultMode" type="button" class="btn btn-primary" (click)="updateFeedbackState()">
            {{ actionlabel }}
        </button>

        <button *ngIf="consultMode" type="button" class="btn btn-primary" (click)="delete()">
            {{ 'buttons.action.delete' | translate }}
        </button>

        <button type="button" class="btn btn-secondary" (click)="close()">
            {{ 'pages.generic.close' | translate }}
        </button>
    </div>
</div>
<div class="row justify-content-center p-5" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
  </div>
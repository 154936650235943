import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pages } from 'src/app/shared/model/shared-items';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { Contact } from 'src/app/shared/model/itsm';
import { ItsmService } from 'src/app/shared/services/itsm.service';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';

@Component({
  selector: 'app-supportcontacts',
  templateUrl: './supportcontacts.component.html',
  styleUrls: ['./supportcontacts.component.scss']
})
export class SupportcontactsComponent implements OnInit {
  // pagination
  perPage = constants.recordsPerPage.cases;
  currentPage: number;

  // data
  allContacts: Contact[];
  subsetContacts: Contact[];
  contactsLoaded = false;
  includeInactive: boolean;
  vendors: string[];

  //search
  source: string;
  assetName: string;
  assetSerialNumber: string;
  assetVendor: string;
  sortedBy: string;
  sortedOrder: number;

  // user prefs
  flags: any[];
  flagRefs: string[]=[];
  flaggingRef: string; //used to stop the flapping of flags whilst waiting for update to complete

  // view
  viewType: string;
  page: string;

  constructor(
    private itsmService: ItsmService,
    private router: Router,
    private userActivity: UserActivityService
  ) { }

  ngOnInit() {
    // initialise
    this.currentPage = 1;
    this.page = Pages.contacts;
    this.viewType = 'list';

    // load contacts
    this.loadContacts();
    this.userActivity.logActivity(TYPES.support, PAGES.contacts, ACTIONS.visit);
  }


  loadContacts() {
    Promise.all([
      Promise.resolve(),
      this.itsmService.getCachedContacts(),
    ])
    .then(res => {
      this.allContacts = res[1];
      this.subsetContacts = this.allContacts.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
      this.contactsLoaded = true;

    });
  }

  realoadContacts(): void{
    this.loadContacts();
  }
  addContactNav(): void{
    this.router.navigate(['/support/contacts/addcontacts']);
  }
  onPageChange($event){
    this.contactsLoaded = false;
    this.subsetContacts = this.allContacts.slice(this.perPage*(this.currentPage-1), this.perPage*(this.currentPage-1)+this.perPage);
    this.contactsLoaded = true;
  }

  messageEmitter(event){
  }

}

<div class="filter" *ngIf="entries && entries.length > 0">
  <div class="search">
    <span>{{ 'pages.rss.dashboard.search' | translate }} </span>
    <input class="form-control" type="text" placeholder="Search" [(ngModel)]="searchText" (input)="search()">
  </div>
  <div class="sortBy">
    <span>{{ 'pages.rss.dashboard.sortBy' | translate }}</span>
    <div class="selects">
      <select class="form-select" aria-label="filter select" [(ngModel)]="sortedTyped" (change)="updateFilter()">
        <option value="date">Date</option>
        <option value="title">Title</option>
      </select>
      <select class="form-select" aria-label="filter sort select" [(ngModel)]="sortedOrdered" (change)="updateFilter()">
        <option value="asc">ASC</option>
        <option value="desc">DESC</option>
      </select>
    </div>
  </div>
  <span class="ms-auto"> {{filteredEntries.length}} {{ 'pages.rss.dashboard.results' | translate }}</span>
</div>
  
<div class="grid-container mt-5">
  <div class="card main highlight" *ngFor="let entry of filteredEntries" (click)="openItem(entry)">
    <div class="header">
      <span class="title" data-toggle="tooltip" data-placement="top"
      title="{{entry.title}}">{{entry.title}}</span>
      <h5 *ngIf="entry.pubDate" class="date">{{ entry.pubDate | date:'dd/MM/yyyy HH:mm' }}</h5>
    </div>
    <div class="card-body">
      <p class="card-text" [innerHTML]="entry.description"></p>
    </div>
  </div>
</div>

<div class="row justify-content-center p-5" *ngIf="!loaded">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<div class="row p-3 justify-content-center redborder-sides" *ngIf="loaded && filteredEntries && filteredEntries.length === 0">
  <div class="col-md-12 table-border justify-content-center">
    {{ 'pages.rss.dashboard.noResults' | translate }}
  </div>
</div>

import { Component, Input } from '@angular/core';

type Icon = 'equal' | 'up' | 'down';

@Component({
  selector: 'app-data-boxes',
  templateUrl: './data-boxes.component.html',
  styleUrls: ['./data-boxes.component.scss']
})

export class DataBoxesComponent {

  @Input()
  heading: string;

  @Input()
  count: number;

  @Input()
  className: string;

  @Input()
  icon?: Icon;

  @Input()
  footerMonth?: string;

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { File } from '../model/files';

@Injectable()
export class FileAccessService {
    private fileAccessURL = environment.endpoints.fileAccess;

    // Define a mapping between file extensions and MIME types
    public mimeTypeMap: { [key: string]: string } = {
        csv: 'text/csv',
        txt: 'text/plain',
        pdf: 'application/pdf',
    };

    constructor(private http: HttpClient) {
    }

    getFilesList(): Promise<File[]> {
        return this.http
            .get(this.fileAccessURL + 'view')
            .toPromise()
            .then(response => response as File[])
            .catch(this.handleError);
    }

    getFilesByFolder(directory: string): Promise<File[]> {
        return this.http
            .get(this.fileAccessURL + 'view/' + directory)
            .toPromise()
            .then(response => response as File[])
            .catch(this.handleError);
    }

    downloadFile(fileName: string): any {
        return this.http.post(this.fileAccessURL + 'download', JSON.stringify({ fileName }), {responseType: 'blob'});
    }

    downloadFileByDirectory(fileName: string, directory: string): any {
        return this.http.post(this.fileAccessURL + 'folder/download', JSON.stringify({ fileName, directory }), {responseType: 'blob'});
    }

    // Get the MIME type based on the file extension
    getMimeType(fileExtension: string): string | undefined {
        return this.mimeTypeMap[fileExtension.toLowerCase()];
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}

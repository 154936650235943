import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, AfterViewInit } from '@angular/core';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpUsersPreferencesService } from 'src/app/shared/httpServices/http-users-preferences.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/services/alert.service';
import { Question, QuestionResponse, Survey, SurveyComponentEnum, SurveyResponse } from 'src/app/shared/model/feedback';
import { ClosedQuestionComponent } from '../question-tools/closed-choice/closed-choice.component';
import { RatingQuestionComponent } from '../question-tools/rating/rating.component';
import { FreeTextComponent } from '../question-tools/free-text/free-text.component';
import { ListOrderQuestionComponent } from '../question-tools/list-order/list-order.component';
import { MultiSelectComponent } from '../question-tools/multi-select/multi-select.component';
import { RadioSingleSelectQuestionComponent } from '../question-tools/single-select/single-select.component';

@Component({
  selector: 'app-survey-form',
  templateUrl: './survey-form.component.html',
  styleUrls: ['./survey-form.component.scss']
})
export class SurveyFormComponent implements OnInit, AfterViewInit {

  @ViewChild('questionContainer', { read: ViewContainerRef }) questionContainer: ViewContainerRef;
  private currentQuestionComponentRef: any; // Stores the reference to the current question component
  private currentQuestionIndex = 0;

  @Output() finished = new EventEmitter<boolean>();

  @Input() survey: Survey;

  public activeQuestion: Question;
  public surveyForm: FormGroup;
  public index = 0;
  public componentList = SurveyComponentEnum;
  public loading = false;
  private requiredQuestions = [SurveyComponentEnum.closedChoice, SurveyComponentEnum.singleSelect, SurveyComponentEnum.rating];
  private arrayFormControl = [SurveyComponentEnum.multiSelect];

  constructor(
    private httpUsersPreferencesService: HttpUsersPreferencesService,
    private translateService: TranslateService,
    private alertService: AlertService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {

    // build survey form
    this.surveyForm = new FormGroup({});

    if (this.survey?.questions.length > 0) {
      this.activeQuestion = this.survey.questions[this.index];
      this.buildForm();
    }
    
  }

  ngOnDestroy() {
    // destroys current question component when destroying parent component
    if (this.currentQuestionComponentRef) {
      this.currentQuestionComponentRef.destroy();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadQuestion();
    });
    
  }

  private buildForm() {

    this.survey.questions.forEach(question => {
      const required = this.requiredQuestions.includes(question.component);
      if (this.arrayFormControl.includes(question.component)) {
        // create new control for all checkbox
        this.surveyForm.addControl(question.title, new FormArray([]));

        // get the answers form array
        const formArray = this.surveyForm.get(question.title) as FormArray;

        // fill the array with answerw form group
        question.answers.forEach(answer => {
          formArray.push(
            new FormGroup({
              name: new FormControl(answer),
              checked: new FormControl(false),
            })
          )
        });
      } else {
        this.surveyForm.addControl(question.title, new FormControl(undefined, required ? Validators.required : []));
      }
    })
  }

  private getComponent(type: string) {
    switch (type) {
      case SurveyComponentEnum.rating: return RatingQuestionComponent
      case SurveyComponentEnum.freeText: return FreeTextComponent
      case SurveyComponentEnum.closedChoice: return ClosedQuestionComponent
      case SurveyComponentEnum.listOrder: return ListOrderQuestionComponent
      case SurveyComponentEnum.multiSelect: return MultiSelectComponent
      case SurveyComponentEnum.singleSelect: return RadioSingleSelectQuestionComponent
    }
  }

  /**
   * 
   */
  private loadQuestion() {
    // destroy question component when load new question
    if (this.currentQuestionComponentRef) {
      this.currentQuestionComponentRef.destroy();
    }

    // Creates the question component dynamically
    const component = this.getComponent(this.activeQuestion.component);
    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.currentQuestionComponentRef = this.questionContainer.createComponent(factory);

    // send the necessary data to the question component
    this.currentQuestionComponentRef.instance.question = this.activeQuestion
    this.currentQuestionComponentRef.instance.surveyForm = this.surveyForm;

    // call question component ngOnInit
    if (this.currentQuestionComponentRef.instance.ngOnInit) {
      this.currentQuestionComponentRef.instance.ngOnInit();
    }
  }

  nextQuestion() {
    // switch next question
    this.currentQuestionIndex++;
    this.activeQuestion = this.survey.questions[this.currentQuestionIndex];
    this.loadQuestion();
  }

  previousQuestion() {
    // back to the previous one
    this.currentQuestionIndex--;
    this.activeQuestion = this.survey.questions[this.currentQuestionIndex];
    this.loadQuestion();
  }

  /**
   * submit the survey answers
   */
  public submit() {
    if (this.surveyForm.valid) {

      const surveyResponse = new SurveyResponse(this.survey._id.$oid);

      // get all values in the response object
      for (const field in this.surveyForm.controls) {
        const originalQuestion = this.survey.questions.find(question => question.title === field);
        let values = this.surveyForm.controls[field].value;

        // special management for list order component, add the rank value
        if (originalQuestion.component === SurveyComponentEnum.listOrder) {
          let listOrderValue = [];
          this.surveyForm.controls[field].value.forEach((value, index) => {
            listOrderValue.push({ value: value, rank: index });
          });
          values = listOrderValue;
        }
        const questionResponse = new QuestionResponse(originalQuestion._id.$oid, originalQuestion.title, values);
        surveyResponse.answers.push(questionResponse);
      }

      this.loading = true;
      this.httpUsersPreferencesService.postSurvey(surveyResponse)
        .then(() => {
          this.alertService.addSuccess(this.translateService.instant('modals.feedbackMenu.survey.postSucceed'));
        })
        .catch(() => {
          this.alertService.addError(this.translateService.instant('modals.feedbackMenu.survey.postFailed'));
        })
        .finally(() => {
          this.loading = false;
          this.finished.emit(false);
        })
    }
  }

}

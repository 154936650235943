import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import * as Highcharts from 'highcharts';
import WordCloud from 'highcharts/modules/wordcloud.js';
import { ACTIONS, constants, PAGES, TYPES } from 'src/app/shared/constants';
import { CVE } from 'src/app/shared/model/cve';
import { DateRange, VulnSearchCriteria, VulnSeverityStatsCriteria } from 'src/app/shared/model/searchCriteria';
import { AlertService } from 'src/app/shared/services/alert.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { VulnerabilitiesService } from 'src/app/shared/services/vulnerabilities.service';
import { Utilities } from 'src/app/shared/utilities';
import { WordCloudItem } from 'src/app/shared/model/signal';
import { ActivatedRoute } from '@angular/router';
import { Pages, SessionStorageItem } from 'src/app/shared/model/shared-items';
import { ChartColors, ChartType } from 'src/app/shared/model/activity';
import { UserActivityService } from 'src/app/shared/services/user-activity.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationAudience, NotificationCategory } from 'src/app/shared/model/notification';
import { ReportPdfAccessLevel } from 'src/app/shared/model/files';
import { FlaggedListComponent } from 'src/app/shared/components/flagged-list/flagged-list.component';
import { ContextService } from 'src/app/shared/services/context-service';

WordCloud(Highcharts);
@Component({
  selector: 'app-vulnerabilities',
  templateUrl: './vulnerabilities.component.html',
  styleUrls: ['./vulnerabilities.component.scss'],
})

export class VulnerabilitiesComponent extends FlaggedListComponent implements OnInit {

  // scrolling
  public scrollLoading = false;

  // data
  searched: boolean;
  vulnsLoaded: boolean;
  vulns: CVE[] = [];
  vulnsCount: number;
  watchCount: number;

  // saved searches
  savedSearches: VulnSearchCriteria[];
  searchName: string;
  hideSaveSearch: boolean;

  // user prefs
  watchedProducts: string[];

  // layout
  advSearch: boolean;
  page: Pages;

  // search fields
  cve: string;
  vendor: string;
  product: string;
  description: string;
  severity: string;
  dateFrom: string;
  dateTo: string;
  cpe: string;
  sort: string;
  sortOrder: number;

  // stats
  sinceLastLogin: number;
  totalVendors: number;
  totalProducts: number;
  totalVulnerabilities: number;
  lastUpdated: string;

  vulnsPage = Pages.vulns;
  flaggedPage = Pages.flagged;
  watchedPage = Pages.watched;
  analyticsPage = Pages.analytics;

  // charts
  highcharts: typeof Highcharts = Highcharts;
  analyticsDataLoaded: boolean;

  updateStackedBarSeverity: boolean;
  loadStackedBarSeverity = false;
  stackedBarSeverityOptions = {
    colors: ['#50be87', '#1961ac', '#4bb4e6', '#bcdff7', '#007836'],
    chart: {
      type: 'column',
      backgroundColor: ChartColors.white,
    },
    // colors: constants.graphColors,
    title: {
      align: 'right',
      text: '12 Month Trend Analysis - Severity',
    },
    legend: {
      layout: 'horizontal',
      align: 'left',
      verticalAlign: 'top',
      floating: true,
      borderWidth: 1,
    },
    xAxis: {
      categories: ['None', 'Low', 'Medium', 'High', 'Critical'],
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Vulnerabilities',
        align: 'high',
      },
      labels: {
        overflow: 'justify',
      },
    },
    tooltip: {
      valueSuffix: ' vulnerabilities published',
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        stacking: 'normal',
      },
    },
    credits: {
      enabled: false,
    },
    series: [],
  };

  updateUrgencyPie: boolean;
  urgPieChartOptions = {
    colors: ['#1961ac', '#4bb4e6', '#bcdff7', '#007836'],
    // colors: constants.graphColors,
    chart: {
      plotBorderWidth: null,
      plotShadow: false,
      backgroundColor: ChartColors.white,
    },
    title: {
      text: 'Severity',
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: ChartType.pie,
        data: [],
      },
    ],
    credits: {
      enabled: false,
    },
  };

  updateWordChart: boolean;
  wordChartOptions = {
    colors: constants.graphColors,
    chart: {
      type: ChartType.wordcloud,
      backgroundColor: ChartColors.white,
    },
    title: {
      text: 'Keywords',
    },
    series: [
      {
        data: [],
        type: undefined,
      },
    ],
    credits: {
      enabled: false,
    },
  };

  datepipe: DatePipe = new DatePipe('en-GB');

  // initial page to load
  loadpage: string;

  //Export pdf
  closeResult: string;
  exportPDF = false;
  PDFName = '';
  reportOption = 'pdf';
  pdfAccessLevel = '0';

  // multi select severities
  dropdownSettings = {
    itemsShowLimit: 3,
  };

  severityData = [
    { id: 'NONE', text: 'None' },
    { id: 'LOW', text: 'Low' },
    { id: 'MEDIUM', text: 'Medium' },
    { id: 'HIGH', text: 'High' },
    { id: 'CRITICAL', text: 'Critical' },
  ];
  selectedSeverities = [];
  update = false;

  // init filters
  validDateRanges: boolean;
  private dateRanges: DateRange[];
  private vulnsFilterCriteria = new VulnSearchCriteria();
  private flaggedFilterCriteria = new VulnSearchCriteria();
  private watchFilterCriteria = new VulnSearchCriteria();

  constructor(
    private vulnService: VulnerabilitiesService,
    public userprefService: UserPreferenceService,
    public alertService: AlertService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private userActivity: UserActivityService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    public context: ContextService
  ) {
    super(userprefService, alertService);
    this.flagType = constants.local.flags.vulns;
    this.viewType = constants.local.views.vuln;
    super.checkPreferences();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => (this.loadpage = params.loadpage));

    // initialise
    this.advSearch = false;
    this.hideSaveSearch = false;
    this.vulnsLoaded = false;
    this.analyticsDataLoaded = false;
    this.loadStackedBarSeverity = false;
    this.updateStackedBarSeverity = false;

    // count the signals since last login
    this.userprefService
      .getUserLastLoginDate()
      .then((userPref) => {
        this.vulnService
          .vulnsCountSinceLastLogin(userPref)
          .then((lastLoginRes) => {
            this.sinceLastLogin = lastLoginRes;
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });

    // load the flags vulns from the user preferences
    this.refreshVulnUserPrefs();
    // load default page for navigation
    if (!this.loadpage || this.loadpage === 'latest') {
      this.page = this.vulnsPage;
      this.loadLatestVulns();
    } else if (this.loadpage === 'lastlogin') {
      this.loadSinceLastVisit();
    } else if (this.loadpage === '7days') {
      this.loadSinceXDays(7);
    }
    this.dateRanges = [DateRange.pubDate];
    this.validDateRanges = true;
    this.userActivity.logActivity(TYPES.resources, PAGES.vulnerabilities, ACTIONS.visit);
  }

  /**
   * Load all datas fot signals
   */
  public async vulnSearch() {
    this.vulnsLoaded = false;
    const allPromise = Promise.all([
      // get the total number of signals
      this.getVulnsCount(),
      // get the first signals to displayed
      this.getVulns(false),
    ]);

    const onRejected = (err) => {
      this.handlerError(err);
      this.vulnsLoaded = true;
    };

    await allPromise.then(undefined, onRejected);
  }

  /**
   * Load the vulnerabilities with the vulnsFilterCriteria filter
   */
  public getVulns(fromScroll: boolean): Promise<void> {
    const filter = Object.assign({}, this.vulnsFilterCriteria);
    filter.count = false;
    if(fromScroll) {
      this.scrollLoading = true;
      this.vulnsFilterCriteria.skip += this.vulnsFilterCriteria.limit;
    } else {
      this.vulns = [];
    }
    return this.vulnService
      .vulnSearch(filter)
      .then((res) => {
        this.vulns = this.vulns.concat(res);
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        this.vulnsLoaded = true;
        this.scrollLoading = false;
      });
  }

  /**
   * Get the total number of vulnerabilites
   */
  public getVulnsCount(): Promise<void> {
    const filter = Object.assign({}, this.vulnsFilterCriteria);
    filter.count = true;
    filter.limit = undefined;
    return this.vulnService
      .vulnSearch(filter)
      .then((res) => {
        this.vulnsCount = res && res[0] ? res[0].matching_vulns : 0;
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Load next values of the list when scrolling
   */
  public loadNextValues() {
    if(!this.vulnsLoaded){
      return;
    }
    if (this.page === this.vulnsPage && this.vulns.length < this.vulnsCount) {
      this.getVulns(true);
    } else if (this.page === this.flaggedPage && this.vulns.length < this.flagRefs.length) {
      this.loadFlagged(true);
    } else if (this.page === this.watchedPage && this.vulns.length < this.watchCount) {
      this.loadWatched(true);
    }
  }

  /**
   * Sort the CVEs with the given filed
   */
  public sortCVEs(field: string) {
    if (this.sort === field && this.sortOrder) {
      if (this.sortOrder === -1) {
        this.sortOrder = 1;
      } else {
        this.sortOrder = -1;
      }
    } else {
      this.sort = field;
      this.sortOrder = -1;
    }

    if (this.searched) {
      this.search();
    } else if (this.page === this.flaggedPage) {
      this.loadFlagged(false);
    } else {
      if (!this.loadpage || this.loadpage === 'latest') {
        this.page = this.vulnsPage;
        this.loadLatestVulns();
      } else if (this.loadpage === 'lastlogin') {
        this.loadSinceLastVisit();
      } else if (this.loadpage === '7days') {
        this.loadSinceXDays(7);
      }
    }
  }

  /**
   * Search on a bunch of attributes for a signal
   */
  public search() {
    this.searched = true;

    // switch on vulnerabilities tab
    this.page = Pages.vulns;
    this.vulnsLoaded = false;

    // reset list and init new criteria filter
    this.vulns = [];
    this.vulnsFilterCriteria = new VulnSearchCriteria();
    this.vulnsFilterCriteria.returnStub = true;

    // fill the new filter with selected properties
    if (this.cve) {
      this.vulnsFilterCriteria.cve = this.cve;
    }
    if (this.exportPDF) {
      this.vulnsFilterCriteria.exportPDF = true;
      this.vulnsFilterCriteria.PDFName =
        this.PDFName + ' - ' + constants.accessLevels[this.pdfAccessLevel] + '.' + this.reportOption;
      this.vulnsFilterCriteria.pdfAccessLevel = this.pdfAccessLevel;
    }
    if (this.vendor) {
      this.vulnsFilterCriteria.vendor = this.vendor;
    }
    if (this.product) {
      this.vulnsFilterCriteria.product = this.product;
    }
    if (this.description) {
      this.vulnsFilterCriteria.description = this.description;
    }
    if (this.severity) {
      this.vulnsFilterCriteria.severity = this.severity;
    }
    if (this.selectedSeverities?.length > 0) {
      this.vulnsFilterCriteria.severities = this.selectedSeverities.map(({ id }) => id);
    }
    if (this.cpe) {
      this.vulnsFilterCriteria.cpe = this.cpe;
    }
    if (this.dateFrom) {
      this.vulnsFilterCriteria.pubDateFrom = this.dateFrom;
    }
    if (this.dateTo) {
      this.vulnsFilterCriteria.pubDateTo = this.dateTo;
    }
    if (this.sort && this.sortOrder) {
      this.vulnsFilterCriteria.sort = this.sort;
      this.vulnsFilterCriteria.sortOrder = Number(this.sortOrder);
    }

    if (this.validDateRanges) {
      if (this.exportPDF) {
        this.exportVuln();
      } else {
        this.vulnSearch();
      }
    } else {
      this.vulns = [];
      this.vulnsLoaded = true;
      this.handlerError(
        this.translateService.instant('pages.dashboard.errors.cantReturnResults')
        + ' - '
        + this.translateService.instant('pages.dashboard.errors.invalidDateRange')
      );
    }
  }

  validateDateRange() {
    if (this.dateFrom && this.dateTo) {
      this.vulnsFilterCriteria.pubDateFrom = this.dateFrom;
      this.vulnsFilterCriteria.pubDateTo = this.dateTo;
      this.validDateRanges = this.vulnsFilterCriteria.checkDateRanges(this.dateRanges);
    } else {
      this.validDateRanges = true;
    }
  }

  /**
   * Reset all the advanced fields
   */
  public reset() {
    delete this.cve;
    delete this.vendor;
    delete this.product;
    delete this.description;
    delete this.severity;
    delete this.dateFrom;
    delete this.dateTo;
    delete this.cpe;

    delete this.sort;
    this.sortOrder = -1;

    this.validDateRanges = true;
    this.searched = false;
  }

  /**
   * Add a product name to the user's preferences so they can watch products
   *
   * @param product
   * @param deleted whether to delete or create
   */
  public watch(product: string, deleted: boolean) {
    // update the users flags appropriately
    this.userprefService
      .userPreferencesUpdateWatchedProducts(constants.local.watch.vuln, product.toLowerCase(), deleted)
      .then(() => {
        this.refreshVulnUserPrefs();
        this.loadWatched(false);
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Return the last vulns
   */
  public loadLatestVulns(): void {
    this.searched = false;
    if(!this.vulnsFilterCriteria) {
      this.vulnsFilterCriteria = new VulnSearchCriteria();
    }

    this.vulnsFilterCriteria.returnStub = true;

    if (this.sort && this.sortOrder) {
      this.vulnsFilterCriteria.sort = this.sort;
      this.vulnsFilterCriteria.sortOrder = Number(this.sortOrder);
    }

    this.vulnSearch();
  }

  /**
   * Open the modal with the given template
   */
  public openModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;

        if (result === 'save') {
          this.exportPDF = true;
          this.search();
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  /**
   * Open  a search modal with the given template
   */
  public openSearchModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'search-modal' }).result.then(
      (result) => {
        if (result === 'save') {
          this.saveSearch();
        }
      },
      () => {}
    );
  }

  /**
   * Save the search criteria in user preferences for future use
   */
  public saveSearch(): void {
    const copy = JSON.parse(JSON.stringify(this.vulnsFilterCriteria));
    delete copy.count;
    delete copy.limit;
    delete copy.skip;
    delete copy.sort;
    delete copy.sortOrder;
    copy.name = this.searchName;

    this.userprefService.userPreferencesUpdateChildArray('searches', 'vuln', copy, false).then(() => {
      this.searchName = undefined;
      this.refreshVulnUserPrefs();
      this.hideSaveSearch = true;
    });
  }

  /**
   * Load a saved search and run
   *
   * @param savedSearch
   */
  public loadSearch(savedSearch: VulnSearchCriteria) {
    this.vulnsFilterCriteria = savedSearch;
    this.vulnSearch();
  }

  /**
   * Delete a saved search
   *
   * @param event
   * @param content
   * @param savedSearch
   */
  public deleteSearch(event, content, savedSearch: VulnSearchCriteria): void {
    event.stopPropagation();
    this.modalService.open(content, { ariaLabelledBy: 'search-modal' }).result.then(
      (result) => {
        if (result === 'delete') {
          this.userprefService.userPreferencesUpdateChildArray('searches', 'vuln', savedSearch, true).then(() => {
            this.refreshVulnUserPrefs();
          });
        }
      });
  }

  /**
   * Typeahead function for vendor
   *
   * @param text$
   */
  public vendorTA = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 2 ? [] : this.vulnService.vulnVendorName(term.toLowerCase()).pipe(catchError(() => of([])))
      )
    );

  /**
   * Typeahead function for product/vendor
   * Vendor ignored at backend if null or empty
   *
   * @param text$
   */
  public productTA = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 2
          ? []
          : this.vulnService.vulnProductName(term.toLowerCase(), this.vendor).pipe(catchError(() => of([])))
      )
    );

 /**
  *  Load flagged vulns
  **/
  public loadFlagged(fromScroll: boolean): void {
    this.page = this.flaggedPage;
    this.searched = false;

    if (this.flagRefs.length !== 0) {

      if(fromScroll) {
        this.scrollLoading = true;
        this.flaggedFilterCriteria.skip += this.flaggedFilterCriteria.limit;
      } else {
        this.vulns = [];
        this.vulnsLoaded = false;
        this.flaggedFilterCriteria = new VulnSearchCriteria();
        this.flaggedFilterCriteria.returnStub = true;
        this.flaggedFilterCriteria.cveRefs = this.flagRefs;
        if (this.sort && this.sortOrder) {
          this.flaggedFilterCriteria.sort = this.sort;
          this.flaggedFilterCriteria.sortOrder = Number(this.sortOrder);
        }
      }

      this.vulnService
      .vulnLoadList(this.flaggedFilterCriteria)
      .then((res) => {
        this.vulns = this.vulns.concat(res);
      })
      .catch((err) => {
        this.handlerError(err);
      })
      .finally(() => {
        this.vulnsLoaded = true;
        this.scrollLoading = false;
      });
    }
  }

  /**
   *  Load watched products vulns
   **/
  public loadWatched(fromScroll: boolean): void {
    this.page = this.watchedPage;
    this.searched = false;

    if (this.watchedProducts.length !== 0) {
      if(fromScroll) {
        this.scrollLoading = true;
        this.watchFilterCriteria.skip += this.watchFilterCriteria.limit;
      } else {
        this.vulns = [];
        this.vulnsLoaded = false;
        this.watchFilterCriteria = new VulnSearchCriteria();
        this.watchFilterCriteria.returnStub = true;
        this.watchFilterCriteria.products = this.watchedProducts;
      }

      this.vulnService
      .vulnLoadProductList(this.watchFilterCriteria)
      .then((watchedVuln) => {
        this.vulns = this.vulns.concat(watchedVuln);
      })
      .catch((err) => {
        this.handlerError(err);
      })
      .finally(() => {
        this.vulnsLoaded = true;
        this.scrollLoading = false;
      });
    }
  }

  /**
   * Load all the annual analytics data for graphical presentation
   * Urgency and categories need formatting for charts
   */
  public loadAnalytics(): void {
    this.page = this.analyticsPage;
    this.analyticsDataLoaded = false;

    if (sessionStorage.getItem(SessionStorageItem.vulnTotalProducts)) {
      this.totalProducts = Number(sessionStorage.getItem(SessionStorageItem.vulnTotalProducts));
    } else {
      this.vulnService
        .vulnProductCount()
        .then((res) => {
          this.totalProducts = res;
          sessionStorage.setItem(SessionStorageItem.vulnTotalProducts, String(this.totalProducts));
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }

    if (sessionStorage.getItem(SessionStorageItem.vulnTotalVendors)) {
      this.totalVendors = Number(sessionStorage.getItem(SessionStorageItem.vulnTotalVendors));
    } else {
      this.vulnService
        .vulnVendorCount()
        .then((res) => {
          this.totalVendors = res;
          sessionStorage.setItem(SessionStorageItem.vulnTotalVendors, String(this.totalVendors));
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }

    if (sessionStorage.getItem(SessionStorageItem.vulnTotalVulns)) {
      this.totalVulnerabilities = Number(sessionStorage.getItem(SessionStorageItem.vulnTotalVulns));
    } else {
      this.vulnService
        .vulnCount()
        .then((res) => {
          this.totalVulnerabilities = res;
          sessionStorage.setItem(SessionStorageItem.vulnTotalVulns, String(this.totalVulnerabilities));
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }

    if (sessionStorage.getItem(SessionStorageItem.vulnLastUpdated)) {
      this.lastUpdated = sessionStorage.getItem(SessionStorageItem.vulnLastUpdated);
    } else {
      this.vulnService
        .vulnLastUpdate()
        .then((res) => {
          this.lastUpdated = res[0].lastModifiedDate;
          sessionStorage.setItem(SessionStorageItem.vulnLastUpdated, this.lastUpdated);
        })
        .catch((err) => {
          this.alertService.handlerError(err);
        });
    }

    // get last weeks
    if (sessionStorage.getItem(SessionStorageItem.vulnWeekWord)) {
      this.wordChartOptions.series[0].data = JSON.parse(sessionStorage.getItem(SessionStorageItem.vulnWeekWord));
      this.updateWordChart = true;
    } else {
      const tempCrit = new VulnSearchCriteria();
      tempCrit.pubDateFrom = Utilities.getTodayMinusDays(7);
      tempCrit.pubDateTo = Utilities.getToday();
      tempCrit.returnStub = true;

      this.vulnService
        .vulnSearch(tempCrit).then((vulns) => {
          this.processVulnsWordCloud(vulns).then((res) => {
            const t = [];
            res.forEach((e) => {
              if (e.weight > 1 && e.name.length > 2 && !constants.ignoreList.includes(e.name)) {
                t.push(e);
              }
            });
            t.sort((a, b) => b.weight - a.weight);
            sessionStorage.setItem(SessionStorageItem.vulnWeekWord, JSON.stringify(t.slice(0, 100)));
            this.wordChartOptions.series[0].data = t.slice(0, 100);
            this.updateWordChart = true;
          });
        })
        .catch((err) => {
          this.handlerError(err);
        });
    }

    // get 12 months stats
    if (
      sessionStorage.getItem(SessionStorageItem.vulnAnnualSeverityPet) &&
      sessionStorage.getItem(SessionStorageItem.vulnAnnualSeveritySeries) &&
      sessionStorage.getItem(SessionStorageItem.vulnAnnualSeverityCategories)
    ) {
      this.urgPieChartOptions.series[0].data = JSON.parse(sessionStorage.getItem(SessionStorageItem.vulnAnnualSeverityPet));
      this.stackedBarSeverityOptions.series = JSON.parse(sessionStorage.getItem(SessionStorageItem.vulnAnnualSeveritySeries));
      this.stackedBarSeverityOptions.xAxis.categories = JSON.parse(
        sessionStorage.getItem(SessionStorageItem.vulnAnnualSeverityCategories)
      );

      this.updateUrgencyPie = true;
      this.updateStackedBarSeverity = true;
      this.loadStackedBarSeverity = true;
      this.analyticsDataLoaded = true;
    } else {
      const vulnsFilterCriteria = new VulnSeverityStatsCriteria();
      vulnsFilterCriteria.attributeName = constants.fields.vulns.severity;
      vulnsFilterCriteria.pubDateFrom = Utilities.get12MonthsAgo();
      vulnsFilterCriteria.pubDateTo = Utilities.getToday();

      this.vulnService.vulnMonthlyStats(vulnsFilterCriteria).then((res) => {
        this.stackedBarSeverityOptions.xAxis.categories = [];

        // initialise an array for each urgency
        const none = { name: 'None', data: [] };
        const low = { name: 'Low', data: [] };
        const med = { name: 'Medium', data: [] };
        const high = { name: 'High', data: [] };
        const crit = { name: 'Critical', data: [] };

        let totallow = 0;
        let totalmed = 0;
        let totalhigh = 0;
        let totalcrit = 0;

        let arrayIndex = 1;
        res.forEach((dataPoint) => {
          // add this months data to categories
          const x = dataPoint.name.substring(4) + '/' + dataPoint.name.substring(0, 4);
          this.stackedBarSeverityOptions.xAxis.categories.push(x);

          // get this months data point for each urgency
          dataPoint.data.forEach((dp) => {
            if (dp._id.baseSeverity === 'NONE') {
              none.data.push(dp.count);
            } else if (dp._id.baseSeverity === 'LOW') {
              totallow += dp.count;
              low.data.push(dp.count);
            } else if (dp._id.baseSeverity === 'MEDIUM') {
              totalmed += dp.count;
              med.data.push(dp.count);
            } else if (dp._id.baseSeverity === 'HIGH') {
              totalhigh += dp.count;
              high.data.push(dp.count);
            } else if (dp._id.baseSeverity === 'CRITICAL') {
              totalcrit += dp.count;
              crit.data.push(dp.count);
            }
          });

          // if there were no datapoints for this month then add a 0
          if (none.data.length !== arrayIndex) {
            none.data.push(0);
          }
          if (low.data.length !== arrayIndex) {
            low.data.push(0);
          }
          if (med.data.length !== arrayIndex) {
            med.data.push(0);
          }
          if (high.data.length !== arrayIndex) {
            high.data.push(0);
          }
          if (crit.data.length !== arrayIndex) {
            crit.data.push(0);
          }
          arrayIndex++;
        });

        const sevSeries = [];
        sevSeries.push(none);
        sevSeries.push(low);
        sevSeries.push(med);
        sevSeries.push(high);
        sevSeries.push(crit);

        this.urgPieChartOptions.series[0].data = [];
        this.urgPieChartOptions.series[0].data.push(['Low', totallow]);
        this.urgPieChartOptions.series[0].data.push(['Medium', totalmed]);
        this.urgPieChartOptions.series[0].data.push(['High', totalhigh]);
        this.urgPieChartOptions.series[0].data.push(['Critical', totalcrit]);

        sessionStorage.setItem(SessionStorageItem.vulnAnnualSeverityPet, JSON.stringify(this.urgPieChartOptions.series[0].data));
        sessionStorage.setItem(SessionStorageItem.vulnAnnualSeveritySeries, JSON.stringify(sevSeries));
        sessionStorage.setItem(SessionStorageItem.vulnAnnualSeverityCategories,
          JSON.stringify(this.stackedBarSeverityOptions.xAxis.categories)
        );

        this.updateUrgencyPie = true;
        this.stackedBarSeverityOptions.series = sevSeries;
        this.updateStackedBarSeverity = true;
        this.loadStackedBarSeverity = true;
        this.analyticsDataLoaded = true;
      });
    }
  }

  /**
   * Load vulnerabilites since last login
   */
  public loadSinceLastVisit(): void {
    this.page = this.vulnsPage;
    this.vulnsLoaded = false;

    // since last login
    this.userprefService
      .getUserLastLoginDate()
      .then((res) => {
        this.vulnsFilterCriteria = new VulnSearchCriteria();
        this.vulnsFilterCriteria.modDateFrom = Utilities.getDateAsString(res);
        this.vulnsFilterCriteria.modDateTo = Utilities.getTodayPlusDays(1);
        this.vulnsFilterCriteria.returnStub = true;

        if (this.sort && this.sortOrder) {
          this.vulnsFilterCriteria.sort = this.sort;
          this.vulnsFilterCriteria.sortOrder = Number(this.sortOrder);
        }

        this.vulnSearch()
          .then(() => (this.vulnsLoaded = true))
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

 /**
  * Get the total number of watched vulnerabilites
  */
  private loadWatchedCount() {
    if (this.watchedProducts && this.watchedProducts.length !== 0) {
      const watchFilterCriteriaCount = new VulnSearchCriteria();
      watchFilterCriteriaCount.count = true;
      watchFilterCriteriaCount.limit = undefined;
      watchFilterCriteriaCount.products = this.watchedProducts;
      this.vulnService
      .vulnLoadProductList(watchFilterCriteriaCount)
      .then((res) => {
        this.watchCount = res && res[0] ? res[0].matching_vulns : 0;
      })
      .catch((err) => {
        this.handlerError(err);
      });
    }
  }

  /**
   * Refresh the local flagRefs, savedSearches and viewType variable from user preferences
   */
  private refreshVulnUserPrefs() {
    // load the flagged signals from user preferences
      const preference = this.userprefService.userPreferences;
      if (preference?.watched?.products) {
        this.watchedProducts = preference.watched.products;
        // load the vulnerabilities watch count
        this.loadWatchedCount();
      } else {
        this.watchedProducts = [];
      }
      if (preference?.searches?.vuln) {
        this.savedSearches = preference.searches.vuln;
      } else {
        this.savedSearches = [];
      }
  }

  /**
   * Export the vulnerabilities
   */
  private exportVuln() {
    this.vulnsLoaded = false;

    this.vulnService
      .vulnSearch(this.vulnsFilterCriteria)
      .then(() => {
        // this.vulns = res;
        this.vulnsLoaded = true;
        this.exportPDF = false;
        this.alertService.addSuccess(this.translateService.instant('pages.generic.fileuploaded'));
        this.userActivity.logActivity(TYPES.reports, PAGES.vulnerabilities, ACTIONS.generateReport);
        const fullName =
        `${
            Utilities.capitalizeFirstLetter(ReportPdfAccessLevel[this.vulnsFilterCriteria.pdfAccessLevel])}
            /${this.vulnsFilterCriteria.PDFName
          }`;
        this.notificationService.sendNotification(
          NotificationCategory.generateReport,
          {title: fullName, content: fullName},
          NotificationAudience.admins
        );
      })
      .catch((err) => {
        throw err;
      });
  }

  /**
   * Get the dismiss reason from a given reason
   */
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /**
   * Process the summry of each signal into a counted word list
   * for the use in a wordcloud
   */
  private processVulnsWordCloud(cves: CVE[]): Promise<WordCloudItem[]> {
    return new Promise((resolve, reject) => {
      // concat all the summary
      let totalWords = '';
      cves.forEach((s) => {
        totalWords = totalWords + ' ' + s.cve.description.description_data[0].value.toLowerCase();
      });

      // break down into a word count
      const lines = totalWords.split(/[,\. \(\)]+/g);
      resolve(
        Highcharts.reduce(
          lines,
          (arr, word) => {
            let item: WordCloudItem = Highcharts.find(arr, (obj) => obj.name === word);
            if (item) {
              item.weight += 1;
            } else {
              item = {
                name: word,
                weight: 1,
              };
              arr.push(item);
            }
            return arr;
          },
          []
        )
      );
    });
  }

  /**
   * Load vulnerabilites since x days ago
   */
  private loadSinceXDays(days: number): void {
    this.page = this.vulnsPage;
    this.vulnsLoaded = false;

    // since last login
    this.userprefService
      .getUserLastLoginDate()
      .then(() => {
        this.vulnsFilterCriteria = new VulnSearchCriteria();
        this.vulnsFilterCriteria.pubDateFrom = Utilities.getTodayMinusDays(days);
        this.vulnsFilterCriteria.pubDateTo = Utilities.getToday();
        this.vulnsFilterCriteria.returnStub = true;

        if (this.sort && this.sortOrder) {
          this.vulnsFilterCriteria.sort = this.sort;
          this.vulnsFilterCriteria.sortOrder = Number(this.sortOrder);
        }

        this.vulnSearch()
          .then(() => (this.vulnsLoaded = true))
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        this.handlerError(err);
      });
  }

  /**
   * Error handling forwarder, only not going direct so
   * it allows us to add context within this module
   *
   * @param err
   */
  private handlerError(err) {
    this.alertService.handlerError(err);
  }
}

<div class="modal-header">
  <h5 class="modal-title" style="color: #ff7900" id="exampleModalLabel">{{'modals.feedbackMenu.mainPage.title' | translate}}</h5>
  <button type="button" class="btn-close" (click)="close(false)"><span class="visually-hidden"></span></button>
</div>
<div class="modal-body">

  <!-- selection page -->
  <div *ngIf="selectedPage==='selection'" >
    <span>{{'modals.feedbackMenu.mainPage.description' | translate}}</span>
    <div class="feedback-selection">
      <div (click)="openFeedbackModal()">
        <fa-icon [icon]="['fas', 'plus']" size="lg" data-toggle="tooltip" data-placement="top"></fa-icon>
        <span>{{'modals.feedbackMenu.mainPage.addFeedback' | translate}}</span>
      </div>
      <div (click)="openSurveyList()">
        <fa-icon [icon]="['fas', 'people-group']" size="lg" data-toggle="tooltip" data-placement="top"></fa-icon>
        <span>{{'modals.feedbackMenu.mainPage.answerSurvey' | translate}}</span>
      </div>
    </div>
  </div>

  <!-- feedback page -->
  <div *ngIf="selectedPage==='feedback'">
    <app-add-feedback [feedback]="feedback" (finished)="close($event)"></app-add-feedback>
  </div>

  <!-- surveys list page -->
  <div *ngIf="selectedPage==='survey-list'">
    <app-survey-list (surveySelected)="onSurveySelected($event)" (finished)="close($event)"></app-survey-list>
  </div>

  <!-- surveys form page -->
  <div *ngIf="selectedPage==='survey-form'">
    <app-survey-form [survey]="selectedSurvey" (finished)="close($event)"></app-survey-form>
  </div>

</div>
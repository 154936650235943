import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_heatmap from 'highcharts/modules/heatmap';
import { BaseChartComponent } from '../basechart/basechart.component';
import { getDefaultHighchartOptions } from '../../utils';
HC_heatmap(Highcharts);

@Component({
  selector: 'app-incidentbyseveritycategory',
  templateUrl: '../basechart/basechart.component.html',
})
export class CybersocxdrIncidentBySeverityCategoryComponent extends BaseChartComponent<
  Array<Highcharts.SeriesHeatmapOptions>
> {
  draw(renderingId: string) {
    // Build a set of categories
    const xdrCategories = [];
    for (const data of this.data[0].data) {
      if (xdrCategories.findIndex((category) => category === data[0]) === -1) {
        xdrCategories.push(data[0]);
      }
    }

    this.options = {
      chart: {
        type: 'heatmap',
        marginTop: 40,
        marginBottom: 80,
        plotBorderWidth: 1,
      },

      title: {
        text: this.title,
      },

      xAxis: {
        type: 'category',
        categories: xdrCategories,
      },

      yAxis: {
        categories: ['Unknow', 'Low', 'Medium', 'High', 'Critical'],
        title: null,
        reversed: true,
      },

      accessibility: {
        point: {
          descriptionFormatter: (point) => point.value.toString(),
        },
      },

      colorAxis: {
        min: 0,
        minColor: this.context.isDarkTheme() ? '#999' : '#FFFFFF',
        maxColor: getDefaultHighchartOptions().colors[0],
      },

      legend: {
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'top',
        y: 25,
        symbolHeight: 280,
      },

      tooltip: {
        formatter() {
          return (
            '<b>There are <b>' +
            this.point.value +
            '<b> incidents <b>' +
            getPointCategoryName(this.point, 'y') +
            '</b><br><b>' +
            ' for category ' +
            getPointCategoryName(this.point, 'x')
          );
        },
      },

      series: this.data,

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              yAxis: {
                labels: {
                  formatter() {
                    return this.value.toString().charAt(0);
                  },
                },
              },
            },
          },
        ],
      },
    };
  }
}

const getPointCategoryName = (point, dimension) => {
  const series = point.series;
  const isY = dimension === 'y';
  const axis = series[isY ? 'yAxis' : 'xAxis'];
  return axis.categories[point[isY ? 'y' : 'x']];
};

export class Alert {
    type: string;
    message: string;

    constructor(type: string, message: string) {
        this.type = type;
        this.message = message;
     }
}

export class GPAlert {
    _id: { $oid: string };
    category: string;
    title: string;
    message: string;
    startDate: string;
    endDate: string;
    active: string;
}

export class GPError {
    message: string;
    exception: any;

    constructor(message, exception) {
        this.message = message;
        this.exception = exception;
    }
}

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES, REGIONS, constants } from 'src/app/shared/constants';
import { ModalService } from 'src/app/shared/modals/modal.service';
import { UserPreferenceService } from 'src/app/shared/services/userpreferences.service';
import { NotificationParameters } from 'src/app/shared/modals/modal';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Notification } from '../../shared/model/notification';
import { OktaAuthService } from 'src/app/app.service';
import { UserTheme } from 'src/app/shared/model/shared-items';
import { ContextService } from 'src/app/shared/services/context-service';
import { AlertService } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
})
export class UserNavbarComponent implements OnInit {

  @Input() accessRestricted: boolean;

  active = false;
  public selectedThemeLabel;
  public changeTenants = false;
  public icon;
  public switchTooltip;

  //notifications
  public notifications: Notification[] = [];
  public newNotificationsCount = 0;
  public notificationTitle: string;
  public notificationDescription: string;
  public notificationDate: string;
  private notificationsLoaded: boolean;
  private notificationsOffset = 0;
  private totalNotificationsCount: number;
  private notificationsPerScroll: number;
  

  // global portal languages
  public languages = [
    //english
    {
      name: LANGUAGES.english.name,
      label: LANGUAGES.english.label,
      lang: LANGUAGES.english.lang,
      current: this.active,
    },
    //france
    {
      name: LANGUAGES.france.name,
      label: LANGUAGES.france.label,
      lang: LANGUAGES.france.lang,
      current: this.active,
    },
    // netherlands
    {
      name: LANGUAGES.netherlands.name,
      label: LANGUAGES.netherlands.label,
      lang: LANGUAGES.netherlands.lang,
      current: this.active,
    },
    // sweden
    {
      name: LANGUAGES.sweden.name,
      label: LANGUAGES.sweden.label,
      lang: LANGUAGES.sweden.lang,
      current: this.active,
    },
    // germany
    {
      name: LANGUAGES.germany.name,
      label: LANGUAGES.germany.label,
      lang: LANGUAGES.germany.lang,
      current: this.active,
    },
  ];

  // global portal regions
  public regions = [
    // belgium
    {
      name: REGIONS.belgium.name,
      value: REGIONS.belgium.value,
      current: this.active,
    },
    // china
    {
      name: REGIONS.china.name,
      value: REGIONS.china.value,
      current: this.active,
    },
    // denmark
    {
      name: REGIONS.denmark.name,
      value: REGIONS.denmark.value,
      current: this.active,
    },
    // france
    {
      name: REGIONS.france.name,
      value: REGIONS.france.value,
      current: this.active,
    },
    // germany
    {
      name: REGIONS.germany.name,
      value: REGIONS.germany.value,
      current: this.active,
    },
    //global
    {
      name: REGIONS.global.name,
      value: REGIONS.global.value,
      current: this.active,
    },
    // maghreb and west africa
    {
      name: REGIONS.maghrebWestAfrica.name,
      value: REGIONS.maghrebWestAfrica.value,
      current: this.active,
    },
    // netherlands
    {
      name: REGIONS.netherlands.name,
      value: REGIONS.netherlands.value,
      current: this.active,
    },
    // norway
    {
      name: REGIONS.norway.name,
      value: REGIONS.norway.value,
      current: this.active,
    },
    // southAfrica
    {
      name: REGIONS.southAfrica.name,
      value: REGIONS.southAfrica.value,
      current: this.active,
    },
    // sweden
    {
      name: REGIONS.sweden.name,
      value: REGIONS.sweden.value,
      current: this.active,
    },
    // uniteKingdom
    {
      name: REGIONS.uniteKingdom.name,
      value: REGIONS.uniteKingdom.value,
      current: this.active,
    },
  ];

  // default to global EN
  public selectRegion = {
    name: REGIONS.global.name,
    value: REGIONS.global.value,
    current: true,
  };

  public selectLanguage = {
    name: LANGUAGES.english.name,
    label: LANGUAGES.english.label,
    lang: LANGUAGES.english.lang,
    current: true,
  };

  constructor(
    private translateService: TranslateService,
    private userPreferenceService: UserPreferenceService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    public oktaAuthService: OktaAuthService,
    public contextService: ContextService,
    private alertService: AlertService
  ) {}
  
  ngOnInit(): void {

    const preferences = this.userPreferenceService.userPreferences;
    this.notificationsPerScroll = constants.recordsPerPage.notifications;

    if (this.oktaAuthService.availableTenants?.length > 1) {
      // allow the changing of tenants
      this.changeTenants = true;
    }

    if(preferences) {
      // init languages
      this.languages.forEach((lang) => {
        if (lang.lang === preferences.language) {
          this.selectLanguage = lang;
          lang.current = true;
        }
      });

      // init regions
      this.regions.forEach((reg) => {
        if (reg.value === preferences.region) {
          this.selectRegion = reg;
          reg.current = true;
        }
      });
      if (!this.accessRestricted && this.oktaAuthService.selectedTenant) {
        // get notifications
        this.notificationService.getNotificationsCount().then( (res) => {
          this.totalNotificationsCount = res;
          this.setupNotifications(preferences, 0, this.notificationsPerScroll);
        });
        
      }
    }

    const isLight = this.contextService.userSelectedTheme === UserTheme.light;
    this.selectedThemeLabel = isLight ? 'pages.dashboard.lightMode' : 'pages.dashboard.darkMode';
    this.icon = isLight ? 'sun' : 'moon';
    this.switchTooltip =  isLight ? 'pages.generic.switchToDark' : 'pages.generic.switchToLight';
  }

  private setupNotifications(preferences, offset: number, limit: number): void {
    this.newNotificationsCount = 0;
    this.notificationService.getNotifications(offset, limit).then((notifications: Notification[]) => {

      // Translate and add notifications
      this.notifications = this.notifications.concat(notifications.map( notif => this.notificationService.translateNotification(notif)));
      
      let popupNotification = null;
      if (preferences.lastNotification) {
        for (const notification of this.notifications) {
          // eslint-disable-next-line no-underscore-dangle
          if (notification._id.$oid === preferences.lastNotification) {
            break;
          } else {
            if (notification.notification.popup) {
              popupNotification = notification;
            }
            this.newNotificationsCount = this.newNotificationsCount + 1;
          }
        }
      } else {
        this.newNotificationsCount = notifications.length;
        popupNotification = this.notifications?.[0];
      }
      if (popupNotification?.notification?.popup) {
        this.openNotification(popupNotification);
      }
      this.notificationsLoaded = true;
    });
  }

  public updateUserPrefNotif(): void {
    if (this.notifications.length > 0 && this.newNotificationsCount !== 0) {
      this.newNotificationsCount = 0;
      this.userPreferenceService.updateUserPrefNotif(this.notifications);
    }
  }

  public changeLanguage(selectedLanguage) {
    this.selectLanguage = selectedLanguage;
    this.languages.forEach((lang) => {
      lang.current = this.active;
    });
    selectedLanguage.current = !selectedLanguage.current;
    this.translateService.use(selectedLanguage.lang);

    // update user preferences
    this.userPreferenceService.userPreferencesUpdateSingle(
      constants.local.userPreferenceLanguage,
      selectedLanguage.lang.toLowerCase()
    );
  }

  public changeRegion(selectedRegion) {
    this.selectRegion = selectedRegion;
    this.regions.forEach((reg) => {
      reg.current = this.active;
    });
    selectedRegion.current = !selectedRegion.current;

    // update user preferences
    this.userPreferenceService.userPreferencesUpdateSingle(
      constants.local.userPreferenceRegion,
      selectedRegion.value.toLowerCase()
    );
  }

  public changeTheme() {
    const newTheme = this.contextService.userSelectedTheme === UserTheme.light ? UserTheme.dark : UserTheme.light;
    this.userPreferenceService.userPreferencesUpdateSingle(constants.local.userPreferenceTheme, newTheme)
      .then(() => window.location.reload())
      .catch(err => {
        this.alertService.handlerError(err);
      });
  }

  public openNotification(notification: Notification) {
    const params = new NotificationParameters();
    params.title = notification.notification.title;
    params.bodyMessage = notification.notification.description;
    params.category = notification.notification.category;
    if (notification.notification.link) {
      params.link = notification.notification.link;
    }
    const date = new Date(notification.publishedTime);
    params.datetime = date.toLocaleString();

    if (notification.notification.category === 6 ) {
      this.modalService.openGlobalFeedback(null);
    } else {
      this.modalService.openNotification(params);
    }
    if (this.newNotificationsCount > 0) {
      // update notification count
      this.updateUserPrefNotif();
    }
  }

  /**
   * when user scroll, load the next notifications
   */
  public loadNextNotifications() {
    if (!this.notificationsLoaded || !this.notifications || this.notifications.length >= this.totalNotificationsCount) {
      return;
    }
    this.notificationsOffset += this.notificationsPerScroll;
    this.setupNotifications(this.userPreferenceService.userPreferences, this.notificationsOffset, this.notificationsPerScroll);
  }

  public openGlobalFeedback() {
    this.modalService.openGlobalFeedback(null);
  }

  public logout() {
    this.oktaAuthService.logout();
  }

}

<img src="assets/images/maliciousFileDetection.png" alt="Malicious File Detection Banner"/>

<p class="text-center lead">{{ 'pages.updates.certTools.mfd.mfdIntro' | translate }}</p>

<form *ngIf="!loadingQuota">
  <div class="row justify-content-center">
      <div class="col-md-6 col-sm-6 col-xs-12 mb-3 mt-3 col align-self-center">

        <div *ngIf="mfdQuota <= 0" class="alert alert-info mt-4 mb-4" role="alert">
          <span class="alert-icon"><span class="visually-hidden">Info</span></span>
          <p>{{ 'pages.updates.certTools.mfd.allMfdQuotaUsed' | translate }}</p>
        </div>

        <div class="upload">
          <h1 class="browse">{{ 'pages.generic.selectFileToUpload' | translate }} :</h1>
          <input type="file" name="film" id="file" class="browse" #fileInput (change)="onChange($event)" *ngIf="mfdQuota > 0">
          <span id="file-select-button" class="btn btn-secondary" (click)="triggerFileInputClick()" *ngIf="mfdQuota > 0">{{ 'pages.updates.certTools.mfd.chooseAFile' | translate }}</span>
          <span id="file-select-button" class="btn btn-secondary disabled" (click)="triggerFileInputClick()"  *ngIf="mfdQuota === null || mfdQuota <= 0">{{ 'pages.updates.certTools.mfd.chooseAFile' | translate }}</span>
          <p class="browse mt-2"><strong>{{ 'pages.updates.certTools.mfd.maximalSize' | translate }} : </strong> 100 Mb</p>
          <p class="browse">{{ 'pages.updates.certTools.mfd.fileFormat' | translate }}</p>
        </div>

        <div class="file" *ngIf="file">
          {{ file?.name}}
        </div>

        <div class="d-flex mt-4 mb-1">
          <button class="btn btn-primary disabled" *ngIf="!file">{{ 'pages.updates.certTools.scanFile' | translate }}</button>
          <button class="btn btn-primary" *ngIf="file && !loading" (click)="upload();">{{ 'pages.updates.certTools.scanFile' | translate }}</button>
          <div class="spinner-border text-primary" role="status" *ngIf="file && loading">
            <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
          </div>
        </div>

      </div>
  </div>



</form>

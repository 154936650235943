import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'statFilter' })
export class StatFilter implements PipeTransform {

  constructor() { }

  transform(list: any[], value: string) {
    if(list){
      const result = list.filter(i => i._id == value);
      if(result.length > 0){
        return result[0].count;
      }else{
        return 0;
      }
    }
  }

}

import { Utilities } from '../utilities';

export class Notification {
    _id?: {
        $oid: string;
    };
    publishedTime?: number;
    notification: {
        title: string;
        description: string;
        popup: boolean;
        category?: NotificationCategory;
        tenantName?: string;
        audience?: NotificationAudience;
        link?: string;
        author?: string,
        toBeTranslated?: boolean
    };

}

export class NotificationPreferences {
    // Map categories with value. 1 = show notifications from this category / 0 = do not show
    preferences = new Map<string, number>();

    constructor() {
        this.setPreferences();
    }

    setPreferences() {
        const notifCatLength = Utilities.enumToStringArray(NotificationCategory).length;
        for (let i=0; i<notifCatLength; i++) {
            this.preferences.set(NotificationCategory[i], 1);
        }
    }
}

export enum NotificationCategory {
    announcement, reportDownload, fileScan, generateReport, usersManagement, fileUpload, feedback
}

export enum MandatoryNotificationCategory {
    feedback
}

export enum NotificationAudience {
    admins, all
}

<div class="row">
  <div class="col-12">
    <div class="row">
      <p class="col-md-10 col-xs-12 mx-auto mb-4 fw-bold">
        {{ 'pages.securitycase.benchmarking.intro' | translate }}
      </p>
    </div>

    <div class="card">
      <div class="card-body">
        <form>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
              <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label for="currentPeriod">
                    {{ 'pages.generic.currentPeriod' | translate }}
                    <app-help message="{{'pages.generic.currentPeriodDescD' | translate}}"></app-help>
                  </label>
                  <select
                    [(ngModel)]='currentPeriod'
                    class='form-select'
                    name='currentPeriod'
                    id='currentPeriod'
                    (ngModelChange)="displayIncidentChart = false">
                    <option value='last30days'><b>{{ 'pages.generic.last30days' | translate }}</b></option>
                    <option value='lastFullCalendarMonth'><b>{{ 'pages.generic.lastFullCalendarMonth' | translate }}</b>
                    </option>
                  </select>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <label for="comparisonPeriod">
                    {{ 'pages.generic.comparisonPeriod' | translate }}
                    <app-help message="{{'pages.generic.comparisonPeriodDescD' | translate}}"></app-help>
                  </label>
                  <select
                    [(ngModel)]='comparisonPeriod'
                    class='form-select'
                    name='comparisonPeriod'
                    id='comparisonPeriod'
                    (ngModelChange)="displayIncidentChart = false">
                    <option value='previous30days'><b>{{ 'pages.generic.previous30days' | translate }}</b></option>
                    <option value='previousFullCalendarMonth'><b>{{ 'pages.generic.previousFullCalendarMonth' |
                        translate }}</b></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-primary" (click)="generate();">{{ 'pages.generic.generate' | translate}} </button>
            <button class="btn btn-primary" (click)="exportReport(content);" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.support.dashboard.downloadResDesc' | translate }}" *ngIf="!generationReportLoading">{{
              'buttons.action.generateReport' | translate }}</button>
            <button class="btn btn-primary" type="button" *ngIf="generationReportLoading">
              <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
              {{ 'pages.generic.loading' | translate }}
            </button>
          </div>
        </form>

      </div>
    </div>

  </div>
</div>


<div class="row pt-3" *ngIf="displayIncidentChart">
  <div class="col-12">

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === 'ibp' }" (click)="page = 'ibp'; showCases=[]">Incidents By
          Priority</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === 'tas' }" (click)="page = 'tas'; showCases=[]">Threat
          Actors</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === 'tac' }" (click)="page = 'tac'; showCases=[]">Threat
          Actions</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === 'tar' }" (click)="page = 'tar'; showCases=[]">Threat
          Attributes</a>
      </li>
    </ul>

  </div>
</div>


<div class="row mt-5 justify-content-center p-5" *ngIf="loading">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
  </div>
</div>

<!-- Threat Priorities -->
<div *ngIf="displayIncidentChart && page === 'ibp'">
  <app-incidents-by-priority *ngIf="currentCases" [currentCases]="currentCases" [previousCases]="previousCases"
    [monthCases]="monthCases" [currentPeriodName]="currentPeriod" [previousPeriodName]="comparisonPeriod">
  </app-incidents-by-priority>
</div>


<!-- Threat Actors -->
<div *ngIf="displayIncidentChart && page === 'tas'">
  <app-incidents-by-actors *ngIf="currentCases" [currentCases]="currentCases" [previousCases]="previousCases"
    [monthCases]="monthCases" [currentPeriodName]="currentPeriod" [previousPeriodName]="comparisonPeriod">
  </app-incidents-by-actors>
</div>

<!-- Threat Actions -->
<div *ngIf="displayIncidentChart && page === 'tac'">
  <app-incidents-by-action *ngIf="currentCases" [currentCases]="currentCases" [previousCases]="previousCases"
    [currentPeriodName]="currentPeriod" [previousPeriodName]="comparisonPeriod">
  </app-incidents-by-action>
</div>

<!-- Attributes -->
<div *ngIf="displayIncidentChart && page === 'tar'">
  <app-incidents-by-attribute *ngIf="currentCases" [currentCases]="currentCases" [previousCases]="previousCases"
    [currentPeriodName]="currentPeriod" [previousPeriodName]="comparisonPeriod">
  </app-incidents-by-attribute>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{ 'modals.export.title' | translate }}</h5>
  </div>
  <div class="modal-body">
    <div class="form-group col-12 pt-2">
      <label for="contact">{{ 'modals.export.reportName' | translate }}</label>
      <input type="text" class="form-control" name="PDFName" aria-describedby="PDFName" [(ngModel)]="PDFName">
    </div>
    <div class="row">
      <div class="col-6">
        <select class="form-control" name="reportOpt" aria-describedby="reportOpt" [(ngModel)]="reportOption">
          <option value="xlsx">XLSX</option>
        </select>
      </div>
      <div class="col-6">
        <select class="form-control" name="reportLevel" aria-describedby="reportLevel" [(ngModel)]="pdfAccessLevel">
          <option title="{{ 'pages.generic.ACCESS_INTERNAL_REPORTS' | translate }}" value="0">{{
            'pages.generic.internal' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_CONFIDENTIAL_REPORTS' | translate }}" value="1">{{
            'pages.generic.confidential' | translate }}</option>
          <option title="{{ 'pages.generic.ACCESS_RESTRICTED_REPORTS' | translate }}" value="2">{{
            'pages.generic.restricted' | translate }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close click')">{{ 'pages.generic.close' |
      translate }}</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('save')">{{ 'pages.generic.save' | translate
      }}</button>
  </div>
</ng-template>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpItsmService {

  private fileURL =  environment.endpoints.itsm + 'case/attachment/';

  private cvURL = environment.endpoints.itsm + 'cv/';

  constructor(
    private httpClient: HttpClient) {}

  // @post /vitalUpload
  async postCaseAttachement(file: File, caseId: string, user: string): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);

    const url = this.fileURL + caseId + '/' + user;

    const upload$ = this.httpClient.post(url, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return await firstValueFrom(upload$);
  }

  /**
     * Get CV object from backend service
     *
     * @param object
     * @param type
     */
  public getCV(type: string): Observable<any[]>{
    return this.httpClient.get<any[]>(this.cvURL + 'case' + '/' + type);
  }

}

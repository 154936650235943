import { Component } from '@angular/core';
import { constants } from 'src/app/shared/constants';


@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent {

  nucleusUrl = '';

  // network info
  public twitterlabel = constants.networks.twitterLabel;
  public linkedinlabel = constants.networks.linkedinLabel;
  public twitterUrl = constants.networks.twitter;
  public linkedinUrl = constants.networks.linkedin;

  // contact mails
  public mailToPortalSupport = constants.mailToPortalSupport;
  public mailToInfoSec = constants.mailToInfoSec;

  public scrollUp() {
    window.scrollTo(0, 0);
  }
}

import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

@Component({
  selector: 'app-sankey',
  templateUrl: '../basechart/basechart.component.html',
})
export class SankeyComponent extends BaseChartComponent<Array<Highcharts.SeriesOptionsType>> {
  @Input()
  height: number = 400;

  options: Highcharts.Options = {
    colors: ['#FF7900', '#FFB400', '#A885D8', '#085EBD', '#4BB4E6', '#492191', '#FFB4E6', '#0A6E31', '#50BE87'],
    chart: {
      type: 'sankey',
      height: this.height,
      backgroundColor: '#FFFFFF00',
      borderColor: null,
    },
    title: {
      text: undefined,
    },
    tooltip: {
      headerFormat: null,
      pointFormat: '{point.fromNode.name} \u2192 {point.toNode.name}: {point.weight}',
    },
    legend: {
      itemStyle: { color: 'black' },
      layout: 'proximate',
      align: 'left',
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    series: undefined,
    plotOptions: {
      series: {
        dataLabels: {
          style: { fontSize: '14px', fontFamily: 'HelvNeueOrange' },
        },
      },
      column: undefined,
    },
  };

  draw(renderingid: string) {
    this.useDefaultOptions = false;
    this.options.title.text = this.title;
    this.options.series = this.data;
  }
}



<div class="row pb-3" >
    <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
    <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 p-3">
      <form>
        <div class="row">
            <div class="form-group col-4">
                <label for="firstName">  {{ 'pages.generic.firstName' | translate }}</label>
                <input type="text" class="form-control" name="firstName" id="firstName" aria-describedby="First Name" [(ngModel)]="firstName" [ngClass]="{'is-invalid': !firstName}">
            </div>
            <div class="form-group col-4">
                <label for="lastName">{{ 'pages.generic.lastName' | translate }}</label>
                <input type="text" class="form-control" name="lastName" id="lastName" aria-describedby="Last Name" [(ngModel)]="lastName" [ngClass]="{'is-invalid': !lastName}">
            </div>
            <div class="form-group col-4">
                <label for="email">{{ 'pages.generic.email' | translate }}</label>
                <input type="text" class="form-control" name="email" id="email" aria-describedby="email" [(ngModel)]="email" [ngClass]="{'is-invalid': !email}">
            </div>
        </div>
        <button *ngIf="!editPage" type="button" (click)="submit();" class="btn btn-primary">{{ 'pages.support.contacts.contactaddbutton'  | translate }}</button>
        <button *ngIf="editPage" type="button" (click)="submit();" class="btn btn-primary">{{ 'pages.support.contacts.contacteditbutton'  | translate }}</button>
      </form>
    </div>
    <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
</div>

<div class="row">
    <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
    <div class="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 p-3">

        <div class="row justify-content-center" *ngIf="loader">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
            </div>
        </div>

    </div>
    <div class="col-xl-1 d-none d-lg-block">&nbsp;</div>
</div>

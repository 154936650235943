import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Question } from 'src/app/shared/model/feedback';

@Component({
  selector: 'app-abstract-question',
  templateUrl: './abstract-question.component.html',
  styleUrls: ['./abstract-question.component.scss']
})
export class AbstractQuestionComponent {
  
  @Input() question: Question;
  @Input() surveyForm: FormGroup;

}

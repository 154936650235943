import { Component, Input } from '@angular/core';
import { Last24HData } from 'src/app/shared/model/cybersocxdr/last24HData';

@Component({
  selector: 'app-daily-info',
  templateUrl: './daily-info.component.html',
})
export class DailyInfoComponent {
  @Input()
  last24HData: Last24HData;
}

<div *ngIf="loaded && surveys.length > 0">
    <p>Please select a form : </p>
    <ol class="list-group list-group-numbered">
        <li *ngFor="let survey of surveys" class="list-group-item d-flex justify-content-between align-items-start"
            (click)="openSurvey(survey)">
            <div class="ms-2 me-auto">
                <div class="fw-bold">{{survey.title}}</div>
                {{survey.desc}}
            </div>
            <span class="tag bg-primary">{{survey.questions.length}} questions</span>
        </li>
    </ol>
    <div class="actions">
        <button type="button" class="btn btn-secondary ms-auto" (click)="close()">
            {{ 'pages.generic.close' | translate }}
        </button>
    </div>
</div>
<div *ngIf="loaded && surveys.length === 0">
    <label>{{'modals.feedbackMenu.mainPage.noSurvey' | translate}}</label>
</div>

<div *ngIf="!loaded" class="row justify-content-center p-5">
    <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
    </div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LayoutComponent } from './layout/layout/layout.component';
import { SupportModule } from './support/support.module';
import { UserModule } from './user/user.module';
import { UpdatesModule } from './updates/updates.module';
import { ReportsModule } from './reports/reports.module';
import { CybersocxdrModule } from './cybersocxdr/cybersocxdr.module';
import { AssetManagementModule } from './assetmanagement/assetmanagement.module';
import { ActivityModule } from './activity/activity.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { AlertComponent } from './shared/components/global-notifications/alert/alert.component';
import { BannerComponent } from './shared/components/banner/banner.component';
import { CallbackComponent } from './callback.component';
import { NotAssignedComponent } from './notassigned/not-assigned.component';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NotFoundComponent } from './shared/404/notfound.component';
import { WorldWatchService } from './shared/services/worldwatch.service';
import { UserPreferenceService } from './shared/services/userpreferences.service';
import { VulnerabilitiesService } from './shared/services/vulnerabilities.service';
import { OktaInterceptor } from './shared/interceptors/oktaInterceptor';
import { AlertService } from './shared/services/alert.service';
import { ActivityService } from './shared/services/activity.service';
import { AppSharedModule } from './app-shared.module';
import { ItsmService } from './shared/services/itsm.service';
import { ItsmGenerator } from './shared/mock/itsm.generator';
import { HighchartsChartModule } from 'highcharts-angular';

import { RssService } from './shared/services/rss.service';
import { OktaUserService } from './shared/services/oktauser.service';
import { TenantComponent } from './tenant/tenant.component';
import { FormsModule } from '@angular/forms';
import { FileAccessService } from './shared/services/fileaccess.service';
import { NoMenuLayoutComponent } from './layout/nomenulayout/nomenulayout.component';
import { TermsOfUseComponent } from './shared/components/policies/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './shared/components/policies/privacy-policy/privacy-policy.component';
import { CookiePolicyComponent } from './shared/components/policies/cookie-policy/cookie-policy.component';
import { CybersocxdrService } from './shared/services/xtendedsoc/cybersocxdr.service';
import { RetentionService } from './shared/services/retention.service';
import { NotAuthorizedComponent } from './shared/403/not-authorized.component';
import { MfdService } from './shared/services/maliciousfiledetection.service';
import { RecentSupportActivityComponent } from './dashboard/widgets/recent-support-activity/recent-support-activity.component';
import { WorldwatchCarouselComponent } from './dashboard/widgets/worldwatch-carousel/worldwatch-carousel.component';
import { LatestActivityComponent } from './dashboard/widgets/latest-activity/latest-activity.component';
import { CustomizeDashboardComponent } from './dashboard/customize-dashboard/customize-dashboard.component';
import {
  FakeLatestActivity,
  FakeOpenCasesComponent,
  FakePendingCasesOcdComponent,
  FakeRecentSupportActivity,
  FakeSinceLogin,
  FakeSinceLoginHalfWidth,
  FakeTruePositivesVSFalsePositivesComponent,
  FakeWidget1,
  FakeWidget2,
  FakeWorldwatchCarousel,
} from './dashboard/customize-dashboard/fakeWidgets';
import { DndModule } from 'ngx-drag-drop';
import { PendingCasesOcdComponent } from './dashboard/widgets/pending-cases-ocd/pending-cases-ocd.component';
import { OpenCasesComponent } from './dashboard/widgets/open-cases/open-cases.component';
import { AnalyticsComponent } from './dashboard/analytics/analytics.component';
import { UserActivityService } from './shared/services/user-activity.service';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { FooterComponent } from './layout/footer/footer.component';
import { UserAccountComponent } from './dashboard/user-account/user-account/user-account.component';
import { TruePositivesVSFalsePositivesComponent } from './dashboard/widgets/true-positives-vs-false-positives/true-positives-vs-false-positives.component';
import { ServiceSettingsComponent } from './dashboard/user-account/service-settings/service-settings.component';
import { NotificationService } from './shared/services/notification.service';
import { ActivityAnalyticsComponent } from './dashboard/analytics/activity-analytics/activity-analytics.component';
import { ActionsAnalyticsComponent } from './dashboard/analytics/actions-analytics/actions-analytics.component';
import { AnalyticsFilterComponent } from './dashboard/analytics/analytics-filter/analytics-filter.component';
import { UserNavbarComponent } from './layout/user-navbar/user-navbar.component';
import { ServicesNavbarComponent } from './layout/services-navbar/services-navbar.component';
import { NgOptimizedImage } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { WidgetsDashboardComponent } from './dashboard/widgets-dashboard/widgets-dashboard.component';
import { AssignedApplicationsComponent } from './assignedapplications/assigned-applications.component';
import { DataShareService } from './shared/services/data-share.service';
import { ModalModule } from './shared/modals/modal.module';
import { SearchBarComponent } from './shared/components/search-bar/search-bar.component';
import { HttpErrorInterceptor } from './shared/interceptors/httpErrorInterceptor';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastComponent } from './shared/components/global-notifications/toast/toast.component';
import { OffsetDatetimePipe } from './shared/pipes/offsetdatetime.pipe';
import { AppDirectivesModule } from './shared/directives/app-directives.module';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    WidgetsDashboardComponent,
    DefaultLayoutComponent,
    LayoutComponent,
    UserNavbarComponent,
    ServicesNavbarComponent,
    FooterComponent,
    NoMenuLayoutComponent,
    BreadcrumbComponent,
    AlertComponent,
    BannerComponent,
    CallbackComponent,
    NotFoundComponent,
    TenantComponent,
    NotAssignedComponent,
    AssignedApplicationsComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    CookiePolicyComponent,
    NotAuthorizedComponent,
    RecentSupportActivityComponent,
    WorldwatchCarouselComponent,
    LatestActivityComponent,
    CustomizeDashboardComponent,
    TruePositivesVSFalsePositivesComponent,
    FakeWidget1,
    FakeWidget2,
    FakeLatestActivity,
    FakeRecentSupportActivity,
    FakeWorldwatchCarousel,
    FakeSinceLogin,
    FakeSinceLoginHalfWidth,
    FakePendingCasesOcdComponent,
    FakeOpenCasesComponent,
    FakeTruePositivesVSFalsePositivesComponent,
    PendingCasesOcdComponent,
    OpenCasesComponent,
    AnalyticsComponent,
    AnalyticsFilterComponent,
    ActivityAnalyticsComponent,
    ActionsAnalyticsComponent,
    UserAccountComponent,
    ServiceSettingsComponent
  ],
  imports: [
    AppSharedModule,
    AppDirectivesModule,
    BrowserModule,
    SupportModule,
    UserModule,
    UpdatesModule,
    ReportsModule,
    CybersocxdrModule,
    AssetManagementModule,
    ActivityModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    NgbModule,
    HighchartsChartModule,
    DndModule,
    DashboardModule,
    NgOptimizedImage,
    ModalModule,
    ToastComponent,
    InfiniteScrollModule,
    SearchBarComponent,
    OffsetDatetimePipe
  ],
  providers: [
    RetentionService,
    WorldWatchService,
    UserPreferenceService,
    VulnerabilitiesService,
    AlertService,
    ItsmService,
    DataShareService,
    RssService,
    ItsmGenerator,
    ActivityService,
    OktaUserService,
    CybersocxdrService,
    FileAccessService,
    MfdService,
    UserActivityService,
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OktaInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { BaseChartComponent } from '../basechart/basechart.component';

interface ExtendedPointOptionsObject extends Highcharts.PointOptionsObject {
  originalValue?: number;
}

@Component({
  selector: 'app-customchart',
  templateUrl: '../basechart/basechart.component.html',
})
export class CustompourcentchartComponent
  extends BaseChartComponent<Array<Highcharts.SeriesOptionsType>>
  implements OnChanges
{
  @Input() createdTo?: string;
  @Input() createdFrom?: string;
  @Input() isStacking = false;
  @Input() dateFormat = '%d %b'; // that's the default highchart value
  @Input() titleY = 'Opened Incident';
  @Input() hovered: string;
  @Input() height: number;

  options: Highcharts.Options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Months',
      },
      labels: {
        formatter: (ctx: Highcharts.AxisLabelsFormatterContextObject): string =>
          Highcharts.dateFormat('%b', ctx.value as number),
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: this.titleY,
      },
    },

    legend: {
      layout: 'horizontal',
      enabled: true,
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.originalValue}   </b> <br/>',
      shared: true,
    },
    credits: { enabled: false },
    exporting: { enabled: false },
    plotOptions: {
      column: {
        stacking: undefined,
        grouping: false,
        borderWidth: 1,
        borderColor: '#ffffff',
        borderRadius: 4,
        dataLabels: {
          enabled: true,
          inside: true,
          formatter: function () {
            return this.y >= 10 ? this.y + '%' : null;
          },
          style: {
            fontWeight: 'bold',
            fontSize: '16px',
            textOutline: 'none',
          },
        },
      },
    },
    series: undefined,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if ('hovered' in changes) {
      if (!changes.hovered.firstChange) {
        const serie = this.chart.series.find((s) => s.name === this.hovered);
        if (serie) {
          serie.onMouseOver();
          this.chart.tooltip.refresh(serie.points);
        } else {
          this.chart.series.forEach((s) => s.onMouseOut());
          this.chart.tooltip.hide();
        }
      }
    } else {
      super.ngOnChanges(changes);
    }
  }

  draw(renderingId: string) {
    if (this.data[0]) {
      if (!Array.isArray(this.options.xAxis)) {
        if (this.createdFrom) {
          this.options.xAxis.min = new Date(this.createdFrom).getTime();
        }
        if (this.createdTo) {
          this.options.xAxis.max = new Date(this.createdTo).getTime();
        }
      }
      const originalData = JSON.parse(JSON.stringify(this.data));

      const timeKeys = [
        ...new Set(
          originalData
            .map((series: Highcharts.SeriesColumnOptions) => series.data.map((point) => point[0] ?? 0))
            .flat()
            .filter((t) => t !== 0)
        ),
      ];

      //complete the data with 0 values for missing time keys
      originalData.forEach((series: Highcharts.SeriesColumnOptions) => {
        series.data = timeKeys.map((time) => {
          const point = series.data.find((point) => point[0] === time);
          return point ? [time, point[1]] : [time, 0];
        });
      });
      this.seriesToPercentages(originalData);

      this.options.series = originalData;
    } else {
      this.options.series = [
        {
          name: 'No data found for this period',
          data: [],
        } as Highcharts.SeriesXrangeOptions,
      ];
    }

    this.options.yAxis = {
      title: {
        text: this.titleY,
      },
      max: 100,
      min: 0,
    };
    this.options.title.text = this.title;
    this.options.chart.height = this.height;
  }

  // Calculate the percentage of each series and update the data.
  private seriesToPercentages(originalData: Highcharts.SeriesOptionsType[]): void {
    let seriesValues: { [key: string]: number[] } = {};
    originalData.forEach((series: Highcharts.SeriesColumnOptions, i) => {
      series.pointPlacement = -0.2 + i * 0.4;
      seriesValues[series.name] = series.data.map((point) => (Array.isArray(point) ? point[1] : 0));
    });

    let totals: number[] = [];
    Object.values(seriesValues).forEach((values, index) => {
      values.forEach((value, i) => {
        totals[i] = (totals[i] || 0) + value;
      });
    });

    originalData.forEach((series: Highcharts.SeriesColumnOptions) => {
      const values = seriesValues[series.name];
      let percentages: number[] = values.map((value, i) => Number(((value / totals[i]) * 100).toFixed(0)));
      this.updateSeriesData(percentages, series);
    });
  }

  /**
   * Update series data with calculated percentages.
   * @param percentages - The calculated percentages.
   * @param series - The series data to update.
   */
  private updateSeriesData(percentages: number[], series: Highcharts.SeriesColumnOptions) {
    series.data = series.data.map((point, index) =>
      Array.isArray(point)
        ? ({ x: point[0], y: percentages[index], originalValue: point[1] } as ExtendedPointOptionsObject)
        : point
    );
  }
}

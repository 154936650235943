<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <p>{{ 'pages.dashboard.intelligence.info' | translate }}</p>
        <br />
        <div class="row">
          <div class="col-3 text-center" *ngFor="let file of fileNames">
            <div class="card boxshadow">
              <div class="card-body m-h-7">
                <div class="d-flex no-block text-center">
                  <div class="m-r-20 align-self-center"><fa-icon [icon]="['far', 'file']" size="lg"
                      [styles]="{'stroke': 'orange', 'color': 'orange'}"></fa-icon>
                  </div>
                  <div class="align-self-center max-width">
                    {{convertFileName(file)}}
                    <br /><br />
                    <button type="button" (click)="downloadFile(file)" class="btn btn-primary">{{
                      'pages.generic.downloadPDF' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

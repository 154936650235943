export class UpdateCase {
    id: string;
    update: UpdateCaseFields;
}

export class UpdateCaseFields {
    type: string;
    subject: string;
    description: string;
    priority: string;
    contactId: string;
    secondContactId: string;
    assetId: string;
    customerReference: string;
    worldWatchReference: string;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';

import { ReportsRoutingModule } from './reports-routing.module';
import { AppSharedModule } from '../app-shared.module';
import { ReportsStaticComponent } from './reports-static/reports-static.component';
import { ReportsLiveIndexComponent } from './reports-live/reports-live-index/reports-live-index.component';
import { ReportLiveItsmComponent } from './reports-live/report-live-itsm/report-live-itsm.component';
import { ReportLiveSecurityCaseComponent } from './reports-live/report-live-securitycase/report-live-securitycase.component';
import { IntelligenceComponent } from './intelligence/intelligence.component';
import { ReportLiveBenchmarkingComponent } from './reports-live/report-live-benchmarking/report-live-benchmarking.component';
import { ReportScheduleComponent } from './report-schedule/report-schedule.component';
import { ReportBaseChartComponent } from './reports-live/report-live-securitycase/report-live-charts/base-chart/report-base-chart.component';
import { TpvsfpChartComponent } from './reports-live/report-live-securitycase/report-live-charts/tpvsfp/tpvsfp-chart.component';
import { ThreatsChartComponent } from './reports-live/report-live-securitycase/report-live-charts/threats/threats-chart.component';
import { ThreatActorChartComponent } from './reports-live/report-live-securitycase/report-live-charts/individual-charts/threat-actor-chart.component';
import { ThreatKillchainChartComponent } from './reports-live/report-live-securitycase/report-live-charts/individual-charts/threat-killchain-chart.component';
import { ThreatActionChartComponent } from './reports-live/report-live-securitycase/report-live-charts/individual-charts/threat-action-chart.component';
import { ThreatAssetsChartComponent } from './reports-live/report-live-securitycase/report-live-charts/individual-charts/threat-assets-chart.component';
import { ThreatAttributesChartComponent } from './reports-live/report-live-securitycase/report-live-charts/individual-charts/threat-attributes-chart.component';
import { FalsePositivesChartComponent } from './reports-live/report-live-securitycase/report-live-charts/false-positives/false-positives-chart.component';


@NgModule({
  declarations: [
    ReportsStaticComponent,
    ReportsLiveIndexComponent,
    ReportLiveItsmComponent,
    ReportLiveSecurityCaseComponent,
    IntelligenceComponent,
    ReportLiveBenchmarkingComponent,
    ReportScheduleComponent,
    ReportBaseChartComponent,
    TpvsfpChartComponent,
    ThreatsChartComponent,
    ThreatActorChartComponent,
    ThreatKillchainChartComponent,
    ThreatActionChartComponent,
    ThreatAssetsChartComponent,
    ThreatAttributesChartComponent,
    FalsePositivesChartComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ReportsRoutingModule,
    AppSharedModule,
    HighchartsChartModule
  ],
  exports: [
    ReportsStaticComponent,
    ReportsLiveIndexComponent,
    ReportLiveSecurityCaseComponent,
    ReportLiveBenchmarkingComponent,
    TpvsfpChartComponent,
    ThreatsChartComponent,
    ThreatActorChartComponent,
    ThreatKillchainChartComponent,
    ThreatActionChartComponent,
    ThreatAssetsChartComponent,
    ThreatAttributesChartComponent,
    FalsePositivesChartComponent
  ],
  bootstrap: [ ReportsStaticComponent, ReportsLiveIndexComponent, ReportLiveSecurityCaseComponent, ReportLiveBenchmarkingComponent ]
})
export class ReportsModule { }

<div class="content">

  <!-- since last login-->
   @if(loadpage !== 'lastlogin' && lastLoginTimestamp) {
     <div class="card news">
       <ul class="p-0 m-0 news" style="width: 100%">
         @if(loadLastVisit) {
           <div class="row justify-content-center">
             <div class="spinner-border text-primary" role="status">
               <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
             </div>
           </div>
          } @else {
            <a class="click" (click)="loadSinceLastVisit(true)">
              <li class="mb-2">
                <span [ngClass]="context.isDarkTheme() ? 'lighter-blue' : 'darker-blue'">
                  {{ sinceLastLogin }}
                </span>
                {{ 'pages.updates.signal.sinceLastVisitPost' | translate }}
              </li>
            </a>
          }
       </ul>
     </div>
   }

  <div class="pb-3">
    <div class="card">
      <div class="card-body">
        <div class="card-title d-flex justify-content-end">
          <!--advanced search -->
          <div class="form-check form-switch custom-switch right me-3" style="float:left" data-toggle="tooltip"
            data-placement="top" title="{{ 'pages.worldwatch.enableAdvSrchDesc' | translate }}">
            <input class="form-check-input" type="checkbox" [attr.aria-checked]="advSearch" role="switch"
              id="advanceSearch"  [(ngModel)]="advSearch">
            <label class="custom-control-label" for="advanceSearch">{{ 'pages.generic.advancedSearch' | translate
              }} </label>
          </div>
          <!-- saved search -->
          <div class="form-check form-switch custom-switch right" style="float:left" data-toggle="tooltip"
            data-placement="top" title="{{ 'pages.worldwatch.enableSavedSearchDesc' | translate }}">
            <input class="form-check-input" type="checkbox" [attr.aria-checked]="openSaveSearch" role="switch"
              id="openSaveSearch" [(ngModel)]="openSaveSearch">
            <label class="custom-control-label" for="openSaveSearch">{{ 'pages.generic.savedSearches' | translate
              }}</label>
          </div>
        </div>
        <form [formGroup]="advisoryForm">
          <!--simple search -->
          <div class="row">

            <!-- id -->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top"
              title="{{ 'pages.worldwatch.searchSignalDesc' | translate }}">
              <label for="referenceInput">{{ 'pages.updates.signal.fieldTitles.reference' | translate }} <app-help message="{{ 'pages.updates.signal.fieldTitles.referenceHelper' | translate }}"></app-help> </label>
              <input type="text" class="form-control" id="referenceInput" name="id"
                formControlName="id">
            </div>

            <!-- title -->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top">
              <label for="queryInput">{{ 'pages.updates.signal.fieldTitles.title' | translate }}</label>
              <input type="text" class="form-control" id="queryInput" name="query"
                [placeholder]="'pages.updates.signal.fieldTitles.title' | translate" formControlName="title">
            </div>

            <!-- urgency -->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-3 mb-3">
              <div class="custom-label" id="urgencyFilter">{{ 'pages.updates.signal.fieldTitles.urgency' | translate
                }}</div>
              <ng-multiselect-dropdown [settings]="dropdownSettings" formControlName="severity" [data]="urgenciesData"
                name="typeFilter" aria-labelledby="urgencyFilter">
              </ng-multiselect-dropdown>
            </div>

            <!-- threatCategoryTitle -->
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-3 mb-3">
              <label for="categorySelect">{{ 'pages.updates.signal.fieldTitles.category' | translate }}</label>
              <select class="form-select"  id="categorySelect" name="category" formControlName="threatCategoryTitle">
                <option></option>
                <option>{{ 'pages.worldwatch.advisory' | translate }}</option>
                <option>{{ 'pages.worldwatch.announcement' | translate }}</option>
                <option>{{ 'pages.worldwatch.breach' | translate }}</option>
                <option>{{ 'pages.worldwatch.breakingStory' | translate }}</option>
                <option>{{ 'pages.worldwatch.news' | translate }}</option>
                <option>{{ 'pages.worldwatch.threat' | translate }}</option>
                <option>{{ 'pages.worldwatch.update' | translate }}</option>
                <option>{{ 'pages.worldwatch.vulnerability' | translate }}</option>
              </select>
            </div>
          </div>

          <!-- advanced search -->
          <div [ngbCollapse]="!advSearch" id="advSearch">
            
            <!-- created date -->
            <div class="row">

              <!-- from -->
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top">
                <div class="custom-label">
                  {{ 'pages.updates.signal.fieldTitles.createdFrom' | translate }}
                </div>
                <div class="row">
                  <span class="visually-hidden" id="publishedFromLabel">{{
                    'pages.updates.signal.fieldTitles.publishedFrom' | translate }}</span>
                  <div class="col-12">
                    <input type="datetime-local" class="form-control" name="publishFrom" aria-labelledby="publishedFromLabel" formControlName="createdAfter"
                      [ngClass]="{'is-invalid': createdRange.errors?.incorrect}">
                  </div>
                  </div>
                </div>              

                <!-- to -->
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top">
                <div class="custom-label">
                  {{ 'pages.updates.signal.fieldTitles.createdTo' | translate }}
                </div>
                <div class="row">
                </div>
                <span class="visually-hidden" id="publishedToLabel">{{
                  'pages.updates.signal.fieldTitles.publishedTo' | translate }}</span>
                  <div class="col-12">
                    <input type="datetime-local" class="form-control is-invalid" name="publishTo" aria-labelledby="publishedToLabel" formControlName="createdBefore"
                    [ngClass]="{'is-invalid': createdRange.errors?.incorrect}">
                  </div>
                </div>

              <!-- updated date -->
              <!-- from -->
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top">
                <div class="custom-label">
                  {{ 'pages.updates.signal.fieldTitles.updatedFrom' | translate }}
                </div>
                <div class="row">
                  <span class="visually-hidden" id="publishedFromLabel">{{
                    'pages.updates.signal.fieldTitles.publishedFrom' | translate }}</span>
                  <div class="col-12">
                    <input type="datetime-local" class="form-control" name="publishFrom" aria-labelledby="publishedFromLabel" formControlName="updatedAfter"
                      [ngClass]="{'is-invalid': updatedRange.errors?.incorrect}">
                  </div>
                  </div>
                </div>              

                <!-- to -->
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 mb-3" data-toggle="tooltip" data-placement="top">
                <div class="custom-label">
                  {{ 'pages.updates.signal.fieldTitles.updatedTo' | translate }}
                </div>
                <div class="row">
                </div>
                <span class="visually-hidden" id="publishedToLabel">{{
                  'pages.updates.signal.fieldTitles.publishedTo' | translate }}</span>
                  <div class="col-12">
                    <input type="datetime-local" class="form-control" name="publishTo" aria-labelledby="publishedToLabel" formControlName="updatedBefore"
                    [ngClass]="{'is-invalid': updatedRange.errors?.incorrect}">
                  </div>
                </div>
              </div>

            <!-- created date -->
            <div class="row">

              <!-- created date range error -->
              <div class="col-6 mb-3" data-toggle="tooltip" data-placement="top">
              @if(createdRange.errors?.incorrect) {
                <div class="alert alert-danger alert-sm" role="alert">
                  <span class="alert-icon"><span class="visually-hidden">Error</span></span>
                  <p>{{ 'pages.updates.signal.fieldTitles.dateHelper' | translate }}</p>
                </div>
                }        
              </div>              

              <!-- updated date range error -->
              <div class="col-6 mb-3" data-toggle="tooltip" data-placement="top">
              @if(updatedRange.errors?.incorrect) {
                <div class="alert alert-danger alert-sm" role="alert">
                  <span class="alert-icon"><span class="visually-hidden">Error</span></span>
                  <p>{{ 'pages.updates.signal.fieldTitles.dateHelper' | translate }}</p>
                </div>
                }
              </div>
            </div>
        </div>

          <div>
            <button class="btn btn-primary" (click)="applySearch()" [disabled]="createdRange.errors?.incorrect || updatedRange.errors?.incorrect">{{ 'pages.generic.filter' | translate }}</button>
            <button class="btn btn-primary" (click)="reset(true)">{{ 'pages.generic.reset' | translate }}</button>
            <button class="btn btn-primary" (click)="openExportModal()" disabled>
              {{ 'buttons.action.generateReport' | translate }}
            </button>
            <button class="btn btn-primary save-search" (click)="openSearchModal()" [disabled]="!hasActiveFilter()"
              data-toggle="tooltip" data-placement="top" title="{{ 'pages.worldwatch.saveSearchDesc' | translate }}">
              {{ 'pages.generic.saveSearch' | translate }}
            </button>
          </div>

        </form>
      </div>
    </div>

    @if(savedFilters && savedFilters.length > 0) {
      <div class="card" [ngbCollapse]="!openSaveSearch"
        id="savedSearch">
        <div class="card-body save-search">
          <div class="col-8">
            <!-- save search buttons -->
             @for(filter of savedFilters; track $index) {
               <span style="float: left">
                 <button class="btn btn-secondary mt-1 search" title="{{filter.name}}"
                   [ngClass]="{'selected': selectedFilter && selectedFilter.name === filter.name}"
                   (click)="loadSelectedFilter(filter)">
                   {{ filter.name }}
                 </button>
               </span>
             }
          </div>
          <div class="col-4">
            <!-- delete search button -->
            <button class="btn btn-primary save-search mt-1" data-toggle="tooltip" data-placement="top"
              [disabled]="!selectedFilter" title="{{ 'buttons.action.deleteSearch' | translate }}"
              (click)="deleteSearch()">
              {{ 'buttons.action.deleteSearch' | translate }}
            </button>
            <!-- update search button -->
            <button class="btn btn-primary save-search mt-1" data-toggle="tooltip" data-placement="top"
              [disabled]="!selectedFilter" title="{{ 'buttons.action.updateSearch' | translate }}"
              (click)="saveSearch(true)">
              {{ 'buttons.action.updateSearch' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="rowfiller" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    (scrolled)="loadAdvisories(true)">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{ 'active' : page === signalsPage }"
          (click)="page = signalsPage; displayAdvisories();">{{ 'pages.updates.signal.signals' | translate }}
          <span class="tag text-bg-primary ms-2" style="padding:0rem 0.5rem;"
            *ngIf="signalsCount">{{signalsCount}}</span>
        </a>
      </li>
      <li class="nav-item" data-toggle="tooltip" data-placement="top"
        title="{{ 'pages.worldwatch.signalFlagDesc' | translate }}">
        <a class="nav-link" [ngClass]="{ 'active' : page === flaggedPage }" (click)="displayFlaggedAdvisories();">{{
          'pages.generic.flagged' | translate }}
          <span class="tag text-bg-primary ms-2" style="padding:0rem 0.5rem;">{{userPreferenceService.userPreferences?.flags?.worldwatch?.length}}</span>
        </a>
      </li>
      <!-- 
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{ 'active' : page === analyticsPage }"
            (click)="page = analyticsPage; loadAnalytics();">{{ 'pages.generic.analytics' | translate
            }}</a>
        </li>
      -->
    </ul>

    <!-- 
      <div class="tools" *ngIf="page === signalsPage">
        <fa-icon [icon]="['fas', 'th-list']" size="2x" class="p-1 click" (click)="changeViewType('list')"></fa-icon>
        <fa-icon [icon]="['fas', 'th-large']" size="2x" class="p-1 click" (click)="changeViewType('grid')"></fa-icon>
      </div>
    -->

    <br /><br />

    <div class="row justify-content-center p-5" *ngIf="!chartDataLoaded && page === analyticsPage">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>

    <div *ngIf="chartDataLoaded && page === analyticsPage">
      <h2> {{ 'pages.generic.thisMonth' | translate }} </h2>
      <div class="row p-1 status-table" *ngIf="chartDataLoaded">
        <div class="col-xl-4 col-lg-4 col-sm-12 item first-col-status">
          <h4>{{ 'pages.generic.atAGlance' | translate }}<br /><small>{{monthWord}}</small></h4>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">{{totalStats}}</h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.generic.new' | translate }}</h6>
              <a (click)="loadNew('month')" class="btn btn-primary">{{ 'pages.generic.view' | translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{categoryStats | statFilter : 'Breach' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.breaches' | translate }}</h6>
              <a (click)="loadCategory('month','Breach')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{urgencyStats | statFilter : 'Critical' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.critical' | translate }}</h6>
              <a (click)="loadUrgency('month','Critical')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{urgencyStats | statFilter : 'High' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.high' | translate }}</h6>
              <a (click)="loadUrgency('month','High')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <highcharts-chart [Highcharts]="Highcharts" [options]="catPieChartOptions" [(update)]="updateCategoryPie"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
        <div class="col-3">
          <highcharts-chart [Highcharts]="Highcharts" [options]="urgPieChartOptions" [(update)]="updateUrgencyPie"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>

        <div class="col-6">
          <highcharts-chart [Highcharts]="Highcharts" [options]="wordChartOptions" [(update)]="updateWordChart"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
    </div>

    <div *ngIf="annualChartDataLoaded && page === analyticsPage">
      <h2 class="pt-2"> {{ 'pages.generic.last12months' | translate }} </h2>
      <div class="row p-1 status-table" *ngIf="annualChartDataLoaded">
        <div class="col-xl-4 col-lg-4 col-sm-12 item first-col-status">
          <h4>{{ 'pages.generic.atAGlance' | translate }}<br /><small>{{ 'pages.generic.last12months' | translate }}
            </small></h4>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">{{annualTotalStats}}</h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.generic.total' | translate }}</h6>
              <a (click)="loadNew('annual')" class="btn btn-primary">{{ 'pages.generic.view' | translate }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{annualCategoryStats | statFilter : 'Breach' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.breaches' | translate }}</h6>
              <a (click)="loadCategory('annual','Breach')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{annualUrgencyStats | statFilter : 'Critical' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.critical' | translate }}</h6>
              <a (click)="loadUrgency('annual','Critical')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <div class="card-body text-center">
              <h2 class="card-title">
                <span>{{annualUrgencyStats | statFilter : 'High' }}</span>
              </h2>
              <h6 class="card-subtitle mb-2 text-muted">{{ 'pages.updates.signal.high' | translate }}</h6>
              <a (click)="loadUrgency('annual','High')" class="btn btn-primary">{{ 'pages.generic.view' | translate
                }}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2" *ngIf="annualChartDataLoaded">
        <div class="col-6">
          <highcharts-chart [Highcharts]="Highcharts" [options]="catAnnualPieChartOptions"
            [(update)]="updateAnnualCategoryPie" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
        <div class="col-6">
          <highcharts-chart [Highcharts]="Highcharts" [options]="urgAnnualPieChartOptions"
            [(update)]="updateAnnualUrgencyPie" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
      <div class="row" *ngIf="annualChartDataLoaded">
        <div class="col-12" *ngIf="loadStackedBarUrgency">

          <highcharts-chart [Highcharts]="Highcharts" [options]="stackedBarUrgencyOptions"
            [(update)]="updateStackedBarUrgency" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
        <div class="col-12" *ngIf="loadStackedBarCategory">

          <highcharts-chart [Highcharts]="Highcharts" [options]="stackedBarCategoryOptions"
            [(update)]="updateStackedBarCategory"
            style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
        <div class="col-12 mb-4" *ngIf="loadStackedBarBreach">

          <highcharts-chart [Highcharts]="Highcharts" [options]="stackedBarBreachOptions"
            [(update)]="updateStackedBarBreach" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
        </div>
      </div>
    </div>
    <div class="card-body p-t-1 p-0">
      <app-signal-list [(form)]="advisoryForm" [signals]="signals" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loading]="loading" [canSort]="page === signalsPage"
      (sortEmitter)="onSort($event)" (flagEmitter)="flag($event)"></app-signal-list>
    </div>
    <!-- 
      <div class="card-body p-t-1 p-0"
        *ngIf="userPreferenceService.userPreferences?.signalViewType === 'grid' && (page === signalsPage || page === flaggedPage)">
        <app-signal-grid [(form)]="advisoryForm" [signals]="signals" [flagRefs]="flagRefs" [flaggingRef]="flaggingRef" [loaded]="loaded"
          (flagEmitter)="flag($event);"></app-signal-grid>
      </div>
    -->
    <div class="row justify-content-center p-5" *ngIf="scrollLoading && (page === signalsPage || page === flaggedPage)">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">{{ 'pages.generic.loading' | translate }}</span>
      </div>
    </div>
  </div>
</div>